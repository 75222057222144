<template>
  <div class="push-list">
    <content-header title="Push 列表">
      <Button type="primary" icon="md-add" @click="createPushTrigger">创建推送</Button>
    </content-header>
    <div class="table-container">
      <div class="filter-box">
        <div class="filter-box-left">
          <RadioGroup v-model="table_filter.push_status" type="button" button-style="solid" @on-change="changeStatus">
            <Radio label="all">全部</Radio>
            <Radio label="executing">执行中</Radio>
            <Radio label="draft">草稿</Radio>
            <Radio label="paused">已暂停</Radio>
            <Radio label="completed">已完成</Radio>
          </RadioGroup>
        </div>
        <div class="filter-box-right">
          <Select v-model="table_filter.push_type"
                  placeholder="全部">
            <Option value='all'>全部</Option>
            <Option value='notification'>通知</Option>
            <Option value='message'>JSON</Option>
          </Select>
        </div>
      </div>
      <div class="table-wrap" ref="tableListWrap">
        <Table v-show="current_table_data.length > 0"
               :columns="columns"
               :data="current_table_data"
               @on-row-click="rowHandle"
               ref="pushList">
          <template slot-scope="{ row }" slot="push_type">
            <div style="display: flex;align-items: center;">
              <Tooltip :content="row.push_type === 'notification' ? '通知类型' : 'JSON 类型'" placement="top" transfer style="margin-right: 18px">
                <img v-if="row.push_type === 'notification'" class="push-type-icon table-icon" src="../../assets/image/ic_notification@2x.png" alt="notification">
                <img v-if="row.push_type === 'message'" class="push-type-icon table-icon" src="../../assets/image/ic_json@2x.png" alt="message">
              </Tooltip>
              <Tooltip :content="getScheduleTypeText(row.schedule_type)" placement="top" transfer>
                <img v-if="row.schedule_type === 'immediate'" class="schedule-type-icon table-icon" src="../../assets/image/ic_immediate@2x.png" alt="immediate">
                <img v-if="row.schedule_type === 'periodic_time'" class="schedule-type-icon table-icon" src="../../assets/image/ic_periodic_time@2x.png" alt="periodic_time">
                <img v-if="row.schedule_type === 'precise_time'" class="schedule-type-icon table-icon" src="../../assets/image/ic_precise_time@2x.png" alt="precise_time">
              </Tooltip>
            </div>
          </template>
          <template slot-scope="{ row }" slot="task_name">
            <Tooltip style="width: 100%" :content="row.task_name" :disabled="$tools.getStringPixel(row.task_name) < column_config.task_name.minWidth - 36" :maxWidth="200" placement="top" transfer>
              <div class="task-title">
                <a href="#" @click.prevent="jumpToPushDetail(row)">{{ row.task_name }}</a>
<!--                <a href="#" @click.prevent="editItemHandler(row, operationCheck(row, 'edit'))">{{ row.task_name }}</a>-->
              </div>
            </Tooltip>
          </template>
          <template slot-scope="{ row }" slot="group">
            <Tooltip style="width: 100%" :content="row.group" :disabled="$tools.getStringPixel(row.group) < column_config.group.minWidth - 36" :maxWidth="200" placement="top" transfer>
              <div class="task-title">
                <span href="#">{{ row.group }}</span>
              </div>
            </Tooltip>
          </template>
<!--          <template slot-scope="{ row }" slot="schedule_type">-->
<!--            <Tooltip :content="getScheduleTypeText(row.schedule_type)" placement="top" transfer>-->
<!--              <img v-if="row.schedule_type === 'immediate'" class="schedule-type-icon table-icon" src="../../assets/image/ic_immediate@2x.png" alt="immediate">-->
<!--              <img v-if="row.schedule_type === 'periodic_time'" class="schedule-type-icon table-icon" src="../../assets/image/ic_periodic_time@2x.png" alt="periodic_time">-->
<!--              <img v-if="row.schedule_type === 'precise_time'" class="schedule-type-icon table-icon" src="../../assets/image/ic_precise_time@2x.png" alt="precise_time">-->
<!--            </Tooltip>-->
<!--          </template>-->
          <template slot-scope="{ row }" slot="status">
            <span class="status-content">
              <span class="dot" :style="{ 'background-color': statusMap[row.status].color }"></span>
              <span class="status-text" :style="{ 'color': statusMap[row.status].color }">{{ statusMap[row.status].label }}</span>
            </span>
          </template>
          <template slot-scope="{ row }" slot="is_local_send">
            {{ row.is_local_send === 1 ? '本地时间' : 'UTC 时间' }}
          </template>
          <template slot-scope="{ row }" slot="operation">
            <div :style="{ 'text-align': 'center' }">
<!--              <span class="edit-btn operation-btn" :class="{ 'disabled-btn': !operationCheck(row, 'edit') }" @click="editItemHandler(row, operationCheck(row, 'edit'))">编辑</span>-->
<!--              <span class="operation-text">|</span>-->
              <Dropdown class="operation-dropdown" trigger="click" transfer>
                <span class="more-btn operation-btn">更多</span>
                <DropdownMenu class="push-list-dropdown-menu" slot="list" style="min-width: 60px;">
                  <DropdownItem name="copy" @click.native="copyItemHandler(row)">复制</DropdownItem>
                  <DropdownItem v-if="operationCheck(row, 'pause')" name="delete" @click.native="pauseItemHandler(row)">暂停</DropdownItem>
                  <DropdownItem v-if="operationCheck(row, 'recover')" name="recovery" @click.native="recoverItemHandler(row)">恢复</DropdownItem>
                  <DropdownItem v-if="operationCheck(row, 'delete')" name="delete" @click.native="deleteItemHandler(row)">删除</DropdownItem>
                  <DropdownItem v-if="row.status === 4" name="status_change" @click.native="changeItemStatusHandler(row)">更改状态</DropdownItem>
                  <DropdownItem name="send_report" @click.native="sendReport(row)">发送报告</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </template>
        </Table>
        <empty-content v-show="current_table_data.length === 0"/>
        <div class="page-box">
          <Page :total="filtered_table_data.length"
                :current.sync="page_num"
                :page-size="page_size"
                @on-page-size-change="pageHandle"
                show-sizer/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import ContentHeader from '../../components/page/content/ContentHeader.vue';
import PushListConfig from '../../push-common/page_config/PushListConfig.json';
import EmptyContent from '../../components/common/EmptyContent.vue';
import routerTrigger from '../../push-common/mixins/routerTrigger';

export default {
  name: 'PushList',
  components: { EmptyContent, ContentHeader },
  mixins: [routerTrigger],
  data() {
    return {
      table_filter: {
        push_type: ['all'],
        push_status: 'executing',
      },
      columns: [],
      table_data: [],
      total: 0,
      page_num: 1,
      page_size: 20,
      column_config: PushListConfig.column_config,
      statusMap: {
        0: {
          label: '已暂停',
          color: '#BBBCC9',
        },
        1: {
          label: '运行中',
          color: '#00C371',
        },
        2: {
          label: '运行中（即时）', // 即时任务的 运行中
          color: '#00C371',
        },
        3: {
          label: '已完成',
          color: '#24283c',
        },
        4: {
          label: '草稿',
          color: '#FFAA00',
        },
        5: {
          label: '等待开始',
          color: '#2978FF',
        },
        6: {
          label: '已结束',
          color: '#BBBCC9',
        },
      },
      current_push: null,
      status: 1,
      changeOptionStatus: false,
      optionVisible: false,
      rotation_list: [],
    };
  },
  computed: {
    tableListElement() {
      return this.$refs.tableListWrap;
    },
    filtered_table_data() {
      let tableData = this.table_data;
      if (this.table_filter.push_type !== 'all') {
        tableData = this.table_data.filter((item) => item.push_type === this.table_filter.push_type);
      }
      return tableData;
    },
    current_table_data() {
      const tableData = this.filtered_table_data;
      return tableData.slice((this.page_num - 1) * this.page_size, (this.page_num) * this.page_size);
    },
    normalRender() {
      const render = (h, p) => {
        const { key } = p.column;
        const value = p.row[key];
        if (!value) {
            return h('span', '-');
        }
        return h('span', value);
      };
      return render;
    },
    judgeRender() {
      const render = (h, p) => {
        const { key } = p.column;
        const value = p.row[key];
        return h('span', {
          style: {
            // eslint-disable-next-line no-undef
            display: 'none',
          },
        }, value ? '是' : '否');
      };
      return render;
    },
    dateRender() {
      const render = (h, p) => {
        const { key } = p.column;
        const value = p.row[key];
        if (!value) return h('span', '-');
        return h('span', value.slice(0, 10));
      };
      return render;
    },
  },
  methods: {
    async fetchData() {
      const loader = this.$loader(this.tableListElement).show();
      try {
        const results = await Promise.all([this.fetchRotationList(), this.fetchPushList()]);
        const data = results[0].data || [];
        const { header, list } = results[1];
        const map = new Map();
        data.forEach((item) => map.set(item.task_id, item));
        list.forEach((item) => { // 推送列表信息中添加分组相关信息
          // eslint-disable-next-line no-param-reassign
          item.group = map.has(item.task_id) ? map.get(item.task_id).group_name.group_name : null;
        });
        this.configTable(header, list);
      } finally {
        loader.hide();
      }
    },
    async fetchPushListData() {
      const loader = this.$loader(this.tableListElement).show();
      try {
        const result = await this.fetchPushList();
        const { header, list } = result;
        const map = new Map();
        this.rotation_list.forEach((item) => map.set(item.task_id, item));
        list.forEach((item) => { // 推送列表信息中添加分组相关信息
          // eslint-disable-next-line no-param-reassign
          item.group = map.has(item.task_id) ? map.get(item.task_id).group_name.group_name : null;
        });
        this.configTable(header, list);
      } finally {
        loader.hide();
      }
    },
    fetchRotationList() {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const promise = this.$api.getRotationInfo(urlParams)
        .then((response) => {
          const { group_info, data } = response.data;
          if (group_info) {
            this.rotation_list = data ? [...data] : [];
            return Promise.resolve({
              groupInfo: group_info,
              data,
            });
          }
          return Promise.reject(new Error('推送分组信息获取失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    fetchPushList() {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const params = {
        status: [],
      };
      if (this.table_filter.push_status === 'all') {
        params.status = JSON.stringify([0, 1, 2, 3, 4, 5, 6]);
      } else if (this.table_filter.push_status === 'executing') {
        params.status = JSON.stringify([1, 2, 5]);
      } else if (this.table_filter.push_status === 'paused') {
        params.status = JSON.stringify([0]);
      } else if (this.table_filter.push_status === 'draft') {
        params.status = JSON.stringify([4]);
      } else if (this.table_filter.push_status === 'completed') {
        params.status = JSON.stringify([3, 6]);
      }
      const promise = this.$api.getTaskList(urlParams, params);
      return promise.then((response) => {
        const { header, list } = response.data;
        if (list) {
          this.total = list.length;
          return Promise.resolve({
            header,
            list,
          });
        }
        return Promise.reject(new Error('推送列表获取失败'));
      }).catch((error) => {
        this.$Message.error(error.message);
        this.resetTableData();
      });
    },
    configTable(header, records) {
      this.columns = this.configColumns(header);
      this.table_data = this.configTableData(records);
    },
    configColumns(header) {
      const columns = [];
      // eslint-disable-next-line no-param-reassign
      header = header.filter((x) => x !== 'schedule_type');
      header.forEach((key) => {
        console.log(key);
        const keyConfig = this.column_config[key];
        console.log(key, keyConfig);
        const column = {
          key,
          title: keyConfig.label || key,
          fixed: keyConfig.fixed || null,
          width: keyConfig.width || undefined,
          minWidth: keyConfig.minWidth || this.column_config.minWidth,
          maxWidth: keyConfig.maxWidth || this.column_config.maxWidth,
          align: keyConfig.align || this.column_config.align,
          className: keyConfig.className || undefined,
          slot: keyConfig.slot || null,
          render: this[keyConfig.render] || undefined,
        };
        if (column.key !== 'auto') {
          columns.push(column);
        }
      });
      return columns;
    },
    configTableData(records) {
      return records;
    },
    resetTableData() { // 清空table数据
      this.table_data = [];
      this.page_num = 1;
    },
    getScheduleTypeText(scheduleType) {
      if (scheduleType === 'immediate') {
        return '即时推送';
      } if (scheduleType === 'periodic_time') {
        return '周期性推送';
      } if (scheduleType === 'precise_time') {
        return '定时推送';
      }
      return '未知推送';
    },
    fetchPushDetail(pushId, callback) { // 可以考虑将其放在CreatePush组件中请求
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: pushId,
      };
      const promise = this.$api.getTaskDetail(urlParams)
        .then((response) => {
          this.current_push = { ...response.data.task };
          callback.call(this);
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    jumpToPushDetail(row) {
      // this.$store.commit('setCurrentPushId', {
      //   current_push_id: row.task_id,
      // });
      this.$store.commit('setPushFormStatus', {
        push_form_status: 'edit', // Push 详情为只读状态
      });
      this.$store.commit('updatePushSpecificStatus', {
        push_specific_status: row.status,
      });
      this.$store.commit('updatePushScheduleType', {
        push_schedule_type: row.schedule_type,
      });
      this.$router.push({
        name: 'PushDetail',
        params: {
          app_id: this.$route.params.app_id,
          task_id: row.task_id,
          status: row.status,
          schedule_type: row.schedule_type,
        },
      });
    },
    createPushTrigger() {
      // 清除之前选中的PushId
      // this.$store.commit('setCurrentPushId', {
      //   current_push_id: null,
      // });
      // 清除之前选中的Push的详细信息
      this.$store.commit('setCurrentPush', {
        current_push: null,
      });
      // 设置Push表单为创建模式
      this.$store.commit('setPushFormStatus', {
        push_form_status: 'create',
      });
      this.$router.push({
        name: 'CreatePush',
      });
    },
    editItemHandler(row, editable) {
      if (!editable) {
        this.$Message.warning('该推送不满足可编辑条件');
        return;
      }
      // this.$store.commit('setCurrentPushId', {
      //   current_push_id: row.task_id,
      // });
      // 设置Push表单为编辑模式
      this.$store.commit('setPushFormStatus', {
        push_form_status: 'edit',
      });
      this.$router.push({
        name: 'CreatePush',
      });
    },
    copyItemHandler(row) {
      this.$router.push({
        name: 'CreatePush',
        query: {
          task_id: row.task_id,
        },
      });
    },
    operationCheck(row, operation) { // 验证当前项是否满足当前该操作的条件
      let result = false;
      if (operation === 'pause') {
        const pauseStatusList = [1, 2, 5]; // 可暂停的状态
        if ((row.schedule_type === 'periodic_time' || row.schedule_type === 'precise_time')
          && pauseStatusList.indexOf(row.status) !== -1) {
          result = true;
        }
      } else if (operation === 'edit') {
        if ((row.status === 4) // 草稿可以编辑
          || (row.status === 0) // 已暂停任务可以编辑，注意：草稿和已暂停任务编辑保存时不能修改其 status
          || (row.schedule_type === 'precise_time' && row.status === 5) // 定时任务也可以编辑
          || (row.schedule_type === 'periodic_time' && (row.status === 5 || row.status === 1))) { // 周期任务还未开始的，可以编辑
          result = true;
        }
      } else if (operation === 'delete') {
        if (row.status === 4
          || row.status === 5) { // 草稿和等待开始可以删除
          result = true;
        }
      } else if (operation === 'recover') {
        if (row.status === 0
          && ((row.schedule_type === 'precise_time' && new Date(row.push_time) > Date.now()) || row.schedule_type === 'periodic_time')) {
          result = true;
        }
      }
      return result;
    },
    pauseItemHandler(row) {
      const taskId = row.task_id;
      this.$Modal.confirm({
        title: '确定暂停该推送吗？',
        content: null,
        onOk: () => {
          this.pauseItem(taskId);
        },
        onCancel: () => {},
      });
    },
    pauseItem(taskId) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: taskId,
      };
      const promise = this.$api.pauseTask(urlParams)
        .then((response) => {
          const { result } = response.data;
          if (result === 'pause success') {
            this.$Message.success('暂停推送成功');
            return this.fetchPushListData();
          }
          return Promise.reject(new Error('暂停推送失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    recoverItemHandler(row) {
      const taskId = row.task_id;
      this.$Modal.confirm({
        title: '确定恢复该推送吗？',
        content: null,
        onOk: () => {
          this.recoverItem(taskId);
        },
        onCancel: () => {},
      });
    },
    recoverItem(taskId) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: taskId,
      };
      const promise = this.$api.recoverTask(urlParams)
        .then((response) => {
          const { result } = response.data;
          if (result === 'recover success') {
            this.$Message.success('恢复推送成功');
            return this.fetchPushListData();
          }
          return Promise.reject(new Error('恢复推送失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    deleteItemHandler(row) {
      const taskId = row.task_id;
      this.$Modal.confirm({
        title: '确定删除该推送吗？',
        content: null,
        onOk: () => {
          this.deleteItem(taskId);
        },
        onCancel: () => {},
      });
    },
    deleteItem(taskId) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: taskId,
      };
      const promise = this.$api.deleteTask(urlParams)
        .then((response) => {
          const { result } = response.data;
          if (result === 'delete success') {
            this.$Message.success('删除推送成功');
            return this.fetchPushListData();
          }
          return Promise.reject(new Error('删除推送失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    changeItemStatusHandler(row) {
      const taskId = row.task_id;
      this.$Modal.confirm({
        title: '确定修改为正式推送？',
        content: null,
        onOk: () => {
          this.changeItemStatus(taskId);
        },
        onCancel: () => {},
      });
    },
    changeItemStatus(taskId) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: taskId,
      };
      const params = {
        task_status: 1,
      };
      const promise = this.$api.updateTaskStatus(urlParams, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('草稿状态更新成功');
            return this.fetchPushListData();
          }
          return Promise.reject(new Error('草稿状态更新失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    rowHandle(data) {
      if (data.status === 0) {
        this.status = 0;
      } else {
        this.status = 1;
      }
    },
    pageHandle(data) {
      this.page_size = data;
    },
    changeStatus() {
      this.fetchPushListData();
    },
    sendReport(row) {
      this.$router.push({
        name: 'DataAnalysisDetail',
        params: {
          app_id: this.$route.params.app_id,
          task_id: row.task_id,
        },
        query: {
          task_name: row.task_name,
        },
      });
    },
  },
  watch: {
    'filtered_table_data.length': {
      handler(newVal) {
        const maxPageNum = Math.ceil(newVal / this.page_size) || 1;
        if (this.page_num > maxPageNum) {
          this.page_num = maxPageNum;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped lang="less">
  .push-list {
    .table-container {
      padding: 24px;
      background: #FFFFFF;
      border-radius: 4px;
      .filter-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .filter-box-left {
        }
        .filter-box-right {
          display: flex;
          .select {
            margin-right: 8px;
          }
        }
      }
      /deep/ .ivu-tooltip .ivu-tooltip-rel {
        vertical-align: middle;
      }
      .table-wrap {
        position: relative;
        min-height: 180px;
        .task-detail-link {
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          color: #2F54EB;
          width: 168px;
        }
        .task-title{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          a {
            font-size: 14px;
            width:inherit;
          }
        }
      }
      /deep/ .ivu-table {
        .ivu-table-cell-slot {
          text-align: center;
        }
        .is-local-send {
          .ivu-table-cell-slot {
            text-align: left;
          }
        }
        .table-icon {
          width: 20px;
          height: 20px;
          line-height: 20px;
          vertical-align: middle;
        }
        .status-content {
          .dot, .status-text {
            vertical-align: middle;
          }
          .dot {
            display: inline-block;
            width: 6px;
            height: 6px;
            line-height: 6px;
            margin-right: 6px;
            border-radius: 50%;
          }
          .status-text {
            font-size: 14px;
            font-weight: 400;
            color: #00C371;
            line-height: 20px;
          }
        }
        .operation-btn, .operation-text {
          vertical-align: middle;
        }
        .operation-btn {
          margin: 0 6px;
          font-size: 14px;
          font-weight: 400;
          color: #2978FF;
          line-height: 20px;
          cursor: pointer;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .disabled-btn {
          color: #BBBCC9;
          cursor: not-allowed;
        }
        .operation-text {
          width: 1px;
          height: 12px;
          line-height: 12px;
          color: #EAEBF7;
        }
        .operation-dropdown {
          margin-left: 6px;
          vertical-align: middle;
        }
      }
      .page-box {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
      }
    }
  }
</style>
