import chartJSON from '../page_config/color.json';
import getLineAndBarChartOption from '../../untils/getLineAndBarChartOption';
import getPieChartOption from '../../untils/getPieChartOptions';

export default {
  computed: {
    color_list() {
      return chartJSON.color_list;
    },
    tooltip_color_list() {
      return chartJSON.tooltip_color_list;
    },
    index_list_map() {
      return new Map();
    },
    dimension_list_map() {
      return new Map();
    },
  },
  methods: {
    getDimensionMap(dimensions) {
      const map = new Map();
      dimensions.forEach((dimension) => {
        map.set(dimension.value, dimension.label);
      });
      return map;
    },
    getLineChartData(chartName, xAxis, items, dimensionLabelMap) {
      let colorCount = 0;
      const seriesList = [];
      const indexInfoList = [];
      const chartType = 'line';
      items.forEach((indexItem, index) => {
        // 获取当前指标对应的字母和名字
        const currentLetter = this.$tools.getLetterByNumber(index);
        const currentName = indexItem.name;
        const indexInfoObj = {
          index_letter: currentLetter,
          index_name: this.index_list_map.get(currentName),
          // 数组搜索性能不佳
          dimension_list: [],
        };
        Object.keys(indexItem.data).forEach((dimensionValue) => {
          const seriesObj = {
            color: this.color_list[colorCount % this.color_list.length],
            tooltip_color: this.tooltip_color_list[colorCount % this.tooltip_color_list.length],
            index_letter: currentLetter,
            series_value: dimensionValue,
            series_label: dimensionLabelMap.get(dimensionValue),
            data: indexItem.data[dimensionValue],
          };
          seriesList.push(seriesObj);
          indexInfoObj.dimension_list.push({
            name: `${currentLetter}_${dimensionValue}`,
            dimensionValue,
            dimensionName: dimensionLabelMap.get(dimensionValue),
            color: this.color_list[colorCount % this.color_list.length],
            is_selected: true,
          });
          colorCount += 1;
        });
        indexInfoList.push(indexInfoObj);
      });
      return {
        name: chartName,
        type: chartType,
        x_axis: xAxis,
        series_list: seriesList,
        index_info_list: indexInfoList,
      };
    },
    getDualAxisChartData(chartName, xAxis, items, dimensionLabelMap) {
      let colorCount = 0;
      const seriesList = [];
      const indexInfoList = [];
      const chartType = 'dual_axis';
      items.forEach((indexItem, index) => {
        // 获取当前指标对应的字母和名字
        const currentLetter = this.$tools.getLetterByNumber(index);
        const currentName = indexItem.name;
        const indexInfoObj = {
          index_letter: currentLetter,
          index_name: this.index_list_map.get(currentName),
          // 数组搜索性能不佳
          dimension_list: [],
        };
        Object.keys(indexItem.data).forEach((dimensionValue) => {
          const seriesObj = {
            color: this.color_list[colorCount % this.color_list.length],
            tooltip_color: this.tooltip_color_list[colorCount % this.tooltip_color_list.length],
            index_letter: currentLetter,
            series_value: dimensionValue,
            series_label: dimensionLabelMap.get(dimensionValue),
            data: indexItem.data[dimensionValue],
          };
          seriesList.push(seriesObj);
          indexInfoObj.dimension_list.push({
            name: `${currentLetter}_${dimensionValue}`,
            dimensionValue,
            dimensionName: dimensionLabelMap.get(dimensionValue),
            color: this.color_list[colorCount % this.color_list.length],
            is_selected: true,
          });
          colorCount += 1;
        });
        indexInfoList.push(indexInfoObj);
      });
      return {
        name: chartName,
        type: chartType,
        x_axis: xAxis,
        series_list: seriesList,
        index_info_list: indexInfoList,
      };
    },
    getBarChartData(chartName, xAxis, items, dimensionLabelMap) {
      console.log(chartName, xAxis, items, dimensionLabelMap);
      let colorCount = 0;
      const seriesList = [];
      const indexInfoList = [];
      const chartType = 'bar';
      items.forEach((indexItem, index) => {
        // 获取当前指标对应的字母和名字
        const currentLetter = this.$tools.getLetterByNumber(index);
        const currentName = indexItem.name;
        const indexInfoObj = {
          index_letter: currentLetter,
          index_name: this.index_list_map.get(currentName),
          // 数组搜索性能不佳
          dimension_list: [],
        };
        Object.keys(indexItem.data).forEach((dimensionValue) => {
          const seriesObj = {
            color: this.color_list[colorCount % this.color_list.length],
            tooltip_color: this.tooltip_color_list[colorCount % this.tooltip_color_list.length],
            index_letter: currentLetter,
            series_value: dimensionValue,
            series_label: dimensionLabelMap.get(dimensionValue),
            data: indexItem.data[dimensionValue],
            is_stack: true,
          };
          seriesList.push(seriesObj);
          indexInfoObj.dimension_list.push({
            name: `${currentLetter}_${dimensionValue}`,
            dimensionValue,
            dimensionName: dimensionLabelMap.get(dimensionValue),
            color: this.color_list[colorCount % this.color_list.length],
            is_selected: true,
          });
          colorCount += 1;
        });
        indexInfoList.push(indexInfoObj);
      });
      console.log('series_list');
      console.log(seriesList);
      return {
        name: chartName,
        type: chartType,
        x_axis: xAxis,
        series_list: seriesList,
        index_info_list: indexInfoList,
      };
    },
    // getBarChartData(chartName, items, dimensionLabelMap) {
    //   const chartType = 'bar';
    //   const seriesList = [];
    //   const indexInfoList = [];
    //   const xAxis = Object.keys(items[0].data).map((key) => dimensionLabelMap.get(key));
    //   const yAxisData = {};
    //   items.forEach((indexItem) => {
    //     const itemName = indexItem.name;
    //     yAxisData[itemName] = [];
    //     Object.keys(items[0].data).forEach((dimension) => {
    //       yAxisData[itemName].push(indexItem.data[dimension].reduce((a, b) => a + b));
    //     });
    //   });
    //   items.forEach((indexItem, index) => {
    //     // 获取当前指标对应的字母和名字
    //     const currentLetter = this.$tools.getLetterByNumber(index);
    //     const currentName = indexItem.name;
    //     const indexInfoObj = {
    //       index_letter: currentLetter,
    //       index_name: this.index_list_map.get(currentName),
    //       name: `${currentLetter}_${currentName}`,
    //       is_selected: true,
    //       color: this.color_list[index % this.color_list.length],
    //       // 数组搜索性能不佳
    //       dimension_list: [],
    //     };
    //     Object.keys(indexItem.data).forEach((dimensionValue) => {
    //       indexInfoObj.dimension_list.push({
    //         dimensionValue,
    //         dimensionName: dimensionLabelMap.get(dimensionValue),
    //         is_selected: true,
    //       });
    //     });
    //     const seriesObj = {
    //       color: this.color_list[index % this.color_list.length],
    //       tooltip_color: this.tooltip_color_list[index % this.tooltip_color_list.length],
    //       index_letter: currentLetter,
    //       series_label: this.index_list_map.get(currentName),
    //       series_value: currentName,
    //       data: yAxisData[currentName],
    //     };
    //     seriesList.push(seriesObj);
    //     indexInfoList.push(indexInfoObj);
    //   });
    //   return {
    //     name: chartName,
    //     type: chartType,
    //     x_axis: xAxis,
    //     series_list: seriesList,
    //     index_info_list: indexInfoList,
    //   };
    // },
    getPieChartData(chartName, items, dimensionLabelMap) {
      const chartType = 'pie';
      const seriesList = [];
      const indexInfoList = [];
      items.forEach((item, index) => {
        const currentLetter = this.$tools.getLetterByNumber(index);
        const currentName = item.name;
        const dimensionData = item.data;
        const data = [];
        const dimensionList = [];
        // eslint-disable-next-line no-shadow
        Object.keys(dimensionData).forEach((dimension, index) => {
          const obj = {
            name: dimensionLabelMap.get(dimension),
            value: item.data[dimension].reduce((a, b) => a + b),
            color: this.color_list[index % this.color_list.length],
            tooltip_color: this.tooltip_color_list[index % this.tooltip_color_list.length],
          };
          const dimensionObj = {
            name: dimensionLabelMap.get(dimension),
            dimensionName: dimensionLabelMap.get(dimension),
            dimensionValue: dimension,
            color: this.color_list[index % this.color_list.length],
            is_selected: true,
          };
          data.push(obj);
          dimensionList.push(dimensionObj);
        });
        const seriesObj = {
          name: this.index_list_map.get(currentName),
          series_label: currentName,
          series_value: currentName,
          index_letter: currentLetter,
          data,
        };
        const newDimensionList = [];
        const newData = JSON.parse(JSON.stringify(data));
        // eslint-disable-next-line no-shadow
        newData.forEach((item) => {
          if (item.value === '-0.01219999999999999900.00280.01130.003200000') {
            // eslint-disable-next-line no-param-reassign
            item.value = -0.01219999999999999900;
          }
          // eslint-disable-next-line no-param-reassign
          item.value = Number(item.value);
        });
        for (let i = 0; i < newData.length - 1; i += 1) {
          for (let j = 0; j < newData.length - 1 - i; j += 1) {
            if (newData[j].value < newData[j + 1].value) {
              const temp = newData[j + 1];
              // eslint-disable-next-line no-param-reassign
              newData[j + 1] = newData[j];
              // eslint-disable-next-line no-param-reassign
              newData[j] = temp;
            }
          }
        }
        for (let i = 0; i < newData.length; i += 1) {
          for (let j = 0; j < dimensionList.length; j += 1) {
            if (newData[i].name === dimensionList[j].dimensionName) {
              newDimensionList.push(dimensionList[j]);
            }
          }
        }
        for (let i = 0; i < dimensionList.length; i += 1) {
          dimensionList[i] = newDimensionList[i];
        }
        const indexObj = {
          name: this.index_list_map.get(currentName),
          series_label: currentName,
          series_value: currentName,
          index_letter: currentLetter,
          dimension_list: dimensionList,
        };
        indexInfoList.push(indexObj);
        seriesList.push(seriesObj);
      });
      return {
        name: chartName,
        type: chartType,
        series_list: seriesList,
        index_info_list: indexInfoList,
      };
    },
    getChartConfiguration(type) {
      let config;
      if (type === 'line' || type === 'bar') {
        config = {
          grid: {
            top: '24px',
            bottom: '40px',
            left: '42px',
            right: '0px',
          },
        };
      } else if (type === 'pie') {
        config = {
          grid: {
            top: '20px',
            bottom: '0px',
            left: '20px',
            right: '20px',
          },
        };
      } else if (type === 'dual_axis') {
        config = {
          grid: {
            top: '30px',
            bottom: '45px',
            left: '60px',
            right: '60px',
          },
          legend: null,
        };
      }
      return config;
    },
    resizeChart(chart) {
      const cb = function () {
        if (chart) {
          chart.resize();
        }
      };
      // 绑定chart实例的自适应事件，绑定事件和解除事件写在一起方便维护
      window.addEventListener('resize', cb);
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', cb);
      });
    },
    drawChart(chartData, chartConfiguration) {
      console.log('drawChart');
      console.log(chartData, chartConfiguration);
      const { type } = chartData;
      try {
        const element = this.getChartElement(chartData.name);
        let currentSeriesIndex = 0; // y
        let currentDataIndex = 0; // x
        let option;
        if (type === 'line' || type === 'bar' || type === 'dual_axis') {
          option = getLineAndBarChartOption(chartData, chartConfiguration);
        } else {
          console.log(chartConfiguration);
          option = getPieChartOption(chartData, chartConfiguration);
        }
        const chart = this.$echarts.init(element);
        chart.on('mouseover', (params) => {
          currentSeriesIndex = params.seriesIndex;
          currentDataIndex = params.dataIndex;
          // eslint-disable-next-line no-param-reassign
          chartConfiguration.currentSeriesIndex = currentSeriesIndex;
          // eslint-disable-next-line no-param-reassign
          chartConfiguration.currentDataIndex = currentDataIndex;
          if (type === 'line' || type === 'bar' || type === 'dual_axis') {
            option = getLineAndBarChartOption(chartData, chartConfiguration);
          } else {
            console.log(chartConfiguration);
            option = getPieChartOption(chartData, chartConfiguration);
          }
        });
        chart.on('mouseout', () => {
          currentSeriesIndex = 0;
          currentDataIndex = 0;
        });
        this.resizeChart(chart);
        // $once监听自定义事件，只执行一次
        this.$once('hook:beforeDestroy', () => {
          chart.clear();
        });
        chart.setOption(option, true);
        return chart;
      } catch (e) {
        console.log(e);
        console.log(e.message);
        return null;
      }
    },
    chartDimensionSelectChangeHandler(chartInfo, dimensionGroup, newType) {
      let chartData;
      const {
        x_axis,
        items,
        dimensions,
      } = chartInfo.chart_data;
      if (newType) {
        // eslint-disable-next-line no-param-reassign
        chartInfo.type = newType;
      }
      const chartType = chartInfo.type;
      const dimensionLabelMap = this.getDimensionMap(dimensions);
      // eslint-disable-next-line no-underscore-dangle
      const clonedItems = this.$tools.deepClone(items);
      clonedItems.forEach((item) => {
        Object.keys(dimensionGroup).forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          item.data = this.$tools.getPartialObj(item.data, dimensionGroup[key].selected_dimension_list, true);
        });
        // eslint-disable-next-line no-param-reassign
        // item.data = this.$tools.getPartialObj(item.data, dimensionGroup[item.name].selected_dimension_list);
      });
      if (chartType === 'line') {
        chartData = this.getLineChartData(chartInfo.name, x_axis, clonedItems, dimensionLabelMap);
      } else if (chartType === 'bar') {
        chartData = this.getBarChartData(chartInfo.name, x_axis, clonedItems, dimensionLabelMap);
      } else if (chartType === 'pie') {
        chartData = this.getPieChartData(chartInfo.name, clonedItems, dimensionLabelMap);
      } else if (chartType === 'dual_axis') {
        chartData = this.getDualAxisChartData(chartInfo.name, x_axis, clonedItems, dimensionLabelMap);
      }
      // eslint-disable-next-line no-param-reassign
      chartInfo.index_data_list = chartData.index_info_list;
      const chartConfiguration = this.getChartConfiguration(chartType);
      let chart = null;
      this.$nextTick(() => {
        chart = this.drawChart(chartData, chartConfiguration);
        // eslint-disable-next-line no-param-reassign
        chartInfo.chart = chart;
      });
    },
  },
};
