<template>
  <div class="test-machine-form">
    <div class="form-container">
      <Form ref="testMachineForm" :model="test_machine_form" :rules="test_machine_form_rules" :label-width="110">
        <FormItem class="select-form-item" label="测试机" prop="test_machine">
          <Button class="refresh-icon-btn" @click="refreshTestMachineListHandler">
            <img class="refresh-icon" :class="{ 'spin-refresh-icon': refreshing_test_machine }" src="../../../../assets/image/ic_refresh@2x.png" alt="Refresh icon"/>
          </Button>
          <Select class="test-machine-select" v-model="test_machine_form.test_machine" filterable clearable style="width: 200px">
            <Option v-for="item in test_machine_list"
                    :key="item.id"
                    :value="item.fcm_token"
                    :label="item.remark"></Option>
          </Select>
          <Dropdown trigger="click" @on-click="dropdownHandler">
            <Button class="test-machine-btn" type="default">
              <img class="test-machine-icon" src="../../../../assets/image/ic_testmachine@2x.png" alt="test machine"/>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="edit">
                <img class="edit-icon dropdown-item-icon" src="../../../../assets/image/ic_edit@2x.png" alt="edit">
                <span class="edit-text dropdown-item-text">编辑</span>
              </DropdownItem>
              <DropdownItem name="create">
                <img class="delete-icon dropdown-item-icon" src="../../../../assets/image/ic_button_add_black@2x.png" alt="edit">
                <span class="delete-text dropdown-item-text">新增</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </FormItem>
        <FormItem>
          <Button type="primary" :disabled="!test_machine_form.test_machine" @click="sendTest">发送测试</Button>
        </FormItem>
      </Form>
    </div>
    <create-test-machine-modal
      v-model="create_test_machine_modal_visible"
      @create="createTestMachineHandler"/>
    <test-machine-list-modal
      v-model="test_machine_list_modal_visible"
      :test_machine_list="test_machine_list"
      @edit="editTestMachineHandler"
      @delete="deleteTestMachineHandler"/>
  </div>
</template>

<script>
import CreateTestMachineModal from '../create_push_modal/CreateTestMachineModal.vue';
import TestMachineListModal from '../create_push_modal/TestMachineListModal.vue';
import routerTrigger from '../../../../push-common/mixins/routerTrigger';

export default {
  name: 'TestMachineForm',
  components: { TestMachineListModal, CreateTestMachineModal },
  mixins: [routerTrigger],
  props: {
    push_type: String,
    notification_data: Object,
    message_data: String, // 也是字符串类型，应该和 ContentForm 保持一致
  },
  data() {
    return {
      test_machine_list: [],
      test_machine_form: {
        test_machine: '',
      },
      test_machine_form_rules: {
        // test_machine: [
        //   { required: true, message: '请选择测试机', trigger: 'change' },
        // ],
      },
      create_test_machine_modal_visible: false,
      test_machine_list_modal_visible: false,
      refreshing_test_machine: false,
    };
  },
  methods: {
    fetchData() {
      this.fetchTestMachineList();
    },
    fetchTestMachineList() {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const params = {
        remark: '',
      };
      const promise = this.$api.getTestMachineList(urlParams, params);
      promise.then((response) => {
          const { data } = response.data;
          this.test_machine_list = [...data];
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    refreshTestMachineListHandler() {
      this.refreshing_test_machine = true;
      const promise = this.fetchTestMachineList();
      promise.then((response) => {
        const { data } = response.data;
        if (Array.isArray(data)) {
          this.$Message.success('测试机刷新成功');
        }
      }).finally(() => {
        this.refreshing_test_machine = false;
      });
    },
    createTestMachineHandler(testMachineInfo) {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const params = {
        ...testMachineInfo,
      };
      const promise = this.$api.addTestMachine(urlParams, params)
        .then((response) => {
          const { msg } = response.data;
          if (msg === 'success') {
            this.$Message.success('新建测试机成功');
            // 重新获取测试机列表
            return this.fetchTestMachineList();
          }
          return Promise.reject(new Error('新建测试机失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    editTestMachineHandler() {
      // 子组件内已经发完编辑请求，外部更新测试机列表
      this.fetchTestMachineList();
    },
    deleteTestMachineHandler(testMachineId) {
      const targetIndex = this.test_machine_list.findIndex((item) => item.id === testMachineId);
      this.test_machine_list.splice(targetIndex, 1);
    },
    sendTest() {
      // 判断push_content的输入是否符合要求
      if (this.push_type === 'notification'
       && JSON.stringify(this.notification_data) !== '{}' && this.notification_data.title !== '' && this.notification_data.body !== '') {
        this.$emit('send-trigger', this.test_machine_form.test_machine, 'notification');
      } else if (this.push_type === 'message' && this.$tools.checkJSON(this.message_data)) {
        this.$emit('send-trigger', this.test_machine_form.test_machine, 'message');
      } else {
        this.$Message.warning('推送内容填写不完整或格式不正确');
      }
    },
    dropdownHandler(name) {
      if (name === 'create') {
        this.create_test_machine_modal_visible = true;
      } else {
        this.test_machine_list_modal_visible = true;
      }
    },
  },
  mounted() {
    this.fetchTestMachineList();
  },
};
</script>

<style scoped lang="less">
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .test-machine-form {
    width: 470px;
    .form-container {
      /deep/ .ivu-form-item-content {
        display: flex;
      }
      .select-form-item {
        margin-bottom: 16px;
        .refresh-icon-btn {
          line-height: 16px;
          padding: 8px;
          /*需要改进*/
          border-right-color: transparent;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          &:hover {
            border-right: 1px solid #57a3f3;
          }
          &:focus {
            z-index: 99;
          }
          .refresh-icon {
            width: 16px;
            height: 16px;
          }
          .spin-refresh-icon {
            transform-origin: center;
            animation: spin 0.4s infinite linear;
          }
        }
        .test-machine-select {
          position: relative;
          flex-grow: 1;
          margin-right: 8px;
        }
        /deep/ .ivu-select-selection {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .test-machine-btn {
          padding: 8px;
          .test-machine-icon {
            width: 16px;
            height: 16px;
          }
        }
        /deep/ .ivu-dropdown-item {
          .dropdown-item-text, .dropdown-item-icon {
            vertical-align: middle;
          }
          .dropdown-item-text {
            font-size: 14px;
            font-weight: 400;
            color: #1D1E26;
            line-height: 20px;
          }
          .dropdown-item-icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
      }
    }
    /deep/ .ivu-select-dropdown {
      max-width: 100%;
      overflow: hidden;
      .ivu-select-dropdown-list {
        .ivu-select-item {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
</style>
