export default {
  // app
  appListURL: '/apps',
  addAppURL: '/apps/add',
  updateAppURL: '/apps/update',
  deleteAppURL: '/apps/delete',
  appDetailURL: '/apps/{app_id}/app_detail',
  appInfoURL: '/apps/{app_id}/app_info',
  // 0 1 ?
  // task
  taskListURL: '/apps/{app_id}/tasks/all', //  /apps/A01/tasks/all?status=[1]
  taskDetailURL: '/apps/{app_id}/tasks/{task_id}/detail',
  addTaskURL: '/apps/{app_id}/tasks/add',
  updateTaskURL: '/apps/{app_id}/tasks/{task_id}/modify',
  pauseTaskURL: '/apps/{app_id}/tasks/{task_id}/pause',
  deleteTaskURL: '/apps/{app_id}/tasks/{task_id}/delete',
  recoverTaskURL: '/apps/{app_id}/tasks/{task_id}/recover',
  updateTaskStatusURL: '/apps/{app_id}/tasks/{task_id}/status',
  // temp task
  tempTaskListURL: '/apps/{app_id}/config/{task_id}/temp_rotation',
  tempTaskDetailURL: '/apps/{app_id}/config/temp_rotation/detail/{temp_task_id}',
  addTempTaskURL: '/apps/{app_id}/config/{task_id}/temp_rotation',
  updateTempTaskURL: '/apps/{app_id}/config/temp_rotation/update/{temp_task_id}',
  pauseTempTaskURL: '/apps/{app_id}/config/temp_rotation/delete/{temp_task_id}',
  addMessageTempTaskURL: '/apps/{app_id}/config/{task_id}/temp_message',
  pauseMessageTempTaskURL: '/apps/{app_id}/config/{task_id}/temp_message',
  // rotation: '/',
  rotationInfoURL: '/apps/{app_id}/config/rotation',
  addRotationURL: '/apps/{app_id}/config/rotation',
  updateRotationURL: '/apps/{app_id}/config/rotation/{rotation_id}',
  deleteRotationURL: '/apps/{app_id}/config/rotation/{rotation_id}',

  fileUploadURL: '/apps/upload',
  // group
  groupListURL: '/apps/{app_id}/config/groups',
  addGroupURL: '/apps/{app_id}/config/groups',
  updateGroupURL: '/apps/{app_id}/config/groups/{group_id}',
  deleteGroupURL: '/apps/{app_id}/config/groups/{group_id}',
  // template
  templateListURL: '/apps/{app_id}/config/{group_id}/templates',
  addTemplateURL: '/apps/{app_id}/config/templates',
  addMultiTemplateURL: '/apps/{app_id}/config/mul_templates',
  updateTemplateURL: '/apps/{app_id}/config/templates/{template_id}',
  deleteTemplateURL: '/apps/{app_id}/config/templates/{template_id}',
  // test machine
  testMachineListURL: '/apps/{app_id}/testing_machines',
  addTestMachineURL: '/apps/{app_id}/testing_machines',
  updateTestMachineURL: '/apps/{app_id}/testing_machines',
  deleteTestMachineURL: '/apps/{app_id}/testing_machines',
  notificationTestPushURL: '/apps/{app_id}/notification/test',
  messageTestPushURL: '/apps/{app_id}/message/test',
  analysisCountriesListURL: '/apps/{app_id}/deliver/countries',
  dataAnalysisURL: '/apps/{app_id}/deliver/index',
  dataAnalysisDetailURL: '/apps/{app_id}/deliver/{task_id}',
  funnelURL: '/apps/{app_id}/deliver/{task_id}/funnel_plot',
  analysisTableURL: '/apps/{app_id}/deliver/all_tasks_info',
  analysisDetailSub: '/apps/{app_id}/deliver/{task_id}/sub_jobs',
  analysisDetailData: '/apps/{app_id}/deliver/{task_id}/sub/{value}',
  analysisDetailFunnelData: '/apps/{app_id}/deliver/{task_id}/sub/{value}/funnel_plot',
  analysisDetailTableData: '/apps/{app_id}/deliver/{task_id}/sub_table',
  biApps: '/bi_apps',
  getEstimations: '/apps/{app_id}/get_estimations',
  getAppUserGroupsURL: '/apps/{app_id}/get_user_groups',
};
