import api from './api';

export default function uploadImage(imageFile, pid) { // 可以抽成全局函数
  const params = { file: imageFile };
  if (pid) {
    params.pid = pid;
  }
  const promise = api.fileUpload(params)
    .then((response) => {
      const { msg, url } = response.data;
      if (msg === 'success') {
        // then中的返回的新Promise实例的值为url
        return url;
      }
      return Promise.reject(new Error('哎呀，图标上传出错啦'));
    });
  return promise;
}
