<template>
  <Modal
    class="column-config-modal"
    :value="visible"
    :closable="true"
    width="600"
    @on-ok="okHandler"
    @on-cancel="cancelHandler">
    <div class="header" slot="header">
      <h1 class="header-title">{{ header_title }}</h1>
    </div>
    <div class="main">
      <div class="left-box">
        <h2 class="dimension-title title">维度</h2>
        <div class="dimension-list">
          <div
            class="item"
            :class="{
              'selected-item': selected_dimension_list.find((d) => d.value === item.value)
            }"
            v-for="(item) in dimensionList"
            :key="item.value"
            @click="selectItem(item, 'dimension')">
            <span class="item-text">{{ item.label }}</span>
          </div>
        </div>
      </div>
      <div class="right-box">
        <h2 class="index-title title">指标</h2>
        <transition-group tag="div" name="drag" class="index-list">
          <div
            class="item"
            :class="{
              'selected-item': selected_index_list.find((d) => d.value === item.value)
            }"
            v-for="(item, index) in indexList"
            :key="`${item.value}-${index}`"
            draggable
            @dragstart="dragStartHandler(index)"
            @dragenter="dragEnterHandler($event, index)"
            @dragover="dragOverHandler($event, index)"
            @click="selectItem(item, 'index')">
            <span class="item-text">{{ item.label }}</span>
          </div>
        </transition-group>
      </div>
    </div>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="primary" @click="okHandler">应用</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'ColumnConfigModal',
  props: {
    visible: {
      type: Boolean,
    },
    selectedDimensionList: {
      type: Array,
    },
    selectedIndexList: {
      type: Array,
    },
    dimensionList: Array,
    indexList: Array,
  },
  data() {
    return {
      header_title: '表格维度指标设置',
      selected_dimension_list: [],
      selected_index_list: [],
      current_index: null,
    };
  },
  methods: {
    selectItem(item, name) {
      const param = 'value';
      const value = item[param];
      const index = this[`selected_${name}_list`].findIndex((d) => d[param] === value);
      if (index !== -1) {
        if (this[`selected_${name}_list`].length > 1 && value !== 'date') {
          this[`selected_${name}_list`].splice(index, 1);
        }
      } else {
        this[`selected_${name}_list`].push({ ...item });
      }
    },
    okHandler() {
      const selectedDimensionList = [];
      const selectedIndexList = [];
      this.dimensionList.forEach((dimension) => {
        if (this.selected_dimension_list.find((item) => item.value === dimension.value)) {
          selectedDimensionList.push(dimension);
        }
      });
      this.indexList.forEach((index) => {
        if (this.selected_index_list.find((item) => item.value === index.value)) {
          selectedIndexList.push(index);
        }
      });
      this.$emit('confirm', selectedDimensionList, selectedIndexList);
    },
    cancelHandler() {
      this.$emit('cancel');
      this.selected_dimension_list = [...this.selectedDimensionList];
      this.selected_index_list = [...this.selectedIndexList];
    },
    dragStartHandler(index) {
      this.current_index = index;
    },
    dragEnterHandler(evt, index) {
      evt.preventDefault();
      if (this.current_index !== index) {
        const source = this.indexList[this.current_index];
        this.indexList.splice(this.current_index, 1);
        this.indexList.splice(index, 0, source);
        this.current_index = index;
      }
    },
    dragOverHandler(evt) {
      evt.preventDefault();
    },
  },
  watch: {
    selectedDimensionList: {
      handler(newVal) {
        this.selected_dimension_list = [...newVal];
      },
      immediate: true,
    },
    selectedIndexList: {
      handler(newVal) {
        this.selected_index_list = [...newVal];
      },
      immediate: true,
    },
  },
};
</script>
<style scoped lang="less">
@import '../../style/modal.less';
</style>
<style scoped lang="less">
@box-width: 50%;
@modal-padding-side: 32px;
@header-padding: 20px 32px;
@footer-padding: 24px @modal-padding-side;
@main-box-height: 300px;
.column-config-modal {
  .header {
    padding: @header-padding;
    .header-title {
      font-size: 20px;
      font-weight: 600;
      color: #202444;
      line-height: 28px;
    }
  }
  .main {
    display: flex;
    padding: 0 @modal-padding-side;
    background-color: #ffffff;
    .left-box, .right-box {
      padding: 16px;
      border: 1px solid #dcdee2;
    }
    .left-box {
      width: @box-width;
      height: @main-box-height;
      overflow-y: auto;
    }
    .right-box {
      width: @box-width;
      height: 300px;
      border-left: 0px;
      overflow-y: auto;
    }
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
    }
    .dimension-list, .index-list {
      display: flex;
      flex-direction: column;
      .item {
        position: relative;
        height: 36px;
        margin-bottom: 6px;
        padding: 0 16px;
        line-height: 34px;
        border: 1px solid #dcdee2;;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;
        transition: transform 0.2s ease;
        &:hover {
          background-color: #f6f8fc;
        }
        .item-text {
          font-size: 16px;
          font-weight: 400;
          color: #545778;
          /*???*/
          /*vertical-align: middle;*/
        }
      }
      .selected-item::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 100%;
        top: 0;
        left: 0;
        /*border-top-left-radius: 4px;*/
        /*border-bottom-left-radius: 4px;*/
      }
    }
    .dimension-list {
      .selected-item::before {
        background-color: #02B286;
      }
    }
    .index-list {
      .selected-item::before {
        background-color: #2978FF;
      }
    }
  }
  .footer {
    padding: @footer-padding;
  }
}
</style>
