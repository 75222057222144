export default {
  methods: {
    fetchData() {},
  },
  watch: {
    '$route.params.app_id': function () {
      this.$nextTick(() => {
        this.fetchData();
      });
    },
  },
};
