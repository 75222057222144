export default {
  requestURLParamParser(url, params) {
    const regx = /\{(\w+)\}/g;
    if (!regx.test(url)) return url;
    const newURL = url.replace(regx, (arg1, arg2) => params[arg2]);
    return newURL;
  },
  routeParamParser(url, param) {
    const regx = /\/:(\w+)\//g;
    const newURL = url.replace(regx, (arg1, arg2) => `/${param[arg2]}/`);
    return newURL;
  },
  imageFileToURL(file) {
    if (!file) return null;
    const URLObject = window.URL || window.webkitURL || window.mozURL;
    const url = URLObject.createObjectURL(file);
    console.log(url);
    return url;
  },
  dateFormatter(date) {
    if (typeof date === 'string') {
      return date;
    }
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    return `${year}-${month}-${day}`;
  },
  dateTimeFormatter(date) {
    if (typeof date === 'string') {
      return date;
    }
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    if (hour < 10) hour = `0${hour}`;
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  },
  deepCompare(obj1, obj2) {
    const type1 = Object.prototype.toString.call(obj1);
    const type2 = Object.prototype.toString.call(obj2);
    if (type1 !== type2) {
      return false;
    }
    if (type1 === '[object Object]' && type2 === '[object Object]') {
      return Object.keys(obj1).every((key) => this.deepCompare(obj1[key], obj2[key]));
    } if (type1 === '[object Array]' && type2 === '[object Array]') {
      return obj1.every((val, index) => this.deepCompare(val, obj2[index])) && obj1.length === obj2.length;
    }
    return obj1 === obj2;
  },
  formatJSON(str) {
    if (typeof str === 'string') {
      // 单引号双引号切换
      // eslint-disable-next-line no-param-reassign
      str = str.replace(/False|True/g, (s) => {
        if (s === 'True') {
          return true;
        }
        if (s === 'False') {
          return false;
        }
        return s;
      });
    }
    return str;
  },
  checkJSON(str) {
    if (typeof str === 'string') {
      // eslint-disable-next-line no-param-reassign
      str = str.replace(/False|True/g, (s) => {
        if (s === 'True') {
          return true;
        }
        if (s === 'False') {
          return false;
        }
        return s;
      });
      try {
        const obj = JSON.parse(str);
        if (typeof obj === 'object' && obj) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    }
    return false;
  },
  /**
   * 获取字符串长度
   * @param str {string}
   * @param fontSize {number}
   */
  getStringPixel(str, fontSize = 14) {
    const spanElm = document.createElement('span');
    spanElm.innerHTML = str;
    spanElm.style.fontSize = `${fontSize}px`;
    document.body.appendChild(spanElm);
    const width = spanElm.offsetWidth;
    document.body.removeChild(spanElm);
    return width;
    // const canvas = this.getStringPixel.canvas || (this.getStringPixel.canvas = document.createElement('canvas'));
    // const context = canvas.getContext('2d');
    // context.font = `${fontSize}px`;
    // const metrics = context.measureText(str);
    // return metrics.width;
  },
  percentageFormat(value, withTwoDecimals = false) {
    const number = Number(value);
    if (Number.isNaN(number) || number === 0) {
      return value;
    }
    if (number === -1) {
      return '';
    }
    const temp = number * 10000;
    return withTwoDecimals
      ? `${(number * 100).toFixed(2)}%`
      : `${(Math.round(temp) / 100)}%`;
  },
  numberOfDigit(number) {
    let temp = number;
    let result = 0;
    while (temp >= 1) {
      temp /= 10;
      // eslint-disable-next-line no-plusplus
      result++;
    }
    return result;
  },
  keepDecimals(number, digit = 2, keepDecimals = false) {
    if (typeof number !== 'number') {
      return number;
    }
    let selfDigit = digit;
    let selfKeepDecimals = keepDecimals;
    if (typeof digit === 'boolean') {
      selfDigit = 2;
      selfKeepDecimals = digit;
    }
    const temp = 10 ** selfDigit;
    const result = selfKeepDecimals
      ? (Math.round(number * temp) / temp).toFixed(selfDigit)
      : Math.round(number * temp) / temp;
    return result;
  },
  getLetterByNumber(number) {
    const temp = 65 + number;
    return String.fromCharCode(temp);
  },
  debounce(fn, delay) {
    let timeout = null;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        // eslint-disable-next-line prefer-rest-params
        fn.call(this, arguments);
      }, delay);
    };
  },
  /**
   * 判断表格中的该行数据是否需要筛选去除掉
   * @param row {Object}
   * @param excludeKeyList {Array}
   * @param emptyValue
   * @return {Boolean}
   */
  isEmptyRow(row, excludeKeyList = [], emptyValue = -1) {
    const keys = Object.keys(row);
    const set = new Set(excludeKeyList);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!set.has(key) && row[key] !== emptyValue) {
        return false;
      }
    }
    return true;
  },
};
