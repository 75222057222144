import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import 'blob.js';

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line no-plusplus,no-bitwise
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  // eslint-disable-next-line no-param-reassign
  sheetName = sheetName || 'sheet1';
  const workbook = {
    SheetNames: [sheetName],
    Sheets: {},
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  const wopts = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: 'binary',
  };
  const wbout = XLSX.write(workbook, wopts);
  FileSaver.saveAs(new Blob([s2ab(wbout)], {
    type: 'application/octet-stream',
  }), 'export.xlsx');
}

// function openDownloadDialog(url, saveName) {
//   if (typeof url === 'object' && url instanceof Blob) {
//     // eslint-disable-next-line no-param-reassign
//     url = URL.createObjectURL(url); // 创建blob地址
//   }
//   const aLink = document.createElement('a');
//   aLink.href = url;
//   aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
//   let event;
//   if (window.MouseEvent) event = new MouseEvent('click');
//   else {
//     event = document.createEvent('MouseEvents');
// eslint-disable-next-line max-len
//     event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
//   }
//   aLink.dispatchEvent(event);
// }
/**
 * tableArrayFormatter
 * @param columns {Array}
 * @param tableData {Array}
 */
function tableArrayFormatter(columns, tableData) {
  const keyArr = columns.map((column) => column.key);
  const arr = [];
  arr.push(keyArr);
  tableData.forEach((item) => {
    const tempArr = [];
    keyArr.forEach((key) => {
      tempArr.push(item[key]);
    });
    arr.push(tempArr);
  });
  return arr;
}
const exportXLSXFile = function (columns, tableData, options = {}) {
  const tableArray = tableArrayFormatter(columns, tableData);
  const workSheet = XLSX.utils.aoa_to_sheet(tableArray);
  if (JSON.stringify(options) !== '{}') {
    workSheet['!merges'] = options && options.merges;
    workSheet['!cols'] = options && options.cols;
  }
  sheet2blob(workSheet, '下载.xlsx');
};
export default {
  exportXLSXFile,
};
