<template>
  <div id="app">
    <app-layout>
      <keep-alive include="createPush">
        <router-view/>
      </keep-alive>
    </app-layout>
  </div>
</template>

<style lang="less">
@import './assets/less/top.less';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
  import AppLayout from './layouts/AppLayout.vue';

  export default {
    components: { AppLayout },
    created() {
      // 应用初始化完成，将 Loader 移除
      const loaderElm = document.querySelector('.init-loader-wrap');
      document.body.removeChild(loaderElm);
    },
  };
</script>
