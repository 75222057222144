<template>
  <div class="create-template">
    <div class="content-header">
      <Breadcrumb>
        <BreadcrumbItem :to="{ name: 'Template' }">消息组</BreadcrumbItem>
        <BreadcrumbItem>批量新增</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="operation-box">
      <div class="switch-box">
        <span class="switch-text">自动翻译</span>
        <i-switch v-model="auto_translation"/>
      </div>
      <div class="switch-box">
        <span class="switch-text">继承主图</span>
        <i-switch v-model="extend_image"/>
      </div>
    </div>
    <div class="template-form" ref="templateForm">
      <div class="template-list">
        <div v-for="(item, index) in template_list" :key="item.id" class="template-list-item">
          <basic-item-in-translation-and-template
            :translation-id="item.id"
            :enable_language="false"
            :title.sync="item.title"
            :content.sync="item.content"
            :image.sync="item.image"
            :url.sync="item.url"
            :addLanguageMode="item.addLanguageMode"
            :language_list="getLanguageList(index).filter(i => !i.selected || i.value === item.language)"
            @close-language="closeLanguageSelect"
            @add-language="addLanguageHandler"
            @add-translation="openLanguageSelect"
            @delete="deleteTemplateItemHandler(index)">
          </basic-item-in-translation-and-template>
          <div  v-for="(translationItem, translationIndex) in item.translation_info_list"
                :key="translationItem.id">
            <basic-item-in-translation-and-template
              :translation-id="translationItem.id"
              :title.sync="translationItem.title"
              :content.sync="translationItem.content"
              :image.sync="translationItem.image"
              :language.sync="translationItem.language"
              :url.sync="translationItem.url"
              :language_list="getLanguageList(index).filter(i => !i.selected || i.value === translationItem.language)"
              @delete="deleteTranslationItemHandler(index, translationIndex)">
            </basic-item-in-translation-and-template>
          </div>

        </div>

<!--        <basic-item-in-translation-and-template-->
<!--          v-for="(item, index) in template_list"-->
<!--          :key="`${item.title}-${index}`"-->
<!--          v-bind.sync="item"-->
<!--          :enable_language="false"-->
<!--          @delete="deleteTemplateItemHandler(index)"></basic-item-in-translation-and-template>-->
<!--        <basic-item-in-translation-and-template-->
<!--          v-show="create_template_item"-->
<!--          status="edit"-->
<!--          v-bind.sync="template_item"-->
<!--          :enable_language="false"-->
<!--          @add="addTemplateItemHandler"-->
<!--          @delete="deleteTemplateItemHandler(-1)"></basic-item-in-translation-and-template>-->
      </div>
      <translation-and-template-button @trigger="addTemplateItemTrigger">新增模板消息</translation-and-template-button>
    </div>
    <div class="submit-box">
      <Button type="primary" class="save-btn btn" :loading="saving" @click="saveHandler">
        <img v-if="!saving" class="save-icon" src="../../../assets/image/ic_save@2x.png" alt="save icon">
        <span v-if="!saving" class="save-text">保存</span>
        <span v-if="saving">保存中</span>
      </Button>
      <Button class="btn" :disabled="saving" @click="cancelHandler">取消</Button>
    </div>

  </div>
</template>

<script>
import TranslationAndTemplateButton from '../../../components/common/TranslationAndTemplateButton.vue';
import BasicItemInTranslationAndTemplate
  from '../../../components/common/BasicItemInTranslationAndTemplate.vue';

export default {
  name: 'CreateTemplate',
  components: { BasicItemInTranslationAndTemplate, TranslationAndTemplateButton },
  data() {
    return {
      template_list: [],
      template_item: {
        title: '',
        content: '',
        image: null, // 图片文件
        url: '', // 图片路径
        language: '',
        addLanguageMode: false,
        translation_info_list: [],
      },
      create_template_item: false,
      saving: false, // 正在上传保存中
      auto_translation: false,
      extend_image: false,
    };
  },
  computed: {
    templateFormElement() {
      return this.$refs.templateForm;
    },
    language_list() {
      const selectedLanguageList = this.template_list.map((item) => item.language);
      return this.$store.state.language_list.map((item) => ({
          ...item,
          selected: selectedLanguageList.includes(item.value),
        }));
    },
  },
  methods: {
    addTemplateItemTrigger() {
      // if (this.create_template_item) {
      //   this.$Message.warning('请先将当前消息模板填写完整');
      //   return;
      // }
      // this.create_template_item = true;
      const obj = {
        title: '',
        content: '',
        image: null, // 图片文件
        url: '', // 图片路径
        language: '',
        addLanguageMode: false,
        translation_info_list: [],
        id: this.generateRandomId(),
      };
      this.template_list.push(obj);
    },
    addTemplateItemHandler() {
      this.template_list.push({ ...this.template_item });
      // 重置
      this.template_item = this.$options.data().template_item;
      // 新增隐藏
      this.create_template_item = false;
    },
    deleteTemplateItemHandler(index) {
      if (index === -1) { // 删除新增的item
        this.create_template_item = false;
        // 重置
        this.template_item = this.$options.data().template_item;
      } else { // 不是最后新增的item
        // 直接从列表删除
        this.template_list.splice(index, 1);
      }
    },
    addTemplate(templateList) {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const params = {
        templates: templateList,
      };
      const promise = this.$api.addMultiTemplate(urlParams, params);
      return promise;
    },
    saveHandler() {
      const loader = this.$loader(this.templateFormElement).show();
      this.saving = true;
      // 首先将新增的所有图片文件上传至图片服务器
      const imageList = this.template_list.map((item) => item.image);
      const promiseArr = [];
      imageList.forEach((image, index) => {
        let promise;
        if (image) {
          promise = this.$uploadImage(image, this.$route.params.app_id);
        } else {
          // image不存在，就直接传url
          promise = Promise.resolve(this.template_list[index].url);
        }
        // 每个元素都有一个对应的promise，保证和下标对应
        promiseArr.push(promise);
      });
      Promise.all(promiseArr).then((urlArr) => {
        urlArr.forEach((url, index) => {
          const currentTemplate = this.template_list[index];
          // 更新url
          currentTemplate.url = url;
        });
        const templateParamList = this.template_list.map((template) => ({
          group_id: this.$route.params.group_id,
          title: template.title,
          body: template.content,
          image: template.url,
          localize_list: template.translation_info_list,
        }));
        return this.addTemplate(templateParamList);
      }).then((response) => {
        const { result } = response.data;
        if (result === 'success') {
          this.$Message.success('批量创建消息模板成功');
          this.$router.push({
            name: 'Template',
          });
          return null;
        }
        return Promise.reject(new Error('批量创建消息模板失败'));
      }).catch((error) => {
        this.$Message.error(error.message);
      })
      .finally(() => {
        loader.hide();
        this.saving = false;
      });
    },
    cancelHandler() {
      this.$router.push({
        name: 'Template',
      });
    },
    addLanguageHandler(id, val) {
      this.closeLanguageSelect(id);
      this.template_list.forEach((item) => {
        if (item.id === id) {
          const addLanguageList = [];
          // eslint-disable-next-line no-shadow
          val.forEach((languageItem) => {
            addLanguageList.push(this.language_list.find((i) => i.label === languageItem).value);
          });
          console.log(addLanguageList);
          // eslint-disable-next-line no-shadow
          addLanguageList.forEach((languageItem) => {
            const obj = {
              content: '',
              image: null,
              language: languageItem,
              title: '',
              url: '',
              id: this.generateRandomId(),
            };
            console.log(item.translation_info_list);
            // eslint-disable-next-line no-shadow
            item.translation_info_list.push(obj);
          });
        }
      });
    },
    generateRandomId() {
      const timestamp = Date.now().toString(36);
      const randomStr = Math.random().toString(36).substring(2, 8);
      const randomId = timestamp + randomStr;
      return randomId;
    },
    openLanguageSelect(val) {
      this.template_list.find((item) => item.id === val).addLanguageMode = true;
    },
    closeLanguageSelect(val) {
      this.template_list.find((item) => item.id === val).addLanguageMode = false;
    },
    deleteTranslationItemHandler(index, translationIndex) {
      this.template_list[index].translation_info_list.splice(translationIndex, 1);
    },
    localizeSingleTranslate(text, language, translateInfoItem, param) {
      console.log(text);
      console.log(language);
      if (!text) {
        // eslint-disable-next-line no-param-reassign
        translateInfoItem[param] = '';
        return Promise.resolve();
      }
      const promise = this.$translate(text, '', language)
        .then((response) => {
          const { data } = response.data;
          if (data) {
            const resultText = data.translations[0].translatedText;
            // eslint-disable-next-line no-param-reassign
            translateInfoItem[param] = resultText;
            return null;
          }
          return Promise.reject(new Error('哎呀，自动翻译出问题了'));
        });
      return promise;
    },
    localizeTranslateInfoItem(language, title, content) { // 翻译新增选项中的title和body
      console.log('title', title);
      console.log('body', content);
      if (!language) return Promise.resolve();
      const titlePromise = this.localizeSingleTranslate(title, language, this.template_item, 'title');
      const bodyPromise = this.localizeSingleTranslate(content, language, this.template_item, 'content');
      return Promise.all([titlePromise, bodyPromise]);
    },
    localizeTranslate() {
      // 将form_data中的文本根据列表项的语言逐一翻译并填充
      const titlePromiseList = [];
      const bodyPromiseList = [];
      this.template_list.forEach((item) => {
        const { title, content } = item;
        const languageList = item.translation_info_list.map((translationItem) => translationItem.language);
        languageList.forEach((language, index) => {
          const translateInfoItem = item.translation_info_list[index];
          const titlePromise = this.localizeSingleTranslate(title, language, translateInfoItem, 'title');
          const bodyPromise = this.localizeSingleTranslate(content, language, translateInfoItem, 'content'); // 注意这里属性值是content
          titlePromiseList.push(titlePromise);
          bodyPromiseList.push(bodyPromise);
        });
        const itemLanguage = item.translation_info_list.map((i) => i.language);
        let itemPromise = Promise.resolve();
        itemLanguage.forEach((i) => {
          if (i) {
            itemPromise = this.localizeTranslateInfoItem(i, title, content);
          }
        });
        Promise.all([...titlePromiseList, ...bodyPromiseList, itemPromise])
          .catch((error) => {
            this.$Message.error(error.message);
          });
      });
    },
    getLanguageList(index) {
      const selectedLanguageList = this.template_list[index].translation_info_list.map((item) => item.language);
      return this.$store.state.language_list.map((item) => ({
        ...item,
        selected: selectedLanguageList.includes(item.value),
      }));
    },
  },
  watch: {
    auto_translation(newVal) {
      if (newVal) { // 开启自动翻译
        this.localizeTranslate();
      } else {
        // 关闭自动翻译
      }
    },
    extend_image(newVal) {
      let url = ''; let image = null;
      if (newVal) { // 确认继承主图
        this.template_list.forEach((item) => {
          const hasImage = item.image || item.url; // 判断主图是否有image
          if (hasImage) {
            url = item.url;
            image = item.image;
            item.translation_info_list.forEach((translationItem) => {
              if (translationItem.url === '') {
                // eslint-disable-next-line no-param-reassign
                translationItem.url = url;
                // eslint-disable-next-line no-param-reassign
                translationItem.image = image;
              }
            });
          }
        });
      }
      // 否则都为空
      // 将当前列表项的所有图片以及新增项的图片改成和主图一致
      // this.translation_info_list.forEach((translationItem) => {
      //
      // });
      // this.translation_info_item.url = url;
      // this.translation_info_item.image = image;
    },
  },
};
</script>

<style scoped lang="less">
  .create-template {
    position: relative;
    padding-bottom: 48px;
    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      background: transparent;
      /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-link, /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-separator {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .template-form {
      position: relative;

      .template-list-item {
        padding: 8px;
        border-radius: 4px;
        background: #FFFFFF;
        margin-bottom: 16px;
      }
    }
    .submit-box {
      position: fixed;
      left: @sidebarWidth;
      right: 0;
      bottom: 0;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
      .btn {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
        .save-icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          vertical-align: middle;
        }
        .save-text {
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
          vertical-align: middle;
        }
      }
    }
  }
  .add-language-select {
    top: 80px !important;
  }
  .operation-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
    .switch-box {
      display: inline-block;
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
      .switch-text {
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
</style>
