import Vue from 'vue';
import Loader from '../../components/common/Loader.vue';

const LoaderComponent = Vue.extend(Loader);
const elm = document.createElement('div');

export default function (targetElm) {
  const loader = new LoaderComponent().$mount(elm);
  const parentElm = targetElm || document.body;
  return {
    show() {
      parentElm.appendChild(loader.$el);
      // 方便链式调用
      return this;
    },
    hide() {
      parentElm.removeChild(loader.$el);
      return this;
    },
  };
}
