<template>
  <div
    v-fullscreen="isFullscreen"
    ref="commonTable"
    class="common-table"
    :class="commonTableClassName"
  >
    <div class="table-header">
      <h2 class="table-title">{{ tableTitle }}</h2>
      <table-header-operation
        :columns="tableColumns"
        :table-data="filteredTableData"
        :totalShow="totalShow"
        @show-config-modal="column_config_modal_visible = true"
        @on-fullscreen-change="handleFullscreenChange"
      >
        <slot name="operation"></slot>
      </table-header-operation>
    </div>
    <div class="table-box">
      <vxe-table
        :ref="vxeTableRef"
        :data="filteredTableData"
        show-overflow="tooltip"
        :header-cell-class-name="headerCellClassName"
        :max-height="isFullscreen ? fullscreenTableHeight : maxHeight"
        :row-config="{ height: rowHeight }"
        :column-config="{ resizable: true }"
        :row-class-name="tableRowClassName"
        :sort-config="{
          multiple: true,
          chronological: true,
          showIcon: false,
          sortMethod: customSortMethod,
        }"
        :cell-class-name="warnDisplay"
        :checkbox-config="{ highlight: true }"
        border="full"
        @cell-mouseenter="handleCellMouseenter"
        @cell-mouseleave="handleCellMouseleave"
      >
        <vxe-colgroup
          v-for="(column) in tableColumns"
          :key="column.key"
          :field="column.children ? undefined : column.key"
          :title="column.title"
          :fixed="column.fixed"
          :align="column.align"
          :min-width="column.width"
          :formatter="handleType(column.render)"
          sortable
        >
          <vxe-column
            v-for="columnItem in column.children"
            :key="columnItem.value"
            :field="columnItem.value"
            :title="columnItem.label"
            :formatter="handleType(columnItem.render)"
            :min-width="columnItem.minWidth"
            sortable
          >
            <template #header="{ column }">
              <span class="left">
                <span class="custom-title">{{ column.title }}</span>
                <span class="custom-sort">
                  <Icon
                    class="custom-sort-up"
                    :class="{ 'active-custom-sort-up': column_sort[column.field] === 'asc' }"
                    type="md-arrow-dropup"
                    @click="columnSortChangeHandler(column, 'asc')"
                  />
                  <Icon
                    class="custom-sort-down"
                    :class="{ 'active-custom-sort-down': column_sort[column.field] === 'desc' }"
                    type="md-arrow-dropdown"
                    @click="columnSortChangeHandler(column, 'desc')"
                  />
                </span>
              </span>
              <span class="right">
                <Icon
                  class="custom-funnel"
                  :class="{
                    'active-custom-funnel': column_filter[column.field] && column_filter[column.field].filter&& column_filter[column.field].filter.length > 0 ,
                  }"
                  type="ios-funnel-outline"
                  @click="showColumnFilterModal(column)"
                />
              </span>
            </template>
          </vxe-column>
          <template #header="{ column }">
            <span class="left" :class="{ displayCenter: column.children !== undefined }">
              <span class="custom-title">{{ column.title }}</span>
              <span class="custom-sort" v-if="column.children === undefined">
                <Icon
                  class="custom-sort-up"
                  :class="{ 'active-custom-sort-up': column_sort[column.field] === 'asc' }"
                  type="md-arrow-dropup"
                  @click="columnSortChangeHandler(column, 'asc')"
                />
                <Icon
                  class="custom-sort-down"
                  :class="{ 'active-custom-sort-down': column_sort[column.field] === 'desc' }"
                  type="md-arrow-dropdown"
                  @click="columnSortChangeHandler(column, 'desc')"
                />
              </span>
            </span>
            <span class="right" v-if="column.children === undefined">
              <Icon
                class="custom-funnel"
                :class="{ 'active-custom-funnel': column_filter[column.field] && column_filter[column.field].filter&& column_filter[column.field].filter.length > 0  }"
                type="ios-funnel-outline"
                @click="showColumnFilterModal(column)"
              />
            </span>
          </template>
        </vxe-colgroup>
        <template #empty>
          <div style="min-height: 200px">
            <Loading v-if="!loading" />
            <empty-content v-if="loading" empty_text="表格数据为空~" />
          </div>
        </template>
      </vxe-table>
    </div>
    <column-filter-modal
      :visible="column_filter_visible"
      :column-type="
        this.selected_column.field ? this.column_type[this.selected_column.field] : 'type'
      "
      :filter-list="
        this.selected_column.field ? this.column_filter[this.selected_column.field].filter : []
      "
      :condition-value-list="
        this.selected_column.field ? this.column_filter[this.selected_column.field].valueList : []
      "
      @visible-change="columnFilterVisibleChangeHandler"
      @filter-change="filterChangeHandler"
    />
    <column-config-modal
      :visible="column_config_modal_visible"
      :dimension-list="dimensionList"
      :index-list="indexList"
      :selected-dimension-list="selected_dimension_list"
      :selected-index-list="selected_index_list"
      @confirm="columnConfigConfirmHandler"
      @cancel="column_config_modal_visible = false"
    />
  </div>
</template>
<script>
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import { throttle } from 'lodash';
// eslint-disable-next-line import/extensions
import { VXETable } from 'vxe-table';
import XEUtils from 'xe-utils';
import fullscreenMixin from '../../push-common/mixins/fullScreenMixins';
import TableHeaderOperation from './tableHeaderOperation.vue';
import ColumnFilterModal from './columnFilterModal.vue';
import ColumnConfigModal from './columnConfigModal.vue';
import EmptyContent from './emptyContent.vue';
import Loading from './loading.vue';

VXETable.formats.mixin({
  formatPercentageNumber({ cellValue }, digits = 2) {
    const a = XEUtils.toFixed(XEUtils.round(cellValue * 100, digits), digits);
    const res = cellValue === -1 ? '-' : `${a}%`;
    return res;
  },
  formatFixedNumber({ cellValue }, digits = 2) {
    const res = cellValue === -1 ? '-' : XEUtils.toFixed(XEUtils.round(cellValue, digits), digits);
    return res;
  },
  formatInvalidValue({ cellValue }) {
    const res = cellValue === -1 ? '-' : cellValue;
    return res;
  },
});
const CELL_MIN_WIDTH = 108;
const TYPE = 'type';
const NUMBER = 'number';
export default {
  name: 'CommonTable',
  components: {
    ColumnConfigModal,
    ColumnFilterModal,
    TableHeaderOperation,
    EmptyContent,
    Loading,
  },
  mixins: [fullscreenMixin],
  props: {
    tableTitle: String,
    columns: Array,
    data: Array,
    maxHeight: {
      type: Number,
      default: 600,
    },
    headerRowHeight: {
      type: Number,
      default: 44,
    },
    rowHeight: {
      type: Number,
      default: 36,
    },
    dimensionList: Array,
    indexList: Array,
    selectedDimensionList: {
      type: Array,
      default: () => new Array([]),
    },
    selectedIndexList: {
      type: Array,
      default: () => new Array([]),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyValue: {
      // 单元格为空时显示的值
      type: Number,
      default: -1,
    },
    hasTotalRow: {
      // 第一行是否为总计
      type: Boolean,
      default: false,
    },
    showNumberConfig: {
      type: Boolean,
      default: false,
    },
    table_index: {
      type: Number,
      default: 0,
    },
    totalShow: {
      type: Boolean,
      default: false,
    },
    countryList: {
      type: Array,
      default: () => new Array([]),
    },
  },
  data() {
    return {
      column_filter_visible: false,
      column_config_modal_visible: false,
      column_filter: {}, // key: column.key; value: [] 当前 key 的 filter
      column_sort: {}, // key: column.key; value: asc || des  支持多层级排序
      column_type: {}, // 每列值的类型：数字 || 字符串
      selected_column: {
        field: this.columns[0],
      }, // 当前选中的 column 项
      selected_dimension_list: [],
      selected_index_list: [],
      fullscreenTableHeight: 0,
      highlightRows: [],
      hoverRow: null,
      // tableTooltipConfig: {
      //   showAll: true,
      //   enterable: true,
      //   contentMethod: ({
      //                     type,
      //                     column,
      //                     row,
      //                     items,
      //                     _columnIndex,
      //                     // eslint-disable-next-line consistent-return
      //                   }) => {
      //     const { field } = column;
      //     let val = '';
      //     if (field === 'country') {
      //       // eslint-disable-next-line consistent-return,array-callback-return
      //       this.countryList.find((item) => {
      //         if (item.label === row?.country) {
      //           val = item.value;
      //         }
      //       });
      //       return val;
      //     }
      //     return '';
      //   },
      // },
    };
  },
  mounted() {
    console.log(this.loading);
    this.setFullscreenTableHeight();
    window.addEventListener('resize', this.resizeHandler);
    document.addEventListener('keyup', this.handleKeydown);
    if (this.$route.name === 'Taiji') {
      const html = document.querySelectorAll('.common-table');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < html.length; i++) {
        html[i].style.border = 'none';
        html[i].style.padding = 0;
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeydown);
    window.removeEventListener('resize', this.resizeHandler);
  },
  computed: {
    resizeHandler() {
      return throttle(this.setFullscreenTableHeight, 100);
    },

    dimensionMap() {
      return new Map(this.dimensionList.map((item) => [item.value, item]));
    },
    indexMap() {
      return new Map(this.indexList.map((item) => [item.value, item]));
    },
    commonTableClassName() {
      // eslint-disable-next-line no-underscore-dangle
      return `common-table-${this._uid}`;
    },
    vxeTableRef() {
      // eslint-disable-next-line no-underscore-dangle
      return `vxeTable${this._uid}`;
    },
    tableColumns() {
      const keyArr = [
        ...this.selected_dimension_list.map((dimension) => dimension.value),
        ...this.selected_index_list.map((index) => index.value),
      ];
      const set = new Set(keyArr);
      const dimensionSet = new Set(
        this.selected_dimension_list.map((dimension) => dimension.value),
      );
      const columns = this.columns.filter(
        (columnKey) => set.has(columnKey) || set.has(columnKey.key),
      );
      return columns.map((key) => {
        const isDimension = dimensionSet.has(key);
        // eslint-disable-next-line no-nested-ternary
        const config = isDimension
          ? this.dimensionMap.get(key)
          : key.children !== undefined
            ? this.indexMap.get(key.key)
            : this.indexMap.get(key);
        const obj = {
          // eslint-disable-next-line valid-typeof
          key: key.children !== undefined ? key.key : key,
          title: config.label || config.key, // 暂时
          render: config.render,
          width: config.width || null,
          align: config.align || 'left',
          minWidth: config.minWidth || CELL_MIN_WIDTH,
          maxWidth: config.maxWidth || null,
          fixed: dimensionSet.has(key) ? 'left' : null,
          children: config.children || null,
        };
        return obj;
      });
    },
    tableData() {
      if (this.hasTotalRow && this.data.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.data[0].date = '总计';
        // console.log(this.selected_dimension_list);
        // console.log(this.data[0]);
        const arr = [];
        this.selected_dimension_list.forEach((item) => {
          arr.push(item.value);
        });
        arr.forEach((item, index) => {
          if (index === 0) {
            this.data[0][item] = '总计';
          } else {
            this.data[0][item] = '-';
          }
        });
      }
      return this.data;
    },
    filteredTableData() {
      // 以 tableData 为基础，进行筛选处理
      let curTableData = this.tableData;
      Object.entries(this.column_filter).forEach(([columnKey, filterObj]) => {
        const valueType = this.column_type[columnKey]; // 获取当前列的数据类型
        const filters = filterObj.filter;
        curTableData = filters.reduce(
          (pre, cur) => this.conditionFilter(pre, columnKey, valueType, cur),
          curTableData,
        );
      });
      curTableData = curTableData.filter(
        (row) => !this.$tools.isEmptyRow(
          row,
          this.dimensionList.map((item) => item.value),
          this.empty_value,
        ),
      );
      return curTableData;
    },
  },
  methods: {
    handleCellMouseenter({ rowIndex }) {
      this.hoverRow = rowIndex;
    },
    handleCellMouseleave() {
      this.hoverRow = null;
    },
    handleKeydown(e) {
      if (this.hoverRow !== null) {
        if (e.code === 'KeyH') {
          const rowIndex = this.hoverRow;
          const index = this.highlightRows.indexOf(rowIndex);
          if (index === -1) {
            this.highlightRows.push(rowIndex);
          } else {
            this.highlightRows.splice(index, 1);
          }
        }
      }
    },
    conditionFilter(data, key, type, filter) {
      const { condition, value } = filter;
      let filteredData;
      if (type === TYPE) {
        // 类值
        switch (condition) {
          case 'include':
            filteredData = data.filter((item) => item[key] === value);
            break;
          case 'exclude':
            filteredData = data.filter((item) => item[key] !== value);
            break;
          default:
        }
      } else if (type === NUMBER) {
        // 数值
        switch (condition) {
          case 'greater':
            filteredData = data.filter((item) => item[key] > value);
            break;
          case 'less':
            filteredData = data.filter((item) => item[key] < value);
            break;
          case 'equal':
            filteredData = data.filter((item) => item[key] === value);
            break;
          default:
        }
      }
      return filteredData;
    },
    showColumnFilterModal(column) {
      this.column_filter_visible = true;
      this.selected_column = column;
    },
    headerCellClassName({ column }) {
      if (column.fixed) {
        return 'fixed-header-col';
      }
      return 'normal-header-col';
    },
    columnFilterVisibleChangeHandler(visible) {
      this.column_filter_visible = visible;
    },
    filterChangeHandler(filterList) {
      // 更新某个 column 的筛选项
      const targetField = this.selected_column.field;
      this.column_filter[targetField].filter = filterList;
    },
    columnConfigConfirmHandler(newDimensionList, newIndexList) {
      this.column_config_modal_visible = false; // 关闭配置框
      // 还需要比较两者的顺序
      const dimensionFlag = this.$tools.deepCompare(
        this.selected_dimension_list.map((item) => item.value),
        newDimensionList.map((item) => item.value),
      );
      const indexFlag = this.$tools.deepCompare(
        this.selected_index_list.map((item) => item.value),
        newIndexList.map((item) => item.value),
      );
      if (!dimensionFlag) {
        // 维度列表发生修改
        this.$emit('dimension-change', newDimensionList, newIndexList, this.table_index);
      }
      if (!indexFlag) {
        // 指标列表发生修改
        this.selected_index_list = [...newIndexList];
      }
    },
    updateColumnType(tableData) {
      // eslint-disable-next-line no-unused-vars
      const row = tableData.length ? tableData[0] : null;
      this.tableColumns.forEach((column) => {
        let type = NUMBER;
        if (column.children === null) {
          if (column.render === 'string') {
            type = TYPE;
          }
          this.$set(this.column_type, column.key, type);
        } else {
          column.children.forEach((col) => {
            if (col.render === 'string') {
              type = TYPE;
            }
            this.$set(this.column_type, col.value, type);
          });
        }
      });
      // this.columns.forEach((key) => {
      //   let type = TYPE;
      //   if (row && !Number.isNaN(Number(row[key]))) {
      //     type = NUMBER;
      //   }
      //   this.$set(this.column_type, key, type);
      // });
    },
    updateColumnFilter(tableData) {
      this.columns.forEach((key) => {
        let filterValueList;
        if (key.children === undefined) {
          const columnType = this.column_type[key];
          const tempArr = tableData.map((item) => item[key]);
          if (columnType === TYPE) {
            filterValueList = [...new Set(tempArr)];
          } else {
            filterValueList = [];
          }
          this.$set(this.column_filter, key, {
            valueList: filterValueList,
            filter: [],
          });
        } else {
          key.children.forEach((val) => {
            const columnType = this.column_type[val.key];
            const tempArr = tableData.map((item) => item[val.key]);
            if (columnType === TYPE) {
              filterValueList = [...new Set(tempArr)];
            } else {
              filterValueList = [];
            }
            this.$set(this.column_filter, val.key, {
              valueList: filterValueList,
              filter: [],
            });
          });
        }
      });
    },
    updateColumnSort() {
      this.columns.forEach((key) => {
        if (key.children === undefined) {
          this.$set(this.column_sort, key, null);
        } else {
          key.children.forEach((val) => {
            this.$set(this.column_sort, val.key, null);
          });
        }
      });
    },
    columnSortChangeHandler(column, sortType) {
      const formerSortType = column.order;
      let newSortType = sortType;
      if (formerSortType === newSortType) {
        newSortType = null;
      }
      this.column_sort[column.field] = newSortType;
      console.log(column);
      this.$refs[this.vxeTableRef].sort(column.field, newSortType);
    },

    customSortMethod(params) {
      let list = [];
      const { data, sortList } = params;
      // eslint-disable-next-line array-callback-return,consistent-return
      list = data.sort((a, b) => {
        const newSortList = [...sortList];
        let sortItem = newSortList.shift();
        if (b[this.selected_dimension_list[0].value] === '总计') {
          return 1;
        }
        while (sortItem) {
          const { field, order } = sortItem;
          const factor = order === 'asc' ? 1 : -1;
          if (a[field] !== b[field]) {
            if (field === 'label') {
              const aValue = Date.parse(a[field]);
              const bValue = Date.parse(b[field]);
              if (Number.isNaN(aValue) || Number.isNaN(bValue)) {
                return a[field].localeCompare(b[field]) * factor;
              }
              return (aValue - bValue) * factor;
            }
            console.log(a[field]);
            console.log(b[field]);
            if (typeof a[field] === 'string' || typeof b[field] === 'string') {
              let aValue = null;
              let bValue = null;
              if (typeof a[field] === 'string') {
                aValue = a[field].toLowerCase().charCodeAt(0) || 0;
              } else if (b[field]) {
                bValue = b[field].toLowerCase().charCodeAt(0) || 0;
              }
              if (aValue !== bValue) {
                return (aValue - bValue) * factor;
              }
              return a[field].localeCompare(b[field]) * factor;
            }
            return (a[field] - b[field]) * factor;
          }
          sortItem = newSortList.shift();
        }
      });
      return list;
    },

    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      const classNames = [];
      if (this.hasTotalRow && rowIndex === 0) {
        classNames.push('total-row');
      }
      if (this.highlightRows.includes(rowIndex)) {
        classNames.push('highlight-row');
      }
      if (this.hoverRow === rowIndex) {
        classNames.push('hover-row');
      }
      return classNames;
    },
    // eslint-disable-next-line consistent-return
    warnDisplay({ row, column }) {
      if (
        column.field === 'profit'
        || column.field === 'orders_disparity'
        || column.field === 'income_disparity'
      ) {
        if (row.profit < 0 || row.income_disparity < 0 || row.orders_disparity < 0) {
          return 'warnProfit';
        }
      }
      if (column.field === 'ads_revenue_delta') {
        if (row.ads_revenue_delta < 0 && row.ads_revenue_delta !== -1) {
          return 'warnProfit';
        }
        if (row.ads_revenue_delta >= 0) {
          return 'profit';
        }
      }
      if (column.field === 'impressions_delta') {
        if (row.impressions_delta < 0 && row.impressions_delta !== -1) {
          return 'warnProfit';
        }
        if (row.impressions_delta >= 0) {
          return 'profit';
        }
      }
      if (column.field === 'ecpm_delta') {
        if (row.ecpm_delta < 0 && row.ecpm_delta !== -1) {
          return 'warnProfit';
        }
        if (row.ecpm_delta >= 0) {
          return 'profit';
        }
      }
    },
    handleType(str) {
      if (str === 'percentage') {
        return 'formatPercentageNumber';
      }
      if (str === 'fixedTwoNumber') {
        return 'formatFixedNumber';
      }
      if (str === 'fixedThreeNumber') {
        return ['formatFixedNumber', 3];
      }
      if (str === 'Number') {
        return 'formatInvalidValue';
      }
      return null;
    },
    setFullscreenTableHeight() {
      const tableEl = this.$refs.commonTable;
      const tableStyle = window.getComputedStyle(tableEl);
      const tablePadding = parseFloat(tableStyle.paddingTop) + parseFloat(tableStyle.paddingBottom);
      const tableHeaderStyle = window.getComputedStyle(tableEl.querySelector('.table-header'));
      const tableHeaderHeight = parseFloat(tableHeaderStyle.height)
        + parseFloat(tableHeaderStyle.marginTop)
        + parseFloat(tableHeaderStyle.marginBottom);
      this.fullscreenTableHeight = window.innerHeight - tablePadding - tableHeaderHeight;
    },
  },
  watch: {
    columns: {
      handler(newVal) {
        if (newVal.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.selected_column.field = newVal[0];
        }
        this.updateColumnSort();
      },
      immediate: true,
    },
    tableData: {
      handler(newVal) {
        this.updateColumnType(newVal);
        this.updateColumnFilter(newVal);
        this.highlightRows = [];
      },
      immediate: true,
    },
    selectedDimensionList: {
      // 维度列需要外部传入绑定
      handler(newVal) {
        this.selected_dimension_list = newVal;
      },
      immediate: true,
    },
    indexList: {
      handler(newVal) {
        // 指标列默认全选
        if (this.selected_index_list.length === 0) {
          // 初始化情况
          this.selected_index_list = newVal;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.common-table {
  padding: 20px 24px;
  background: #ffffff;
  //border: 1px solid #eaebf7;
  //border-radius: 4px;
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .table-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #1d1e26;
    }
  }
  .table-box {
    position: relative;
     /deep/ .vxe-table {
      .fixed-header-col,
      .normal-header-col {
        padding: 11px 0;
        .vxe-cell {
          .vxe-cell--title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: #1d1e26;
            .left {
              display: flex;
              align-items: center;
              .custom-title {
                margin-right: 2px;
                line-height: 22px;
                vertical-align: middle;
                font-weight: 600;
              }
              .custom-sort {
                display: inline-block;
                width: 14px;
                height: 18px;
                vertical-align: middle;
                overflow: hidden;
                .custom-sort-up,
                .custom-sort-down {
                  display: block;
                  width: 16px;
                  height: 8px;
                  color: #c4c4c4;
                  cursor: pointer;
                  &:hover {
                    color: #718bac;
                  }
                }
                .active-custom-sort-up,
                .active-custom-sort-down {
                  color: #2b46bd;
                  &:hover {
                    color: #2b46bd;
                  }
                }
              }
            }
            .right {
              .custom-funnel {
                color: #c4c4c4;
                cursor: pointer;
                &:hover {
                  font-weight: 600;
                  color: #5d7092;
                }
              }
              .active-custom-funnel {
                color: #2b46bd;
                font-weight: 800;
                &:hover {
                  color: #2b46bd;
                }
              }
            }
          }
          .displayCenter {
            margin: 0 auto !important;
          }
        }
      }
      .fixed-header-col {
        background-color: #d1daeb;
      }
      .normal-header-col {
        background-color: #f3f5fa;
      }
      .total-row {
        background-color: #e4e5f0;
      }
      .vxe-body--row {
        .highlight-icon-cell {
          position: relative;
        }
        .highlight-icon {
          background: url("../../assets/image/ic_highlight_default@2x.png") center/contain;
          width: 18px;
          height: 18px;
          position: absolute;
          right: 0;
          top: 50%;
          transform:translate(0,-50%);
          cursor: pointer;
          visibility: hidden;
        }
        &.hover-row {
          .highlight-icon {
            visibility: visible;
          }
        }
        &.highlight-row {
          background-color: #fff2df;
          &.hover-row {
            .highlight-icon {
              background-image: url("../../assets/image/ic_unhighlight_default@2x.png");
            }
          }
        }
      }
      .roi-container,
      .ltv-container,
      .top-container {
        .rate-text,
        mean-text {
          line-height: 16px;
          font-size: 14px;
        }
        .amount-text,
        .total-text {
          line-height: 14px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
  /deep/ .warnProfit {
    color: #f94d71 !important;
  }
  /deep/ .profit {
    color: #05b388 !important;
  }
}
.is-fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  z-index: 999 !important;
}
</style>
