import toolFunctions from '../share/toolFunctions';
// import toolTipFormatter from './toolTipFormatter';
const getPieChartOption = (chartData, configuration) => {
  console.log(chartData);
  console.log(configuration);
  const tooltipColorLength = configuration.tooltip_color.length;
  const tooltipFormatter = () => (params) => {
    const { name, value, percent } = params;
    const index = chartData.data.findIndex((item) => item.name === name);
    const tooltipColor = configuration.tooltip_color[index % tooltipColorLength];
    const number = Number(value);
    // let str = `<div class="tooltip">
    //               <h4 class="tooltip-title">${name}:</h4>`;
    let str = '<div class="tooltip">';
    str += `<p class="tooltip-content">
                      <span class="tooltip-text" style="color: ${tooltipColor}!important;">${name}:</span>
                      <span class="tooltip-text" style="color: ${tooltipColor}!important;">${toolFunctions.keepDecimals(number)}(${percent}%)</span>
                  </p>`;
    str += '</div>';
    return str;
  };
  const option = {
    legend: configuration.legend || {
      top: '1%',
      left: '0',
      icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
    },
    tooltip: {
      trigger: 'item',
      // axisPointer: {
      //   type: 'none',
      // },
      formatter: tooltipFormatter(),
      renderMode: 'html',
      backgroundColor: '#fffff',
      borderWidth: 0,
      extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0)',
    },
    grid: configuration.grid || {
      top: '8%',
      bottom: '8%',
      left: '3%',
      right: '3%',
    },
    series: {
      type: 'pie',
      center: configuration.center || ['50%', '50%'],
      radius: configuration.radius || '80%',
      data: chartData.data,
      label: {
        show: true,
        position: configuration?.label?.position || 'outside',
        formatter: (params) => {
          const { name, percent } = params;
          if (percent < 5) return '';
          return `${name}: ${toolFunctions.keepDecimals(percent)}%`;
        },
      },
    },
    color: configuration.color,
  };
  return option;
};

export default getPieChartOption;
