<template>
  <div class="table-header-operation">
    <div class="operation-item">
      <slot />
    </div>
    <div v-if="$slots.default" class="gap"></div>
    <div class="operation-item show-number-checkbox-item" v-if="showNumberDisplay && totalShow">
      <checkbox :value="showNumber" @on-change="showNumberChangeHandler"></checkbox>
      <span class="checkbox-text">显示数字</span>
    </div>
    <div class="gap" v-if="showNumberDisplay && totalShow"></div>
    <div class="operation-item edit-operation-item" @click="showColumnConfig" v-if="advancedReport">
      <Icon class="edit-icon" type="md-create" size="16" color="#505473" />
      <span class="edit-text">设置</span>
    </div>
    <div class="gap" v-if="advancedReport"></div>
    <div class="operation-item export-operation-item" @click="exportData">
      <img
        class="export-icon"
        src="../../assets/image/ic_list_export@2x.png"
        alt="export"
        width="16"
      />
      <span class="export-text">导出</span>
    </div>
    <div class="gap"></div>
    <div class="operation-item fullscreen-item" @click="toggleFullscreen">
      <FullscreenIcon></FullscreenIcon>
    </div>
  </div>
</template>

<script>
import FullscreenIcon from '../FullscreenIcon.vue';
import xlsx from '../../untils/xlsx';

export default {
  name: 'TableHeaderOperation',
  components: { FullscreenIcon },
  props: {
    columns: Array,
    tableData: Array,
    showNumber: Boolean,
    totalShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      advancedReport: true,
      showNumberDisplay: false,
    };
  },
  methods: {
    exportData() {
      if (this.columns.length === 0) {
        this.$CommonMessage.warning('表格内容为空');
        return;
      }
      const columnsCopy = JSON.parse(JSON.stringify(this.columns));
      const tableData = JSON.parse(JSON.stringify(this.tableData));
      const tableDataNew = [];
      for (let i = 0; i < tableData.length; i += 1) {
        const newObj = {};
        Object.keys(tableData[i]).forEach((key) => {
          columnsCopy.forEach((item) => {
            if (item.key === key) {
              if (tableData[i][key] === -1 || tableData[i][key] === '') {
                tableData[i][key] = '-';
              }
              newObj[item.title] = tableData[i][key];
            }
          });
        });
        tableDataNew.push(newObj);
      }
      columnsCopy.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.key = item.title;
      });
      xlsx.exportXLSXFile(columnsCopy, tableDataNew, {});
    },
    showColumnConfig() {
      this.$emit('show-config-modal');
    },
    showNumberChangeHandler(value) {
      this.$emit('update:showNumber', value);
    },
    toggleFullscreen() {
      this.$emit('on-fullscreen-change');
    },
  },
  created() {
    if (this.$route.name === 'profitReport' || this.$route.name === 'AdsReport' || this.$route.name === 'ROI' || this.$route.name === 'LTV' || this.$route.name === 'Taiji' || this.$route.name === 'AdsProfitOverview') {
      this.advancedReport = false;
    }
    if (this.$route.name === 'ROI' || this.$route.name === 'LTV' || this.$route.name === 'Taiji') {
      this.showNumberDisplay = true;
    }
  },
};
</script>

<style scoped lang="less">
.table-header-operation {
  display: flex;
  align-items: center;
  .operation-item {
    margin: 0 16px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .edit-operation-item {
    cursor: pointer;
    .edit-icon {
      margin-right: 4px;
      line-height: 16px;
      vertical-align: middle;
      color: #333440;
    }
    .edit-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      vertical-align: middle;
      color: #333440;
    }
  }
  .export-operation-item {
    cursor: pointer;
    height: 22px;
    .export-icon {
      width: 16px;
      margin-right: 4px;
      line-height: 16px;
      vertical-align: middle;
      color: #333440;
    }
    .export-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      vertical-align: middle;
      color: #333440;
    }
  }
  .gap {
    width: 1px;
    height: 16px;
    background-color: #d5dbe5;
  }
}
</style>
