import requestFactory from './requestFactory';
import urls from './urls';
import toolFunctions from '../share/toolFunctions';

const GET = 'get';
// const POST = 'post';
// const PUT = 'put';
// const DELETE = 'delete';
const getRequest = requestFactory(GET);
// const postRequest = requestFactory(POST);
// const putRequest = requestFactory(PUT);
// const deleteRequest = requestFactory(DELETE);

const { requestURLParamParser } = toolFunctions;

export default {
  getCountryData: (urlParams) => {
    const newURL = requestURLParamParser(urls.analysisCountriesListURL, urlParams);
    return getRequest(newURL);
  },
  getConditionData: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.dataAnalysisURL, urlParams);
    return getRequest(newURL, params);
  },
  getAnalysisDetailData: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.dataAnalysisDetailURL, urlParams);
    return getRequest(newURL, params);
  },
  getFunnelData: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.funnelURL, urlParams);
    return getRequest(newURL, params);
  },
  getTableData: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.analysisTableURL, urlParams);
    return getRequest(newURL, params);
  },
  getDetailTableSubData: (urlParams) => {
    const newURL = requestURLParamParser(urls.analysisDetailSub, urlParams);
    return getRequest(newURL);
  },
  getDetailData: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.analysisDetailData, urlParams);
    return getRequest(newURL, params);
  },
  getDetailFunnelData: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.analysisDetailFunnelData, urlParams);
    return getRequest(newURL, params);
  },
  getDetailTableData: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.analysisDetailTableData, urlParams);
    return getRequest(newURL, params);
  },
};
