/* eslint-disable no-tabs */
/**
 * 获取折线图和柱状图的option
 * chartData: {
 *   name: chartName,
     x_axis: xAxis,
     series_list: seriesList,
     index_info_list: indexInfoList,
 * }
 * seriesObject: {
 *   color,
     tooltip_color,
     index_letter: currentLetter,
     series_value: dimensionValue,
     series_label: dimensionLabelMap.get(dimensionValue),
     data: indexItem.data[dimensionValue],
 * }
 * configuration: {
 *   grid,
 * }
 * @param {Object} chartData
 * @param {Object} configuration
 * @return {Object}
 */
import toolFunctions from '../share/toolFunctions';

const DUAL_AXIS = 'dual_axis';

const getLineAndBarChartOption = (chartData, configuration) => {
  console.log(chartData, configuration);
  const {
    // name,
    type,
    // eslint-disable-next-line camelcase
    x_axis,
    // eslint-disable-next-line camelcase
    series_list,
    // eslint-disable-next-line camelcase
    index_info_list,
  } = chartData;
  const indexLength = index_info_list.length;
  const dimensionLength = index_info_list[0]?.dimension_list.length || 0;
  const series = [];
  series_list.forEach((seriesItem, index) => {
    const {
      color,
      // tooltip_color,
      // eslint-disable-next-line camelcase
      index_letter,
      // eslint-disable-next-line camelcase
      series_value,
      // series_label,
      // eslint-disable-next-line camelcase
      is_stack,
      data,
    } = seriesItem;
    let temp;
    if (type === DUAL_AXIS) {
      // 多轴线图
      temp = {
        name: `${index_letter}_${series_value}`,
        stack: null,
        type: index === 0 ? 'bar' : 'line',
        itemStyle: {
          color,
          opacity: 0.6,
        },
        lineStyle: {
          width: 2,
          color,
        },
        barMaxWidth: 24,
        symbol: 'emptyCircle',
        showSymbol: false,
        showAllSymbol: false,
        // 第一条记录为柱形，柱形对应右侧的y轴
        yAxisIndex: index === 0 ? 1 : 0,
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            opacity: 0.6,
          },
          lineStyle: {
            opacity: 0.36,
          },
        },
        data,
      };
    } else {
      // 折线图或柱状图
      temp = {
        name: `${index_letter}_${series_value}`,
        stack: is_stack ? `bar_${index_letter}` : null,
        type,
        itemStyle: {
          color,
        },
        lineStyle: {
          width: 2,
          color,
        },
        barMaxWidth: 24,
        symbol: 'emptyCircle',
        showSymbol: false,
        showAllSymbol: false,
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            opacity: 0.6,
          },
          lineStyle: {
            opacity: 0.36,
          },
        },
        data,
      };
    }
    series.push(temp);
  });
  const yAxis = [];
  const yAxisBasicItem = {
    axisLabel: {
      margin: 16,
      fontSize: '12px',
      color: '#878AA2',
      formatter: (value) => {
        const numberValue = Number(value);
        const numberCount = toolFunctions.numberOfDigit(numberValue);
        let result = value;
        if (!Number.isNaN(numberValue)) {
          if (numberCount > 6) {
            result = `${numberValue / 1000000}M`;
          } else if (numberCount > 3) {
            result = `${numberValue / 1000}K`;
          }
        }
        return result;
      },
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: type !== DUAL_AXIS,
      lineStyle: {
        color: '#cdcedb',
        type: 'dashed',
      },
    },
    nameLocation: 'middle',
    nameGap: 36,
    nameTextStyle: {
      fontSize: 14,
      fontWeight: 400,
      color: '#545878',
    },
    // max: (value) => {
    //   const { max } = value;
    //   // const absMax = Math.max(Math.abs(max), Math.abs(min));|
    //   const absMax = max;
    //   return Math.ceil(absMax * 1.2);
    // },
    // min: (value) => {
    //   const { min } = value;
    //   // const absMin = Math.max(Math.abs(max), Math.abs(min));
    //   const absMin = min;
    //   return -Math.floor(absMin * 1.2);
    // },
  };
  if (type === DUAL_AXIS) {
    const leftItem = {
      name: '折线图',
      ...yAxisBasicItem,
    };
    const rightItem = {
      name: '柱状图',
      nameRotate: 270,
      ...yAxisBasicItem,
    };
    yAxis.push(leftItem, rightItem);
  } else {
    yAxis.push(yAxisBasicItem);
  }
  const option = {
    title: {
      show: false,
    },
    toolbox: configuration.toolbox || {
      show: false,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      appendToBody: true,
      formatter: configuration.formatterFactory
        ? configuration.formatterFactory()
        : function (params) {
          let key = -99999;
          let targetItem = null;
          let targetIndex = -1;
          params.forEach((item) => {
            if (configuration.currentSeriesIndex === item.seriesIndex && configuration.currentDataIndex === item.dataIndex) {
              key = toolFunctions.keepDecimals(item.value);
            }
          });
          // eslint-disable-next-line no-shadow
          const { name, dataIndex } = params[0];
          let str = `<div class="tooltip">
                      <h4 class="tooltip-title">${name}:</h4>
                      <div class="index-list">`;
          if (indexLength > 0 && dimensionLength === 1) {
            // 多指标，单维度的情况，tooltip需要垂直方向显示
            str += '<div class="index-item"><div class="series-list">';
            const seriesList = series_list;
            const currentArr = [];
            // eslint-disable-next-line no-shadow
            seriesList.forEach((series, index) => {
              const obj = {
                tooltip_color: series.tooltip_color,
                label: index_info_list[index].index_name,
                data: toolFunctions.keepDecimals(series.data[dataIndex]),
                index_letter: series.index_letter,
              };
              currentArr.push(obj);
            });
            for (let i = 0; i < currentArr.length; i += 1) {
              if (currentArr[i].data === key) {
                targetItem = currentArr[i];
                targetIndex = i;
              }
            }
            if (targetIndex >= 10) {
              currentArr.unshift(targetItem);
              targetIndex = 0;
            }
            // eslint-disable-next-line no-shadow
            currentArr.forEach((item, index) => {
              if (index === targetIndex) {
                str += `<div class="series-item">
								<span class="left-side">
										<span class="letter-text" style="background-color: ${item.tooltip_color}; font-weight: 600;">${item.index_letter}</span>
										<span class="series-name" style="font-weight: 600; font-size: 14px">${item.label}</span>
								</span>
								<span class="series-value" style="font-weight: 600; font-size: 14px">${item.data}</span>
							</div>`;
              } else {
                str += `<div class="series-item">
								<span class="left-side">
										<span class="letter-text" style="background-color: ${item.tooltip_color}">${item.index_letter}</span>
										<span class="series-name">${item.label}</span>
								</span>
								<span class="series-value">${item.data}</span>
							</div>`;
              }
            });
            str += '</div></div>';
          } else {
            let seriesOrderList = [];
            // 数据项的显示最好还是依据params进行循环创建
            // 因为涉及到触发action时tooltip内容的更新
            // eslint-disable-next-line no-restricted-syntax
            for (const [currentIndex, item] of index_info_list.entries()) {
              const { index_letter } = item;
              // eslint-disable-next-line no-shadow
              const subSeriesList = series_list.filter((series, index) => {
                const flag1 = series.index_letter === index_letter;
                // legend事件触发时，param中的seriesIndex还是和初始值一样
                const flag2 = params.findIndex((param) => param.seriesIndex === index) !== -1;
                return flag1 && flag2;
              });
              // eslint-disable-next-line no-continue
              if (subSeriesList.length === 0) continue;
              let currentArr = [];
              str += '<div class="index-item"><div class="series-list">';
              // eslint-disable-next-line no-shadow,no-loop-func
              subSeriesList.forEach((series) => {
                const obj = {
                  tooltip_color: series.tooltip_color,
                  label: series.series_label,
                  data: toolFunctions.keepDecimals(series.data[dataIndex]),
                };
                currentArr.push(obj);
              });
              if (currentIndex === 0) {
                currentArr = currentArr.sort((a, b) => b.data - a.data);
                // eslint-disable-next-line no-shadow
                seriesOrderList = currentArr.map((item) => item.label);
              } else {
                // 当前有，下标为0没有
                // eslint-disable-next-line no-shadow,no-loop-func
                const extraIndexList = currentArr.filter((item) => seriesOrderList.findIndex((label) => item.label === label) === -1);
                // eslint-disable-next-line no-shadow
                currentArr = seriesOrderList.map((label) => currentArr.find((item) => item.label === label) || undefined);
                currentArr = [...currentArr, ...extraIndexList];
              }
              for (let i = 0; i < currentArr.length; i += 1) {
                if (currentArr[i].data === key) {
                  targetItem = currentArr[i];
                  targetIndex = i;
                }
              }
              if (targetIndex >= 10) {
                currentArr.unshift(targetItem);
                targetIndex = 0;
              }
              currentArr = currentArr.slice(0, 10);
              // eslint-disable-next-line no-shadow,no-loop-func
              currentArr.forEach((item, index) => {
                if (index === targetIndex) {
                  str += `<div class="series-item">
									<span class="left-side">
											<span class="letter-text" style="background-color: ${item.tooltip_color}; font-weight: 600">${index_letter}</span>
											<span class="series-name" style="font-weight: 600; font-size: 14px">${item.label}</span>
									</span>
									<span class="series-value" style="font-weight: 600; font-size: 14px">${item.data}</span>
								</div>`;
                } else {
                  str += `<div class="series-item">
									<span class="left-side">
											<span class="letter-text" style="background-color: ${item.tooltip_color}">${index_letter}</span>
											<span class="series-name">${item.label}</span>
									</span>
									<span class="series-value">${item.data}</span>
								</div>`;
                }
              });
              str += '</div></div>';
            }
            str += '</div></div>';
          }
          return str;
        },
      renderMode: 'html',
      backgroundColor: 'opacity',
      borderWidth: 0,
      extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0)',
    },
    legend: configuration.legend || {
      show: false,
    },
    grid: configuration.grid
      ? {
        top: configuration.grid.top,
        bottom: configuration.grid.bottom,
        left: configuration.grid.left,
        right: configuration.grid.right,
      }
      : {
        top: '16%',
        bottom: '12%',
        left: '14%',
        right: '14%',
      },
    xAxis: [
      {
        axisLabel: {
          margin: 8,
          color: '#878AA2',
          showMaxLabel: !configuration?.x?.hideMaxLabel,
          formatter: typeof configuration?.x?.formatter === 'function'
            ? configuration.x.formatter
            : (value) => value,
          padding: configuration?.x?.padding
            ? configuration.x.padding
            : undefined,
        },
        axisLine: {
          lineStyle: {
            color: '#D5D7E6',
          },
        },
        axisPointer: {
          snap: true,
          type: 'line',
          lineStyle: {
            width: 2,
            color: '#E6E7F5',
          },
        },
        axisTick: configuration.axisTick || {
          show: true,
          length: 4,
          alignWithLabel: true,
        },
        boundaryGap: configuration.boundaryGap || true,
        data: x_axis,
      },
    ],
    // xAxis: [{
    //   type: 'category',
    //   data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    // }],
    // yAxis: [{
    //   type: 'value',
    // }],
    yAxis,
    series,
  };
  return option;
};
export default getLineAndBarChartOption;
