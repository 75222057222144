<template>
  <div class="temp-name-form">
    <form-content-header title="名称"/>
    <div class="form-container">
      <Form ref="tempNameForm" :model="temp_name_form" :rules="temp_name_form_rules" :label-width="110">
        <FormItem label="名称" prop="name">
          <Input v-model="temp_name_form.name" placeholder="请输入名称" clearable/>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import FormContentHeader from '../../../common/form-content-header.vue';

export default {
  name: 'NameForm',
  components: { FormContentHeader },
  data() {
    return {
      temp_name_form: {
        name: '',
      },
      temp_name_form_rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        },
      };
    },
    watch: {
      '$store.state.current_temp_push': {
        handler(newVal) {
          if (newVal && JSON.stringify(newVal) !== '{}') {
            this.temp_name_form.name = newVal.name;
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped lang="less">
  .temp-name-form {
    margin-bottom: 8px;
    padding: 0 24px;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    @import '../../../../assets/less/create_push_form/formContainer.less';
  }
</style>
