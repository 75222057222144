<template>
  <Modal
    :value="modal_visible"
    class="template-modal"
    :title="title"
    :closable="false"
    :width="800"
    @on-visible-change="displayModal"
    :mask-closable="false">
    <Form ref="templateForm" :model="template_info" :rules="template_info_rules" :label-width="100">
      <FormItem label="标题" prop="title">
        <Input v-model="template_info.title" placeholder="请输入"/>
      </FormItem>
      <FormItem label="内容" prop="content">
        <Input v-model="template_info.content" type="textarea" :rows="4" placeholder="请输入"/>
      </FormItem>
      <FormItem label="图片" prop="image">
        <basic-image-upload :url.sync="template_info.url" :image.sync="template_info.image"></basic-image-upload>
      </FormItem>
      <FormItem label="本地化翻译">
        <div class="operation-box">
          <div class="add-language">
            <div @click="openLanguageSelect"><Icon type="md-add" size="16" color="#165DFF"/>添加语言</div>
            <add-language-select
              :language_list="language_list.filter(i => !i.selected)"
              :add-language-mode="addLanguageMode"
              @close="addLanguageMode = false"
              @confirm="addLanguageHandler"/>
          </div>
          <div class="switch-box">
            <span class="switch-text">自动翻译</span>
            <i-switch v-model="auto_translation"/>
          </div>
          <div class="switch-box">
            <span class="switch-text">继承主图</span>
            <i-switch v-model="extend_image"/>
          </div>
        </div>
      </FormItem>
    </Form>
    <basic-item-in-translation-and-template
      v-for="(item, index) in translation_info_list"
      :key="item.id"
      :language.sync="item.language"
      :title.sync="item.title"
      :content.sync="item.content"
      :image.sync="item.image"
      :url.sync="item.url"
      :language_list="language_list.filter(i => !i.selected || i.value === item.language)"
      @add="saveTranslationItemHandler(item, index)"
      @delete="deleteBasicItemHandler(index)"></basic-item-in-translation-and-template>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="default" @click="cancelHandler">取消</Button>
      <Button class="footer-btn" type="primary" @click="confirmHandler">确认</Button>
    </div>
  </Modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import BasicImageUpload from '../../../common/BasicImageUpload.vue';
import BasicItemInTranslationAndTemplate from '../../../common/BasicItemInTranslationAndTemplate.vue';
import AddLanguageSelect from '../../../common/addLanguageSelect.vue';

export default {
  name: 'TemplateModal',
  components: { BasicImageUpload, BasicItemInTranslationAndTemplate, AddLanguageSelect },
  props: {
    type: String, // create || edit
    modal_visible: Boolean,
    template_info_model: Object,
  },
  model: {
    prop: 'modal_visible',
    event: 'visible-change',
  },
  data() {
    // eslint-disable-next-line no-unused-vars
    const validateImage = (rule, value, callback) => {
      if ((this.template_info.image || this.template_info.url)) {
        callback();
      } else {
        callback(new Error('图片不能为空'));
      }
    };
    return {
      template_info: {
        title: '',
        content: '',
        url: '',
        image: null,
      },
      template_info_rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        // image: [
        //   { validator: validateImage, trigger: 'image-change' },
        // ],
      },
      addLanguageMode: false,
      translation_info_list: [],
      auto_translation: false,
      extend_image: false,
      translation_info_item: {
        title: '',
        content: '',
        language: '',
        image: null,
        url: '',
        status: 'edit',
      },
    };
  },
  computed: {
    title() {
      return this.type === 'create' ? '创建消息模板' : '编辑消息模板';
    },
    language_list() {
      const selectedLanguageList = this.translation_info_list.map((item) => item.language);
      return this.$store.state.language_list.map((item) => ({
          ...item,
          selected: selectedLanguageList.includes(item.value),
        }));
    },
  },
  methods: {
    confirmHandler() {
      const translation_info_list = this.translation_info_list.map((item) => ({
          content: item.content,
          image: item.image,
          language: item.language,
          title: item.title,
          url: item.url,
          id: item.id,
        }));
      const templateInfo = { ...this.template_info, localize_list: [...translation_info_list] };
      this.$refs.templateForm.validate((valid) => {
        if (valid) {
          if (this.type === 'create') { // 创建
            this.$emit('create', { ...templateInfo });
          } else { // 编辑
            if (this.$tools.deepCompare(this.template_info_model, this.template_info) && this.$tools.deepCompare(this.template_info_model.localize_list, this.translation_info_list)) {
              // 未改变
              this.$Message.warning('消息模板未发生修改');
              return;
            }
            this.$emit('edit', { ...templateInfo });
          }
          this.$emit('visible-change', false);
        }
      });
    },
    cancelHandler() {
      this.$emit('visible-change', false);
      this.$emit('cancel');
    },
    displayModal() {
      if (this.type === 'create') {
        this.template_info.url = '';
        this.template_info.image = null;
      }
    },
    openLanguageSelect() {
      this.addLanguageMode = true;
    },
    addLanguageHandler(val) {
      this.addLanguageMode = false;
      const addLanguageList = [];
      val.forEach((item) => {
        addLanguageList.push(this.language_list.find((i) => i.label === item).value);
      });
      console.log(val);
      addLanguageList.forEach((item) => {
        const obj = {
          content: '',
          image: null,
          language: item,
          status: 'edit',
          title: '',
          url: '',
          id: this.generateRandomId(),
        };
        this.translation_info_list.push(obj);
      });
    },
    saveTranslationItemHandler(val, index) {
      // eslint-disable-next-line no-param-reassign
      val.status = 'read';
      this.$set(this.translation_info_list, index, val);
      this.translation_info_item = this.$options.data().translation_info_item;
    },
    deleteBasicItemHandler(index) {
      if (index === -1) { // 说明是最后新增的那一个
        this.create_translation_item = false;
        // 重置
        this.translation_info_item = { ...this.$options.data().translation_info_item };
      } else { // 不是最后新增的那一个
        // 直接从列表删除
        this.translation_info_list.splice(index, 1);
      }
    },
    generateRandomId() {
      const timestamp = Date.now().toString(36);
      const randomStr = Math.random().toString(36).substring(2, 8);
      const randomId = timestamp + randomStr;
      return randomId;
    },
    localizeTranslate() {
      // 将form_data中的文本根据列表项的语言逐一翻译并填充
      const { title, content } = this.template_info;
      const titlePromiseList = [];
      const bodyPromiseList = [];
      const languageList = this.translation_info_list.map((item) => item.language);
      languageList.forEach((language, index) => {
        const translateInfoItem = this.translation_info_list[index];
        const titlePromise = this.localizeSingleTranslate(title, language, translateInfoItem, 'title');
        const bodyPromise = this.localizeSingleTranslate(content, language, translateInfoItem, 'content'); // 注意这里属性值是content
        titlePromiseList.push(titlePromise);
        bodyPromiseList.push(bodyPromise);
      });
      const itemLanguage = this.translation_info_item.language;
      let itemPromise = Promise.resolve();
      if (itemLanguage) {
        itemPromise = this.localizeTranslateInfoItem();
      }
      Promise.all([...titlePromiseList, ...bodyPromiseList, itemPromise])
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    localizeSingleTranslate(text, language, translateInfoItem, param) {
      if (!text) {
        // eslint-disable-next-line no-param-reassign
        translateInfoItem[param] = '';
        return Promise.resolve();
      }
      const promise = this.$translate(text, '', language)
        .then((response) => {
          const { data } = response.data;
          if (data) {
            const resultText = data.translations[0].translatedText;
            // eslint-disable-next-line no-param-reassign
            translateInfoItem[param] = resultText;
            return null;
          }
          return Promise.reject(new Error('哎呀，自动翻译出问题了'));
        });
      return promise;
    },
  },
  watch: {
    modal_visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          // 对整个表单进行重置，将所有字段重置为空，并移除校验结果
          this.$refs.templateForm.resetFields();
        }, 200);
      }
    },
    template_info_model(newVal) {
      Object.assign(this.template_info, newVal);
      this.translation_info_list = newVal ? cloneDeep([...newVal.localize_list]) : [];
    },
    type() {
      if (this.type === 'create') {
        this.imageEmpty = true;
      } else {
        this.imageEmpty = false;
      }
      console.log(this.imageEmpty);
    },
    auto_translation(newVal) {
      if (newVal) { // 开启自动翻译
        this.localizeTranslate();
      } else {
        // 关闭自动翻译
      }
    },
    extend_image(newVal) {
      let url = ''; let image = null;
      if (newVal) { // 确认继承主图
        const hasImage = this.template_info.image || this.template_info.url; // 判断主图是否有image
        if (hasImage) {
          url = this.template_info.url;
          image = this.template_info.image;
        }
      }
      // 否则都为空
      // 将当前列表项的所有图片以及新增项的图片改成和主图一致
      this.translation_info_list.forEach((translationItem) => {
        if (translationItem.url === '') {
          // eslint-disable-next-line no-param-reassign
          translationItem.url = url;
          // eslint-disable-next-line no-param-reassign
          translationItem.image = image;
        }
      });
      this.translation_info_item.url = url;
      this.translation_info_item.image = image;
    },
  },
};
</script>

<style scoped lang="less">
  .template-modal {
    padding: 0 24px;
    .footer {
      display: flex;
      justify-content: flex-end;
      .footer-btn {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .operation-box {
    display: flex;
    align-items: center;
    .add-language {
      font-size: 14px;
      color: #165DFF;
      margin-right: 32px;
      cursor: pointer;
      /deep/ .ivu-icon {
        margin-right: 4px;
      }

    }
    .switch-box {
      display: inline-block;
      margin-right: 32px;
      &:last-child {
        margin-right: 0;
      }
      .switch-text {
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
  .basic-item {
    margin-left: 100px;
  }
  /deep/ .ivu-modal-body {
    max-height: 730px;
    overflow-y: auto;
  }

</style>
