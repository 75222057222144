<template>
  <div class="platform-info-form">
    <div class="config-firebase-form-box form-box">
      <div class="form-title">
        <h3>配置 Firebase</h3>
        <Tooltip  placement="top" transfer class="help-tooltip">
          <img width="16" src="@/assets/image/ic_help@2x.png" alt="Help" />
          <template #content>
            <a class="help-link" :href="helpLink" target="_blank">
              <span>点击查看详细配置说明</span>
              <img src="@/assets/image/ic_link_open@2x.png" width="10" alt="link" />
            </a>
          </template>
        </Tooltip>
      </div>
      <Form ref="configFirebaseForm"
            :model="config_firebase_info"
            :rules="config_firebase_rules"
            :label-width="120">
        <FormItem label="项目 ID" prop="project_id">
          <template #label>
            <span>项目 ID</span>
          </template>
          <Input v-model="config_firebase_info.project_id" disabled placeholder="项目 ID" @on-change="configFirebaseInfoChangeHandler"/>
        </FormItem>
        <FormItem label="Service 账户" prop="file">
          <Upload action="#"
                  accept=".json"
                  :format="['.json']"
                  :before-upload="uploadHandler">
            <Button icon="ios-cloud-upload-outline" class="service-button">{{ upload_text }}</Button>
          </Upload>
        </FormItem>
      </Form>
    </div>
    <div class="config-parse-form-box form-box" v-show="parseVisible">
      <h3>配置 Parse</h3>
      <Form ref="configParseForm"
            :model="config_parse_info"
            :rules="config_parse_rules"
            :label-width="120">
        <FormItem label="APP ID" prop="app_id">
          <Input v-model="config_parse_info.app_id" placeholder="APP ID" @on-change="configParseInfoChangeHandler"/>
        </FormItem>
        <FormItem label="Server URL" prop="server_url">
          <Input v-model="config_parse_info.server_url" placeholder="Server URL" @on-change="configParseInfoChangeHandler"/>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PlatformInfoForm',
    props: {
      type: String,
      // project_id file service_account
      config_firebase_info_model: Object,
      config_parse_info_model: Object,
    },
    data() {
      const fileUploadValidate = (rule, value, callback) => {
        if (!this.config_firebase_info.service_account) {
          callback(new Error('请选择要上传的JSON文件'));
        } else {
          callback();
        }
      };
      return {
        file_name: '',
        helpLink: 'https://vtjr6ivg1m.feishu.cn/docx/TIEtdBeknoZQYHxYTq6chuten7b',
        config_firebase_rules: {
          project_id: [
            {
              required: true,
              message: '项目 ID 不能为空',
              trigger: 'blur',
            },
          ],
          file: [
            {
              required: true,
              validator: fileUploadValidate,
              // trigger: 'blur',
            },
          ],
        },
        config_parse_rules: {
          app_id: [
            {
              required: true,
              message: 'APP ID 不能为空',
              trigger: 'blur',
            },
          ],
          service_url: [
            {
              required: true,
              message: 'Service URL 不能为空',
              trigger: 'blur',
            },
          ],
        },
        config_firebase_info: {
          project_id: '',
          service_account: '',
        },
        config_parse_info: {
          app_id: '',
          server_url: 'https://push.thinkyeah.com/parse/push', // default
          master_key: 'testdev#1',
          restful_key: '',
        },
        parseVisible: false,
      };
    },
    computed: {
      upload_text() {
        return this.file_name || '上传JSON文件';
      },
    },
    mounted() {
      if (this.config_parse_info === undefined) {
        this.parseVisible = true;
      } else {
        this.parseVisible = false;
      }
    },
    methods: {
      resetForm() {
        this.$refs.configFirebaseForm.resetFields();
        this.$refs.configParseForm.resetFields();
      },
      uploadHandler(file) {
        this.file_name = file.name;
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (evt) => {
          const content = evt.target.result;
          const json = JSON.parse(content);
          // 将文件解析的内容赋值到service_account中
          this.config_firebase_info.service_account = json;
          if (json.project_id) {
            // 绑定project_id
            this.config_firebase_info.project_id = json.project_id;
          }
          this.configFirebaseInfoChangeHandler();
          // 阻止自动上传，需要跟随整个表单一起上传
          return false;
        };
      },
      configFirebaseInfoChangeHandler() {
        this.$emit('update:config_firebase_info_model', { ...this.config_firebase_info });
      },
      configParseInfoChangeHandler() {
        this.$emit('update:config_parse_info_model', { ...this.config_parse_info });
      },
    },
    watch: {
      config_firebase_info_model: {
        deep: true,
        handler(newVal) {
          this.config_firebase_info = JSON.parse(JSON.stringify(newVal));
        },
      },
      config_parse_info_model: {
        deep: true,
        handler(newVal) {
          this.config_parse_info = JSON.parse(JSON.stringify(newVal));
        },
      },
      config_firebase_info: {
        handler(val) {
          if (val.service_account === '') {
            this.file_name = '';
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style lang="less">
.help-link{
  text-decoration: underline;
  color: #fff;
  &:hover{
    text-decoration: underline;
    color: #a7d2ff;
  }
  span {
    font-size: 14px;
    margin-right: 4px;
  }
}
</style>
<style scoped lang="less">
  .platform-info-form {
    .form-box {
      &:first-child {
        margin-bottom: 16px;
        border-bottom: 1px solid #EAEBF7;
      }
      .form-title{
        display: flex;
        align-items: center;
        padding: 8px 0;
        .help-tooltip {
          margin-left: 8px;
          /deep/ .ivu-tooltip-rel {
            display: flex;
          }
        }
      }
    }
    .service-button {
      max-width: 336px;
      display: flex;
      align-items: center;
      /deep/ span{
        max-width: 99%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
