const clickOutsideOption = {
  bind(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) {
        // 事件发出的元素在el中，不处理
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
      return null;
    }

    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    el.__biClickOutside__ = documentHandler;
    document.addEventListener('click', documentHandler, true);
  },
  update() {},
  unbind(el) {
    // eslint-disable-next-line no-underscore-dangle
    document.removeEventListener('click', el.__biClickOutside__);
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    delete el.__biClickOutside__;
  },
};
// eslint-disable-next-line no-unused-vars
export default {
  install(Vue) {
    Vue.directive('bi-click-outside', clickOutsideOption);
  },
};
