<template>
  <div class="template-list" ref="templateList">
    <div class="left-box">
      <div class="header">
        <h3 class="title">消息组</h3>
        <Icon class="header-icon" type="md-add-circle" :size="16" @click="createGroupTrigger"/>
      </div>
      <div class="list-container" ref="groupListContainer">
        <div v-show="showGroupList" class="template-info-list">
          <div v-show="edit_group_index == -1" class="group-info-input-item">
            <Input v-model="group_input_item.title" @on-enter="createGroupHandler" @on-blur="hideGroupInputItem"/>
          </div>
          <template-group-item
            v-for="(item, index) in template_group_list"
            :key="item.title"
            :status="edit_group_index === index ? 'edit' : 'read'"
            :title="item.title"
            :count="item.count"
            :id="item.id"
            :selected="current_template_group && item.id === current_template_group.id"
            :ellipsis_width="has_scroller ? 134 : 150"
            @delete="deleteGroupTrigger"
            @edit="editGroupHandler"
            @edit-trigger="edit_group_index = index"
            @hide="edit_group_index = -2"
            @click.native="selectTemplateGroupHandler(item)"></template-group-item>
        </div>
        <empty-content v-show="!showGroupList"/>
      </div>
    </div>
    <div class="right-box">
      <div v-if="!current_template_group" class="empty-header"></div>
      <div v-if="current_template_group" class="header">
        <h3 class="title">{{ current_template_group.title }}·{{ current_template_group.template_list.length }}</h3>
        <div class="operation-box">
          <Button class="operation-btn" @click="createTemplateTrigger">
            <Icon class="btn-icon" type="md-add" />
            <span class="btn-text">新增</span>
          </Button>
          <Button class="operation-btn" type="primary" @click="bulkCreateTemplateTrigger">
            <img class="btn-icon" src="../../assets/image/ic_bulk_add@2x.png" alt="bulk add">
            <span class="btn-text">批量新增</span>
          </Button>
        </div>
      </div>
      <div class="template-detail-list-container" ref="templateListContainer">
        <div v-if="showTemplateList" class="template-detail-list">
          <template-detail-item
            v-for="(item, index) in current_template_group.template_list"
            :key="`${item.title}-${index}`"
            :template_info="item"
            @edit="editTemplateTrigger(item)"
            @delete="deleteTemplateTrigger(item, index, current_template_group.template_list)"></template-detail-item>
        </div>
        <empty-content v-if="!showTemplateList"/>
      </div>
    </div>
    <template-modal
      v-model="template_modal_visible"
      :type="template_modal_type"
      :template_info_model="current_template"
      @create="createTemplateHandler"
      @edit="createTemplateHandler"
      @visible-change="closeTemplateModal"/>
  </div>
</template>

<script>
import TemplateDetailItem from '../../components/page/content/template/TemplateDetailItem.vue';
import TemplateModal from '../../components/page/content/template/TemplateModal.vue';
import EmptyContent from '../../components/common/EmptyContent.vue';
import TemplateGroupItem from '../../components/page/content/template/TemplateGroupItem.vue';
import routerTrigger from '../../push-common/mixins/routerTrigger';

export default {
  name: 'TemplateList',
  components: {
    TemplateGroupItem,
    EmptyContent,
    TemplateModal,
    TemplateDetailItem,
  },
  mixins: [routerTrigger],
  data() {
    return {
      edit_group_index: -2,
      group_input_item: {
        id: '',
        title: '',
        count: 0,
        template_list: [],
      },
      template_group_list: [],
      // 消息库的第一个为默认选中项
      current_template_group: null,
      // 当前编辑的template
      current_template: null,
      template_modal_type: 'create',
      template_modal_visible: false,
      has_scroller: false,
    };
  },
  computed: {
    templateListElement() {
      return this.$refs.templateList;
    },
    groupListContainerElement() { // 左侧消息库列表的container
      return this.$refs.groupListContainer;
    },
    templateListContainer() { // 右侧显示消息模板列表的container
      return this.$refs.templateListContainer;
    },
    showGroupList() {
      return this.edit_group_index > -2 || this.template_group_list.length > 0;
    },
    showTemplateList() {
      return this.current_template_group && this.current_template_group.template_list.length > 0;
    },
  },
  methods: {
    fetchData() {
      this.fetchGroupList();
    },
    isTitleDuplicate(title) {
      const groupNameList = this.template_group_list.map((val) => val.title);
      const isExist = groupNameList.indexOf(title) !== -1;
      return isExist;
    },
    resetList() {
      this.tempalte_group_list = [];
    },
    fetchTemplateList(groupId) {
      const loader = this.$loader(this.templateListContainer).show();
      const urlParams = {
        app_id: this.$route.params.app_id,
        group_id: groupId,
      };
      const promise = this.$api.getTemplateList(urlParams)
        .then((response) => {
          // eslint-disable-next-line camelcase
          const { template_info } = response.data;
          const targetGroupItem = this.template_group_list.find((val) => val.id === groupId);
          targetGroupItem.template_list = template_info.map((item) => ({
            id: item.nid,
            title: item.title,
            content: item.body,
            url: item.image_url,
            localize_list: item.localize_list,
            group_id: item.group.id,
          }));
          targetGroupItem.count = targetGroupItem.template_list.length;
          console.log(targetGroupItem.template_list);
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          loader.hide();
        });
      return promise;
    },
    fetchGroupList() {
      const loader = this.$loader(this.templateListElement).show();
      this.resetList();
      const promise = this.$api.getGroupList({
        app_id: this.$route.params.app_id,
      }).then((response) => {
        const groupInfo = response.data.group_info;
        this.template_group_list = groupInfo.map((item) => (
          {
            id: item.id,
            title: item.group_name,
            count: item.count || 0,
            template_list: [],
          }
        ));
      }).catch((error) => {
        this.$Message.error(error.message);
      }).finally(() => {
        loader.hide();
      });
      return promise;
    },
    fetchAppInfo() {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const promise = this.$api
        .getAppInfo(urlParams)
        .then((response) => {
          const {
            language_info,
          } = response.data.data;
          const language_list = language_info.map((item) => ({
            label: item.lang_desc,
            value: item.lang_code,
          }));
          this.$store.commit('setLanguageList', { language_list });
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {});
      return promise;
    },
    createGroup() {
      const loader = this.$loader(this.groupListContainerElement).show();
      const promise = this.$api.addGroup(
        {
          app_id: this.$route.params.app_id,
        },
        {
          group_name: this.group_input_item.title,
        },
      ).then((response) => {
        const { result } = response.data;
        if (result === 'success') {
          this.$Message.success('新建消息组成功');
          return this.fetchGroupList();
        }
        return Promise.reject(new Error('新建消息组失败'));
      }).catch((error) => {
        this.$Message.error(error.message);
      }).finally(() => {
        loader.hide();
      });
      return promise;
    },
    createGroupTrigger() {
      this.edit_group_index = -1;
    },
    createGroupHandler() {
      const groupNameList = this.template_group_list.map((val) => val.title);
      const isExist = groupNameList.indexOf(this.group_input_item.title) !== -1;
      if (isExist) {
        this.$Message.warning('名称已存在');
        return;
      }
      if (this.group_input_item.title) { // 输入不为空
        // 创建group
        this.createGroup()
          .then(() => {
            this.edit_group_index = -2;
            this.group_input_item = { ...this.$options.data().group_input_item };
          });
      }
    },
    editGroupHandler(groupId, title) {
      const isExist = this.isTitleDuplicate(title);
      if (isExist) {
        this.$Message.warning('消息组名称重复');
        this.edit_group_index = this.template_group_list.findIndex((val) => val.id === groupId);
        return null;
      }
      const urlParams = {
        app_id: this.$route.params.app_id,
        group_id: groupId,
      };
      const params = {
        group_name: title,
      };
      const promise = this.$api.updateGroup(urlParams, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('消息组名称修改成功');
            const target = this.template_group_list.find((val) => val.id === groupId);
            target.title = title;
            return null;
          }
          return Promise.reject(new Error('消息组名称修改失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    deleteGroupTrigger(groupId) {
      this.$Modal.confirm({
        title: '确定删除该消息组吗？',
        content: null,
        onOk: () => {
          this.deleteGroupHandler(groupId);
        },
        onCancel: () => {},
      });
    },
    deleteGroupHandler(groupId) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        group_id: groupId,
      };
      const promise = this.$api.deleteGroup(urlParams)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            const targetIndex = this.template_group_list.findIndex((item) => item.id === groupId);
            // splice函数返回的是被删除元素组成的数组，因为可以被删除多个
            const targetArr = this.template_group_list.splice(targetIndex, 1);
            if (targetArr[0].id === this.current_template_group.id) {
              this.current_template_group = null;
            }
            this.$Message.success('消息组删除成功');
            return null;
          }
          return Promise.reject(new Error('消息组删除失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    hideGroupInputItem() {
      this.edit_group_index = -2;
      // 重置
      this.group_input_item = { ...this.$options.data().group_input_item };
    },
    uploadAppLogo(imageFile) { // 可以抽成全局函数
      const promise = this.$api.fileUpload({ file: imageFile, pid: this.$route.params.app_id })
        .then((response) => {
          const { msg, url } = response.data;
          if (msg === 'success') {
            // then中的返回的新Promise实例的值为url
            return url;
          }
          return Promise.reject(new Error('哎呀，图标上传出错啦'));
        });
      return promise;
    },
    createTemplateTrigger() {
      this.template_modal_type = 'create';
      this.template_modal_visible = true;
    },
    createTemplateHandler(templateInfo) {
      const loader = this.$loader(this.templateListContainer).show();
      const promise = new Promise((resolve) => {
        if (templateInfo.url) {
          resolve(templateInfo.url);
        }
        if (templateInfo.image) {
          resolve(this.uploadAppLogo(templateInfo.image));
        } else {
          resolve(null);
        }
      }).then((url) => {
        const urlParams = {
          app_id: this.$route.params.app_id,
          template_id: this.template_modal_type === 'edit' ? templateInfo.id : undefined,
        };
        const params = {
          title: templateInfo.title,
          body: templateInfo.content,
          image: url,
          group_id: this.current_template_group.id,
          localize_list: templateInfo.localize_list,
        };
        console.log(Array.isArray(params.localize_list));
        const api = this.template_modal_type === 'create' ? this.$api.addTemplate : this.$api.updateTemplate;
        return api(urlParams, params);
      }).then((response) => {
        const { result } = response.data;
        const successMsg = this.template_modal_type === 'create' ? '新建消息模板成功' : '编辑消息模板成功';
        const editMsg = this.template_modal_type === 'create' ? '新建消息模板失败' : '编辑消息模板失败';
        if (result === 'success') {
          this.$Message.success(successMsg);
          return this.fetchTemplateList(this.current_template_group.id);
        }
        return Promise.reject(new Error(editMsg));
      }).catch((error) => {
        this.$Message.error(error.message);
      })
      .finally(() => {
        loader.hide();
        // this.fetchGroupList();
      });
      return promise;
    },
    closeTemplateModal(val) {
      this.template_modal_visible = val;
    },
    bulkCreateTemplateTrigger() {
      this.$router.push({
        name: 'CreateTemplate',
        params: {
          app_id: this.$route.params.app_id,
          group_id: this.current_template_group.id,
        },
      });
    },
    editTemplateTrigger(templateInfo) {
      // 修改弹窗为编辑状态
      this.current_template = templateInfo;
      this.template_modal_type = 'edit';
      this.template_modal_visible = true;
    },
    deleteTemplateTrigger(templateInfo, index, templateList) {
      this.$Modal.confirm({
        title: '确定删除该消息模板吗？',
        content: null,
        onOk: () => {
          this.deleteTemplateHandler(templateInfo.id, index, templateList);
        },
        onCancel: () => {},
      });
    },
    deleteTemplateHandler(templateId, index, templateList) {
      const urlParams = {
        template_id: templateId,
      };
      const promise = this.$api.deleteTemplate(urlParams)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('消息模板删除成功');
            // 删除列表中指定的消息模板
            const targetTemplateArr = templateList.splice(index, 1);
            const targetTemplate = targetTemplateArr[0];
            const targetGroup = this.template_group_list.find((item) => item.id === targetTemplate.group_id);
            targetGroup.count -= 1;
            return null;
          }
          return Promise.reject(new Error('消息模板删除失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    selectTemplateGroupHandler(item) {
      this.current_template_group = item;
    },
    observeTemplateGroupList() {
      if (typeof ResizeObserver === 'function') { // 验证 ResizeObserver 是否可用
        const ob = new ResizeObserver((entries) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const entry of entries) {
            const elm = entry.target;
            const { clientHeight, scrollHeight } = elm;
            if (clientHeight < scrollHeight) { // 说明有滚动条
              this.has_scroller = true;
            } else {
              this.has_scroller = false;
            }
          }
        });
        ob.observe(document.querySelector('.list-container'));
        this.$once('hooks:beforeDestroy', () => {
          ob.disconnect();
        });
      }
    },
  },
  watch: {
    template_modal_visible(val) {
      if (!val) {
        this.current_template = null;
      }
    },
    template_group_list: {
      deep: true,
      handler() {
        const groupLength = this.template_group_list.length;
        if (groupLength === 0) {
          this.current_template_group = null;
        } else if (this.current_template_group === null) {
          // eslint-disable-next-line prefer-destructuring
          this.current_template_group = this.template_group_list[0];
        }
      },
    },
    current_template_group: {
      handler(newVal) {
        if (newVal) {
          const { id } = newVal;
          // 更新右侧template_list
          this.fetchTemplateList(id);
        }
      },
    },
  },
  mounted() {
    // 当页面有 can not find property 'xxx' of undefined 这样的错误时
    // 除了控制台报错之外，也会获取不到页面dom元素（mounted的时候）
    this.fetchGroupList();
    this.observeTemplateGroupList();
    this.fetchAppInfo();
  },
};
</script>

<style scoped lang="less">
  .template-list {
    position: relative;
    display: flex;
    height: calc(~'100% - 32px');
    min-height: 240px;
    margin: 16px 0;
    background-color: #FFFFFF;
    border-radius: 4px;
    .left-box {
      width: 240px;
      height: 100%;
      border-right: 1px solid #EAEBF7;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        padding: 0 16px 0 20px;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: #202444;
          line-height: 22px;
        }
        .header-icon {
          color: @basicColor;
          cursor: pointer;
        }
      }
      .list-container {
        position: relative;
        max-height: calc(100% - 56px);
        overflow: auto;
      }
      .template-info-list {
        padding: 0 4px;
        .group-info-input-item {
          /deep/ .ivu-input {
            margin-bottom: 4px;
            padding: 4px 16px;
          }
        }
      }
    }
    .right-box {
      flex: 1 1 calc(~'100% - 240px');
      max-height: 100%;
      padding: 0 24px;
      background-color: #FFFFFF;
      .empty-header {
        height: 56px;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: #202444;
          line-height: 22px;
        }
        .operation-box {
          .operation-btn {
            margin-right: 8px;
            &:last-child {
              margin-right: 0;
            }
            .btn-icon {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              line-height: 16px;
              vertical-align: middle;
            }
            .btn-text {
              width: 56px;
              height: 22px;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              vertical-align: middle;
            }
          }
        }
      }
      .template-detail-list-container {
        position: relative;
        height: calc(~'100% - 56px');
        padding-bottom: 12px;
        overflow: auto;
      }
    }
  }
</style>
