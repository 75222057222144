/* eslint-disable no-unused-vars */
import requestFactory from './requestFactory';
import urls from './urls';
import toolFunctions from '../share/toolFunctions';

const GET = 'get';
const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';
const getRequest = requestFactory(GET);
const postRequest = requestFactory(POST);
const putRequest = requestFactory(PUT);
const deleteRequest = requestFactory(DELETE);

const { requestURLParamParser } = toolFunctions;

/**
 * group相关 API
 */
export default {
  getGroupList: (urlParams) => {
    const newURL = requestURLParamParser(urls.groupListURL, urlParams);
    return getRequest(newURL);
  },
  addGroup: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.addGroupURL, urlParams);
    return postRequest(newURL, params);
  },
  updateGroup: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.updateGroupURL, urlParams);
    return putRequest(newURL, params);
  },
  deleteGroup: (urlParams) => {
    const newURL = requestURLParamParser(urls.deleteGroupURL, urlParams);
    return deleteRequest(newURL);
  },
  getTemplateList: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.templateListURL, urlParams);
    return getRequest(newURL, params);
  },
  addTemplate: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.addTemplateURL, urlParams);
    return postRequest(newURL, params);
  },
  addMultiTemplate: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.addMultiTemplateURL, urlParams);
    return postRequest(newURL, params);
  },
  updateTemplate: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.updateTemplateURL, urlParams);
    return putRequest(newURL, params);
  },
  deleteTemplate: (urlParams) => {
    const newURL = requestURLParamParser(urls.deleteTemplateURL, urlParams);
    return deleteRequest(newURL);
  },
};
