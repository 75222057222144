<template>
  <div class="push-switch">
    <div class="left-box" @click="switchHandler(true)">
      <span class="text">{{ left_text }}</span>
    </div>
    <div class="right-box" @click="switchHandler(false)">
      <span class="text">{{ right_text }}</span>
      <Tooltip placement="top" transfer>
        <Icon class="icon" type="ios-help-circle-outline" :size="16"/>
        <div slot="content">
          <p>此模式根据用户Token发送，</p>
          <p>消耗资源，请谨慎使用</p>
          <p>且必须选择一个条件</p>
        </div>
      </Tooltip>
    </div>
    <div class="toggle-box" :class="{'true-toggle-box': flag, 'false-toggle-box': !flag}">
      <span v-show="flag" class="text">{{ left_text }}</span>
      <span v-show="!flag" class="text">{{ right_text }}</span>
      <Tooltip v-show="!flag" placement="top" transfer>
        <Icon class="icon" type="ios-help-circle-outline" :size="16"/>
        <div slot="content">
          <p>此模式根据用户Token发送</p>
          <p>消耗资源，请谨慎使用</p>
          <p>且必须选择一个条件</p>
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PushSwitch',
  props: {
    value: String,
    list: Array,
    left_text: String,
    right_text: String,
  },
  model: {
    prop: 'value',
    event: 'switch-change',
  },
  computed: {
    flag() {
      return this.value === this.list[0].value;
    },
  },
  methods: {
    switchHandler(f) {
      let newVal;
      if (this.flag !== f) {
        newVal = this.list[f ? 0 : 1].value;
        this.$emit('switch-change', newVal);
      }
    },
  },
};
</script>

<style scoped lang="less">
  .push-switch {
    display: flex;
    position: relative;
    width: fit-content;
    width: -moz-fit-content;
    height: 32px;
    background: #F2F4F7;
    border-radius: 6px;
    .left-box, .right-box {
      width: 96px;
      padding: 6px 0px;
      line-height: 20px;
      color: #333440;
      cursor: pointer;
      text-align: center;
      .icon {
        color: #a5a7aa;
      }
    }
    .toggle-box {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 92px;
      height: 28px;
      padding: 4px 0px;
      background: #FFFFFF;
      border-radius: 4px;
      color: @basicColor;
      transition: all 0.1s ease-in-out;
      cursor: pointer;
      text-align: center;
    }
    .true-toggle-box {
      left: 2px;
    }
    .false-toggle-box {
      left: 98px;
    }
    .text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      vertical-align: middle;
    }
    .icon {
      margin-left: 4px;
      vertical-align: middle;
      font-weight: 600;
    }
  }
</style>
