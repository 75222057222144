<template>
  <div class="image-upload">
    <div class="file-box"
         ref="file_box"
         @click="fileTrigger">
      <Icon class="delete-icon" :class="{'active-delete-icon': imageURL}" type="md-close" @click.stop="deleteImageHandler"/>
      <div v-if="!showImage" class="message">
<!--        <img src="../../assets/image/ic_upload@2x.png" alt="Add project">-->
        <Icon type="md-add" :size="24"/>
        <span>点击或拖拽</span>
      </div>
      <img v-if="showImage"
           class="logo-image"
           :src="imageURL"
           alt="Logo url">
    </div>
    <!-- The problem of type converting between file and path string -->
    <input type="file"
           accept="image/*"
           style="display: none"
           ref="file_input"
           @change="imageFileChangeHandler($event)"/>
  </div>
</template>

<script>
  export default {
    name: 'ImageUpload',
    props: {
      image_url: String, // 编辑情况下，需要用URL
      image: File,
    },
    model: {
      prop: 'image',
      event: 'image-change',
    },
    computed: {
      imageURL() {
        return this.image ? this.$tools.imageFileToURL(this.image) : this.image_url;
      },
      showImage() {
        return this.image || this.image_url;
      },
    },
    methods: {
      fileTrigger() {
        const fileInputElm = this.$refs.file_input;
        fileInputElm.click();
      },
      imageFileChangeHandler() {
        console.log(this.$refs.file_input);
        const file = this.$refs.file_input.files[0];
        if (file.size > 300 * 1024) {
          this.$Message.warning('图片大小最大不超过300KB');
          return;
        }
        this.$emit('image-change', file);
      },
      deleteImageHandler() {
        this.$emit('update:image_url', '');
        this.$emit('image-change', null);
      },
    },
  };
</script>

<style scoped lang="less">
  @borderRadius: 4px;
  @fileBoxWidth: 96px;
  @fileBoxHeight: @fileBoxWidth;
  @fileBoxBorder: 1px dashed #D5D7E6;
  @fileBoxBgColor: #FFFFFF;
  @fileBoxRadius: @borderRadius;
  @messageTextMarginBottom: 9px;
  .message-text {
    font-size: 14px;
    font-weight: 400;
    color: #BBBCC9;
    line-height: 20px;
  }
  .image-upload {
    width: @fileBoxWidth;
    height: @fileBoxHeight;
    .file-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: @fileBoxWidth;
      height: @fileBoxHeight;
      border: @fileBoxBorder;
      border-radius: @fileBoxRadius;
      background-color: @fileBoxBgColor;
      color: #BBBCC9;
      transition: 0.2s ease-in-out;
      cursor: pointer;
      .delete-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 20px;
        font-weight: 800;
        color: rgb(99, 146, 246);
        opacity: 0;
        transition: 0.2s linear transform, 0.2s linear opacity;
      }
      &:hover {
        border-color: rgba(75, 92, 240, 1);
        background: #EBEFFF;
        .active-delete-icon {
          opacity: 1;
          transform: rotate(180deg);
        }
        .message {
          color: rgba(75, 92, 240, 1);
          span {
            color: rgba(75, 92, 240, 1);
          }
        }
      }
      .message {
        text-align: center;
        i, span {
          transition: color 0.2s ease-in-out;
        }
        span {
          display: block;
          .message-text();
        }
        span:first-child {
          font-size: 30px;
          margin-bottom: @messageTextMarginBottom;
        }
      }
      .logo-image {
        max-width: 90%;
        max-height: 90%;
      }
    }
  }
</style>
