<template>
  <div class="test-machine-item">
    <div v-show="status === 'read'" class="display-box">
      <div class="left-box">
      <!-- Tooltip位置偏移问题 -->
        <Tooltip :content="`Push设备令牌：${test_machine_info.fcm_token}`" placement="top-start" max-width="300" transfer>
          <img src="../../../../assets/image/ic_info@2x.png" alt="info" class="info-icon icon">
        </Tooltip>
        <span class="test-machine-remark">{{ test_machine_info.remark }}</span>
      </div>
      <div class="right-box">
        <img src="../../../../assets/image/ic_edit@2x.png" @click="status = 'edit'" alt="edit" class="edit-icon icon">
        <Poptip v-model="delete_confirm_tip_visible" trigger="click" placement="top-end" theme="light" :max-width="200" offset="14, 0">
          <img src="../../../../assets/image/ic_delete@2x.png" alt="delete" class="delete-icon icon">
          <template slot="content">
            <div class="confirm-box">
              <p class="warning-text-box">
                <Icon class="warning-icon" type="ios-alert" :size="16" color="#FF4D50"/>
                <span class="warning-text">删除后不可逆，请慎重！</span>
              </p>
              <div class="footer">
                <Button type="default" size="small" :disabled="delete_confirm_loading" @click="delete_confirm_tip_visible = false">取消</Button>
                <Button type="error" size="small" :loading="delete_confirm_loading"  @click="deleteConfirmHandler">删除</Button>
              </div>
            </div>
          </template>
        </Poptip>
      </div>
    </div>
    <div v-show="status === 'edit'" class="edit-box">
      <div class="left-box">
        <div class="info-box">
          <label>名称</label>
<!--          <Tooltip content="这里是测试机对应的口令，用于对照着查看" placement="top-start" max-width="300" transfer>-->
<!--            <img src="../../../../assets/image/ic_info@2x.png" alt="info" class="info-icon icon">-->
<!--          </Tooltip>-->
          <Input class="info-input" v-model="test_machine_info.remark"/>
        </div>
        <div class="info-box">
          <label>Push设备令牌</label>
          <Input class="info-input" v-model="test_machine_info.fcm_token"/>
        </div>
      </div>
      <div class="right-box">
        <Icon class="check-icon icon" :size="24" @click="confirmHandler" type="ios-checkmark-circle" color="#2978FF"/>
        <Icon class="check-icon icon" :size="24" @click="cancelHandler" type="ios-close-circle-outline" color="#2978FF"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestMachineItem',
  props: {
    test_machine_info_model: Object,
  },
  data() {
    return {
      test_machine_info: {
        id: '',
        app_name: '',
        fcm_token: '',
        remark: '',
        gaid: '',
      },
      status: 'read', // 'read' || 'edit'
      delete_confirm_tip_visible: false,
      delete_confirm_loading: false,
    };
  },
  methods: {
    confirmHandler() {
      if (this.$tools.deepCompare(this.test_machine_info_model, this.test_machine_info)) {
        this.$Message.warning('未发生修改');
      } else {
        // 编辑状态下，且和原值不相同
        const urlParams = {
          app_id: this.$route.params.app_id,
        };
        const params = {
          ...this.test_machine_info,
        };
        // 编辑请求
        this.$api.updateTestMachine(urlParams, params)
          .then((response) => {
            const { msg } = response.data;
            if (msg === 'success') {
              this.$Message.success('更新测试机成功');
              this.$emit('edit-confirm', { ...this.test_machine_info });
              return null;
            }
            return Promise.reject(new Error('更新测试机失败'));
          })
          .catch((error) => {
            this.$Message.error(error.message);
          })
          .finally(() => {
            this.status = 'read';
          });
      }
    },
    cancelHandler() {
      this.status = 'read';
    },
    deleteConfirmHandler() {
      this.delete_confirm_loading = true;
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const params = {
        id: this.test_machine_info.id,
      };
      this.$api.deleteTestMachine(urlParams, params)
        .then((response) => {
          const { msg } = response.data;
          if (msg === 'success') {
            this.$Message.success('删除测试机成功');
            this.$emit('delete-confirm', this.test_machine_info.id);
            return null;
          }
          return Promise.reject(new Error('删除测试机失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {
          this.delete_confirm_loading = false;
          this.delete_confirm_tip_visible = false;
        });
    },
  },
  watch: {
    test_machine_info_model: {
      immediate: true,
      handler(newVal) {
        this.test_machine_info = { ...newVal };
      },
    },
  },
};
</script>

<style scoped lang="less">
  .test-machine-item {
    padding: 9px 6px;
    border-radius: 4px;
    transition: background-color ease-in-out 0.2s;
    &:hover {
      background-color: #F3F5FA;
    }
    .display-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-box {
        display: flex;
        align-items: center;
        overflow: hidden;
        .info-icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          vertical-align: middle;
        }
        .test-machine-remark {
          font-size: 14px;
          font-weight: 400;
          color: #333440;
          line-height: 22px;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .right-box {
        display: flex;
        align-items: center;
        .edit-icon {
          width: 16px;
          height: 16px;
          margin-right: 16px;
          vertical-align: middle;
        }
        .delete-icon {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
        /deep/ .ivu-poptip-body {
          padding: 0;
          border-radius: 4px;
          overflow: hidden;
        }
        .confirm-box {
          padding: 12px;
          background: #FFFFFF;
          box-shadow: 0px 2px 8px 0px rgba(13,17,52,0.0800);
        }
        .warning-text-box {
          margin-bottom: 12px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0,0,0,0.6500);
          line-height: 22px;
          text-shadow: 0px 2px 8px rgba(13,17,52,0.0800);
          .warning-icon {
            margin-right: 9px;
            color: #FF4D50;
          }
          .warning-text {
            color: rgba(0,0,0,0.6500);
            line-height: 22px;
            text-shadow: 0px 2px 8px rgba(13,17,52,0.0800);
          }
        }
        .footer {
          display: flex;
          justify-content: flex-end;
          /deep/ .ivu-btn {
            &:first-child {
              margin-right: 8px;
            }
          }
        }
      }
    }
    .edit-box {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background: #f7faff;
      border-radius: 4px;
      .left-box {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        background: transparent;
        .info-box {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
          label{
            width: 100px;
            text-align: right;
            padding-right: 8px;
          }
        }
        .info-icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          vertical-align: middle;
        }
        .info-input {
          width: calc(~'100% - 100px');
        }
      }
      .right-box {
        display: flex;
        justify-content: flex-end;
        width: 76px;
        .check-icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .icon {
      cursor: pointer;
    }
  }
</style>
