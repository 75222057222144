<template>
  <div class="basic-item">
    <div v-if="enable_language" class="col-1">
      <Select :value="language" @on-change="languageChangeHandler" filterable>
        <Option v-for="language in language_list"
                :key="language.label"
                :value="language.value">{{ language.label }}</Option>
      </Select>
    </div>
    <div class="col-2">
      <div class="title-box">
        <span class="title-text">标题：</span>
        <Input class="title-input" type="textarea" :value="title" @on-change="titleChangeHandler"/>
      </div>
      <div class="content-box">
        <span class="content-text">内容：</span>
        <Input class="content-input" type="textarea" :value="content" @on-change="contentChangeHandler"/>
      </div>
    </div>
    <div class="col-3">
      <basic-image-upload
        :show_input_text="false"
        :image="image"
        :url="url"
        :disabled="status === 'read'"
        @update:image="imageChangeHandler"
        @update:url="urlChangeHandler"/>
<!--      <Upload class="upload-input" type="drag" action="#" :disabled="status === 'read'">-->
<!--        <div class="upload-input-box">-->
<!--          <Icon class="upload-icon" type="md-add" :size="24"/>-->
<!--          <span class="upload-text">拖拽或点击上传</span>-->
<!--        </div>-->
<!--      </Upload>-->
<!--      <Input class="url-input" :disabled="status === 'read'" placeholder="请输入 URL"/>-->
    </div>
    <div class="col-4">
      <Button v-if="!enable_language" type="primary" size="small" class="confirm-btn"  @click="addTranslation" >添加翻译</Button>
<!--      <Button v-if="status === 'read'" type="primary" size="small" class="confirm-btn" @click="editHandler">编辑</Button>-->
      <Button size="small" type="default"  @click="cancelHandler">删除</Button>
<!--      <span v-if="status === 'edit'" class="operation-btn" @click="confirmHandler">确定</span>-->
<!--      <span class="operation-btn delete-btn" @click="cancelHandler">删除</span>-->
    </div>
    <add-language-select
      :language_list="language_list.filter(i => !i.selected)"
      :add-language-mode="addLanguageMode"
      @close="closeAddLanguage"
      @confirm="addLanguageHandler"/>
  </div>
</template>

<script>
import BasicImageUpload from './BasicImageUpload.vue';
import addLanguageSelect from './addLanguageSelect.vue';

export default {
  name: 'BasicItemInTranslationAndTemplate',
  components: { BasicImageUpload, addLanguageSelect },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    status: { // read || edit
      type: String,
      default: 'read',
    },
    enable_language: {
      type: Boolean,
      default: true,
    },
    addLanguageMode: {
      type: Boolean,
      default: false,
    },
    language_list: Array,
    language: String,
    title: String,
    content: String,
    image: [File, String],
    url: String,
    translationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    languageChangeHandler(value) {
      this.$emit('update:language', value);
    },
    titleChangeHandler(event) {
      this.$emit('update:title', event.target.value);
    },
    contentChangeHandler(event) {
      this.$emit('update:content', event.target.value);
    },
    async imageChangeHandler(image) {
      if (image) {
        const url = await this.$uploadImage(image, this.$route.params.app_id);
        this.$emit('update:url', url);
      } else {
        this.$emit('update:image', image);
      }
    },
    urlChangeHandler(url) {
      this.$emit('update:url', url);
    },
    confirmHandler() {
      // 检验是否为空
      if ((this.language === '' && this.enable_language) || this.title === '' || this.content === '') {
        this.$Message.warning('新增本地化翻译输入不能为空');
        return;
      }
      this.$emit('add');
    },
    editHandler() {
      this.$emit('edit');
    },
    cancelHandler() {
      // 删除事件
      this.$emit('delete');
    },
    addTranslation() {
      this.$emit('add-translation', this.translationId);
    },
    addLanguageHandler(val) {
      this.$emit('add-language', this.translationId, val);
    },
    closeAddLanguage() {
      this.$emit('close-language', this.translationId);
    },
  },
};
</script>

<style scoped lang="less">
  .basic-item {
    display: flex;
    height: 220px;
    margin-bottom: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0), inset 0px -1px 0px 0px #EAEFF7;
    border-radius: 4px;
    border: 1px solid #EAEFF7;
    position: relative;
    .col-1, .col-2, .col-3, .col-4 {
      height: 100%;
      line-height: 218px;
    }
    .col-1 {
      flex: 1 1 21%;
      padding: 0 12px;
      border-right: 1px solid #EAEFF7;
    }
    .col-2 {
      flex: 1 1 41%;
      border-right: 1px solid #EAEFF7;
      .title-box {
        height: 80px;
        border-bottom: 1px solid #EAEFF7;
      }
      .content-box {
        height: 140px;
      }
      .title-box, .content-box {
        display: flex;
        align-items: center;
        padding: 4px 4px 4px 16px;
        text-align: right;
      }
      .title-input, .content-input {
        height: 100%;
        /deep/ .ivu-input {
          height: 100%;
        }
      }
      .title-text, .content-text {
        flex: 0 0 auto;
        font-size: 14px;
        font-weight: 600;
        color: #1D1E26;
        line-height: 20px;
      }
      .title, .content {
        font-size: 14px;
        color: #333440;
        line-height: 23px;
      }
    }
    .col-3 {
      flex: 1 1 31%;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 8px 12px;
      border-right: 1px solid #EAEFF7;
      .upload-input {
        width: 100%;
        max-width: 328px;
        height: 164px;
        margin: 0 auto 8px;
        .upload-box();
      }
      .url-input {
        width: 100%;
        max-width: 328px;
        margin: 0 auto;
      }
    }
    .col-4 {
      flex: 1 1 6.6%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 75px;
      text-align: center;
      .operation-btn {
        font-size: 14px;
        font-weight: 400;
        color: #42b983;
        line-height: 28px;
        vertical-align: middle;
        cursor: pointer;
      }
      .delete-btn {
        color: #2978FF;
      }
      .confirm-btn {
        margin-bottom: 8px;
      }
    }
  }
  .add-language-select {
    top: 0 !important;
  }
</style>
