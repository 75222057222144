<template>
  <Modal
    :value="modal_visible"
    class="create-message-temp-push-modal"
    title="新建 Message 临时推送"
    :closable="false"
    :mask-closable="false">
    <div class="body">
      <Form ref="createMessageTempPushForm" :model="message_temp_push" :rules="message_temp_push_rules" :label-width="66">
        <FormItem prop="name" label="标题">
          <Input v-model="message_temp_push.name" placeholder="请输入"/>
        </FormItem>
        <FormItem prop="message" label="内容（JSON）">
          <Input type="textarea" :rows="4" v-model="message_temp_push.message" placeholder="请输入内容" clearable/>
        </FormItem>
        <FormItem label="有效期" prop="validity_period" class="validity-period-form-item">
          <div class="form-item-box">
            <FormItem prop="start_time">
              <DatePicker type="datetime" v-model="message_temp_push.start_time" placeholder="请选择" transfer/>
            </FormItem>
          </div>
          <span class="validity-period-text">至</span>
          <div class="form-item-box">
            <FormItem prop="end_time">
              <DatePicker type="datetime" v-model="message_temp_push.end_time" placeholder="请选择" transfer/>
            </FormItem>
          </div>
        </FormItem>
      </Form>
    </div>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="default" @click="cancelHandler">取消</Button>
      <Button class="footer-btn" type="primary" @click="okHandler">确认</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'CreateMessageTempPushModal',
  props: {
    modal_visible: Boolean,
  },
  model: {
    prop: 'modal_visible',
    event: 'visible-change',
  },
  data() {
    const validateMessage = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入JSON格式内容'));
      } else if (!this.$tools.checkJSON(value)) {
        callback(new Error('内容不是JSON格式'));
      } else {
        callback();
      }
    };
    return {
      message_temp_push: {
        name: '',
        message: '',
        start_time: '',
        end_time: '',
      },
      message_temp_push_rules: {
        name: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        message: [
          { validator: validateMessage, trigger: 'blur' },
        ],
        start_time: [
          {
            required: true,
            message: '请输入开始时间',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        end_time: [
          {
            required: true,
            message: '请输入结束时间',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
      },
    };
  },
  methods: {
    resetForm() {
      setTimeout(() => {
        console.log('重置表单');
        // 重置表单
        this.$refs.createMessageTempPushForm.resetFields();
      }, 200);
    },
    okHandler() {
      this.$refs.createMessageTempPushForm.validate((valid) => {
        if (valid) {
          this.$emit('visible-change', false);
          this.$emit('create', { ...this.message_temp_push });
        }
      });
    },
    cancelHandler() {
      this.$emit('visible-change', false);
    },
  },
  watch: {
    modal_visible(newVal) {
      if (!newVal) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped lang="less">
  .create-message-temp-push-modal {
    /deep/ .ivu-form {
      padding: 0 24px;
    }
    .body {
      .validity-period-form-item {
        /deep/ .ivu-form-item-content {
          display: flex;
          .form-item-box {
            margin-right: 8px;
            &:last-child {
              margin-right: 0;
            }
          }
          .validity-period-text {
            margin-right: 8px;
          }
        }
      }
      .form-item-box {
        display: inline-block;
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
