import Vue from 'vue';
import LoadingComponent from '../components/common/Loader.vue';

export default {
  loading: (parent, backgroundColor = 'rgb(255, 255, 255)') => {
    const LoadingConstructor = Vue.extend(LoadingComponent);
    const div = document.createElement('div');
    parent.appendChild(div);
    const loadingInstance = new LoadingConstructor({
      propsData: {
        background_color: backgroundColor,
      },
    });
    loadingInstance.$mount(div);
    return loadingInstance;
  },
};
