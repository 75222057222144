<template>
  <div class="funnel-chart-item" :class="{'root-chart-item': isRoot}">
    <div v-if="isRoot" class="main-box">
      <div class="root-content-box content-box">
        <span class="content-text">{{ treeNode.text }}</span>
        <span class="content-count" :style="colorDisplay(treeNode.color)">{{ treeNode.count }}</span>
      </div>
      <div class="line-3 line" :style=lineHalf></div>
    </div>
    <div v-if="!isRoot" class="main-box">
      <div class="line-1 line" :style=lineHalf></div>
      <div class="rate-box">
        <img class="rate-icon" src="../../assets/image/ic_convert@2x.png" alt="convert"/>
        <span class="rate-text" v-if="treeNode.count === 0">0.00%</span>
        <span class="rate-text" v-else>{{ $tools.keepDecimals(treeNode.rate * 100) }}%</span>
      </div>
      <div class="line-2 line" :style=line></div>
      <div class="content-box">
        <span class="content-text">{{ treeNode.text }}</span>
        <span class="content-count" :style="colorDisplay(treeNode.color)">{{ treeNode.count }}</span>
      </div>
      <div v-if="hasChildren" class="line-3 line" :style=lineHalf></div>
    </div>
    <div class="children-box">
      <funnel-chart-item
        v-for="childNode in treeNode.children"
        :line-width="lineWidth"
        :key="`${treeNode.text}-${childNode.text}`"
        :tree-node="childNode"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FunnelChartItem',
  props: {
    treeNode: {
      type: Object,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    lineWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    hasChildren() {
      if (this.treeNode.children) {
        return this.treeNode.children.length > 0;
      }
      return false;
    },
    lineHalf() {
      return { width: `${this.lineWidth / 2}px` };
    },
    line() {
      return { width: `${this.lineWidth}px` };
    },
  },
  methods: {
    colorDisplay(data) {
      return { color: data };
    },
  },
};
</script>

<style scoped lang="less">
  .root-chart-item {
    &:before {
      height: 0;
    }
  }
  .funnel-chart-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 60px;
    position: relative;
    justify-content: center;
    &:before {
      content: '';
      position: absolute;
      top: 12px;
      bottom: -72px;
      left: 0;
      width: 2px;
      background-color: #E6E7F5;
    }
    .main-box {
      display: flex;
      align-items: center;
      height: 24px;
      .line {
        flex: 1 1 auto;
        //min-width: 160px;
        height: 2px;
        /*border: 1px solid #E6E7F5;*/
        background-color: #E6E7F5;
      }
      .line-1 {
        //min-width: 80px;
      }
      .line-3 {
        //min-width: 80px;
      }
      .rate-box, .content-box {
        flex: 0 0 auto;
      }
      .rate-box {
        position: relative;
        width: 48px;
        height: 16px;
        padding: 0 16px;
        .rate-icon {
          width: 100%;
          height: 100%;
        }
        .rate-text {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 100%);
          font-size: 14px;
          font-weight: 400;
          color: #333440;
          line-height: 20px;
        }
      }
      .content-box {
        position: relative;
        width: 118px;
        text-align: center;
        .content-text {
          font-size: 14px;
          font-weight: 400;
          color: #6B6D80;
          line-height: 20px;
        }
        .content-count {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);
          font-size: 21px;
          font-weight: 500;
          color: #44BE8D;
          line-height: 29px;
        }
      }
    }
    .children-box {
      .funnel-chart-item:last-child {
        margin-bottom: 0;
        &:before {
          height: 0;
        }
      }
    }
  }
</style>
