import api from './api';

export default function (text, source, target) {
  const url = 'https://translation.googleapis.com/language/translate/v2?key=AIzaSyB86x_l2tz-z1-B8FcUtY568BnPMaLl2Zg';
  return api.translate(url, {
    q: text,
    source,
    target,
    format: 'text',
  });
}
