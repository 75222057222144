<template>
  <div class="common-funnel-chart" ref="commonFunnelChart">
    <funnel-chart-item
      :is-root="true"
      :tree-node="treeNode"
      :line-width="funnelChartLineWidth"/>
  </div>
</template>

<script>
import FunnelChartItem from './FunnelChartItem.vue';
import colorList from '../../views/app_detail/analysis/color.json';

const CONTENT_WIDTH = 78;
const RATE_WIDTH = 48;

export default {
  name: 'CommonFunnelChart',
  components: { FunnelChartItem },
  props: {
    // treeNode: Object, // 多叉树
    data: Array,
    funnelData: Array,
    tree: Object,
  },
  data() {
    return {
      funnelChartLineWidth: 0, // 默认连接线宽度
      treeNode: {
        text: '',
        rate: 0,
        count: null,
        children: [],
      },
      level: 0,
    };
  },
  mounted() {
    window.addEventListener('resize', this.getLineWidth);
  },
  methods: {
    getLineWidth() {
      const html = document.querySelector('.common-funnel-chart');
      const elmWidth = html.clientWidth;
      this.funnelChartLineWidth = (elmWidth - (this.level * CONTENT_WIDTH) - ((this.level - 1) * RATE_WIDTH)) / ((this.level - 1) * 2);
    },
    getTreeNode() {
      const res = [];
      if (this.tree) {
        const queue = [];
        queue.unshift(this.tree);
        while (queue.length !== 0) {
          const item = queue.shift();
          res.push(item);
          if (item.children) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < item.children.length; i++) {
              queue.push(item.children[i]);
            }
          }
        }
      }
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < res.length; j++) {
        this.$set(res[j], 'color', colorList.color_list[j]);
        // if (str === 'all') {
        //   this.$set(res[j], 'count', this.funnelData[j]);
        // } else {
        //   this.$set(res[j], 'count', this.detailFunnelData[j]);
        // }
        this.$set(res[j], 'count', this.funnelData[j]);
        if (j > 0) {
          const { text } = res[j];
          res[j].rate = res[j].count / (this.getParent({ text }, this.tree).count);
        }
      }
      // eslint-disable-next-line prefer-destructuring
      this.treeNode = res[0];
    },
    getParent(node, tree) {
      const result = [];
      // eslint-disable-next-line no-shadow
      const findParent = function find(node, tree) {
        tree.children.forEach((item) => {
          if (item.text === node.text) {
            result.push(tree);
          }
          if ('children' in item) {
            find(node, item);
          }
        });
      };
      findParent(node, tree);
      return result[0];
    },
    getTreeLevel(tree) {
      if (!tree) return 0;
      let maxChildLevel = 0;
      const children = tree.children || [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < children.length; i++) {
        const childNode = children[i];
        maxChildLevel = Math.max(this.getTreeLevel(childNode), maxChildLevel);
      }
      return maxChildLevel + 1;
    },
  },
  watch: {
    funnelData: {
      handler(val) {
        if (val.length > 0) {
          this.getTreeNode();
        }
      },
    },
    tree: {
      handler(val) {
        this.level = this.getTreeLevel(val);
        this.getLineWidth();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
  .common-funnel-chart {
    height: 110%;
    position: relative;
  }
</style>
