const fullscreenClassName = 'is-fullscreen';
let originalScrollTop = 0;
const fn = (el, binding) => {
  const scrollContainer = el.closest('.content');
  const { value, oldValue } = binding;
  if (value !== oldValue) {
    if (value) {
      originalScrollTop = scrollContainer.scrollTop;
      el.classList.add(fullscreenClassName);
    } else {
      el.classList.remove(fullscreenClassName);
      scrollContainer.scrollTop = originalScrollTop;
    }
  }
};
export default {
  install(Vue) {
    Vue.directive('fullscreen', {
      inserted: fn,
      update: fn,
    });
  },
};
