<template>
  <div class="form-content-header">
    <div class="left-box">
      <div class="gap" :style="{ 'background-color': gap_color }"></div>
      <h3 class="title">{{ title }}</h3>
    </div>
    <div class="right-box">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'form-content-header',
  props: {
    title: String,
    gap_color: {
      type: String,
      default: '#2978FF',
    },
  },
};
</script>

<style scoped lang="less">
  .form-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 21px;
    background-color: #FFFFFF;
    overflow: hidden;
    .left-box {
      .gap, .title {
        vertical-align: middle;
      }
      .gap {
        display: inline-block;
        width: 3px;
        height: 16px;
        margin-right: 8px;
      }
      .title {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        color: #1D1E26;
        line-height: 22px;
      }
    }
  }
</style>
