<template>
  <Modal
    :value="modal_visible"
    class="test-machine-list-modal"
    :closable="false"
    :mask-closable="false">
    <div class="header" slot="header">
      <h3 class="title">{{ title }}</h3>
    </div>
    <div class="body">
      <div v-if="test_machine_list.length > 0" class="test-machine-list">
        <test-machine-item
          v-for="testMachineItem in test_machine_list"
          :key="testMachineItem.id"
          :test_machine_info_model="testMachineItem"
          @edit-confirm="editConfirmHandler"
          @delete-confirm="deleteConfirmHandler"></test-machine-item>
      </div>
      <empty-content v-if="test_machine_list.length === 0" :min_height="120"/>
    </div>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="default" @click="cancelHandler">关闭</Button>
<!--      <Button class="footer-btn" type="primary" @click="okHandler">确认</Button>-->
    </div>
  </Modal>
</template>

<script>
import TestMachineItem from './TestMachineItem.vue';
import EmptyContent from '../../../common/EmptyContent.vue';

export default {
  name: 'TestMachineListModal',
  components: { EmptyContent, TestMachineItem },
  props: {
    modal_visible: Boolean,
    test_machine_list: Array,
  },
  model: {
    prop: 'modal_visible',
    event: 'visible-change',
  },
  data() {
    return {
      title: '编辑测试机',
    };
  },
  methods: {
    editConfirmHandler(testMachineInfo) {
      this.$emit('edit', testMachineInfo);
    },
    deleteConfirmHandler(id) {
      this.$emit('delete', id);
    },
    okHandler() {
      this.$emit('visible-change', false);
      this.$emit('ok');
    },
    cancelHandler() {
      this.$emit('visible-change', false);
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="less">
  .test-machine-list-modal {
    /deep/ .ivu-modal-header {
      padding-bottom: 0;
      border-bottom: 0;
    }
    /deep/ .ivu-modal-body {
      .ivu-form-item:last-child {
        margin-bottom: 0;
      }
    }
    /deep/ .ivu-modal-footer {
      padding-top: 0;
      border-top: none;
    }
    /deep/ .ivu-form {
      padding: 0 24px;
    }
    .header {
      padding-bottom: 0;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
