<template>
  <div class="task-analysis">
    <div class="content-header">
      <Breadcrumb>
        <BreadcrumbItem :to="{ name: 'DataAnalysis'}">数据分析</BreadcrumbItem>
        <BreadcrumbItem class="task-name">{{ this.$route.query.task_name}}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <search-condition
      :showDatePicker="true"
      :time-value="chartTimeValue"
      :searchLoading="searchLoading"
      @func="searchChart">
    </search-condition>
    <overview-chart
      v-for="chartInfo in chart_info_list"
      :key="chartInfo.task_name"
      class="common-chart"
      :chart_info="chartInfo"
      :dimension_type_list="chartInfo.dimension_list"
      :index_list="chartInfo.index_list"
      :index_data_list="chartInfo.index_data_list"
      :show_chart_legend_box="true"
      :show_chart_edit_option="false"
      :ref="`chart_${chartInfo.name}`"
      @chart-type-change="chartDimensionSelectChangeHandler"
      @dimension-change="chartDimensionSelectChangeHandler">
    </overview-chart>
    <div class="funnel-chart-box" >
      <div class="funnel" ref="funnel">
        <common-funnel-chart
          ref="commonFunnelChart"
          :funnel-data="funnelData"
          :tree="tree"/>
      </div>
    </div>
    <div class="detail-table">
      <search-condition
        :searchLoading="detailSearchLoading"
        :showDatePicker="false"
        :subList="subList"
        :subLabel="subLabel"
        :subVisible="true"
        @func="searchDetailChart">
      </search-condition>
        <overview-chart
          style="margin-top: 0"
          :class="!searchLoad ? 'hideChart' : ''"
          v-for="chartInfo in chart_info_sub_list"
          :key="chartInfo.task_name"
          :chart_info="chartInfo"
          :dimension_type_list="chartInfo.dimension_list"
          :index_list="chartInfo.index_list"
          :index_data_list="chartInfo.index_data_list"
          :show_chart_legend_box="true"
          :show_chart_edit_option="false"
          :ref="`chart_${chartInfo.name}`"
          @chart-type-change="chartDimensionSelectChangeHandler"
          @dimension-change="chartDimensionSelectChangeHandler">
        </overview-chart>
        <div class="funnel-chart-box">
          <div class="funnel" ref="funnelDetail" :class="!searchLoad ? 'hideChart' : ''">
            <common-funnel-chart
              ref="commonFunnelDetail"
              :funnel-data="detailFunnelData"
              :tree="detailTree"/>
          </div>
        </div>
      <empty-content v-if="!searchLoad" :empty_text="emptyText" style="background: #FFFFFF"></empty-content>
    </div>
    <div class="table">
      <search-condition
        :showDatePicker="true"
        :timeValue="tableTimeValue"
        :searchLoading="detailTableSearchLoading"
        @func="searchDetailTable">
      </search-condition>
      <common-table
        :table-title="table_title"
        :columns="tableColumns"
        :data="table_data"
        :dimension-list="dimension_list"
        :index-list="index_list"
        :selected-dimension-list="selected_dimension_list"
        :loading.sync="detailTableSearchLoading"/>
    </div>
  </div>
</template>

<script>
import EmptyContent from '../../../components/EmptyContent.vue';
import SearchCondition from './SearchConditon.vue';
import summaryChartMixin from '../../../push-common/mixins/summaryChartMixins';
import OverviewChart from '../../../components/common/OverviewChart.vue';
import CommonFunnelChart from '../../../components/common_funnel_chart/CommonFunnelChart.vue';
import dataAnanlysisDetail from '../../../push-common/page_config/dataAnalysisDetail.json';
import CommonTable from '../../../components/common-table/comonTable.vue';
import TableSortMixin from '../../../components/common-table/tableSortMixin';

const fn = (list, map) => list.map((item) => ({
  label: map.get(item),
  value: item,
}));

export default {
  name: 'DataAnalysisDetail',
  components: {
    CommonFunnelChart,
    SearchCondition,
    OverviewChart,
    CommonTable,
    EmptyContent,
  },
  mixins: [summaryChartMixin, TableSortMixin],
  data() {
    return {
      chart_info_list: [
        {
          id: 'overview_chart_detail',
          type: 'line',
          name: 'overview_chart',
          title: '总览图表',
          api: this.$api.getAnalysisDetailData,
          chart_data: null,
          chart: null,
          index_list: [
            'receive',
            'dismiss',
            'open',
            'accept',
            'deliver',
          ],
          dimension_list: [],
          index_data_list: [],
        },
      ],
      countryValue: [],
      searchLoading: false,
      chartTimeValue: [],
      tableTimeValue: [],
      detailSearchLoading: false,
      detailTableSearchLoading: true,
      funnelChartDataAll: {
        text: '发送数',
        count: 0,
        color: '',
        children: [
          {
            text: '实际可达数',
            count: 0,
            color: '',
            children: [
              {
                text: '打开数',
                count: 0,
                color: '',
              },
              {
                text: '划掉数',
                count: 0,
                color: '',
              },
            ],
          },
        ],
      },
      funnelChartDataDetailAll: {
        text: '发送数',
        count: 0,
        color: '',
        children: [
          {
            text: '实际可达数',
            count: 0,
            color: '',
            children: [
              {
                text: '打开数',
                count: 0,
                color: '',
              },
              {
                text: '划掉数',
                count: 0,
                color: '',
              },
            ],
          },
        ],
      },
      funnelChartDataSection: {
        text: '实际可达数',
        count: 0,
        color: '',
        children: [
          {
            text: '打开数',
            count: 0,
            color: '',
          },
          {
            text: '划掉数',
            count: 0,
            color: '',
          },
        ],
      },
      funnelChartDataDetailSection: {
        text: '实际可达数',
        count: 0,
        color: '',
        children: [
          {
            text: '打开数',
            count: 0,
            color: '',
          },
          {
            text: '划掉数',
            count: 0,
            color: '',
          },
        ],
      },
      funnelData: [],
      countryFilter: false,
      funnelChartData: {},
      funnelChartDetailData: {},
      level: 0,
      detailLevel: 0,
      subValue: null,
      subList: [],
      subLabel: '',
      chart_info_sub_list: [
        {
          id: 'overview_chart_sub_detail',
          type: 'line',
          name: 'overview_sub_chart',
          title: '详细报告',
          subHeading: '',
          api: this.$api.getDetailData,
          chart_data: null,
          chart: null,
          index_list: [
            'receive',
            'dismiss',
            'open',
            'accept',
            'deliver',
          ],
          dimension_list: [],
          index_data_list: [],
        },
      ],
      detailCountryValue: [],
      detailTableCountryValue: [],
      detailFunnelData: [],
      detailCountryFilter: false,
      dimension_list: dataAnanlysisDetail.dimension_list,
      index_list: dataAnanlysisDetail.index_list,
      selected_dimension_list: [
        {
          label: '标签',
          value: 'label',
        },
      ],
      selected_index_list: [],
      table_title: '详细报表',
      columns: [],
      table_data: [],
      emptyText: '点击搜索，开始查询',
      searchLoad: false,
      tree: {},
      detailTree: {},
    };
  },
  created() {
    this.getTimeData();
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    label_map() {
      const map = new Map();
      const list = [...this.dimension_list, ...this.index_list];
      list.forEach((item) => {
        map.set(item.value, item.label);
      });
      return map;
    },
    tableColumns() {
      return this.columns.map((column) => column.key);
    },
    table_width() {
      const tableElement = this.$refs.table || document.querySelector('.table');
      return tableElement ? tableElement.clientWidth : 0;
    },
  },
  watch: {
    countryValue() {
      if (!this.searchLoading) {
        this.getFunnelData();
      }
      console.log(this.countryValue);
      if (this.countryValue.length === 2) {
        this.countryFilter = false;
      } else {
        this.countryFilter = true;
      }
      console.log(this.countryFilter);
    },
    detailCountryValue() {
      if (!this.detailSearchLoading) {
        this.getDetailFunnelData();
      }
      console.log(this.detailCountryValue.length); // 值为2
      if (this.detailCountryValue.length === 2) {
        this.detailCountryFilter = false;
      } else {
        this.detailCountryFilter = true;
      }
    },
  },
  methods: {
    fetchData() {
      this.getData();
      this.getFunnelData();
      this.getDetailSubData();
    },
    getData() {
      this.searchLoading = false;
      const chartLoader = this.$loader(this.getChartContainer(`chart_${this.chart_info_list[0].name}`)).show();
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
      };
      const params = {
        country: this.countryValue,
        begin_time: this.chartTimeValue[0],
        end_time: this.chartTimeValue[1],
      };
      const promise = this.$api.getAnalysisDetailData(urlParams, params);
      return promise.then((response) => {
        const responseData = response.data.chart;
        const chartData = { responseData };
        if (chartData) {
          this.newSummaryChartCallback(chartData, this.chart_info_list[0]);
        } else {
          throw new Error('请求出错');
        }
      }).finally(() => {
        this.searchLoading = true;
        chartLoader.hide();
      });
    },
    getDetailSubData() {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
      };
      const promise = this.$api.getDetailTableSubData(urlParams);
      return promise.then((response) => {
        this.subList = response.data.list;
        this.subValue = response.data.list[0].value;
        this.subLabel = response.data.list[0].label;
        this.chart_info_sub_list[0].subHeading = response.data.list[0].label;
      });
    },
    getDetailData() {
      this.detailSearchLoading = false;
      const chartLoader = this.$loader(this.getChartContainer(`chart_${this.chart_info_sub_list[0].name}`)).show();
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
        value: this.subValue,
      };
      const params = {
        country: this.countryValue,
      };
      const promise = this.$api.getDetailData(urlParams, params);
      return promise.then((response) => {
        const responseData = response.data.chart;
        const chartData = { responseData };
        if (chartData) {
          this.newSummaryChartCallback(chartData, this.chart_info_sub_list[0]);
        } else {
          throw new Error('请求详细任务出错');
        }
      }).finally(() => {
        chartLoader.hide();
        this.detailSearchLoading = true;
      });
    },
    getDetailTableData() {
      this.detailTableSearchLoading = false;
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
      };
      const params = {
        country: this.detailTableCountryValue,
        begin_time: this.tableTimeValue[0],
        end_time: this.tableTimeValue[1],
      };
      const promise = this.$api.getDetailTableData(urlParams, params);
      // eslint-disable-next-line consistent-return
      return promise.then((response) => {
        if (response.data !== null) {
          const {
            header,
            records,
          } = response.data;
          const dimensions = ['label'];
          const indexes = header.filter((item) => dimensions.indexOf(item) === -1);
          // Date
          if (this.selected_dimension_list.length <= 1) {
            this.selected_dimension_list = [...fn(dimensions, this.label_map)];
          }
          if (this.selected_index_list.length === 0) {
            this.selected_index_list = [...fn(indexes, this.label_map)];
          }
          this.configTableColumns([
            ...this.selected_dimension_list.map((item) => item.value),
            ...this.selected_index_list.map((item) => item.value),
          ]);
          this.table_data = [...records];
          return null;
        }
      })
      .finally(() => {
        this.detailTableSearchLoading = true;
      });
    },
    searchChart(data) {
      this.countryValue = data.country;
      this.chartTimeValue = data.time_value;
      console.log(data);
      this.getData();
      this.getFunnelData();
    },
    searchDetailChart(data) {
      this.searchLoad = true;
      this.detailCountryValue = data.country;
      this.subValue = data.value;
      this.getDetailData();
      this.getDetailFunnelData();
    },
    searchDetailTable(data) {
      console.log(data);
      this.detailTableCountryValue = data.country;
      this.tableTimeValue = data.time_value;
      this.getDetailTableData();
    },
    getFunnelData() {
      const funnelLoad = this.$loader(this.$refs.funnel, { backgroundColor: 'rgba(41,120,255,0.04)' }).show();
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
      };
      const params = {
        country: this.countryValue,
      };
      const promise = this.$api.getFunnelData(urlParams, params);
      return promise.then((response) => {
        this.funnelData = [];
        if (this.countryFilter) {
          this.funnelData.push(response.data.accessibility.deliver);
          this.funnelData.push(response.data.event_data.open);
          this.funnelData.push(response.data.event_data.dismiss);
          this.tree = this.funnelChartDataSection;
        } else {
          this.funnelData.push(response.data.accessibility.accept);
          this.funnelData.push(response.data.accessibility.deliver);
          this.funnelData.push(response.data.event_data.open);
          this.funnelData.push(response.data.event_data.dismiss);
          this.tree = this.funnelChartDataAll;
        }
      }).finally(() => {
        funnelLoad.hide();
      });
    },
    getDetailFunnelData() {
      const funnelLoad = this.$loader(this.$refs.funnelDetail, { backgroundColor: 'rgba(41,120,255,0.04)' }).show();
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
        value: this.subValue,
      };
      const params = {
        country: this.countryValue,
      };
      const promise = this.$api.getDetailFunnelData(urlParams, params);
      return promise.then((response) => {
        this.detailFunnelData = [];
        if (this.detailCountryFilter) {
          this.detailFunnelData.push(response.data.accessibility.deliver);
          this.detailFunnelData.push(response.data.event_data.open);
          this.detailFunnelData.push(response.data.event_data.dismiss);
          this.detailTree = this.funnelChartDataDetailSection;
        } else {
          this.detailFunnelData.push(response.data.accessibility.accept);
          this.detailFunnelData.push(response.data.accessibility.deliver);
          this.detailFunnelData.push(response.data.event_data.open);
          this.detailFunnelData.push(response.data.event_data.dismiss);
          this.detailTree = this.funnelChartDataDetailAll;
        }
      })
      .finally(() => {
        funnelLoad.hide();
      });
    },
    getIndexWidth(headerLength, resizedIndexWidth = -1, selectedDimensions, table, curDateWidth) {
      const dimensions = selectedDimensions || table.selected_dimension_list;
      const tableWidth = this.table_width;
      const dateWidth = curDateWidth || 150;
      const dimensionCount = dimensions.length;
      const indexCount = resizedIndexWidth !== -1
        ? headerLength - dimensions.length - 1
        : headerLength - dimensions.length;
      const width = resizedIndexWidth !== -1
        ? (tableWidth - (dimensionCount - 1) * 108 - dateWidth - resizedIndexWidth) / indexCount
        : (tableWidth - (dimensionCount - 1) * 108 - dateWidth) / indexCount;
      return width > 108 ? width : 108;
    },
    configTableColumns(header) {
      this.columns = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < header.length; i++) {
        let obj;
        if (this.selected_dimension_list.find((item) => item.value === header[i])) {
          obj = {
            key: header[i],
            title: this.label_map.get(header[i]),
            width: header[i] === 'date' ? 150 : 108,
            align: 'left',
            fixed: 'left',
            sortable: this.sortable_map.get(header[i]) ? 'custom' : false,
            // render: this.dimensionRender,
            // renderHeader: this.dimensionHeaderRender,
            resizable: true,
          };
        } else {
          obj = {
            key: header[i],
            title: this.label_map.get(header[i]),
            width: this.getIndexWidth(header.length, -1, this.selected_dimension_list),
            minWidth: 96,
            // align: 'center',
            // sortable: this.sortable_map.get(header[i]) ? 'custom' : false,
            // render: this.special_index_list.indexOf(header[i]) !== -1
            //   ? this.special_index_render
            //   : this.indexRender,
            // renderHeader: this.indexHeaderRender,
            resizable: true,
          };
        }
        this.columns.push(obj);
      }
    },
    getTimeData() {
      const date = new Date();
      const date1 = this.$tools.dateFormatter(new Date());
      const date2 = this.$tools.dateFormatter(new Date(date.valueOf() - 7 * 24 * 60 * 60 * 1000));
      const date3 = this.$tools.dateFormatter(new Date(date.valueOf() - 30 * 24 * 60 * 60 * 1000));
      this.$set(this.tableTimeValue, 0, date2);
      this.$set(this.tableTimeValue, 1, date1);
      this.$set(this.chartTimeValue, 0, date3);
      this.$set(this.chartTimeValue, 1, date1);
    },
  },
};
</script>

<style scoped lang="less">
.common-chart {
  margin-top: 16px;
}
.common-chart-translate {
  width:100%;
  height: 374px;
  background: #FFFFFF;
  margin-top: 16px;
  margin-right: 16px;
}
.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  background: transparent;
  /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-link, /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-separator {
    font-size: 20px;
    line-height: 28px;
  }
  .task-name{
    font-size: 20px;
    color: #515a6e;
  }
}
.common-chart{
  border: none !important;
}
.funnel-chart-box {
  background-color: #FFFFFF;
  display: flex;
  position: relative;
  .funnel {
    background: rgba(41,120,255,0.04);
    border-radius: 4px;
    margin: 16px 24px 20px;
    padding-left: 164px;
    padding-right: 164px;
    padding-top: 16px;
    width: calc(~"100% - 48px");
    height: 174px;
    position: relative;
  }
}
.detail-table{
  margin-top: 16px;
  position: relative;
  .hideChart{
    display: none;
  }
}
.table{
  margin-top: 16px;
}
</style>
