<template>
  <div class="target-config-form">
    <form-content-header title="目标设置" />
    <push-switch
      v-model="mode"
      :list="[
        { label: '标准模式', value: 'standard' },
        { label: '高级模式', value: 'advanced' },
      ]"
      left_text="标准模式"
      right_text="高级模式"
      style="margin-bottom: 24px"
    ></push-switch>
    <!--    <RadioGroup v-model="mode" style="margin-bottom: 24px;">-->
    <!--      <Radio label="standard">标准模式</Radio>-->
    <!--      <Radio label="advanced">高级模式</Radio>-->
    <!--    </RadioGroup>-->
    <div v-show="mode === 'standard'" class="form-container">
      <Form
        ref="targetConfigForm"
        :model="standard_form"
        :rules="standard_form_rules"
        :label-width="110"
      >
        <FormItem label="付费类型" prop="payment_type">
          <RadioGroup v-model="standard_form.payment_type">
            <Radio label="all">全部</Radio>
            <Radio label="pro">专业版</Radio>
            <Radio label="free">免费版</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="地区" prop="region.list">
          <Select
            class="include-select"
            v-model="standard_form.region.op"
            style="width: 80px; margin-right: 8px"
            transfer
          >
            <Option value="excluded">不包含</Option>
            <Option value="included">包含</Option>
          </Select>
          <Select
            class="multi-select"
            v-model="standard_form.region.value"
            placeholder="请选择地区，默认不选择为全部"
            :max-tag-count="4"
            :max-tag-placeholder="getMaxTagPlaceholder"
            multiple
            transfer
            filterable
          >
            <Option v-for="item in region_list" :key="item.value" :value="item.value">{{
              item.label
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="厂商">
          <Select
            class="include-select"
            v-model="standard_form.manufacturer.op"
            style="width: 80px; margin-right: 8px"
            transfer
          >
            <Option value="excluded">不包含</Option>
            <Option value="included">包含</Option>
          </Select>
          <Select
            class="multi-select"
            v-model="standard_form.manufacturer.value"
            placeholder="请选择厂商，默认不选择为全部"
            :max-tag-count="4"
            :max-tag-placeholder="getMaxTagPlaceholder"
            multiple
            transfer
            filterable
          >
            <Option v-for="item in manufacturer_list" :key="item.value" :value="item.value">{{
              item.label
            }}</Option>
          </Select>
        </FormItem>
      </Form>
    </div>
    <div v-show="mode === 'advanced'" class="form-container">
      <Form
        ref="targetConfigForm"
        :model="advanced_form"
        :rules="advanced_form_rules"
        :label-width="110"
      >
        <FormItem label="付费类型" prop="payment_type">
          <RadioGroup v-model="advanced_form.payment_type">
            <Radio label="all">全部</Radio>
            <Radio label="pro">专业版</Radio>
            <Radio label="free">免费版</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="地区" prop="region.list">
          <Select
            class="include-select"
            v-model="advanced_form.region.op"
            style="width: 80px; margin-right: 8px"
            transfer
          >
            <Option value="excluded">不包含</Option>
            <Option value="included">包含</Option>
          </Select>
          <Select
            class="multi-select"
            v-model="advanced_form.region.value"
            :max-tag-count="4"
            :max-tag-placeholder="getMaxTagPlaceholder"
            multiple
            transfer
            filterable
          >
            <Option v-for="item in region_list" :key="item.value" :value="item.value">{{
              item.label
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="语言" prop="language">
          <Select
            class="include-select"
            v-model="advanced_form.fcm_languages.op"
            style="width: 80px; margin-right: 8px"
            transfer
          >
            <Option value="excluded">不包含</Option>
            <Option value="included">包含</Option>
          </Select>
          <Select
            class="multi-select"
            v-model="advanced_form.fcm_languages.value"
            :max-tag-count="4"
            :max-tag-placeholder="getMaxTagPlaceholder"
            multiple
            transfer
            filterable
          >
            <Option v-for="item in language_list" :key="item.value" :value="item.value">{{
              item.label
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="APP 版本" prop="app_version">
          <Select
            class="include-select"
            v-model="advanced_form.app_version.op"
            style="width: 80px; margin-right: 8px"
            transfer
          >
            <Option value="excluded">不包含</Option>
            <Option value="included">包含</Option>
          </Select>
          <Select
            class="multi-select"
            v-model="advanced_form.app_version.value"
            :max-tag-count="4"
            :max-tag-placeholder="getMaxTagPlaceholder"
            multiple
            transfer
            filterable
          >
            <Option v-for="item in app_version_list" :key="item.value" :value="item.value">{{
              item.label
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="系统版本" prop="os_version">
          <Select
            class="include-select"
            v-model="advanced_form.os_version.op"
            style="width: 80px; margin-right: 8px"
            transfer
          >
            <Option value="excluded">不包含</Option>
            <Option value="included">包含</Option>
          </Select>
          <Select
            class="multi-select"
            v-model="advanced_form.os_version.value"
            :max-tag-count="4"
            :max-tag-placeholder="getMaxTagPlaceholder"
            multiple
            transfer
            filterable
          >
            <Option v-for="item in os_version_list" :key="item.value" :value="item.value">{{
              item.label
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="用户分群" prop="user_group">
          <Select
            class="include-select"
            value="included"
            disabled
            style="width: 80px; margin-right: 8px"
            transfer
          >
            <Option value="included">包含</Option>
          </Select>
          <Select
            class="multi-select"
            v-model="selectedUserGroup"
            clearable
            transfer
            events-enabled
            @on-change="handleUserGroupChange"
          >
            <Option
              v-for="option in userGroups"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            ></Option>
          </Select>
          <div v-if="!userGroups?.length" class="user-group-tips">
            <Icon type="ios-information-circle-outline" size="14" />
            <span>当前项目暂无用户分群。 </span>
            <a :href="createUserGroupLink" target="_blank">
              去创建用户分群
              <Icon type="md-arrow-forward" size="14" />
            </a>
          </div>
        </FormItem>
      </Form>
    </div>
    <div class="estimate" v-show="estimateNumber !== null">
      <p>
        潜在用户符合此宣传活动的投放条件：
        <span class="estimateNumber">{{ estimateNumber }}</span>
        <Tooltip
          placement="bottom"
          transfer
          max-width="334"
          :content="toolTipContent"
          transfer-class-name="toolTipTransfer"
        >
          <Icon class="icon" type="ios-help-circle-outline" :size="14" />
        </Tooltip>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import FormContentHeader from '../../../common/form-content-header.vue';
import routerTrigger from '../../../../push-common/mixins/routerTrigger';
import PushSwitch from './PushSwitch.vue';

export default {
  name: 'TargetConfigForm',
  components: { PushSwitch, FormContentHeader },
  mixins: [routerTrigger],
  props: {
    current_push: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mode: 'standard',
      region_list: [],
      manufacturer_list: [],
      language_list: [],
      app_version_list: [],
      os_version_list: [],
      standard_form: {
        region: {
          op: 'included',
          value: [],
        },
        manufacturer: {
          op: 'included',
          value: [],
        },
        payment_type: 'all',
      },
      standard_form_rules: {},
      advanced_form: {
        payment_type: 'all',
        other_condition: {
          type: 2,
          group: {},
        },
        region: {
          op: 'included',
          value: [],
        },
        fcm_tokens: {
          op: 'included',
          value: [],
        },
        fcm_languages: {
          op: 'included',
          value: [],
        },
        time_zones: {
          op: 'included',
          value: [],
        },
        app_version: {
          op: 'included',
          value: [],
        },
        os_version: {
          op: 'included',
          value: [],
        },
        os: {
          op: 'included',
          value: [],
        },
      },
      advanced_form_rules: {},
      estimateNumber: '暂无数据',
      toolTipContent:
        '由于设备处于非活动状态，一些目标用户将看不到通知。对于周期性宣传活动，估算仅适用于初次发送',
      userGroups: [],
      selectedUserGroup: '',
    };
  },
  computed: {
    createUserGroupLink() {
      return `https://analysis-bq.thinkyeah.com/${this.$route.params.app_id}/user/group`;
    },
    targetChange() {
      const { standard_form, advanced_form } = this;
      return { standard_form, advanced_form };
    },
    debounce() {
      return this.$tools.debounce(this.getEstimations, 1000);
    },
  },
  methods: {
    handleUserGroupChange(val) {
      if (!val) {
        this.advanced_form.other_condition = {
          type: 2,
          group: {},
        };
      } else {
        this.advanced_form.other_condition = {
          type: 2,
          group: {
            group_table: val,
            user_group_name: this.userGroups.find((x) => x.value === val).label,
          },
        };
      }
    },
    fetchData() {
      this.fetchAppInfo();
    },
    async fetchAppUserGroups() {
      try {
        const { data } = await this.$api.getAppUserGroups({ app_id: this.$route.params.app_id });
        this.userGroups = data.data?.map((x) => ({ value: x.group_table, label: x.user_group_name })) ?? [];
      } catch (e) {
        console.log(e);
      }
    },
    fetchAppInfo() {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const promise = this.$api
        .getAppInfo(urlParams)
        .then((response) => {
          const {
            language_info,
            // license_info,
            app_version,
            region_info,
            os_version,
            manufacturer,
          } = response.data.data;
          this.language_list = language_info.map((item) => ({
            label: item.lang_desc,
            value: item.lang_code,
          }));
          this.$store.commit('setLanguageList', {
            language_list: [...this.language_list],
          });
          this.region_list = region_info.map((item) => ({
            label: item,
            value: item,
          }));
          this.os_version_list = os_version.map((item) => ({
            label: item,
            value: item,
          }));
          this.app_version_list = app_version.map((item) => ({
            label: item,
            value: item,
          }));
          this.manufacturer_list = manufacturer.map((item) => ({
            label: item.name,
            value: item.value,
          }));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {});
      return promise;
    },
    getMaxTagPlaceholder(count) {
      return `已选择${count}项`;
    },
    getEstimations() {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      if (this.mode === 'standard') {
        const standardFormData = { region: '' };
        standardFormData.region = this.standard_form.region;
        if (this.standard_form.payment_type === 'all') {
          standardFormData.license = {
            op: 'included',
            value: [],
          };
        } else if (this.standard_form.payment_type === 'free') {
          standardFormData.license = {
            op: 'included',
            value: ['free'],
          };
        } else if (this.standard_form.payment_type === 'pro') {
          standardFormData.license = {
            op: 'included',
            value: ['pro'],
          };
        }
        console.log(standardFormData);
        const promise = this.$api.getEstimationsData(urlParams, standardFormData);
        promise.then((response) => {
          if (response.data.data < 10000 && response.data.data > 0) {
            this.estimateNumber = response.data.data;
            this.toolTipContent = `估算的依据是已注册接受通知的大约${this.estimateNumber}位用户。由于设备处于非活动状态，一些目标用户将看不到通知。对于周期性宣传活动，估算仅适用于初次发送`;
          } else if (response.data.data > 10000) {
            const num = response.data.data / 10000;
            this.estimateNumber = `${Math.round(num)}万`;
            this.toolTipContent = `估算的依据是已注册接受通知的大约${this.estimateNumber}位用户。由于设备处于非活动状态，一些目标用户将看不到通知。对于周期性宣传活动，估算仅适用于初次发送`;
          } else {
            this.estimateNumber = '暂无数据';
            this.toolTipContent = '由于设备处于非活动状态，一些目标用户将看不到通知。对于周期性宣传活动，估算仅适用于初次发送';
          }
        });
      } else {
        const { payment_type, ...advancedFormData } = this.advanced_form;
        if (payment_type === 'all') {
          advancedFormData.license = {
            op: 'included',
            value: ['pro', 'free'],
          };
        } else if (payment_type === 'free') {
          advancedFormData.license = {
            op: 'included',
            value: ['free'],
          };
        } else if (payment_type === 'pro') {
          advancedFormData.license = {
            op: 'included',
            value: ['pro'],
          };
        }
        const promise = this.$api.getEstimationsData(urlParams, advancedFormData);
        promise.then((response) => {
          if (response.data.data < 10000 && response.data.data > 0) {
            this.estimateNumber = response.data.data;
            this.toolTipContent = `估算的依据是已注册接受通知的大约${this.estimateNumber}位用户。由于设备处于非活动状态，一些目标用户将看不到通知。对于周期性宣传活动，估算仅适用于初次发送`;
          } else if (response.data.data > 10000) {
            const num = response.data.data / 10000;
            this.estimateNumber = `${Math.round(num)}万`;
            this.toolTipContent = `估算的依据是已注册接受通知的大约${this.estimateNumber}位用户。由于设备处于非活动状态，一些目标用户将看不到通知。对于周期性宣传活动，估算仅适用于初次发送`;
          } else {
            this.estimateNumber = '暂无数据';
            this.toolTipContent = '由于设备处于非活动状态，一些目标用户将看不到通知。对于周期性宣传活动，估算仅适用于初次发送';
          }
        });
      }
    },
  },
  watch: {
    current_push: {
      // 编辑或者复制某个push的时候
      handler(newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          // 设置mode
          this.mode = newVal.query_type === 0 ? 'standard' : 'advanced';
          if (this.mode === 'standard') {
            this.standard_form.region = { ...newVal.push_condition.region };
            this.standard_form.manufacturer = newVal.push_condition.manufacturer
              ? { ...newVal.push_condition.manufacturer }
              : this.standard_form.manufacturer;
            if (newVal.push_condition.license.value.length === 0) {
              this.standard_form.payment_type = 'all';
            } else {
              const type = newVal.push_condition.license.value[0];
              this.standard_form.payment_type = type;
            }
          } else if (this.mode === 'advanced') {
            const { license, ...advancedValue } = JSON.parse(JSON.stringify(newVal.push_condition));
            let paymentType;
            if (license.value.length === 0) {
              paymentType = 'all';
            } else {
              // eslint-disable-next-line prefer-destructuring
              paymentType = license.value[0];
            }
            if (advancedValue.other_condition?.group) {
              this.selectedUserGroup = advancedValue.other_condition.group.group_table;
            }
            this.advanced_form = {
              payment_type: paymentType,
              ...advancedValue,
            };
          }
        }
      },
      immediate: true,
    },
    standard_form: {
      handler() {
        this.debounce();
      },
      deep: true,
    },
    advanced_form: {
      handler() {
        this.debounce();
      },
      deep: true,
    },
    mode() {
      this.debounce();
    },
  },
  created() {
    this.fetchAppInfo();
    this.fetchAppUserGroups();
  },
  mounted() {
    this.getEstimations();
  },
};
</script>

<style scoped lang="less">
.target-config-form {
  margin-bottom: 8px;
  padding: 0 24px;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  @import "../../../../assets/less/create_push_form/formContainer.less";
  .form-container {
    /deep/ .include-select {
      width: 80px;
      margin-right: 8px;
    }
    /deep/ .multi-select {
      width: calc(~"100% - 88px");
    }
  }
  .estimate {
    width: 100%;
    height: 42px;
    background: #fafbfc;
    border-radius: 4px;
    margin: 0 auto;
    margin-bottom: 20px;
    p {
      line-height: 22px;
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 14px;
      color: #333440;
      opacity: 0.7;
      padding-left: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      .estimateNumber {
        font-family: "PingFang SC";
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #2978ff;
      }
      .toolTipTransfer {
        padding: 12px 16px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
      }
      .icon {
        margin-left: 6px;
        display: block;
        vertical-align: middle;
      }
    }
  }

  .user-group-tips {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 17px;
    margin-top: 8px;
    color: #878aa2;
    text-align: right;
    font-size: 12px;
    .ivu-icon {
      margin-right: 4px;
    }
    a {
      font-size: 12px;
      color: #2978ff;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>
