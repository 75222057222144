<template>
  <div class="data-analysis">
      <content-header title="数据分析">
      </content-header>
      <search-condition
        :timeValue="chartTimeValue"
        :searchLoading="searchLoading"
        :countryVisible="true"
        country_value="value"
        @func="searchChart">
      </search-condition>
      <overview-chart
        v-for="chartInfo in chart_info_list"
        :key="chartInfo.task_name"
        class="common-chart"
        :chart_info="chartInfo"
        :dimension_type_list="chartInfo.dimension_list"
        :index_list="chartInfo.index_list"
        :index_data_list="chartInfo.index_data_list"
        :show_chart_legend_box="true"
        :show_chart_edit_option="false"
        :ref="`chart_${chartInfo.name}`"
        :empty_text="emptyText"
        @chart-type-change="chartDimensionSelectChangeHandler"
        @dimension-change="chartDimensionSelectChangeHandler">
      </overview-chart>
    <div class="funnel-chart-box" >
      <div class="funnel" ref="funnel">
        <common-funnel-chart
          :funnel-data="funnelData"
          :tree="funnelData.length > 3 ? funnelAllTree :funnelTree"/>
      </div>
    </div>
    <search-condition
      class="tableCondition"
      :timeValue="tableTimeValue"
      :searchLoading="searchLoading"
      :countryVisible="false"
      @func="searchTable">
    </search-condition>
      <div class="tableChart">
        <div class="tableTitle">总览表格</div>
          <div v-show="current_table_data.length" class="table" ref="tableListWrap">
            <Table :columns="tableColumns" :data="current_table_data" class="table-list">
              <template slot-scope="{ row }" slot="task_name" class="task-name">
                <Tooltip style="width: 100%" :content="row.task_name" :disabled="$tools.getStringPixel(row.task_name) < 207" :maxWidth="200" placement="top" transfer>
                  <div class="task-title" >
                    <a href="#" @click.prevent="jumpToDataAnalysisDetail(row)">{{row.task_name}}</a>
                  </div>
                </Tooltip>
              </template>
              <template slot-scope="{ row }" slot="push_type">
                <Tooltip :content="row.push_type === 'notification' ? '通知类型' : 'JSON 类型'" placement="top" transfer>
                  <img v-if="row.push_type === 'notification'" class="push-type-icon table-icon" src="../../assets/image/ic_notification@2x.png" alt="notification">
                  <img v-if="row.push_type === 'message'" class="push-type-icon table-icon" src="../../assets/image/ic_json@2x.png" alt="message">
                </Tooltip>
              </template>
              <template slot-scope="{ row }" slot="created_at_time" >
                <span>{{row.created_at_time}}</span>
              </template>
              <!--          <template slot-scope="{ row }" slot="schedule_type">-->
              <!--            <span>{{row.schedule_type}}</span>-->
              <!--          </template>-->
              <template slot-scope="{ row }" slot="schedule_type" >
                <Tooltip :content="getScheduleTypeText(row.schedule_type)" placement="top" transfer>
                  <img v-if="row.schedule_type === 'immediate'" class="schedule-type-icon table-icon" src="../../assets/image/ic_immediate@2x.png" alt="immediate">
                  <img v-if="row.schedule_type === 'daily'||row.schedule_type ==='weekly'" class="schedule-type-icon table-icon" src="../../assets/image/ic_periodic_time@2x.png" alt="periodic_time">
                  <img v-if="row.schedule_type === 'precise_time'" class="schedule-type-icon table-icon" src="../../assets/image/ic_precise_time@2x.png" alt="precise_time">
                </Tooltip>
              </template>
              <template slot-scope="{ row }" slot="analytic_data">
                <Tooltip
                  placement="top"
                  :content="tooltip(row)"
                  max-width="400">
                  <a class="data-box" href="#" @click.prevent="jumpToDataAnalysisDetail(row)">
                    <div class="deliver-box" :style="geDeliverBoxWidth(row)"></div>
                  </a>
                </Tooltip>
              </template>
            </Table>
            <div class="page-box">
              <Page
                class="page"
                simple
                :total="table_list.length"
                :page-size="pageSize"
                :current.sync="pageNum"
                @on-page-size-change="pageHandle"
                show-sizer />
            </div>
          </div>
          <empty-content v-show="!current_table_data.length" :empty_text="tableEmptyText"></empty-content>
    </div>

  </div>
</template>

<script>
import EmptyContent from '../../components/EmptyContent.vue';
import ContentHeader from '../../components/page/content/ContentHeader.vue';
import SearchCondition from './analysis/SearchConditon.vue';
import OverviewChart from '../../components/common/OverviewChart.vue';
import summaryChartMixin from '../../push-common/mixins/summaryChartMixins';
import AnalysisListConfig from '../../push-common/page_config/AnalysisList.json';
import CommonFunnelChart from '../../components/common_funnel_chart/CommonFunnelChart.vue';

export default {
  name: 'DataAnalysis',
  components: {
    EmptyContent,
    SearchCondition,
    ContentHeader,
    OverviewChart,
    CommonFunnelChart,
},
  mixins: [summaryChartMixin],
  data() {
    return {
      chart_info_list: [
        {
          id: 'overview_chart',
          type: 'line',
          name: 'overview_chart',
          title: '总览图表',
          api: this.$api.getConditionData,
          chart_data: null,
          chart: null,
          index_list: [
            'accepted',
            'delivered',
          ],
          dimension_list: [],
          index_data_list: [],
        },
      ],
      chartTimeValue: [],
      tableTimeValue: [],
      countryValue: [],
      table_list: [],
      tableColumns: [],
      pageNum: 1,
      pageSize: 10,
      column_config: AnalysisListConfig.column_config,
      taskNameWidth: 0,
      searchLoading: false,
      push_type: '',
      emptyText: '点击搜索，开始查询',
      tableEmptyText: '点击搜索，开始查询',
      funnelAllTree: {
        text: '发送数',
        count: 0,
        color: '',
        children: [
          {
            text: '实际可达数',
            count: 0,
            color: '',
            children: [
              {
                text: '打开数',
                count: 0,
                color: '',
              },
              {
                text: '划掉数',
                count: 0,
                color: '',
              },
            ],
          },
        ],
      },
      funnelTree: {
        text: '实际可达数',
        count: 0,
        color: '',
        children: [
          {
            text: '打开数',
            count: 0,
            color: '',
          },
          {
            text: '划掉数',
            count: 0,
            color: '',
          },
        ],
      },
      funnelData: [],
    };
  },
  created() {
    this.getTimeData();
  },
  mounted() {
    // this.getConditionData();
    // this.getTableData();
  },
  computed: {
    current_table_data() {
      const tableData = this.table_list;
      return tableData.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
    tableListElement() {
      return this.$refs.tableListWrap;
    },
  },
  // watch: {
  //   push_type() {
  //     if (!this.searchLoading) {
  //       this.getTableData();
  //     }
  //   },
  // },
  methods: {
    getTaskNameWidth() {
      const html = document.querySelector('.task-title');
      console.log('html', html);
      // this.taskNameWidth = html.clientWidth;
    },
    getConditionData() {
      this.searchLoading = false;
      // const tableLoader = this.$loader(this.tableListElement).show();
      const chartLoader = this.$loader(this.getChartContainer(`chart_${this.chart_info_list[0].name}`)).show();
      const funnelLoad = this.$loader(this.$refs.funnel, { backgroundColor: 'rgba(41,120,255,0.04)' }).show();
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const params = {
        begin_time: this.chartTimeValue[0],
        end_time: this.chartTimeValue[1],
        country: this.countryValue,
        push_type: this.push_type,
      };
      const promise = this.$api.getConditionData(urlParams, params);
      // eslint-disable-next-line no-unused-vars
      return promise.then((response) => {
        const responseData = response.data.chart_data;
        const chartData = { responseData };
        console.log(chartData);
        this.getFunnelData(chartData);
        // const { header, list } = response.data.table_data;
        // this.configTable(header, list);
        if (chartData) {
          this.newSummaryChartCallback(chartData, this.chart_info_list[0]);
        } else {
          throw new Error('请求出错');
        }
      }).catch((error) => {
        this.$Message.error(error.message);
        this.emptyText = '获取数据失败';
      }).finally(() => {
        // tableLoader.hide();
        chartLoader.hide();
        funnelLoad.hide();
        this.searchLoading = true;
      });
    },
    getTableData() {
      this.searchLoading = false;
      const tableLoader = this.$loader(this.tableListElement).show();
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const params = {
        push_type: this.push_type,
        begin_time: this.tableTimeValue[0],
        end_time: this.tableTimeValue[1],
      };
      const promise = this.$api.getTableData(urlParams, params);
      // eslint-disable-next-line no-unused-vars
      return promise.then((response) => {
        const { header, list } = response.data;
        this.configTable(header, list);
        this.getTaskNameWidth();
        this.tableEmptyText = '暂无数据';
      }).catch((error) => {
        this.$Message.error(error.message);
        this.tableEmptyText = '获取数据失败';
      }).finally(() => {
        tableLoader.hide();
        this.searchLoading = true;
      });
    },
    getLoadingInstance(elm) {
      if (!elm) return null;
      return this.$loading(elm);
    },
    configTable(header, list) {
      this.tableColumns = this.configColumns(header);
      this.table_list = this.configTableData(list);
    },
    configColumns(header) {
      const columns = [];
      header.forEach((key) => {
        const keyConfig = this.column_config[key];
        const column = {
          key,
          title: keyConfig.label || ' ',
          align: keyConfig.align || 'left',
          fixed: keyConfig.fixed || null,
          minWidth: keyConfig.minWidth || this.column_config.minWidth,
          maxWidth: keyConfig.maxWidth || null,
          slot: keyConfig.slot || null,
          render: this[keyConfig.render] || undefined,
          className: keyConfig.className || null,
        };
        columns.push(column);
      });
      return columns;
    },
    configTableData(list) {
      return [...list];
    },
    getTimeData() {
      const date = new Date();
      const date1 = this.$tools.dateFormatter(new Date());
      const date2 = this.$tools.dateFormatter(new Date(date.valueOf() - 7 * 24 * 60 * 60 * 1000));
      const date3 = this.$tools.dateFormatter(new Date(date.valueOf() - 30 * 24 * 60 * 60 * 1000));
      this.$set(this.chartTimeValue, 0, date2);
      this.$set(this.chartTimeValue, 1, date1);
      this.$set(this.tableTimeValue, 0, date3);
      this.$set(this.tableTimeValue, 1, date1);
    },
    searchChart(data) {
      this.$set(this.chartTimeValue, 0, data.begin_time);
      this.$set(this.chartTimeValue, 1, data.end_time);
      this.countryValue = data.country;
      this.push_type = data.push_type;
      this.getConditionData();
    },
    searchTable(data) {
      this.push_type = data.push_type;
      this.$set(this.tableTimeValue, 0, data.begin_time);
      this.$set(this.tableTimeValue, 1, data.end_time);
      this.getTableData();
    },
    pageHandle(data) {
      this.pageSize = data;
    },
    geDeliverBoxWidth(row) {
      const total = row.analytic_data.accepted_times + row.analytic_data.delivered_times;
      const width = row.analytic_data.delivered_times / total;
      return { width: `${width * 100}%` };
    },
    getScheduleTypeText(scheduleType) {
      if (scheduleType === 'immediate') {
        return '即时推送';
      } if (scheduleType === 'weekly') {
        return '周期性推送';
      } if (scheduleType === 'precise_time') {
        return '定时推送';
      } if (scheduleType === 'daily') {
        return '周期性推送';
      }
      return '未知推送';
    },
    jumpToDataAnalysisDetail(row) {
      this.$router.push({
        name: 'DataAnalysisDetail',
        params: {
          app_id: this.$route.params.app_id,
          task_id: row.task_id,
        },
        query: {
          task_name: row.task_name,
        },
      });
    },
    tooltip(row) {
      if (row.analytic_data.accepted_times + row.analytic_data.delivered_times === 0 || row.analytic_data.delivered_times === 0) {
        const str = `发送数:${row.analytic_data.accepted_times} 实际可达数:${row.analytic_data.delivered_times} 0%`;
        return str;
      } if (row.analytic_data.accepted_times === 0) {
        const str = `发送数:${row.analytic_data.accepted_times} 实际可达数:${row.analytic_data.delivered_times} 100%`;
        return str;
      }
      const a = row.analytic_data.delivered_times / (row.analytic_data.accepted_times + row.analytic_data.delivered_times);
      const res = Math.floor(a * 10000) / 100;
      const str = `发送数:${row.analytic_data.accepted_times} 实际可达数:${row.analytic_data.delivered_times}  ${res}%`;
      return str;
    },
    getFunnelData(data) {
      this.funnelData = [];
      const { index, rows } = data.responseData;
      const funnelDataArr = rows[0].values;
      const acceptField = ['accepted'];
      const receiveField = ['delivered'];
      const openField = ['notification_open', 'notification_foreground', 'message_open'];
      const dismissField = ['notification_dismiss', 'message_dismiss'];
      const notificationOpenField = ['notification_open', 'notification_foreground'];
      const acceptIndex = [];
      const receiveIndex = [];
      const openIndex = [];
      const dismissIndex = [];
      if (this.push_type === '') {
        openIndex.push(...openField.map((item) => index.indexOf(item)));
        dismissIndex.push(...dismissField.map((item) => index.indexOf(item)));
      } else if (this.push_type === 'notification') {
        openIndex.push(...notificationOpenField.map((item) => index.indexOf(item)));
        dismissIndex.push(index.indexOf('notification_dismiss'));
      } else {
        openIndex.push(index.indexOf('message_open'));
        dismissIndex.push(index.indexOf('message_dismiss'));
      }
      acceptIndex.push(...acceptField.map((item) => index.indexOf(item)));
      receiveIndex.push(...receiveField.map((item) => index.indexOf(item)));
      const acceptValue = this.setNodeData(acceptIndex, funnelDataArr);
      const receiveValue = this.setNodeData(receiveIndex, funnelDataArr);
      const openValue = this.setNodeData(openIndex, funnelDataArr);
      const dismissValue = this.setNodeData(dismissIndex, funnelDataArr);
      console.log(acceptValue);
      if (acceptValue > receiveValue) {
        this.funnelData.push(acceptValue);
      }
      this.funnelData.push(receiveValue);
      this.funnelData.push(openValue);
      this.funnelData.push(dismissValue);
    },
    setNodeData(index, data) {
      const arr = [];
      index.forEach((item) => {
        data.forEach((element) => {
          arr.push(element[item]);
        });
      });
      return arr.reduce((a, b) => a + b, 0);
    },
  },
};
</script>

<style scoped lang="less">
.all-chart{
  height: 539px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-top: 16px;
}
.conversion{
  height: 347px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-top: 16px;
}
.tableChart{
  margin-top: 16px;
  border: 1px #fff solid;
  background-color: #fff;
  .task-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    a {
      font-size: 14px;
    }
  }
  .push-type-icon{
    display: inline-block;
    width: 24px;
    height:24px;
    vertical-align: middle;
  }
  .schedule-type-icon {
    display: inline-block;
    width: 20px;
    height:20px;
    vertical-align: middle;
  }
  /deep/ .ivu-tooltip .ivu-tooltip-rel {
    vertical-align: middle;
  }
  /deep/ .ivu-table .align-center{
    .ivu-table-cell-slot {
      text-align: center;
    }
    text-align: center;
  }
}
.table {
  position: relative;
  min-height: 300px;
}
.tableTitle {
  font-size: 16px;
  font-weight: bold;
  padding: 16px 24px;
}
.page-box{
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
}
.page{
  padding-top:5px;
  padding-bottom: 5px;
}
.table-list {
  margin-left: 24px;
  margin-right: 24px;
  position: relative;
}
.common-chart {
  margin-top: 16px;
  border-bottom: none;
}
.data-box {
  display: flex;
  width:100px;
  height: 20px;
  border-radius: 10px;
  background-color: #2d8cf0;
  overflow: hidden;
}
.deliver-box{
  background-color: #ed4014;
  border-radius: 10px;
}
.funnel-chart-box {
  background-color: #FFFFFF;
  display: flex;
  position: relative;
  margin-bottom: 16px;
  .funnel {
    background: rgba(41,120,255,0.04);
    border-radius: 4px;
    margin: 16px 24px 20px;
    padding-left: 164px;
    padding-right: 164px;
    padding-top: 16px;
    width: calc(~"100% - 48px");
    height: 174px;
    position: relative;
  }
}
</style>
