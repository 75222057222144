<template>
  <div class="common-chart">
    <h1 class="chart-title">{{ chart_info.title || chart_info.name }}</h1>
    <p class="subHeading" v-if="chart_info.subHeading">{{chart_info.subHeading}}</p>
    <div class="operation-box">
      <div class="left-box">
        <slot name="date"></slot>
        <div v-if="dimension_type_list.length !== 0" class="dimension-list">
          <dimension-select-item
            class="dimension-item"
            v-for="dimensionValue in dimension_type_list"
            :key="dimensionValue"
            :dimension_name="dimensionTypeLabelMap.get(dimensionValue)"
            :dimension_value="dimensionValue"
            :dimension_list="dimension_group[dimensionValue] ? dimension_group[dimensionValue].dimension_list : []"
            :selected_dimension_list="dimension_group[dimensionValue] ? dimension_group[dimensionValue].selected_dimension_list : []"
            @dimension-change="dimensionSelectChangeHandler"/>
        </div>
      </div>
      <div class="right-box">
<!--        <chart-type-option-->
<!--          v-if="show_chart_type_option"-->
<!--          :type="chart_info.type"-->
<!--          :dual_axis_disabled="isDualAxisDisabled"-->
<!--          class="chart-type-option"-->
<!--          @chart-type-change="chartTypeChangeHandler"/>-->
        <Dropdown v-if="show_chart_edit_option" trigger="click" @on-click="dropdownItemClickHandler">
          <img class="edit-icon" src="../../assets/image/ic_edit@2x.png"/>
          <DropdownMenu slot="list">
            <DropdownItem name="edit">编辑</DropdownItem>
            <DropdownItem name="delete">删除</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div class="chart-container" ref="chartContainer">
      <div v-if="chart_info.chart_data" class="chart" ref="chart"></div>
      <empty-content v-if="!chart_info.chart_data" :empty_text="empty_text" class="empty-content-wrap"></empty-content>
      <div v-if="show_chart_legend_box" class="legend-box-wrap">
        <div v-show="chart_info.type === 'line' || chart_info.type === 'bar' || chart_info.type === 'dual_axis'" class="legend-box line-legend-box">
<!--          <div class="option-box">-->
<!--            <Checkbox v-model="select_all_series" @on-change="selectAllSeries">全选</Checkbox>-->
<!--          </div>-->
          <div class="index-list">
            <div
              v-for="indexItem in index_data_list"
              :key="indexItem.index_name"
              class="index-item">
              <div class="index-info">
                <span class="index-letter">{{ indexItem.index_letter }}</span>
                <span class="index-name">{{ indexItem.index_name }}</span>
<!--                <span class="index-name" v-text="chart_name[indexItem.index_name]"></span>-->
              </div>
              <div class="dimension-list">
                <div
                  v-for="(dimensionItem) in dimensionListInLegendBox(indexItem)"
                  :key="dimensionItem.dimensionName"
                  class="dimension-item"
                  @click="selectDimensionItem(dimensionItem)">
                  <span class="dimension-color"
                        :class="{'unselected-dimension-color': !dimensionItem.is_selected}"
                        :style="{'border-color': dimensionItem.color}"></span>
                  <span class="dimension-name"
                        :class="{'unselected-dimension-name': !dimensionItem.is_selected}">
                    {{ dimensionItem.dimensionName }}
                  </span>
                  <span class="dimension-option"
                        :class="{ 'selected-dimension-option': isSelectedDimensionOption(dimensionItem) }"
                        @mouseover="hoverDimensionItem(dimensionItem)"
                        @mouseleave="leaveDimensionItem(dimensionItem)"
                        @click.stop="clickDimensionItem(dimensionItem, dimensionListInLegendBox(indexItem))">
                    <LegendItemSVG>123</LegendItemSVG>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="isSingleIndexWidthMultipleDimensions" class="index-page-box">
              <Page
                :current="page_num"
                :page-size="page_size"
                :total="total"
                :simple="true"
                @on-change="pageNumChange"></Page>
            </div>
          </div>
        </div>
        <div v-show="chart_info.type === 'pie'" class="legend-box pie-legend-box">
          <div class="option-box">
            <Checkbox v-model="select_all_dimensions_in_pie" @on-change="selectAllSeries">全选</Checkbox>
          </div>
          <div class="dimension-list">
            <div
              v-for="dimensionItem in isSingleIndexWidthMultipleDimensions
                ? theFirstDimensionList
                : dimensionListInIndexList.slice(0, 15) || []"
              :key="dimensionItem.dimensionName"
              class="dimension-item"
              @click="selectDimensionItem(dimensionItem)">
              <span
                class="dimension-color"
                :class="{'unselected': !dimensionItem.is_selected}"
                :style="{'background-color': dimensionItem.color}"></span>
              <span
                class="dimension-name"
                :class="{'unselected': !dimensionItem.is_selected}">
                {{ dimensionItem.dimensionName }}
              </span>
            </div>
            <div v-if="isSingleIndexWidthMultipleDimensions" class="dimension-page-box">
              <Page
                :current="page_num"
                :page-size="page_size"
                :total="total"
                :simple="true"
                @on-change="pageNumChange"></Page>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ChartTypeOption from '../common_chart/ChartTypeOption.vue';
import LegendItemSVG from '../common_chart/LegendItemSVG.vue';
import DimensionSelectItem from '../common_chart/DimensionSelectItem.vue';
import DimensionSelectList from '../common_chart/DimensionSelectList.json';
import EmptyContent from '../EmptyContent.vue';

const PAGE_SIZE = 10;

export default {
  name: 'CommonChart',
  components: {
    DimensionSelectItem, LegendItemSVG, EmptyContent,
  },
  props: {
    chart_info: Object,
    show_chart_type_option: {
      type: Boolean,
      default: true,
    },
    show_chart_edit_option: {
      type: Boolean,
      default: true,
    },
    dimension_type_list: {
      type: Array,
      default: () => [],
    },
    index_list: {
      type: Array,
      default: () => [],
    },
    index_data_list: {
      type: Array,
      default: () => [
        {
          dimension_list: [],
        },
      ],
    },
    show_chart_legend_box: {
      type: Boolean,
      default: false,
    },
    empty_text: {
      type: String,
      default: '获取数据失败',
    },
  },
  data() {
    return {
      select_all_series: true,
      select_all_dimensions_in_bar: true,
      select_all_dimensions_in_pie: true,
      selected_dimensions_in_bar: [],
      page_num: 1,
      page_size: PAGE_SIZE,
      dimension_group: {},
    };
  },
  computed: {
    dimensionListInIndexList() {
      if (this.index_data_list.length === 0) {
        return [];
      }
      return this.index_data_list[0].dimension_list;
    },
    total() {
      return this.index_data_list[0].dimension_list.length;
    },
    theFirstDimensionList() {
      const totalDimensionList = this.index_data_list[0].dimension_list;
      const startIndex = (this.page_num - 1) * this.page_size;
      const endIndex = (this.page_num) * this.page_size;
      const currentDimensionList = totalDimensionList.slice(startIndex, endIndex);
      return currentDimensionList;
    },
    isSingleIndexWidthMultipleDimensions() {
      return this.index_list.length === 1 && this.index_data_list[0]?.dimension_list.length > this.page_size;
    },
    isDualAxisDisabled() {
      // 多轴线图可以选择的前提：多指标单维度
      return !(this.index_list.length > 1 && this.index_data_list[0]?.dimension_list.length === 1);
    },
    dimensionTypeLabelMap() {
      const arr = DimensionSelectList.list.map((item) => ([item.value, item.label]));
      return new Map(arr);
    },
  },
  methods: {
    isSelectedDimensionOption(dimensionItem) {
      const flatArr = this.index_data_list.reduce((prev, cur) => {
        prev.push(...cur.dimension_list);
        return prev;
      }, []);
      const selectedArr = flatArr.filter((item) => item.is_selected);
      return selectedArr.length === 1 && selectedArr[0] === dimensionItem;
    },
    dimensionListInLegendBox(indexItem) {
      return this.isSingleIndexWidthMultipleDimensions
        ? this.theFirstDimensionList
        : indexItem.dimension_list.slice(0, 15) || [];
    },
    dropdownItemClickHandler(name) {
      if (name === 'edit') {
        this.$emit('edit');
      } else if (name === 'delete') {
        this.$emit('delete');
      }
    },
    chartTypeChangeHandler(newType) {
      this.$emit('chart-type-change', this.chart_info, this.dimension_group, newType);
    },
    selectDimensionItem(dimensionItem) {
      const chartObj = this.chart_info.chart;
      const { name } = dimensionItem;
      if (chartObj) {
        chartObj.dispatchAction({
          type: dimensionItem.is_selected ? 'legendUnSelect' : 'legendSelect',
          name,
        });
        // eslint-disable-next-line no-param-reassign
        dimensionItem.is_selected = !dimensionItem.is_selected;
      }
    },
    hoverDimensionItem(dimensionItem) {
      const chartObj = this.chart_info.chart;
      const { name } = dimensionItem;
      if (chartObj) {
        chartObj.dispatchAction({
          type: 'highlight',
          seriesName: name,
        });
      }
    },
    leaveDimensionItem(dimensionItem) {
      const chartObj = this.chart_info.chart;
      const { name } = dimensionItem;
      if (chartObj) {
        chartObj.dispatchAction({
          type: 'downplay',
          seriesName: name,
        });
      }
    },
    clickDimensionItem(dimensionItem) {
      const chartObj = this.chart_info.chart;
      if (chartObj) {
        const flatArr = this.index_data_list.reduce((prev, cur) => {
          prev.push(...cur.dimension_list);
          return prev;
        }, []);
        const selectedArr = flatArr.filter((tempItem) => tempItem.is_selected);
        if (selectedArr.length === flatArr.length) {
          // 当前所有项都为显示状态
          flatArr.forEach((item) => {
            const b = dimensionItem === item;
            chartObj.dispatchAction({
              type: b ? 'legendSelect' : 'legendUnSelect',
              name: item.name,
            });
            // eslint-disable-next-line no-param-reassign
            item.is_selected = b;
          });
        } else if (selectedArr.length === 1) {
          // 当前项只有一个为显示状态
          if (dimensionItem === selectedArr[0]) {
            // 这个项恰好为当前点击的项，所有项改为显示状态
            chartObj.dispatchAction({
              type: 'legendAllSelect',
            });
            // eslint-disable-next-line no-param-reassign
            flatArr.forEach((i) => { i.is_selected = true; });
          } else {
            // 这个项不是当前显示的项，改当前项为显示状态，当前项改为不显示状态
            chartObj.dispatchAction({
              type: 'legendSelect',
              name: dimensionItem.name,
            });
            chartObj.dispatchAction({
              type: 'legendUnSelect',
              name: selectedArr[0].name,
            });
            selectedArr[0].is_selected = false;
            // eslint-disable-next-line no-param-reassign
            dimensionItem.is_selected = true;
          }
        }
      }
    },
    selectAllSeries(val) {
      const chartObj = this.chart_info.chart;
      if (chartObj) {
        this.index_data_list.forEach((indexItem) => {
          const dimensionList = indexItem?.dimension_list || [];
          dimensionList.forEach((dimensionItem) => {
            const { name } = dimensionItem;
            chartObj.dispatchAction({
              type: val ? 'legendSelect' : 'legendUnSelect',
              name,
            });
            // eslint-disable-next-line no-param-reassign
            dimensionItem.is_selected = val;
          });
        });
      }
    },
    pageNumChange(pageNum) {
      this.page_num = pageNum;
    },
    dimensionSelectChangeHandler(dimensionValue, selectedDimensionList) {
      this.dimension_group[dimensionValue].selected_dimension_list = [...selectedDimensionList];
      this.$emit('dimension-change', this.chart_info, this.dimension_group);
    },
  },
  watch: {
    'chart_info.chart_data.dimensions': {
      handler() { // 该函数只有在重新发起请求后才会触发，CommonChart中的维度值的修改不会触发该函数，但是会重新绘画
        let result = {};
        if (this.dimension_type_list.length === 0 || this.index_data_list.length === 0) {
          result = {};
        } else {
          this.dimension_type_list.forEach((dimensionTypeValue) => {
            result[dimensionTypeValue] = {
              dimension_list: [],
              selected_dimension_list: [],
            };
          });
          this.chart_info.chart_data.dimensions.forEach((dimension) => {
            const dimensionValueStr = dimension.value;
            const tempList = dimensionValueStr.split(',');
            tempList.forEach((item, index) => {
              const currentDimensionTypeValue = this.dimension_type_list[index];
              const dimensionArr = result[currentDimensionTypeValue].dimension_list;
              const selectedDimensionArr = result[currentDimensionTypeValue].selected_dimension_list;
              if (dimensionArr.indexOf(item) === -1) {
                dimensionArr.push(item);
                selectedDimensionArr.push(item);
              }
            });
          });
        }
        this.dimension_group = { ...result };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="less">
@import url('../../style/commonChartTooltip.less');
.common-chart {
  .ivu-page-simple .ivu-page-simple-pager input {
    width: 36px;
  }
}
</style>
<style scoped lang="less">
@chartHeight: 330px;
@boxBorder: 1px solid #EAEBF7;
@borderRadius: 4px;
.common-chart {
  padding: 20px 24px;
  background-color: #FFFFFF;
  border: @boxBorder;
  border-radius: @borderRadius;
  .chart-title {
    font-size: 16px;
    font-weight: 600;
    color: #202444;
    line-height: 22px;
  }
  .subHeading{
    margin-left: 16px;
    margin-top: 8px;
  }
  .operation-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .left-box {
      .dimension-list{
        .dimension-item {
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .right-box {
      display: flex;
      align-items: center;
      .chart-type-option {
        margin-right: 16px;
      }
      .edit-icon {
        width: 16px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
  .chart-container {
    position: relative;
    .chart {
      height: @chartHeight;
    }
    .legend-box {
      .unselected {
        opacity: 0.6;
      }
    }
    .option-box {
      flex: 0 0 72px;
    }
    .line-legend-box {
      display: flex;
      align-items: flex-start;
      .index-list {
        flex: 1 0 calc(~'100% - 72px');
        .index-item {
          display: flex;
          align-items: flex-start;
          line-height: 20px;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
          .index-info {
            flex: 0 0 134px;
            margin-right: 12px;
            .index-letter, .index-name {
              vertical-align: middle;
            }
            .index-letter {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 6px;
              border-radius: 50%;
              background-color: #878AA2;
              color: #ffffff;
              font-size: 10px;
              text-align: center;
            }
            .index-name {
              display: inline-block;
              width: calc(~'100% - 26px');
              height: 20px;
              font-size: 12px;
              font-weight: 400;
              color: #545778;
              line-height: 20px;
              text-align: right;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .dimension-list {
            flex: 0 0 calc(~'100% - 146px');
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            line-height: 20px;
            .dimension-item {
              height: 20px;
              margin-right: 24px;
              cursor: pointer;
              .dimension-color, .dimension-name, .dimension-option {
                display: inline-block;
                height: 20px;
                vertical-align: middle;
              }
              .dimension-color {
                width: 16px;
                height: 0px;
                margin-right: 6px;
                border: 2px solid #5B8FF9;
              }
              .dimension-name {
                margin-right: 4px;
                font-size: 12px;
                font-weight: 400;
                color: #545778;
              }
              .dimension-option {
                height: 16px;
                color: #D5DBE6;
                transition: color ease-in-out 0.2s, opacity ease-in-out 0.2s;
                opacity: 0;
                &:hover {
                  color: #5D7092;
                }
              }
              .selected-dimension-option {
                color: #5B8FF9;
                opacity: 1;
                &:hover {
                  color: #5476d8;
                }
              }
              .unselected-dimension-color, .unselected-dimension-name {
                opacity: 0.6;
              }
              &:hover {
                .dimension-option {
                  opacity: 1;
                }
              }
            }
          }
        }
        .index-page-box {
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }
    .bar-legend-box {
      display: flex;
      align-items: center;
      .index-list {
        padding-right: 20px;
        .index-item {
          display: inline-block;
          height: 20px;
          margin-right: 24px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          .index-letter, .index-name {
            vertical-align: middle;
          }
          .index-letter {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 6px;
            line-height: 20px;
            background: #5B8FF9;
            border-radius: 50%;
            color: #ffffff;
            text-align: center;
          }
          .index-name {
            font-size: 12px;
            font-weight: 400;
            color: #545778;
            line-height: 17px;
          }
        }
      }
      .dimension-list {
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-left: 1px solid #EAEBF7;
      }
    }
    .pie-legend-box {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .option-box {}
      .dimension-list {
        .dimension-item {
          display: inline-block;
          margin-right: 24px;
          line-height: 17px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          .dimension-color, .dimension-name {
            display: inline-block;
            vertical-align: middle;
          }
          .dimension-color {
            width: 12px;
            height: 12px;
            margin-right: 6px;
            border-radius: 50%;
          }
          .dimension-name {
            font-size: 14px;
            font-weight: 400;
            color: #545778;
          }
        }
        .dimension-page-box {
          display: flex;
          flex-direction: row-reverse;
          margin-top: 6px;
        }
      }
    }
  }
}
</style>
