<template>
  <div class="basic-image-upload">
    <Upload class="upload-input"
            :class="{'disabled-upload-input': editDisable}"
            type="drag"
            action="#"
            :max-size="300"
            :disabled="editDisable"
            accept="image/*"
            :format="['jpg','jpeg','png']"
            :before-upload="beforeUploadHandler">
      <Icon class="delete-icon" :class="{'active-delete-icon': imageURL}" type="md-close" @click.stop="deleteImageHandler"/>
      <div v-if="!showImage" class="upload-input-box">
        <Icon class="upload-icon" type="md-add" :size="24"/>
        <span class="upload-text">拖拽或点击上传</span>
        <span class="upload-text">（大小不超过300KB）</span>
      </div>
      <img v-if="showImage" class="upload-image" :src="imageURL" alt="image">
    </Upload>
    <div class="image-url-input-box">
      <span v-if="show_input_text" class="input-text">或 URL：</span>
      <Input class="url-input" :value="url" :disabled="editDisable" placeholder="URL" @on-change="urlChangeHandler"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasicImageUpload',
  props: {
    url: String,
    image: [File, String],
    editDisable: Boolean,
    height: {
      type: Number,
      default: 164,
    },
    show_input_text: {
      type: Boolean,
      default: true,
    },
    // disabled: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  computed: {
    showImage() {
      return this.url || this.image;
    },
    imageURL() {
      return this.image ? this.$tools.imageFileToURL(this.image) : this.url;
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    beforeUploadHandler(file) {
      if (file.size > 307200) {
          this.$Message.warning('图片大小超过限制');
      } else {
        this.$emit('update:image', file);
        // 清空输入框
        this.$emit('update:url', '');
        this.$nextTick(() => {
          this.$emit('image-change');
        });
      }
      return false;
    },
    urlChangeHandler(evt) {
      const newURL = evt.target.value;
      this.$emit('update:url', newURL);
      // 清空图片文件
      this.$emit('update:image', null);
      this.$nextTick(() => {
        this.$emit('image-change');
      });
    },
    deleteImageHandler() {
      if (!this.editDisable) {
        // 清空图片
        this.$emit('update:url', '');
        this.$emit('update:image', null);
        this.$nextTick(() => {
          this.$emit('image-change');
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
  .basic-image-upload {
    .upload-input {
      position: relative;
      width: 100%;
      height: 164px;
      margin-bottom: 8px;
      text-align: center;
      /*padding: 8px;*/
      .upload-box();
      .upload-image {
        max-width: 100%;
        max-height: 100%;
        padding: 4px 0;
        border-radius: 8px;
      }
      .delete-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 20px;
        font-weight: 600;
        opacity: 0;
        transition: 0.2s linear transform, 0.2s linear opacity;
      }
      &:hover {
        .active-delete-icon {
          opacity: 1;
          transform: rotate(180deg);
        }
      }
    }
    .disabled-upload-input {
      /deep/ .ivu-upload-drag {
        cursor: not-allowed;
        &:hover {
          border: 1px dashed #dcdee2;
        }
      }
      &:hover {
        /deep/ .ivu-upload {
          .upload-icon, .upload-text {
            color: #BBBCC9;
          }
        }
      }
    }
    .image-url-input-box {
      display: flex;
      align-items: center;
      line-height: 32px;
      .input-text {
        width: 76px;
        margin-right: 4px;
      }
      .url-input {
        /*flex: 1 1 auto;*/
      }
    }
  }
</style>
