/* eslint-disable no-param-reassign */
import requestFactory from '../../api/requestFactory';
import api from '../../api/api';
import uploadImage from '../../api/uploadImage';
import translate from '../../api/translate';

export default {
  install(Vue) {
    // axios请求方法的工厂函数
    Vue.prototype.$requestFactory = requestFactory;
    // 请求方法
    Vue.prototype.$getRequest = requestFactory('get');
    // 所有API的对象
    Vue.prototype.$api = api;
    // 上传图片方法
    Vue.prototype.$uploadImage = uploadImage;
    // 谷歌翻译
    Vue.prototype.$translate = translate;
  },
};
