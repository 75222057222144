<template>
  <div class="temp-push-display-box">
    <div class="content-box">
      <h3 class="temp-push-title">{{ temp_push.title || temp_push.name }}</h3>
      <p class="temp-push-validity-period">{{ temp_push.start_time }} 至 {{ temp_push.end_time }}</p>
    </div>
    <div class="operation-box">
      <Icon type="ios-trash" :size="20" @click="pauseTempPushTrigger"/>
    </div>
    <div class="status-box">{{ statusText }}</div>
  </div>
</template>

<script>
export default {
  name: 'TempPushBox',
  props: {
    temp_push: {
      type: Object,
      default: () => ({
        name: '',
        title: '',
        start_time: '',
        end_time: '',
      }),
    },
  },
  computed: {
    statusText() {
      return '运行中';
    },
  },
  methods: {
    pauseTempPushTrigger() {
      this.$emit('pause', this.temp_push.id);
    },
  },
};
</script>

<style scoped lang="less">
  .temp-push-display-box {
    position: relative;
    height: 108px;
    margin-bottom: 18px;
    padding: 30px;
    background: rgba(41,120,255,0.1000);
    border-radius: 4px;
    transition: background-color 0.4s ease-in-out;
    border: 1px solid rgba(41,120,255,0.1000);
    &:hover {
      border-color: #2978FF;
    }
    .content-box {
      .temp-push-title, .temp-push-validity-period {
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .temp-push-title {
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 600;
        color: #1D1E26;
        line-height: 22px;
      }
      .temp-push-validity-period {
        font-size: 14px;
        font-weight: 400;
        color: #333440;
        line-height: 22px;
      }
    }
    .status-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 52px;
      height: 20px;
      line-height: 20px;
      background: #00C38C;
      border-radius: 4px 0px 4px 0px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
    }
    .operation-box {
      position: absolute;
      top: 12px;
      right: 12px;
      color: #bebfcb;
      transition: color 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        color: @errorColor;
      }
    }
  }
</style>
