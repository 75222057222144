<template>
  <div class="content-header">
    <h1 class="title">{{ title }}</h1>
    <div class="operation">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContentHeader',
    props: {
      title: String,
    },
  };
</script>

<style scoped lang="less">
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    background: transparent;
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #202444;
      line-height: 28px;
    }
    .operation {
      display: flex;
      .header-search {
        margin-right: 12px;
      }
    }
  }
</style>
