/* eslint-disable camelcase,no-param-reassign */
import getOldLineAndBarChartOption from '../../untils/getNewChartOption';
import chartConfig from '../page_config/color.json';
import getOldPieChartOption from '../../untils/getPieChartOption';
import commonChartMixin from './commonChartMixins';
import IndexSelectList from '../page_config/IndexSelectList.json';
import DimensionSelectList from '../page_config/DimensionSelectedList.json';

export default {
  mixins: [commonChartMixin],
  computed: {
    chart_index_obj() {
      return Object.create(null);
    },
    color_list() {
      return chartConfig.color_list;
    },
    tooltip_color_list() {
      return chartConfig.tooltip_color_list;
    },
    index_list_map() {
      return new Map(IndexSelectList.list.map((item) => ([item.value, item.label])));
    },
    dimension_list_map() {
      return new Map(DimensionSelectList.list.map((item) => ([item.value, item.label])));
    },
  },
  methods: {
    transformResponseData(by_fields, rows, series, index_list) {
      const xAxis = [...series];
      const dimensions = [];
      rows.forEach((row) => {
        const { by_values } = row;
        const currentDimensionLabel = by_values.map((by_value) => this.dimension_list_map.get(by_value) || by_value).join('-');
        const currentDimensionValue = by_values.join('-');
        dimensions.push({
          label: currentDimensionLabel,
          value: currentDimensionValue,
        });
      });
      const items = [];
      index_list.forEach((indexValue, index) => {
        const obj = {
          name: indexValue,
          data: {},
        };
        dimensions.forEach((dimension, dimensionIndex) => {
          obj.data[dimension.value] = rows[dimensionIndex].values.map((arr) => arr && arr[index]);
        });
        items.push(obj);
      });
      return {
        x_axis: xAxis,
        items,
        dimensions,
      };
    },
    newSummaryChartCallback(responseData, chartInfo) {
      const detail_result = responseData.responseData;
      if (detail_result) {
        const {
          by_fields,
          series,
          rows,
          index,
        } = detail_result;
        chartInfo.index_list = index.slice();
        const {
          x_axis,
          items,
          dimensions,
        } = this.transformResponseData(by_fields, rows, series, chartInfo.index_list);
        // eslint-disable-next-line no-param-reassign
        chartInfo.chart_data = {
          x_axis,
          items,
          dimensions,
        };
        console.log(chartInfo.chart_data);
        let chartData;
        const chartType = chartInfo.type;
        const dimensionLabelMap = this.getDimensionMap(dimensions);
        if (chartType === 'line') {
          chartData = this.getLineChartData(chartInfo.name, x_axis, items, dimensionLabelMap);
        } else if (chartType === 'bar') {
          chartData = this.getBarChartData(chartInfo.name, x_axis, items, dimensionLabelMap);
        } else if (chartType === 'pie') {
          chartData = this.getPieChartData(chartInfo.name, items, dimensionLabelMap);
        }
        // eslint-disable-next-line no-param-reassign
        chartInfo.index_data_list = chartData.index_info_list;
        const chartConfiguration = this.getChartConfiguration(chartType);
        let chart = null;
        this.$nextTick(() => {
          chart = this.drawChart(chartData, chartConfiguration);
          // eslint-disable-next-line no-param-reassign
          chartInfo.chart = chart;
        });
        return null;
      }
      throw new Error(`${chartInfo.title} 图表数据获取失败`);
    },
    summaryChartCallback(responseData, chartItem) {
      const { x_axis } = responseData;
      if (x_axis) {
        // line or bar
        this.summaryLineAndBarCallback(responseData, chartItem);
      } else {
        // pie
        this.summaryPieCallback(responseData, chartItem);
      }
    },
    summaryLineAndBarCallback(responseData, chartItem) {
      const {
        dimensions,
        indexes,
        type_list,
        unit_list,
        x_axis,
      } = responseData;
      if (dimensions && indexes) {
        const yAxis = this.getYAxisData(responseData, indexes, dimensions);
        // -1 置 null
        this.$tools.transformToNullInObject(yAxis, -1);
        const chartData = this.getChartData(chartItem.name, x_axis, yAxis, dimensions, indexes, type_list, unit_list);
        const configuration = this.getChartConfiguration(chartData.type, chartData.dimensions);
        let chart = this.oldDrawChart(chartData, configuration);
        this.$once('hook:beforeDestroy', () => {
          chart.clear();
          chart = null;
        });
        // eslint-disable-next-line no-param-reassign
        chartItem.chart = chart;
      }
    },
    // getChartElement(chartName) {
    //   const element = Array.isArray(this.$refs[`chart_${chartName}`])
    //     ? this.$refs[`chart_${chartName}`][0]
    //     : this.$refs[`chart_${chartName}`];
    //   return element;
    // },
    getYAxisData(data, indexes, dimensions) {
      const yAxis = {};
      if (dimensions.length >= 1 && indexes.length === 1) {
        // 单指标情况多维度情况
        const index = indexes[0];
        dimensions.forEach((dimension) => {
          yAxis[dimension] = data[index][dimension];
        });
      } else if (dimensions.length === 1 && indexes.length >= 1) {
        // 多指标单维度情况
        const dimension = dimensions[0];
        indexes.forEach((index) => {
          yAxis[index] = data[index][dimension];
        });
      }
      return yAxis;
    },
    getChartData(chartName, xAxis, yAxis, dimensions, indexes, typeList, unitList) {
      const isSingleIndex = indexes.length <= 1;
      let curDimensions = [];
      const singleYAxis = true;
      let name = [];
      if (isSingleIndex) {
        curDimensions = [...dimensions];
        name = dimensions.map((dimension) => this.chart_index_obj[dimension] || dimension);
      } else {
        // 多指标
        indexes.forEach((index, i) => {
          curDimensions[i] = index;
          name[i] = this.chart_index_obj[index] || index;
        });
      }
      const isStackBar = typeList[0] === 'stack-bar';
      const type = this.getWholeChartType(typeList, dimensions.length, indexes.length);
      const curTypeList = this.getChartTypeList(typeList, dimensions.length, isSingleIndex);
      const curUnitList = this.getUnitList(unitList, dimensions.length, isSingleIndex);
      return {
        id: chartName,
        xAxis,
        yAxis,
        dimensions: curDimensions,
        name,
        chartType: curTypeList,
        type,
        unit_list: curUnitList,
        singleYAxis,
        isStackBar,
      };
    },
    getUnitList(unitList, dimensionLength, isSingleIndex) {
      if (isSingleIndex) {
        const unit = unitList[0];
        return new Array(dimensionLength).fill(unit);
      }
      return unitList;
    },
    getChartTypeList(typeList, dimensionLength, isSingleIndex) {
      if (isSingleIndex) {
        const type = typeList[0].indexOf('line') !== -1 ? 'line' : 'bar';
        return new Array(dimensionLength).fill(type);
      }
      return typeList;
    },
    getWholeChartType(typeList, dimensionLength, indexLength) {
      const type = typeList[0];
      if (type === 'pie') {
        return 'pie';
      } if (dimensionLength > 1 || indexLength > 1) {
        return `multi_${type}`;
      }
      return type;
    },
    getChartConfiguration(type) {
      let config;
      if (type === 'line' || type === 'bar') {
        config = {
          color: this.color_list[0],
          tooltip_color: this.tooltip_color_list[0],
          grid: {
            top: '30px',
            bottom: '45px',
            left: '50px',
            right: '24px',
          },
          legend: null,
          // legend: {
          //   bottom: '-0.5%',
          //   left: 'center',
          //   width: '800px',
          // },
        };
      } else if (type === 'dual_axis') {
        config = {
          color: this.color_list[0],
          tooltip_color: this.tooltip_color_list[0],
          grid: {
            top: '30px',
            bottom: '45px',
            left: '60px',
            right: '60px',
          },
          legend: null,
        };
      } else if (type === 'multi_line' || type === 'multi_bar' || type.indexOf('multi') !== -1) {
        config = {
          color: this.color_list,
          tooltip_color: this.tooltip_color_list,
          tooltip_order: 'valueDesc',
          // hideYAxis: !isSingleIndex,
          grid: {
            top: '24px',
            bottom: '45px',
            left: '50px',
            right: '24px',
          },
          legend: null,
          // legend: dimensions.length > 12
          //   ? null
          //   : {
          //     bottom: '-0.5%',
          //     left: 'center',
          //     width: '800px',
          //   },
        };
      } else if (type === 'pie') {
        config = {
          center: ['50%', '42%'],
          radius: '120px',
          color: this.color_list,
          tooltip_color: this.tooltip_color_list,
          label: {
            position: 'inner',
          },
          legend: null,
          // legend: {
          //   bottom: '1%',
          //   left: 'center',
          //   width: '800px',
          // },
          grid: {
            top: '24px',
            bottom: '24px',
            left: '24px',
            right: '24px',
          },
        };
      }
      return config;
    },
    getNewChartElement(chartName) {
      console.log(`chart_${chartName}`);
      console.log(this.$refs[`chart_${chartName}`]);
      if (this.$refs[`chart_${chartName}`]) {
        return Array.isArray(this.$refs[`chart_${chartName}`])
          ? this.$refs[`chart_${chartName}`][0]
          : this.$refs[`chart_${chartName}`];
      }
      return null;
    },
    oldDrawChart(chartData, configuration) {
      const { type } = chartData;
      let chartId;
      let chartElement;
      try {
        chartId = chartData.id;
        // eslint-disable-next-line prefer-destructuring
        chartElement = Array.isArray(this.$refs[`chart_${chartId}`])
          ? this.$refs[`chart_${chartId}`][0]
          : this.$refs[`chart_${chartId}`];
        if (!chartElement) throw new Error('Element not exists');
      } catch (error) {
        console.error(error);
        return null;
      }
      const chart = this.$echarts.init(chartElement);
      let option;
      if (type === 'line' || type === 'bar' || type.indexOf('multi') !== -1) {
        option = getOldLineAndBarChartOption(chartData, configuration);
      } else if (type === 'pie') {
        option = getOldPieChartOption(chartData, configuration);
      }
      chart.setOption(option, true);
      return chart;
    },
    summaryPieCallback(responseData, chartItem) {
      console.log(responseData, chartItem);
      const { type, unit, record } = responseData;
      const data = [...record.sort((a, b) => b.value - a.value)];
      const chartData = {
        id: chartItem.name,
        type,
        unit,
        data,
      };
      const configuration = this.getChartConfiguration(type);
      this.oldDrawChart(chartData, configuration);
    },
    getChartElement(chartName) {
      return this.$refs[`chart_${chartName}`][0].$refs.chart;
    },
    getChartContainer(chartItemRef) {
      const element = this.$refs[chartItemRef][0].$refs.chartContainer;
      return element;
    },
    getLoadingInstance(chartName) {
      const element = this.getChartElement(chartName);
      return this.$loading(element);
    },
    chartTypeChangeHandler(chartInfo) {
      let chartData;
      const {
        x_axis,
        items,
        dimensions,
      } = chartInfo.chart_data;
      const chartType = chartInfo.type;
      const dimensionLabelMap = this.getDimensionMap(dimensions);
      if (chartType === 'line') {
        chartData = this.chartTypeChangeHandler(chartInfo.name, x_axis, items, dimensionLabelMap);
      } else if (chartType === 'bar') {
        chartData = this.getBarChartData(chartInfo.name, x_axis, items, dimensionLabelMap);
      } else if (chartType === 'pie') {
        chartData = this.getPieChartData(chartInfo.name, items, dimensionLabelMap);
      } else if (chartType === 'dual_axis') {
        chartData = this.getDualAxisChartData(chartInfo.name, x_axis, items, dimensionLabelMap);
      }
      // eslint-disable-next-line no-param-reassign
      chartInfo.index_data_list = chartData.index_info_list;
      const chartConfiguration = this.getChartConfiguration(chartType);
      let chart = null;
      this.$nextTick(() => {
        chart = this.drawChart(chartData, chartConfiguration);
        // eslint-disable-next-line no-param-reassign
        chartInfo.chart = chart;
      });
    },
    chartDimensionSelectChangeHandler(chartInfo, dimensionGroup, newType) {
      let chartData;
      const {
        x_axis,
        items,
        dimensions,
      } = chartInfo.chart_data;
      if (newType) {
        // eslint-disable-next-line no-param-reassign
        chartInfo.type = newType;
      }
      const chartType = chartInfo.type;
      console.log(items);
      const dimensionLabelMap = this.getDimensionMap(dimensions);
      // eslint-disable-next-line no-underscore-dangle
      const clonedItems = this.$tools.deepClone(items);
      console.log(clonedItems);
      clonedItems.forEach((item) => {
        Object.keys(dimensionGroup).forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          item.data = this.$tools.getPartialObj(item.data, dimensionGroup[key].selected_dimension_list, true);
        });
        // eslint-disable-next-line no-param-reassign
        // item.data = this.$tools.getPartialObj(item.data, dimensionGroup[item.name].selected_dimension_list);
      });
      if (chartType === 'line') {
        chartData = this.getLineChartData(chartInfo.name, x_axis, clonedItems, dimensionLabelMap);
      } else if (chartType === 'bar') {
        chartData = this.getBarChartData(chartInfo.name, x_axis, clonedItems, dimensionLabelMap);
      } else if (chartType === 'pie') {
        chartData = this.getPieChartData(chartInfo.name, clonedItems, dimensionLabelMap);
      } else if (chartType === 'dual_axis') {
        chartData = this.getDualAxisChartData(chartInfo.name, x_axis, clonedItems, dimensionLabelMap);
      }
      // eslint-disable-next-line no-param-reassign
      chartInfo.index_data_list = chartData.index_info_list;
      const chartConfiguration = this.getChartConfiguration(chartType);
      let chart = null;
      this.$nextTick(() => {
        chart = this.drawChart(chartData, chartConfiguration);
        // eslint-disable-next-line no-param-reassign
        chartInfo.chart = chart;
      });
    },
  },
};
