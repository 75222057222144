<template>
  <Dropdown class="dimension-select-item" trigger="click" placement="bottom-start" @on-visible-change="visibleChangeHandler">
    <div :class="['select-box', is_visible ? 'select-box-active' : '']" >
      <img class="select-icon" src="../../assets/image/ic_dimension@2x.png" alt="维度筛选">
      <span class="select-text">
        {{ dimension_name }}
        <span v-if="selected_dimension_list_in_data.length > 0" class="selected-count">{{ selected_dimension_list_in_data.length }}</span>
      </span>
    </div>
    <div class="dropdown-box" slot="list">
      <div class="search-box">
        <Input placeholder="搜索" v-model="search_content">
          <Icon type="ios-search" slot="prefix"/>
        </Input>
      </div>
      <div class="option-box">
        <Checkbox v-model="is_select_all" @on-change="selectAllChangeHandler">全选</Checkbox>
        <span class="reset-text" @click="resetHandler">重置</span>
      </div>
    </div>
  </Dropdown>
</template>

<script>
/**
 * 用于CommonChart中，某个维度的所有维度值的筛选
 * 全选，则显示全部
 * 不为空，则显示部分
 */
export default {
  name: 'DimensionSelectItem',
  props: {
    dimension_name: String,
    dimension_value: String,
    dimension_list: {
      type: Array,
      default: Array,
    },
    selected_dimension_list: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      selected_dimension_list_in_data: [],
      search_content: '',
      is_select_all: false,
      is_visible: false,
    };
  },
  computed: {
    displayed_dimension_list() {
      if (this.search_content === '') {
        return this.dimension_list;
      }
      return this.dimension_list.filter((dimension) => {
        const lowerSearchContent = this.search_content.toLowerCase();
        const lowerDimensionValue = dimension.toLowerCase();
        return lowerDimensionValue.indexOf(lowerSearchContent) !== -1;
      });
    },
  },
  methods: {
    visibleChangeHandler(visible) {
      this.is_visible = visible;
      if (!visible) {
        // dropdown消失时
        const formerListStr = JSON.stringify(this.selected_dimension_list);
        const latterListStr = JSON.stringify(this.selected_dimension_list_in_data);
        if (formerListStr !== latterListStr) {
          const list = this.selected_dimension_list_in_data.length === 0
            ? [...this.dimension_list]
            : [...this.selected_dimension_list_in_data];
          this.$emit('dimension-change', this.dimension_value, list);
          this.search_content = '';
        }
      }
    },
    selectDimensionChangeHandler() {
      this.is_select_all = this.displayed_dimension_list.every((item) => this.selected_dimension_list_in_data.indexOf(item) !== -1);
    },
    selectAllChangeHandler(value) {
      if (value) {
        this.selected_dimension_list_in_data = [...this.displayed_dimension_list];
      } else {
        this.selected_dimension_list_in_data = [];
      }
    },
    resetHandler() {
      this.selected_dimension_list_in_data = [];
    },
  },
  watch: {
    selected_dimension_list: {
      handler() {
        // 组件初始化，将当前所选的dimension_list赋值
        if (this.selected_dimension_list.length === this.dimension_list.length) { // 如果是全选的，则为空
          this.selected_dimension_list_in_data = this.is_select_all ? [...this.dimension_list] : [];
        } else {
          this.selected_dimension_list_in_data = [...this.selected_dimension_list]; // 如果不是全选，则正常赋值
        }
      },
      immediate: true,
    },
    'displayed_dimension_list.length': {
      handler() {
        this.is_select_all = this.displayed_dimension_list.every((item) => this.selected_dimension_list_in_data.indexOf(item) !== -1);
      },
    },
  },
};
</script>

<style scoped lang="less">
@height:32px;
.dimension-select-item {
  .select-box {
    position: relative;
    height: @height;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px ;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    user-select: none;
    &:hover,
    &.select-box-active {
      cursor: pointer;
      border-color: #2978FF;
    }
    .select-icon, .select-text {
      vertical-align: middle;
    }
    .select-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    .select-text {
      font-size: 14px;
      font-weight: 400;
      color: #545878;
      line-height: 20px;
      .selected-count {
        display: inline-block;
        width: 30px;
        border-radius: 9px;
        border: 1px solid #dddeea;
        background-color: #EAEBF7;
        text-align: center;
      }
    }
  }
  .dropdown-box {
    padding: 6px 0;
    .search-box {
      padding: 0 8px 6px;
    }
    .checkbox-box {
      max-height: 200px;
      overflow: auto;
      padding-left: 8px;
      .checkbox-item {
        display: block;
        padding: 6px 8px;
        &:hover {
          background-color: #eeeffb;
        }
      }
    }
    .option-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 16px 0;
      border-top: 1px solid #eeeffb;
      .reset-text{
        color: #2978FF;
        &:hover{
          color: tint(#2978FF, 20%);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
