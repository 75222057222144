import { AuthorityControl } from 'dc-vue-sso';
import configIcon from '@/assets/image/ic_sidebar_authority@2x.png';

const APP_HOME_LAYOUT = 'AppHomeLayout';
export default [
  {
    path: '/app/authority_config',
    name: 'AuthorityConfig',
    component: AuthorityControl,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '权限管理',
      icon: configIcon,
      level: 'authorityConfig',
    },
  },
];
