<template>
  <div class="rendering-box" :style="{ 'height': `${height}px` }">
    <div class="top">
      <img class="top-img" src="../../../../assets/image/status_bar@2x.png" alt="top image">
    </div>
    <div class="title-box">
      <div class="left-box">
        <p class="text" :class="{'show-text': notification_data.title, 'show-title': notification_data.title}">{{ notification_data.title }}</p>
        <p class="text" :class="{'show-text': notification_data.body, 'show-body': notification_data.body}">{{ notification_data.body }}</p>
      </div>
      <div class="right-box">
        <img class="title-img" :class="{'show-image': notificationImageURL}" :src="tinyImageURL" alt="title image">
      </div>
    </div>
    <div class="content-box">
      <p class="text-1" :class="{'show-text': notification_data.title, 'show-title': notification_data.title}">{{ notification_data.title }}</p>
      <p class="text-2" :class="{'show-text': notification_data.body, 'show-body': notification_data.body}">{{ notification_data.body }}</p>
      <p class="text-3" :class="{'show-text': notificationImageURL}">
        <img :src="largeImageURL" alt="title image" class="title-img" :class="{'show-image': notificationImageURL}">
      </p>
    </div>
  </div>
</template>

<script>
import placeholderPicTiny from '../../../../assets/image/pic_placeholder_s@2x.png';
import placeholderPicLarge from '../../../../assets/image/pic_placeholder_s@2x(2).png';

export default {
  name: 'RenderingBox',
  props: {
    push_type: String,
    notification_data: Object,
    height: {
      type: Number,
      default: 760,
    },
  },
  computed: {
    notificationImageURL() {
      if (JSON.stringify(this.notification_data) !== '{}') {
        return this.notification_data.url
          ? this.notification_data.url
          : this.$tools.imageFileToURL(this.notification_data.image);
      }
      return null;
    },
    tinyImageURL() {
      return this.notificationImageURL
        ? this.notificationImageURL
        : placeholderPicTiny;
    },
    largeImageURL() {
      return this.notificationImageURL
        ? this.notificationImageURL
        : placeholderPicLarge;
    },
  },
};
</script>

<style scoped lang="less">
  .rendering-box {
    width: 360px;
    height: 760px;
    margin-left: 110px;
    background: #F0F2F5;
    border-radius: 12px;
    .top {
      margin-bottom: 16px;
      background-color: transparent;
      .top-img {
        width: 360px;
        height: 24px;
      }
    }
    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 8px 8px;
      padding: 15px 16px;
      background-color: #FFFFFF;
      border-radius: 12px;
      .left-box {
        flex: 1 1 84%;
        max-width: 84%;
        .text {
          width: 100%;
          min-height: 10px;
          margin-bottom: 10px;
          background: #F5F7FA;
          border-radius: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .show-title {
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 600;
          color: #262626;
          line-height: 20px;
        }
        .show-body {
          font-size: 12px;
          color: #262626;
          line-height: 20px;
        }
      }
      .right-box {
        .title-img {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          vertical-align: middle;
        }
        .show-image {
          width: auto;
          height: auto;
          max-width: 32px;
          max-height: 32px;
          border-radius: 4px;
        }
      }
    }
    .content-box {
      margin: 0 8px;
      padding: 15px 16px;
      background-color: #FFFFFF;
      border-radius: 12px;
      .text-1 {
        height: 10px;
        margin-bottom: 10px;
        background: #F5F7FA;
        border-radius: 2px;
      }
      .text-2 {
        height: 26px;
        margin-bottom: 15px;
        background: #F5F7FA;
        border-radius: 2px;
      }
      .text-1, .text-2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text-3 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 314px;
        height: 157px;
        background: #F5F7FA;
        border-radius: 4px;
        .title-img {
          width: 64px;
          height: 64px;
        }
        .show-image {
          width: auto;
          height: auto;
          max-width: 314px;
          max-height: 157px;
          border-radius: 4px;
        }
      }
      .show-title {
        height: auto;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 600;
        color: #262626;
        line-height: 20px;
      }
      .show-body {
        height: auto;
        margin-bottom: 12px;
        font-size: 12px;
        color: #262626;
        line-height: 20px;
      }
    }
    .show-text {
      background: transparent!important;
    }
  }
</style>
