<template>
  <Modal
    class="column-filter-modal"
    :value="visible"
    :closable="false"
    :mask-closable="false"
    :width="372">
    <div class="header" slot="header"></div>
    <div class="body">
      <div class="filter-header">
        <span class="condition-text">条件</span>
        <span class="condition-value">值</span>
        <span class="condition-delete"></span>
      </div>
      <div v-if="columnType === 'type'" class="filter-body">
        <div class="filter-item"
             v-for="(filter, index) in filter_list"
             :key="index">
          <Select v-model="filter.condition" class="condition-select">
            <Option
              v-for="item in type_condition_list"
              :key="item.value"
              :value="item.value">{{ item.label }}</Option>
          </Select>
          <Select v-model="filter.value" filterable class="condition-value-select">
            <Option
              v-for="item in formatConditionValueList"
              :key="item.value"
              :value="item.value">{{ item.label }}</Option>
          </Select>
          <Icon class="delete-icon" type="ios-trash-outline" size="16" @click="deleteHandler(index)"/>
        </div>
      </div>
      <div v-if="columnType === 'number' || columnType === 'percent'" class="filter-body">
        <div class="filter-item"
             v-for="(filter, index) in filter_list"
             :key="index">
          <Select v-model="filter.condition" class="condition-select">
            <Option
              v-for="item in number_condition_list"
              :key="item.value"
              :value="item.value">{{ item.label }}</Option>
          </Select>
          <template v-if="columnType === 'percent'">
            <InputNumber
              v-model="filter.value"
              :formatter="value => `${value}%`"
              :parser="value => value.replace('%', '')"
              class="condition-value-input"></InputNumber>
          </template>
          <template v-else>
            <InputNumber v-model="filter.value" class="condition-value-input"></InputNumber>
          </template>
          <!--          <Input v-model="filter.value" class="condition-value-input" type="number"/>-->
          <Icon class="delete-icon" type="ios-trash-outline" size="16" @click="deleteHandler(index)"/>
        </div>
      </div>
      <div class="add-box" @click="addCondition">
        <Icon class="add-icon" type="md-add"/>
        <span class="add-text">添加</span>
      </div>
    </div>
    <div class="footer" slot="footer">
      <div class="refresh-box" @click="resetFilterList">
        <Icon class="refresh-icon" type="md-refresh"/>
        <span class="refresh-text">清除筛选</span>
      </div>
      <div class="button-box">
        <Button type="primary" ghost @click="cancelHandler">取消</Button>
        <Button type="primary" @click="confirmHandler">确认</Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ColumnFilterModal',
  props: {
    visible: Boolean,
    columnType: {
      type: String,
      default: 'type',
    }, // 用来判断列的值是数字还是固定的值
    filterList: Array,
    conditionValueList: Array,
  },
  data() {
    return {
      filter_list: [
        {
          condition: '',
          value: null,
        },
      ],
      type_condition_list: [
        {
          value: 'include',
          label: '包含',
        },
        {
          value: 'exclude',
          label: '不包含',
        },
      ],
      number_condition_list: [
        {
          value: 'greater',
          label: '大于',
        },
        {
          value: 'less',
          label: '小于',
        },
        {
          value: 'equal',
          label: '等于',
        },
      ],
    };
  },
  mounted() {
    console.log(this.filterList);
  },
  computed: {
    formatConditionValueList() { // 对 conditionValueList 进行格式化处理
      return this.conditionValueList.map((item) => ({
        label: item,
        value: item,
      }));
    },
  },
  methods: {
    isNullCondition() { // filter_list 长度至少为 1
      return this.filter_list.length === 1 && !this.filter_list[0].condition && !this.filter_list[0].value;
    },
    validateCondition() { // 验证条件输入是否为空
      const lastFilter = this.filter_list[this.filter_list.length - 1];
      return lastFilter.condition !== '' && lastFilter.value !== '';
    },
    addCondition() {
      const basicFilter = {
        condition: '',
        value: null,
      };
      if (this.validateCondition()) {
        this.filter_list.push(basicFilter);
      } else {
        this.$Message.warning('请将当前输入框填写完整');
      }
    },
    resetFilterList() {
      this.filter_list = cloneDeep(this.$options.data().filter_list);
    },
    deleteHandler(index) { // 删除当前筛选项
      if (this.filter_list.length > 1) {
        this.filter_list.splice(index, 1);
      } else if (this.filter_list.length === 1) {
        this.resetFilterList();
      }
    },
    confirmHandler() {
      const isNull = this.isNullCondition();
      const isValid = this.validateCondition();
      if (isValid || isNull) { // 最后一项是否填写完整，或者是否为空
        // eslint-disable-next-line no-nested-ternary
        const filerList = isNull // 如果是 number 类型，需要将 value 值强转为 Number
          ? []
          : (this.columnType === 'number' ? this.filter_list.map((item) => ({ condition: item.condition, value: Number(item.value) })) : this.filter_list);
        if (!this.$tools.deepCompare(filerList, this.filterList)) { // 是否有修改
          this.$emit('filter-change', filerList);
        } else {
          this.$Message.warning('未发生修改');
        }
        this.$emit('visible-change', false);
      } else {
        this.$Message.warning('请将筛选条件填写完整');
      }
    },
    cancelHandler() {
      this.$emit('visible-change', false);
    },
  },
  watch: {
    filterList: {
      handler(newVal) {
        if (newVal.length) {
          this.filter_list = cloneDeep(newVal);
        } else { // 当前不存在筛选项，初始化一个空的 filter
          this.filter_list = [
            {
              condition: this.columnType === 'type' ? 'include' : 'greater',
              value: null,
            },
          ];
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.column-filter-modal {
  /deep/ .ivu-modal-content {
    padding: 20px 24px;
    .body {
      margin-bottom: 16px;
      .filter-header {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        .condition-text, .condition-value {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #333440;
        }
        .condition-text {
          flex: 0 0 96px;
          margin-right: 8px;
        }
        .condition-value {
          flex: 0 0 192px;
          margin-right: 12px;
        }
        .condition-delete {
          flex: 0 0 16px;
        }
      }
      .filter-body {
        .filter-item {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 12px;
          }
          .condition-select {
            flex: 0 0 96px;
            margin-right: 8px;
          }
          .condition-value-select, .condition-value-input {
            flex: 0 0 192px;
            margin-right: 12px;
          }
          .delete-icon {
            flex: 0 0 16px;
            cursor: pointer;
            &:hover {
              color: #2b46bd;
            }
          }
        }
      }
      .add-box {
        display: inline-block;
        cursor: pointer;
        .add-icon {
          width: 16px;
          margin-right: 4px;
          vertical-align: middle;
          color: #2978FF;
        }
        .add-text {
          vertical-align: middle;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #2978FF;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;
      border-top: 1px solid #EAEBF7;
      .refresh-box {
        cursor: pointer;
        .refresh-icon {
          width: 16px;
          margin-right: 4px;
          vertical-align: middle;
          color: #878AA2;
        }
        .refresh-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #878AA2;
        }
      }
    }
  }
}
</style>
