/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { checkSSOLogin } from 'dc-vue-sso';
import authorityConfigRoute from './authorityConfigRoute';
import store from '../store';
import mainRoute from './mainRoute';
import AppDetailRoute from './appDetailRoute';

Vue.use(VueRouter);

const APP_HOME_LAYOUT = 'AppHomeLayout';
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: APP_HOME_LAYOUT,
    },
    redirect: {
      name: 'AppList',
    },
  },
  ...mainRoute,
  ...AppDetailRoute,
  ...authorityConfigRoute,
  { // 兜底
    path: '*',
    redirect: {
      name: 'AppList',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/', // 默认为process.env.BASE_URL即publicPath，为部署应用包时的基本URL
  routes,
});

const defaultTitle = 'Push 系统';

router.beforeEach(async (to, from, next) => {
  const currentTitle = to.meta.title || defaultTitle;
  const toLevel = to.meta.level;
  document.title = currentTitle;
  const appList = store.state.app_list;
  const { isLogin } = await checkSSOLogin();
  if (to.name === 'Push' && from.name === 'PushDetail') {
    store.commit('setCurrentPush', {
      current_push: null,
    });
  }
  const appId = to.params.app_id;
  let isAppIdValid = true;
  if (!isLogin) {
    next(false);
  } else if (toLevel === 'appDetail') {
      if (appList.length === 0) {
        // 在进入页面之前，检查是否需要请求初始化数据
        try {
          await store.dispatch('fetchInitData', {});
          isAppIdValid = !appId || store.state.app_list.some((x) => x.app_id === appId);
          // eslint-disable-next-line no-unused-expressions
          isAppIdValid ? next() : next('/');
        } catch (e) {
          next({ // 出错，回到应用列表页
            name: 'AppList',
          });
        }
      } else {
        isAppIdValid = !appId || appList.some((x) => x.app_id === appId);
        // eslint-disable-next-line no-unused-expressions
        isAppIdValid ? next() : next('/');
      }
    } else {
    next();
  }
});

export default router;
