<template>
  <div class="fullscreen-icon-wrap" @click="$emit('click')">
    <Icon type="md-expand" size="16" color="#9e9da2"></Icon>
  </div>
</template>

<script>
export default {
  name: 'FullscreenIcon',
};
</script>

<style scoped lang="less">
.fullscreen-icon-wrap {
  cursor: pointer;
}
</style>
