<template>
  <div class="temp-content-form">
    <form-content-header title="内容"/>
    <div class="form-container">
      <Form ref="tempContentForm" :model="temp_content_form" :rules="tempContentFormRules" :label-width="110">
        <FormItem label="消息组" prop="group">
          <Select v-model="temp_content_form.group" placeholder="请选择">
            <Option v-for="item in group_list" :key="item.id" :value="item.id">{{ item.title }}</Option>
          </Select>
        </FormItem>
        <FormItem label="下次推送消息" prop="template">
          <Select v-model="temp_content_form.template" placeholder="请选择">
            <Option v-for="item in template_list" :key="item.id" :value="item.id">{{ item.title }}</Option>
          </Select>
        </FormItem>
        <FormItem label="标题" prop="title">
          <Input v-model="temp_content_form.title" placeholder="请输入标题" disabled clearable/>
        </FormItem>
        <FormItem label="内容" prop="body">
          <Input type="textarea" :rows="3" v-model="temp_content_form.body" placeholder="请输入内容" disabled clearable/>
        </FormItem>
        <FormItem label="图片" prop="image">
          <basic-image-upload
            :image.sync="temp_content_form.image"
            :url.sync="temp_content_form.url"
            :disabled="true"/>
        </FormItem>
        <FormItem label="有效期" prop="validity_period" class="validity-period-form-item">
          <div class="form-item-box">
            <FormItem prop="start_time">
              <DatePicker type="datetime" v-model="temp_content_form.start_time" :options="startTimeOptions" placeholder="请选择" transfer/>
            </FormItem>
          </div>
          <span class="validity-period-text">至</span>
          <div class="form-item-box">
            <FormItem prop="end_time">
              <DatePicker type="datetime" v-model="temp_content_form.end_time" :options="endTimeOptions" placeholder="请选择" transfer/>
            </FormItem>
          </div>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
  /* eslint-disable camelcase */

  import FormContentHeader from '../../../common/form-content-header.vue';
import BasicImageUpload from '../../../common/BasicImageUpload.vue';

export default {
  name: 'TempContentForm',
  components: { FormContentHeader, BasicImageUpload },
  props: {
    push_schedule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      temp_content_form: {
        group: '',
        template: '',
        title: '',
        body: '',
        image: null,
        url: '',
        validity_period: 0,
        start_time: '',
        end_time: '',
      },
      tempContentFormRules: {
        group: [
          // 类型为number的时候需要加上type
          {
            required: true,
            type: 'number',
            message: '请选择素材组',
            trigger: 'change',
          },
        ],
        template: [
          // 类型为number的时候需要加上type
          {
            required: true,
            type: 'number',
            message: '请选择模板',
            trigger: 'change',
          },
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        body: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        start_time: [
          {
            required: true,
            message: '请输入开始时间',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        end_time: [
          {
            required: true,
            message: '请输入结束时间',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
      },
      group_list: [],
      template_list: [],
    };
  },
  computed: {
    startTimeOptions() {
      const { begin_time, end_time } = this.push_schedule;
      return {
        disabledDate(date) {
          if (begin_time) {
            const startDateLimit = new Date(begin_time).valueOf() - 86400000;
            if (end_time) {
              const endDateLimit = new Date(end_time).valueOf() + 86400000;
              return date && (date.valueOf() <= startDateLimit || date.valueOf() >= endDateLimit);
            }
            return date && date.valueOf() <= startDateLimit;
          }
          return false;
        },
      };
    },
    endTimeOptions() {
      const { begin_time, end_time } = this.push_schedule;
      const { start_time } = this.temp_content_form;
      return {
        disabledDate(date) {
          if (begin_time) {
            const startDateLimit = new Date(begin_time) > (start_time || 0)
              ? new Date(begin_time)
              : new Date(start_time);
            if (end_time) {
              const endDateLimit = new Date(end_time) + 86400000;
              return date && ((date.valueOf() <= startDateLimit) || date.valueOf() >= endDateLimit);
            }
            return date && date.valueOf() <= startDateLimit;
          }
          return false;
        },
      };
    },
  },
  methods: {
    fetchGroupList() {
      this.group_list = [];
      const promise = this.$api.getGroupList({
        app_id: this.$route.params.app_id,
      }).then((response) => {
        const groupInfo = response.data.group_info;
        this.group_list = groupInfo.map((item) => (
          {
            id: item.id,
            title: item.group_name,
            count: item.count || 0,
            template_list: null,
          }
        ));
      }).catch((error) => {
        this.$Message.error(error.message);
      }).finally(() => {});
      return promise;
    },
    fetchTemplateList(groupId) {
      this.template_list = [];
      const urlParams = {
        app_id: this.$route.params.app_id,
        group_id: groupId,
      };
      const promise = this.$api.getTemplateList(urlParams)
        .then((response) => {
          // eslint-disable-next-line camelcase
          const { template_info } = response.data;
          const templateList = template_info.map((item) => ({
            id: item.nid,
            title: item.title,
            body: item.body,
            url: item.image_url,
            localize_list: item.localize_list,
            group_id: item.group.id,
          }));
          this.template_list = templateList;
          const targetGroupItem = this.group_list.find((val) => val.id === groupId);
          if (targetGroupItem) { // 如果有目标group的话，就绑定
            targetGroupItem.template_list = templateList;
            if (!this.temp_content_form.template) { // 在template值为空的情况下在需要赋值
              this.temp_content_form.template = this.template_list.length > 0
                ? this.template_list[0].id
                : '';
            }
          }
        }).catch((error) => {
          console.log(error);
          this.$Message.error(error.message);
        });
      return promise;
    },
  },
  watch: {
    '$store.state.current_temp_push': {
      handler(newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          this.temp_content_form.group = newVal.group_id;
          this.temp_content_form.template = newVal.next_display;
          this.temp_content_form.start_time = new Date(newVal.begin_time);
          this.temp_content_form.end_time = new Date(newVal.end_time);
        }
      },
      immediate: true,
    },
    'temp_content_form.group': {
      handler(newVal) {
        const targetGroup = this.group_list.find((group) => group.id === newVal);
        if (targetGroup && targetGroup.template_list) { // 如果之前已经请求过，可以直接使用
          this.template_list = targetGroup.template_list;
          this.temp_content_form.template = this.template_list.length > 0
            ? this.template_list[0].id
            : null;
        } else if (newVal) { // 否则请求template_list
          this.fetchTemplateList(newVal);
        }
      },
      immediate: true,
    },
    'temp_content_form.template': {
      handler(newVal) {
        if (newVal && this.template_list.length > 0) {
          const targetTemplate = this.template_list.find((template) => template.id === newVal);
          this.temp_content_form.title = targetTemplate.title;
          this.temp_content_form.body = targetTemplate.body;
          this.temp_content_form.url = targetTemplate.url;
          this.temp_content_form.image = null;
        }
      },
      immediate: true,
    },
    template_list(newVal) {
      if (newVal.length > 0 && this.temp_content_form.template) {
        const targetTemplate = newVal.find((template) => template.id === this.temp_content_form.template);
        if (targetTemplate) { // 如果template_list中有对应的template item的话
          this.temp_content_form.title = targetTemplate.title;
          this.temp_content_form.body = targetTemplate.body;
          this.temp_content_form.url = targetTemplate.url;
          this.temp_content_form.image = null;
        }
      }
    },
  },
  created() {
    this.fetchGroupList();
  },
};
</script>

<style scoped lang="less">
  .temp-content-form {
    margin-bottom: 8px;
    padding: 0 24px;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    @import '../../../../assets/less/create_push_form/formContainer.less';
    .form-container {
      .validity-period-form-item {
        /deep/ .ivu-form-item-content {
          display: flex;
          .form-item-box {
            margin-right: 8px;
            &:last-child {
              margin-right: 0;
            }
          }
          .validity-period-text {
            margin-right: 8px;
          }
        }
      }
      .form-item-box {
        display: inline-block;
      }
      /deep/ .image-url-input-box {
        .input-text {
          margin-right: 4px;
        }
        .url-input {
          display: inline-block;
          width: calc(~'100% - 64px');
        }
      }
    }
  }
</style>
