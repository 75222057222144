<template>
  <component :is="layout">
    <slot/>
  </component>
</template>

<script>
const APP_DEFAULT_LAYOUT = 'AppDefaultLayout';
export default {
  name: 'AppLayout',
  computed: {
    layout() {
      const layout = this.$route.meta.layout || APP_DEFAULT_LAYOUT;
      return () => import(`@/layouts/${layout}.vue`);
    },
  },
};
</script>

<style scoped>

</style>
