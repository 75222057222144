<template>
  <div class="basic-info-form">
    <Form ref="appInfoForm" :model="app_info" :rules="app_info_rules" :label-width="120">
      <!--label prop：-->
      <FormItem label="应用 ID" prop="id">
        <Select v-model="app_info.id" :placeholder="type === 'edit' ? app_info.id : '应用 ID' " @on-change="appInfoIdChangeHandler" :disabled="type === 'edit'">
          <Option v-for="item in appsList" :value="item.pid" :key="item.pid" :label="item.pid">{{ item.project_name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="应用名称" prop="product_name">
        <Input v-model="app_info.product_name" placeholder="应用名称" @on-change="appInfoChangeHandler"/>
      </FormItem>
      <FormItem label="商城应用 ID" prop="store_app_id">
        <span slot="label">
          <span>商城应用 ID</span>
          <Tooltip  placement="top" transfer>
          <Icon class="icon" type="ios-help-circle-outline" :size="16"/>
          <div slot="content">
            <p>Android 包名</p>
          </div>
        </Tooltip>
        </span>
        <Input v-model="app_info.store_app_id" placeholder="商城应用 ID" @on-change="appInfoChangeHandler"/>
      </FormItem>
      <FormItem label="类别" prop="category">
        <Select v-model="app_info.category" placeholder="类别" @on-change="appInfoChangeHandler">
          <Option value="App">App</Option>
          <Option value="Game">Game</Option>
        </Select>
      </FormItem>
      <FormItem label="平台" prop="platform">
        <Select v-model="app_info.platform" placeholder="平台" @on-change="appInfoChangeHandler">
          <Option value="iOS">iOS</Option>
          <Option value="Android">Android</Option>
        </Select>
      </FormItem>
      <FormItem label="发布日期" prop="publish_time">
        <DatePicker
          v-model="app_info.publish_time"
          type="date"
          :options="date_picker_option"
          transfer
          placeholder="选择日期" @on-change="appInfoChangeHandler"></DatePicker>
      </FormItem>
      <FormItem label="优先级" prop="priority">
        <span slot="label">
          <span>优先级</span>
          <Tooltip  placement="top" transfer>
          <Icon class="icon" type="ios-help-circle-outline" :size="16"/>
          <div slot="content">
            <p>push任务发送优先级</p>
            <p>高优先级会唤醒设备等</p>
          </div>
        </Tooltip>
        </span>
        <RadioGroup v-model="app_info.priority" @on-change="appInfoChangeHandler">
          <Radio :label="0">普通优先级</Radio>
          <Radio :label="1">高优先级</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="图标" prop="logo">
        <image-upload class="app-info-image-upload" v-model="app_info.logo" :image_url.sync="app_info.url" @image-change="appInfoChangeHandler"/>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import ImageUpload from '../../../common/ImageUpload.vue';

  export default {
    name: 'AppInfoForm',
    props: {
      type: String,
      app_info_model: Object,
    },
    components: { ImageUpload },
    inject: ['appIDListFn'],
    data() {
      const validateID = (rule, value, callback) => {
        const index = this.appIDListFn().indexOf(value);
        if (index === -1 || this.type === 'edit') { // 只有在创建的状态下才会对ID进行重复性的验证
          callback();
        } else {
          callback(new Error('ID 值重复'));
        }
      };
      const validateImage = (rule, value, callback) => {
        if ((this.app_info.logo || this.app_info.url)) {
          callback();
        } else {
          callback(new Error('图标不能为空'));
        }
      };
      return {
        app_info_rules: {
          id: [
            { required: true, message: 'ID 不能为空', trigger: 'blur' },
            { validator: validateID, message: 'ID 不能重复', trigger: 'blur' },
          ],
          product_name: [
            { required: true, message: '应用名称不能为空', trigger: 'blur' },
          ],
          store_app_id: [
            { required: true, message: '商城应用 ID不能为空', trigger: 'blur' },
          ],
          category: [
            { required: true, message: '类别不能为空', trigger: 'change' },
          ],
          platform: [
            { required: true, message: '平台不能为空', trigger: 'change' },
          ],
          publish_time: [
            {
              required: true,
              message: '发布日期不能为空',
              trigger: 'blur',
              pattern: /.+/,
            },
          ],
          logo: [
            // 需要自定以验证
            { validator: validateImage, message: '图标不能为空', trigger: 'image-change' },
          ],
        },
        app_info: {
          id: '',
          category: '',
          product_name: '',
          platform: '',
          store_app_id: '',
          publish_time: '',
          priority: 0,
          logo: null,
          url: '',
        },
        date_picker_option: {
          disabledDate(date) {
            return date.valueOf() > Date.now();
          },
        },
        appsList: [],
      };
    },
    mounted() {
      this.biApps();
    },
    methods: {
      appInfoChangeHandler() { // app_info触发app_info_model的修改不适合用watch，因为要考虑到在编辑状态下app_info_model给app_info赋值也会触发后者的watch
        this.$emit('update:app_info_model', { ...this.app_info });
      },
      appInfoIdChangeHandler(data) {
        console.log(data);
        this.appsList.forEach((item) => {
          if (item.pid === data) {
            console.log(item.project_logo);
            this.app_info.url = item.project_logo;
            this.app_info.product_name = item.project_name;
            this.app_info.id = data;
            this.app_info.category = item.category;
            this.app_info.store_app_id = item.store_app_id;
            this.app_info.platform = item.platform;
            this.app_info.publish_time = item.publish_time;
          }
        });
        this.$emit('update:app_info_model', { ...this.app_info });
      },
      resetForm() {
        this.$refs.appInfoForm.resetFields();
      },
      async biApps() {
        const allAppsList = (await this.$api.getBiApps()).data.list;
        const addedAppIdList = (await this.$api.getAppList()).data.list.map((item) => item.app_id);
        this.appsList = allAppsList.filter((item) => !addedAppIdList.includes(item.pid));
      },
    },
    watch: {
      app_info_model(newVal) {
        // 土匪式对象深拷贝，需要改进
        // this.app_info = JSON.parse(JSON.stringify(newVal));
        // 因为是单层的，所以可以这么使用
        this.app_info = { ...newVal };
      },
    },
  };
</script>

<style scoped lang="less">
  .app-info-form {
  }
  /deep/ .ivu-form-item-error {
    .image-upload .file-box {
      border: 1px solid #ed4014!important;
    }
  }
</style>
