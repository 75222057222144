<template>
  <div class="week-day-picker">
    <div class="week-day-item"
         :class="{ 'selected-item': value.indexOf(item.value) !== -1 }"
         v-for="item in week_day_list"
         :key="item.value"
         @click="selectDayItem(item.value)">{{ item.label }}</div>
  </div>
</template>

<script>
export default {
  name: 'WeekDayPicker',
  props: {
    value: Array,
  },
  model: {
    prop: 'value',
    event: 'week-day-change',
  },
  data() {
    return {
      week_day_list: [
        {
          label: '一',
          value: 0,
        },
        {
          label: '二',
          value: 1,
        },
        {
          label: '三',
          value: 2,
        },
        {
          label: '四',
          value: 3,
        },
        {
          label: '五',
          value: 4,
        },
        {
          label: '六',
          value: 5,
        },
        {
          label: '日',
          value: 6,
        },
      ],
    };
  },
  methods: {
    selectDayItem(value) {
      let newArr;
      const targetIndex = this.value.indexOf(value);
      if (targetIndex === -1) { // 原来未选中该选项
        newArr = [value, ...this.value];
      } else { // 原来就已经选中该选项
        this.value.splice(targetIndex, 1);
        newArr = [...this.value];
      }
      this.$emit('week-day-change', newArr);
    },
  },
};
</script>

<style scoped lang="less">
  @weekDayItemSize: 30px;
  .week-day-picker {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 8px;
    .week-day-item {
      width: @weekDayItemSize;
      height: @weekDayItemSize;
      line-height: @weekDayItemSize;
      margin: 0 8px 6px 0;
      border-radius: 50%;
      text-align: center;
      background-color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      color: #333440;
      transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
    .selected-item {
      background-color: #2978FF;
      color: #FFFFFF;
    }
  }
</style>
