import Loader from '../../components/common/Loader.vue';

export default {
  install(Vue) {
    const LoaderComponent = Vue.extend(Loader);
    const elm = document.createElement('div');
    // eslint-disable-next-line no-undef
    const fn = (targetElm, config = {}) => {
      console.log(config);
      const { maxHeight, backgroundColor } = config;
      const loader = new LoaderComponent({
        propsData: {
          maxHeight,
          backgroundColor,
        },
      }).$mount(elm);
      const parentElm = targetElm || document.body;
      return {
        show() {
          parentElm.appendChild(loader.$el);
          // 方便链式调用
          return this;
        },
        hide() {
          parentElm.removeChild(loader.$el);
          return this;
        },
      };
    };
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$loader = fn;
  },
};
