<template>
  <div class="create-push">
    <div class="content-header">
      <Breadcrumb>
        <BreadcrumbItem :to="{ name: 'Push' }">Push 列表</BreadcrumbItem>
        <BreadcrumbItem>{{ pushFormTitle }}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="push-form" ref="pushForm">
      <div class="main-form">
        <div class="left-form">
          <name-form ref="nameForm" :current_push="current_push"></name-form>
          <content-form ref="contentForm"
                        :auto="!!content_form_data_auto"
                        :current-push="current_push"
                        :push-form-status="pushFormStatus"
                        @create-temp-push="createTempPushTrigger"
                        @show-temp-push-list="showTempPushListTrigger"
                        @pause-temp-push="pauseTempPushTrigger">
            <!--  命名插槽  -->
            <template slot="tempPushButtonGroup">
              <Tooltip content="临时推送仅在编辑状态下的重复循环发送模式下才有效" :disabled="tempPushButtonGroupVisible && !current_push.temp_task" :max-width="200" placement="top">
                <!--  临时推送按钮在创建状态下不显示，是在创建之后，且在重复循环发送模式下才显示的  -->
                <ButtonGroup>
                  <!--  只有不存在正在运行的临时任务时，才可以新增临时任务  -->
                  <Button class="create-temp-push-btn" :disabled="!(tempPushButtonGroupVisible && !current_push.temp_task)" @click="createTempPushTrigger">
                    <Icon type="md-add" style="margin-right: 4px" size="16"></Icon>
                    <span>临时推送</span>
                  </Button>
                  <Button class="show-temp-push-list-btn" :disabled="!(tempPushButtonGroupVisible && current_push.push_content.push_type !== 'message')" @click="showTempPushListTrigger">
                    <Icon type="ios-list" :size="24"></Icon>
                  </Button>
                </ButtonGroup>
              </Tooltip>
            </template>
            <!--  临时任务显示框，只有在编辑状态下，且拥有在运行的临时任务时才显示  -->
            <temp-push-display-box v-if="pushFormStatus !== 'create' && !!running_temp_push" :temp_push="running_temp_push" @pause="pauseTempPushTrigger"/>
          </content-form>
          <target-config-form ref="targetConfigForm" :current_push="current_push"></target-config-form>
          <time-config-form ref="timeConfigForm" :current_push="current_push"></time-config-form>
<!--          <template v-if="content_form_data_type === 'notification'">-->
            <extra-config-form  ref="extraConfigForm" :data-type="content_form_data_type" :current_push="current_push"></extra-config-form>
<!--          </template>-->
        </div>
        <div class="right-form form">
          <div class="wrap">
            <test-machine-form
              :push_type="content_form_data_type"
              :notification_data="content_form_notification_data"
              :message_data="content_form_message_data"
              @send-trigger="sendTestHandler"></test-machine-form>
            <rendering-box
              :push_type="content_form_data_type"
              :notification_data="content_form_notification_data"></rendering-box>
          </div>
        </div>
      </div>
      <!-- 本地化翻译只有在notification类型是才会显示 -->
      <div v-if="content_form_data_type === 'notification'" class="bottom-form">
        <localized-translation-form
          ref="localizedTranslationForm"
          :form_data="content_form_notification_data"
          :current_push="current_push"></localized-translation-form>
      </div>
    </div>
    <div v-if="pushFormStatus === 'read'" class="submit-box">
      <Button type="default" class="submit-btn" @click="cancelHandler">返回</Button>
    </div>
    <div v-else-if="pushFormStatus === 'edit'" class="submit-box">
      <Button type="primary" class="send-btn submit-btn" :disabled="periodicTaskEditDisabled" @click="sendHandler(false)">
        <img src="../../../assets/image/ic_send.png" alt="send icon" class="send-icon">
        <span class="send-text">保存</span>
      </Button>
      <Button class="submit-btn" @click="cancelHandler">取消</Button>
    </div>
    <!-- 创建 -->
    <div v-else class="submit-box">
      <Button type="primary" class="send-btn submit-btn" @click="sendHandler(true)">
        <img src="../../../assets/image/ic_send.png" alt="send icon" class="send-icon">
        <span class="send-text">发送</span>
        <Modal
          v-model="confirmSend"
          :footer-hide="true">
          <div class="waringModal"><img src="../../../assets/image/Warning.png"/>确定发送吗？</div>
          <div class="modalHandler">
            <Button class="cancel" @click="sendCancelModal">取 消</Button>
            <Button class="confirm" @click="sendHandler(false)" :loading="sendLoading">确 认</Button>
          </div>
        </Modal>
      </Button>
      <!-- 编辑状态下不显示保存为草稿 -->
      <Button class="submit-btn" @click="saveAsDraftHandler">保存为草稿</Button>
      <Button class="submit-btn" @click="cancelHandler">取消</Button>
    </div>
    <create-message-temp-push-modal
      v-model="create_message_temp_push_model_visible"
      @create="createMessageTempPushHandler"/>
  </div>
</template>

<script>
  /* eslint-disable camelcase,no-unused-vars */

import NameForm from '../../../components/page/content/create_push_form/NameForm.vue';
import ContentForm from '../../../components/page/content/create_push_form/ContentForm.vue';
import TargetConfigForm from '../../../components/page/content/create_push_form/TargetConfigForm.vue';
import TimeConfigForm from '../../../components/page/content/create_push_form/TimeConfigForm.vue';
import TestMachineForm from '../../../components/page/content/create_push_form/TestMachineForm.vue';
import ExtraConfigForm from '../../../components/page/content/create_push_form/ExtraConfigForm.vue';
import RenderingBox from '../../../components/page/content/create_push_form/RenderingBox.vue';
import LocalizedTranslationForm
  from '../../../components/page/content/create_push_form/LocalizedTranslationForm.vue';
import TempPushDisplayBox
  from '../../../components/page/content/create_push_form/TempPushDisplayBox.vue';
import CreateMessageTempPushModal
  from '../../../components/page/content/create_temp_push_form/CreateMessageTempPushModal.vue';

export default {
  name: 'CreatePush',
  components: {
    CreateMessageTempPushModal,
    LocalizedTranslationForm,
    RenderingBox,
    TestMachineForm,
    TimeConfigForm,
    ExtraConfigForm,
    TargetConfigForm,
    ContentForm,
    NameForm,
    TempPushDisplayBox,
  },
  data() {
    return {
      content_form_data_type: 'notification',
      content_form_data_auto: false,
      content_form_notification_data: {},
      content_form_message_data: '',
      send_status: '', // 'send' || 'saveAsDraft'
      running_temp_push: null, // 当前正在运行的临时推送任务，只有在编辑推送状态下才会显示
      create_message_temp_push_model_visible: false,
      confirmSend: false,
      originContent: {},
      sendLoading: false,
      current_push: {},
      rotation_list: [],
    };
  },
  computed: {
    // 涉及到refs的，响应式失效；只是因为调用时机靠后以及返回的是引用类型的原因
    nameForm() {
      return this.$refs.nameForm.$refs.nameForm;
    },
    contentForm() {
      return this.$refs.contentForm.$refs.contentForm;
    },
    targetConfigForm() {
      return this.$refs.targetConfigForm.$refs.targetConfigForm;
    },
    timeConfigForm() {
      return this.$refs.timeConfigForm.$refs.timeConfigForm;
    },
    extraConfigFrom() {
      return this.$refs.extraConfigForm.$refs.extraConfigForm;
    },
    localizedTranslationForm() {
      // 组件中自定义的form函数
      return this.$refs.localizedTranslationForm.form();
    },
    nameFormData() {
      return this.$refs.nameForm.name_form;
    },
    contentFormData() {
      return this.$refs && this.$refs.contentForm && this.$refs.contentForm.content_form;
    },
    targetConfigFormData() {
      const { mode } = this.$refs.targetConfigForm;
      const { payment_type, ...advancedFormData } = this.$refs.targetConfigForm.advanced_form;
      if (mode === 'standard') {
        const standardFromData = { region: {}, manufacturer: {} };
        standardFromData.region = this.$refs.targetConfigForm.standard_form.region;
        standardFromData.manufacturer = this.$refs.targetConfigForm.standard_form.manufacturer;
        if (this.$refs.targetConfigForm.standard_form.payment_type === 'all') {
          standardFromData.license = {
            op: 'included',
            value: [],
          };
        } else if (this.$refs.targetConfigForm.standard_form.payment_type === 'free') {
          standardFromData.license = {
            op: 'included',
            value: ['free'],
          };
        } else if (this.$refs.targetConfigForm.standard_form.payment_type === 'pro') {
          standardFromData.license = {
            op: 'included',
            value: ['pro'],
          };
        }
        return standardFromData;
      }
      if (payment_type === 'all') {
        advancedFormData.license = {
          op: 'included',
          value: ['pro', 'free'],
        };
      } else if (payment_type === 'free') {
        advancedFormData.license = {
          op: 'included',
          value: ['free'],
        };
      } else if (payment_type === 'pro') {
        advancedFormData.license = {
          op: 'included',
          value: ['pro'],
        };
      }
      return advancedFormData;
    },
    timeConfigFormData() {
      return this.$refs.timeConfigForm.time_config_form;
    },
    extraConfigFormData() {
      return this.$refs && this.$refs.extraConfigForm && this.$refs.extraConfigForm.extraConfigForm;
    },
    localizedTranslationFormData() { // 语言本地化只有在notification状态下才有
      if (this.content_form_data_type === 'notification') {
        const list = this.$refs.localizedTranslationForm.translation_info_list;
        return list.map((item) => ({
          title: item.title,
          body: item.content,
          language: item.language,
          url: item.url || '',
          image: item.image,
          editable: false,
          readonly: true,
        })) || [];
      }
      return [];
    },
    formList() {
      const arr = [
        this.nameForm,
        this.contentForm,
        this.targetConfigForm,
        this.timeConfigForm,
      ];
      if (this.content_form_data_type === 'notification') { // 只有在notification下才有localize
        arr.push(this.localizedTranslationForm);
      }
      return arr;
    },
    pushFormStatus() {
      return this.$route.params.task_id ? 'edit' : 'create';
    },
    pushFormTitle() {
      if (this.pushFormStatus === 'create') return '创建推送';
      if (this.pushFormStatus === 'edit') return '编辑推送';
      if (this.pushFormStatus === 'read') return '查看推送';
      return '推送';
    },
    tempPushButtonGroupVisible() {
      console.log('0000', this.current_push.push_schedule?.schedule_type === 'periodic_time', this.current_push);
      return this.pushFormStatus === 'edit' && this.current_push && this.current_push.push_schedule?.schedule_type === 'periodic_time';
    },
    periodicTaskEditDisabled() { // 其他不满足条件的推送无法进入编辑页面，但是周期性任务可以，所以只需要排除周期性任务中不满足编辑的推送任务即可
      // const { schedule_type, begin_time } = this.current_push.push_schedule;
      // return schedule_type === 'periodic_time' && new Date(begin_time).getTime() <= Date.now();
      return false;
    },
  },
  methods: {
    async fetchEditData() {
      const loader = this.$loader(this.$refs.pushForm, {
        maxHeight: document.body.clientHeight - 48 - 76,
      }).show();
      try {
        await this.fetchRotationList();
        await this.fetchPushDetail(this.$route.params.task_id || this.$route.query.task_id);
      } finally {
        loader.hide();
      }
    },
    fetchRotationList() { // 如果 store 中不存在 rotation 信息，则重新请求
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const promise = this.$api.getRotationInfo(urlParams)
        .then((response) => {
          const { group_info, data } = response.data;
          if (group_info) {
            this.rotation_list = data ? [...data] : [];
            return null;
          }
          return Promise.reject(new Error('推送分组信息获取失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    fetchPushDetail(pushId) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: pushId,
      };
      const promise = this.$api.getTaskDetail(urlParams)
        .then((response) => {
          if (response.data.task) {
            const newCurrentPush = { ...response.data.task };
            if (newCurrentPush.push_content.push_type === 'notification') { // notification 才有可能有 group 等
              const targetGroup = this.rotation_list.find((item) => item.task_id === newCurrentPush.task_id);
              newCurrentPush.push_content.notification.group = targetGroup ? targetGroup.group_name.id : null;
              newCurrentPush.push_content.notification.template = targetGroup ? targetGroup.next_display.nid : null;
            }
            this.originContent = newCurrentPush;
            this.current_push = { ...newCurrentPush };
            // 更新当前选中的Push详细信息
            // this.$store.commit('setCurrentPush', {
            //   current_push: newCurrentPush,
            // });
            this.$store.commit('setCurrentPushTaskName', {
              task_name: newCurrentPush.task_name,
            });
          } else {
            this.$router.push({
              name: 'Push',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Message.error(error.message);
        });
      return promise;
    },
    operateRotation(groupId, nextDisplayId, taskId, rid, operation) { // 新增 rotation，rotation 为 group 和 task 的一个绑定
      if (!operation) {
        return Promise.resolve('success');
      }
      console.log('rid', rid);
      let api;
      if (operation === 'delete') {
        api = this.$api.deleteRotation;
      } else if (operation === 'add') {
        api = this.$api.addRotation;
      } else if (operation === 'update') {
        api = this.$api.updateRotation;
      }
      const urlParams = {
        app_id: this.$route.params.app_id,
        rotation_id: rid || undefined,
      };
      const params = operation === 'delete'
        ? {} : {
        group_id: groupId,
        next_display: nextDisplayId,
        task_id: taskId,
      };
      const promise = api(urlParams, params)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    validateForm(form) { // 验证单个表单，返回Promise实例
      return form.validate((valid) => valid);
    },
    validatePage(callback) { // 验证页面的所有表单
      const promiseArr = [];
      this.formList.forEach((form) => {
        console.log(form);
        const promise = this.validateForm(form);
        promiseArr.push(promise);
      });
      Promise.all(promiseArr).then((values) => {
        console.log(values);
        if (values.every((value) => value)) {
          // 如果所有表单都验证通过，则执行回调函数
          callback.call(this);
        } else {
          // 分别对应不同的表单
          const errorMessages = ['请填写名称', '请将内容填写完整', '请将目标设置填写完整', '请将时间设置填写完成', '请将翻译填写完整'];
          const currentMessage = errorMessages[values.findIndex((x) => !x)];
          this.$Message.warning(currentMessage);
        }
      });
    },
    packUpParams() { // 打包Push信息参数
      const {
        group,
        template,
        ...notificationData
      } = this.contentFormData.notification;
      const extraConfigFormData = {
        priority: this.extraConfigFormData.priority,
      };
      if (this.contentFormData.type === 'notification') {
        const obj = {};
        this.extraConfigFormData.data.forEach((item) => {
          if (item.key !== '' && item.value !== '') {
            obj[item.key] = item.value;
          }
        });
        extraConfigFormData.data = { ...obj };
        extraConfigFormData.sound = this.extraConfigFormData.sound ? 1 : 0;
        extraConfigFormData.channel_id = this.extraConfigFormData.channel_id;
      }
      let messageData;
      if (this.contentFormData.type === 'message' && !this.nameFormData.auto) {
        messageData = JSON.parse(this.$tools.formatJSON(this.contentFormData.message.body));
      }
      // const imageFileList = []; // 第一个元素对应notification的image，剩余为localize的image
      // // 不管有没有上传文件，都要填充，如果为空，则赋null
      // imageFileList.push(notificationData.image || null);
      // this.localizedTranslationFormData.forEach((item) => {
      //   imageFileList.push(item.image || null);
      // });
      if ([null, undefined, ''].includes(this.contentFormData.notification.group)) {
        notificationData.localize = [...this.localizedTranslationFormData];
      } else {
        notificationData.localize = [];
      }
      const pushContent = this.nameFormData.auto ? {
        auto: this.nameFormData.auto ? 1 : 0, // 控制是否是auto模式
        push_type: this.contentFormData.type,
        notification: this.contentFormData.type === 'notification' ? {
          title: '',
          body: '',
          image: '',
        } : undefined,
        message: this.contentFormData.type === 'message' ? {} : undefined,
      } : {
        push_type: this.contentFormData.type,
        push_data: extraConfigFormData,
        notification: this.contentFormData.type === 'notification'
          ? notificationData : undefined,
        message: this.contentFormData.type === 'message'
          ? messageData : undefined,
        auto: this.nameFormData.auto ? 1 : 0, // 控制是否是auto模式
      };
      // task_status
      let taskStatus;
      const isPeriodicTime = ['periodic_time', 'interval'].includes(this.timeConfigFormData.schedule_type);
      if (this.pushFormStatus === 'create') { // 新建
        if (this.send_status === 'send') {
          taskStatus = isPeriodicTime // 重复性任务的状态为5，其余的任务状态都为2
            ? 5 : 2;
        } else {
          taskStatus = 4; // 草稿
        }
      } else if (this.pushFormStatus === 'edit') { // 编辑
        const currentTaskStatus = this.current_push.task_status;
        if (currentTaskStatus === 4) { // 草稿
          taskStatus = 4;
        } else if (currentTaskStatus === 0) { // 已暂停
          taskStatus = 0;
        } else {
          taskStatus = isPeriodicTime // 重复性任务的状态为5，其余的任务状态都为2
            ? 5 : 2;
        }
      }
      let localize = [];
      if (this.contentFormData.type === 'notification') {
        if ([null, undefined, ''].includes(this.contentFormData.notification.group)) {
          localize = [...this.localizedTranslationFormData];
        } else {
          localize = [...this.contentFormData.notification.localize];
        }
      }
      const pushInfo = {
        // 更新状态时，需要带上task_id
        task_id: this.pushFormStatus === 'create'
          ? undefined
          : this.current_push.task_id,
        app_id: this.$route.params.app_id,
        task_name: this.nameFormData.name,
        push_content: {
          ...pushContent,
        },
        push_condition: {
          ...this.targetConfigFormData,
        },
        push_schedule: {
          is_local: this.timeConfigFormData.is_local,
          schedule_type: this.timeConfigFormData.schedule_type,
          precise_time: this.timeConfigFormData.schedule_type === 'precise_time'
            ? this.$tools.dateTimeFormatter(this.timeConfigFormData.precise_time.time)
            : undefined,
          periodic_time: isPeriodicTime
            ? this.timeConfigFormData.periodic_time
            : undefined,
          begin_time: isPeriodicTime
            ? this.$tools.dateTimeFormatter(this.timeConfigFormData.start_time)
            : null,
          end_time: this.timeConfigFormData.end_time && isPeriodicTime
            ? this.$tools.dateTimeFormatter(this.timeConfigFormData.end_time)
            : null,
        },
        localize, // message的情况为空数组
        // eslint-disable-next-line no-nested-ternary
        task_status: taskStatus,
        query_type: this.$refs.targetConfigForm.mode === 'standard' ? 0 : 1, // 控制是否为token发送，0为否，1为是
      };
      return {
        pushInfo,
        // imageFileList,
      };
    },
    // uploadAllImages(imageList) {
    //   const promiseArr = [];
    //   imageList.forEach((image, index) => {
    //     let promise;
    //     if (image) {
    //       promise = this.$uploadImage(image);
    //     } else {
    //       let url;
    //       if (index === 0) { // 因为默认数组第一个元素是对应的notification中的image
    //         url = this.contentFormData.notification.url;
    //       } else { // 注意index - 1
    //         url = this.localizedTranslationFormData[index - 1].url;
    //       }
    //       promise = Promise.resolve(url);
    //     }
    //     promiseArr.push(promise);
    //   });
    //   return Promise.all(promiseArr);
    // },
    sendTestHandler(deviceToken, pushType) { // 发送测试
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      let pushContent = {};
      let api;
      if (pushType === 'notification') {
        const push_data = {
          data: {},
          sound: 0,
          channel_id: '',
        };
        const { data, sound, channel_id } = this.extraConfigFormData;
        data.forEach((item) => {
          // eslint-disable-next-line no-unused-expressions
          item.key !== '' && item.value !== '' ? push_data.data[item.key] = item.value : null;
        });
        push_data.sound = sound ? 1 : 0;
        push_data.channel_id = channel_id;
        pushContent = {
          title: this.content_form_notification_data.title,
          body: this.content_form_notification_data.body,
          image: this.content_form_notification_data.url
            || (this.content_form_notification_data.image && this.$tools.imageFileToURL(this.content_form_notification_data.image))
            || undefined,
          push_data: { ...push_data },
        };
        api = this.$api.sendNotificationTest;
      }
      if (pushType === 'message') {
        pushContent = JSON.parse(this.$tools.formatJSON(this.content_form_message_data));
        api = this.$api.sendMessageTest;
      }
      const { priority } = this.extraConfigFormData;
      const params = {
        push_content: pushContent,
        device_token: deviceToken,
        priority,
      };
      // eslint-disable-next-line no-nested-ternary
      const text = priority === 1 ? '高优先级' : '普通优先级';
      this.$Modal.confirm({
        title: '温馨提示',
        content: `当前测试任务采用${text}，如有需要，请在左侧额外设置中对优先级进行单独设置`,
        okText: '继续发送',
        cancelText: '取消',
        onOk: () => {
          api(urlParams, params)
            .then((response) => {
              const { result } = response.data;
              if (result === 'success') {
                this.$Message.success('测试发送成功');
                return null;
              }
              return Promise.reject(new Error('测试发送失败'));
            })
            .catch((error) => {
              this.$Message.error(error.message);
            });
        },
      });
    },
    sendPushInfo(pushInfo) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.pushFormStatus === 'create' ? undefined : pushInfo.task_id,
      };
      const params = {
        ...pushInfo,
      };
      const api = this.pushFormStatus === 'create' ? this.$api.addTask : this.$api.updateTask;
      // eslint-disable-next-line no-nested-ternary
      const successMsg = this.send_status === 'send'
        ? (this.pushFormStatus === 'create' ? '创建成功' : '保存成功')
        : '保存草稿成功';
      // eslint-disable-next-line no-nested-ternary
      const errorMsg = this.send_status === 'send'
        ? (this.pushFormStatus === 'create' ? '新建推送失败' : '更新推送失败')
        : '保存草稿失败';

      const hasGroup = !!(this.content_form_notification_data.group
        && this.content_form_notification_data.template); // 是否包含 group 以及 template
      const groupId = this.content_form_notification_data.group;
      const nextDisplay = this.content_form_notification_data.template;
      let rid;

      let operation;
      if (this.pushFormStatus === 'create') {
        operation = hasGroup ? 'add' : null;
      } else if (this.pushFormStatus === 'edit') {
        const currentPushGroup = this.current_push.push_content.notification?.group;
        console.log('currentPushGroup', currentPushGroup);
        if (currentPushGroup) {
          rid = this.rotation_list.find((item) => item.task_id === this.current_push.task_id).rid;
          operation = hasGroup ? 'update' : 'delete';
        } else {
          operation = hasGroup ? 'add' : null;
        }
      }
      const promise = api(urlParams, params)
        .then((response) => {
          const { result, task_id } = response.data;
          if (result === 'success' || result === 'modify success') {
            return this.operateRotation(groupId, nextDisplay, task_id || this.current_push.task_id, rid, operation);
          }
          return Promise.reject(new Error(errorMsg));
        })
        .then((response) => {
          console.log('rotation', response);
          this.$Message.success(successMsg);
          if (this.send_status === 'send'
            && this.pushFormStatus === 'edit'
            && (['periodic_time', 'interval'].includes(this.timeConfigFormData.schedule_type))
            && this.current_push.task_status !== 0
            && this.current_push.task_status !== 4) {
            // 编辑状态下，周期性任务被修改，弹出提示框
            this.$Message.warning('更改内容将会在下一个发送周期里再完全生效');
          }
          this.$router.push({
            name: 'Push',
          });
        })
        .catch((error) => {
          this.$Message.error(error.message ?? '操作失败');
        });
      return promise;
    },
    send() {
      const params = this.packUpParams();
      const { pushInfo } = JSON.parse(JSON.stringify(params));
      console.log(pushInfo);
      let logicalConditions = 0;
      const checkCondition = (condition, value) => {
        if (condition) {
          logicalConditions += value;
        }
      };
      const checkTargetConfig = () => {
        checkCondition(this.targetConfigFormData.license.value.length, 1);
        checkCondition(this.targetConfigFormData.region.op === 'excluded', this.targetConfigFormData.region.value.length);
        checkCondition(this.targetConfigFormData.region.op === 'included' && this.targetConfigFormData.region.value.length > 0, 1);
        checkCondition(this.targetConfigFormData.manufacturer.op === 'excluded', this.targetConfigFormData.manufacturer.value.length);
        checkCondition(this.targetConfigFormData.manufacturer.op === 'included' && this.targetConfigFormData.manufacturer.value.length > 0, 1);
      };
      if (pushInfo.query_type === 0) {
        if (pushInfo.push_schedule.is_local) {
          checkCondition(true, 1);
          checkCondition(this.localizedTranslationFormData.length || this.contentFormData.notification.localize?.length, 1);
          checkTargetConfig();
        }
        if (!pushInfo.push_schedule.is_local) {
          if (this.localizedTranslationFormData.length || this.contentFormData.notification.localize?.length) {
            checkCondition(true, 1);
            checkTargetConfig();
          }
          if (
            !this.localizedTranslationFormData.length
            && !this.contentFormData.notification.localize?.length
            && (this.targetConfigFormData.region.value.length || this.targetConfigFormData.manufacturer.value.length)
          ) {
            const opValue = [this.targetConfigFormData.region.op, this.targetConfigFormData.manufacturer.op];
            if (opValue.includes('excluded')) {
              if (opValue.includes('included')) {
                const field = opValue.indexOf('included') === 0 ? 'region' : 'manufacturer';
                if (this.targetConfigFormData[field].value.length) {
                  checkTargetConfig();
                }
                if (!this.targetConfigFormData[field].value.length) {
                  this.$Message.warning('目标设置条件不可均为不包含');
                  return;
                }
              }
              if (!opValue.includes('included')) {
                this.$Message.warning('目标设置条件不可均为不包含');
                return;
              }
            }
          }
        }
      }
      if (logicalConditions > 5) {
        console.log('logicalConditions', logicalConditions);
        this.$Message.warning('目标设置中不包含条件值过多');
        return;
      }
      if (pushInfo.push_content.push_type === 'notification') {
        pushInfo.push_content.notification.image = pushInfo.push_content.notification.url;
        delete pushInfo.push_content.notification.url;
      }
      const pushSchedule = pushInfo.push_schedule;
      if (pushSchedule.begin_time && pushSchedule.end_time && Date.parse(pushSchedule.begin_time) > Date.parse(pushSchedule.end_time)) {
        this.$Message.warning('结束日期小于开始日期，请重新选择');
        return;
      }
      if (pushSchedule.schedule_type === 'interval') {
        const periodicTime = pushSchedule.periodic_time;
        if (!periodicTime.execute_begin) {
          this.$Message.warning('请选择发送时间');
          return;
        }
        if (!periodicTime.minutes && !periodicTime.hours) {
          this.$Message.warning('请填写时间间隔');
          return;
        }
        pushSchedule.schedule_type = 'periodic_time';
        delete periodicTime.period_day;
        delete periodicTime.time;
        if (!periodicTime.execute_end) {
          periodicTime.execute_end = null;
        }
        periodicTime.hours = periodicTime.hours ?? 0;
        periodicTime.minutes = periodicTime.minutes ?? 0;
      }
      pushInfo.localize.forEach((localizeItem) => {
        // eslint-disable-next-line no-param-reassign
        localizeItem.image = localizeItem.url;
        // eslint-disable-next-line no-param-reassign
        delete localizeItem.url;
      });
      // eslint-disable-next-line consistent-return
      return this.sendPushInfo(pushInfo);
      // 先上传image文件
      // const promise = this.uploadAllImages(imageFileList)
      //   .then((urlArr) => {
      //     urlArr.forEach((url, index) => {
      //       // 更新pushInfo中的所有url，并删除url属性
      //       // 注意，在请求参数中，属性image中放的是图片的url
      //       if (index === 0 && pushInfo.push_content.push_type === 'notification') {
      //         pushInfo.push_content.notification.image = url;
      //         delete pushInfo.push_content.notification.url;
      //       } else if (index !== 0) {
      //         const localizeItem = pushInfo.localize[index - 1];
      //         localizeItem.image = url;
      //         delete localizeItem.url;
      //       }
      //     });
      //     return this.sendPushInfo(pushInfo);
      //   }).catch((error) => {
      //     console.log(error);
      //     this.$Message.error(error.message);
      //   });
      // return promise;
    },
    sendHandler(flag) {
      // if (this.pushFormStatus === 'create') {
      //   this.$Message.success('创建中...');
      // } else {
      //   this.$Message.success('保存中...');
      // }
      if (flag) {
        this.validatePage(() => {
          this.confirmSend = true;
        });
      } else {
        this.sendLoading = true;
        this.send_status = 'send';
        this.validatePage(this.send);
      }
    },
    saveAsDraftHandler() {
      if (this.timeConfigFormData.schedule_type === 'immediate') {
        this.$Message.warning('发送时间为“立即”的无法保存为草稿！');
        return;
      }
      this.send_status = 'saveAsDraft';
      // this.$Message.success('正在保存为草稿...');
      this.validatePage(this.send);
    },
    cancelHandler() {
      const route = {
        name: 'Push',
      };
      this.$router.push(route);
    },
    createTempPushTrigger() {
      this.$store.commit('setCurrentTempPush', {
        current_temp_push: null,
      });
      this.$store.commit('setTempPushFormStatus', {
        temp_push_form_status: 'create',
      });
      const params = {
        app_id: this.$route.params.app_id,
        task_id: this.current_push.task_id,
      };
      if (this.content_form_data_type === 'notification') { // 如果当前是 notification 类型，就直接跳转到表单
        this.$router.push({
          name: 'CreateTempPush',
          params,
        });
      } else {
        // message类型就显示弹窗
        this.create_message_temp_push_model_visible = true;
      }
    },
    showTempPushListTrigger() { // 跳转到临时任务列表
      const params = {
        app_id: this.$route.params.app_id,
        task_id: this.current_push.task_id,
      };
      this.$router.push({
        name: 'TempPush',
        params,
      });
    },
    createMessageTempPushHandler(messageTempPushInfo) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.current_push.task_id,
      };
      const params = {
        name: messageTempPushInfo.name,
        message: JSON.stringify(messageTempPushInfo),
        begin_time: this.$tools.dateTimeFormatter(messageTempPushInfo.start_time),
        end_time: this.$tools.dateTimeFormatter(messageTempPushInfo.end_time),
      };
      const promise = this.$api.addMessageTempTask(urlParams, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('新增临时推送成功');
            // 更新当前push信息
            return this.fetchPushDetail(this.$route.params.task_id);
          }
          return Promise.reject(new Error('新增临时推送失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    pauseTempPushTrigger(tempPushId) {
      this.$Modal.confirm({
        title: '确定停用该临时推送吗？',
        content: null,
        onOk: () => {
          this.pauseTempPush(tempPushId);
        },
        onCancel: () => {},
      });
    },
    pauseTempPush(tempPushId) {
      const currentPush = this.current_push;
      // 临时推送任务和当前任务拥有相同的推送类型
      const pushType = currentPush.push_content.push_type;
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: pushType === 'message' ? this.current_push.task_id : undefined,
        temp_task_id: pushType === 'notification' ? tempPushId : undefined,
      };
      const params = pushType === 'notification' ? {} : {
        id: tempPushId,
      };
      const api = pushType === 'notification'
        ? this.$api.pauseTempTask
        : this.$api.pauseMessageTempTask;
      const promise = api(urlParams, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('临时推送停用成功');
            // 将当前正在运行的临时任务变量置空
            this.running_temp_push = null;
            // 重置store中current_push的temp_task值
            const newCurrentPush = { ...this.current_push };
            newCurrentPush.temp_task = null;
            this.current_push = newCurrentPush;
            return null;
          }
          return Promise.reject(new Error('临时推送停用失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    sendOpenModal() {
      this.confirmSend = true;
    },
    sendCancelModal() {
      this.confirmSend = false;
    },
  },
  watch: {
    current_push: {
      handler(newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}' && newVal.temp_task) {
          const tempPush = { ...newVal.temp_task };
          tempPush.start_time = tempPush.begin_time;
          delete tempPush.begin_time;
          this.running_temp_push = tempPush;
        } else {
          this.running_temp_push = null;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.params.task_id || this.$route.query.task_id) {
      this.fetchEditData();
    }
    this.$watch( // 手动watch，因为$refs只有在mounted之后才可以被使用
      '$refs.contentForm.content_form.type',
      (val) => {
        // 监听content_form中type的变化，用于判断是否显示localize表单
        this.content_form_data_type = val;
      },
      {
        immediate: true,
      },
    );
    this.$watch(
      '$refs.nameForm.name_form.auto',
      (val) => {
        // 监听content_form中type的变化，用于判断是否显示localize表单
        this.content_form_data_auto = val;
      },
      {
        immediate: true,
      },
    );
    this.$watch(
      '$refs.contentForm.content_form.notification',
      (val) => {
        this.content_form_notification_data = { ...val };
      },
      {
        deep: true,
        immediate: true,
      },
    );
    this.$watch(
      '$refs.contentForm.content_form.message.body',
      (val) => {
        this.content_form_message_data = val;
      },
      {
        deep: true,
        immediate: true,
      },
    );
  },
};
</script>

<style scoped lang="less">
  .create-push {
    padding-bottom: 36px;
    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      background: transparent;
      /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-link, /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-separator {
        font-size: 20px;
        line-height: 28px;
      }
      Button{
        font-size: 14px;
        line-height: 30px;
        border: 0;
        width: 96px;
        height: 32px;
        flex-direction: column;
        border-radius: 0;
        text-align: center;
        padding:  0 20px;
      }
      .select{
        background: #2978FF;
        border: 1px solid #2978FF;
        color: #FFFFFF;
      }
      .unselect{
        border: 1px solid #2978FF;
        color: #2978FF;
      }
      .edit{
        border-radius: 4px 0 0 4px;
      }
      .check{
        border-radius: 0 4px 4px 0;
      }
    }
    .push-form {
      position: relative;
      background-color: @contentBackgroundColor;
      .form {
        background: #FFFFFF;
        border-radius: 4px;
      }
      .left-form .form {
        padding: 0px 24px;
      }
      .main-form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .left-form {
          flex: 0 1 calc(~'50% - 4px');
        }
        .right-form {
          flex: 0 1 calc(~'50% - 4px');
          display: flex;
          justify-content: center;
          padding: 82px 0 36px;
          /*布局需要改进*/
          .wrap {
            transform: translateX(-55px);
          }
        }
      }
      .bottom-form {
        margin-bottom: 16px;
        border-radius: 4px;
      }
    }
    .submit-box {
      position: fixed;
      left: @sidebarWidth;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
      z-index: 99;
      .send-btn {
        .send-icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          vertical-align: middle;
        }
        .send-text {
          line-height: 16px;
          vertical-align: middle;
        }
      }
      .submit-btn {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .waringModal{
    margin-top: 23px;
    margin-left: 26px;
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 21px;
    color: #202444;
    display: flex;
    align-items: center;
    img{
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .modalHandler{
    margin-right: 24px;
    display: flex;
    justify-content: flex-end;
    Button{
      width: 65px;
      height: 32px;
      border: 0;
      border-radius: 4px;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .cancel{
      background: #FFFFFF;
      border: 1px solid #2978FF;
      color: #2978FF;
      margin-right: 8px;
    }
    .confirm{
      background: #2978FF;
      color: #FFFFFF;
    }
  }
</style>
