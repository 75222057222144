import toolFunctions from '../share/toolFunctions';

const getPieChartOption = (chartData, configuration) => {
  const {
    series_list,
  } = chartData;
  const series = [];
  const seriesListLength = series_list.length;
  series_list[0].data.forEach((item) => {
    if (item.value === '-0.01219999999999999900.00280.01130.003200000') {
      // eslint-disable-next-line no-param-reassign
      item.value = -0.01219999999999999900;
    }
    // eslint-disable-next-line no-param-reassign
    item.value = Number(item.value);
  });
  series_list.forEach((seriesItem, index) => {
    for (let i = 0; i < seriesItem.data.length - 1; i += 1) {
      for (let j = 0; j < seriesItem.data.length - 1 - i; j += 1) {
        if (seriesItem.data[j].value < seriesItem.data[j + 1].value) {
          const temp = seriesItem.data[j + 1];
          // eslint-disable-next-line no-param-reassign
          seriesItem.data[j + 1] = seriesItem.data[j];
          // eslint-disable-next-line no-param-reassign
          seriesItem.data[j] = temp;
        }
      }
    }
    console.log(seriesItem.data);
    const basicPercentage = Math.floor(100 / seriesListLength);
    const seriesObj = {
      type: 'pie',
      center: ['50%', '50%'],
      radius: ['40%', '60%'],
      width: `${basicPercentage}%`,
      left: `${basicPercentage * index}%`,
      top: 0,
      bottom: 0,
      data: seriesItem.data.map((item) => ({
        value: item.value,
        name: item.name,
        itemStyle: {
          color: item.color,
        },
      })),
      labelLine: {
        show: true,
      },
      label: {
        show: true,
        position: 'inner',
        formatter(params) {
          const { name, percent } = params;
          const str = `${name}
${toolFunctions.keepDecimals(percent)}%`;
          return percent > 5 ? str : '';
          // return str;
        },
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: 12,
        lineHeight: 14,
        fontWeight: 600,
      },
    };
    series.push(seriesObj);
  });
  const title = [];
  series_list.forEach((seriesItem, index) => {
    const basicPercentage = Math.floor(100 / seriesListLength);
    const titleObj = {
      subtext: [`{letter|${seriesItem.index_letter}}`, `{name| ${seriesItem.name}}`].join(''),
      top: 6,
      left: `${basicPercentage * (index + 0.5)}%`,
      textAlign: 'center',
      subtextStyle: {
        rich: {
          letter: {
            width: 20,
            height: 20,
            borderRadius: 10,
            fontSize: 10,
            lineHeight: 20,
            align: 'center',
            verticalAlign: 'middle',
            backgroundColor: '#878AA2',
            color: '#FFFFFF',
          },
          name: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 17,
            color: '#545778',
          },
        },
      },
    };
    title.push(titleObj);
  });
  const option = {
    title,
    legend: configuration.legend || {
      show: false,
      top: '1%',
      left: '0',
      icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
    },
    tooltip: {
      trigger: 'item',
      formatter: configuration.formatterFactory
        ? configuration.formatterFactory()
        : function (params) {
          // eslint-disable-next-line no-shadow
          const {
            name,
            value,
            percent,
            dataIndex,
            seriesIndex,
          } = params;
          const seriesItem = series_list[seriesIndex];
          const { index_letter, data } = seriesItem;
          const { tooltip_color } = data[dataIndex];
          let str = '<div class="tooltip">';
          str += `<div class="pie-item">
                        <span class="left-side">
                            <span class="letter-text" style="background-color: ${tooltip_color}">${index_letter}</span>
                            <span class="series-name">${name}</span>
                        </span>
                        <span class="series-value">${toolFunctions.keepDecimals(value)} (${toolFunctions.keepDecimals(percent)}%)</span>
                      </div>`;
          str += '</div>';
          return str;
        },
      renderMode: 'html',
      // 很重要
      backgroundColor: 'none',
      appendToBody: true,
      borderWidth: 0,
      extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0)',
    },
    grid: configuration.grid || {
      top: '12%',
      bottom: '8%',
      left: '3%',
      right: '3%',
    },
    series,
  };
  return option;
};
export default getPieChartOption;
