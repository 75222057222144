<template>
  <div class="server-info-form">
    <Form ref="serverInfoForm" :model="server_info" :rules="server_info" :label-width="120">
      <FormItem label="Notification 配置" prop="notification_config">
        <Select v-model="server_info.notification_config" placeholder="Notification 配置" @on-change="serverInfoChangeHandler">
          <Option value="firebase">Firebase</Option>
          <Option value="parse">Parse</Option>
        </Select>
      </FormItem>
      <FormItem label="Message 配置" prop="message_config" placeholder="Message 配置">
        <Select v-model="server_info.message_config" @on-change="serverInfoChangeHandler">
          <Option value="firebase">Firebase</Option>
          <Option value="parse">Parse</Option>
        </Select>
      </FormItem>
    </Form>
  </div>
</template>

<script>
  export default {
    name: 'ServerInfoForm',
    props: {
      type: String,
      server_info_model: Object,
    },
    data() {
      return {
        server_info_rules: {
          id: [
            { required: true, message: 'ID 不能为空', trigger: 'change' },
          ],
          product_name: [
            { required: true, message: '应用名称不能为空', trigger: 'change' },
          ],
        },
        server_info: {
          notification_config: 'firebase',
          message_config: 'firebase',
        },
      };
    },
    methods: {
      resetForm() {
        this.$refs.serverInfoForm.resetFields();
      },
      serverInfoChangeHandler() {
        this.$emit('update:server_info_model', { ...this.server_info });
      },
    },
    watch: {
      server_info_model: {
        deep: true,
        handler(newVal) {
          this.server_info = JSON.parse(JSON.stringify(newVal));
        },
      },
    },
  };
</script>

<style scoped>

</style>
