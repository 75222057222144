import Vue from 'vue';
import ViewUI from 'view-design';
import * as echarts from 'echarts';
import 'view-design/dist/styles/iview.css';
import VXETable from 'vxe-table';
import DCVueSSO from 'dc-vue-sso';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vxe-table/lib/style.css';
import axiosPlugin from './push-common/plugins/axiosPlugin';
import toolsPlugin from './push-common/plugins/toolsPlugin';
import loaderPlugin from './push-common/plugins/loaderPlugin';
import clickOutsidePlugin from './push-common/directives/clickOutside';
import extendComponents from './untils/extendComponent';
import fullscreenPlugin from './directives/fullScreen';

Vue.config.productionTip = false;
// eslint-disable-next-line no-undef
Vue.prototype.$echarts = echarts;

Vue.use(ViewUI);
Vue.use(VXETable);
Vue.use(axiosPlugin);
Vue.use(toolsPlugin);
Vue.use(loaderPlugin);
Vue.use(clickOutsidePlugin);
Vue.use(fullscreenPlugin);
Vue.prototype.$loading = extendComponents.loading;

Vue.use(DCVueSSO, { store, baseURL: `${process.env.VUE_APP_API_URL}/sso/api`, isTest: false });
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
