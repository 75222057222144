<template>
  <Modal
    :value="modal_visible"
    class="create-app-modal"
    :title="title"
    :closable="false"
    :mask-closable="false">
    <div class="step-box">
      <Steps :current="step">
        <Step title="App"></Step>
        <Step title="Platform"></Step>
        <Step title="Server（可选）"></Step>
      </Steps>
    </div>
    <div class="form-container" :style="formContainerTransform">
      <div class="form-sub-container">
        <app-info-form ref="form-0" :type="type" :app_info_model.sync="app_info_self"></app-info-form>
      </div>
      <div class="form-sub-container">
        <platform-info-form ref="form-1" :type="type" :config_firebase_info_model.sync="config_firebase_info"
                            :config_parse_info_model.sync="config_parse_info"></platform-info-form>
      </div>
      <div class="form-sub-container">
        <server-info-form ref="form-2" :type="type" :server_info_model.sync="config_server"></server-info-form>
      </div>
    </div>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="success" :disabled="step < 1" @click="okHandler('appModal')">确认</Button>
      <Button class="footer-btn" type="default" @click="cancelHandler">取消</Button>
      <Button v-show="step < 2" class="footer-btn" type="primary" @click="nextStepHandler">下一步</Button>
      <Button v-show="step > 0" class="footer-btn" type="primary" @click="previousStepHandler">上一步</Button>
    </div>
  </Modal>
</template>

<script>
import AppInfoForm from '../page/content/create_app_modal/AppInfoForm.vue';
import PlatformInfoForm from '../page/content/create_app_modal/PlatformInfoForm.vue';
import ServerInfoForm from '../page/content/create_app_modal/ServerInfoForm.vue';

  export default {
    name: 'CreateAppModal',
    // eslint-disable-next-line vue/no-unused-components
    components: { ServerInfoForm, PlatformInfoForm, AppInfoForm },
    props: {
      type: String, // create || edit
      app_info: Object,
      modal_visible: Boolean,
    },
    model: {
      prop: 'modal_visible',
      event: 'visible-change',
    },
    data() {
      return {
        step: 0, // 步骤条当前的步骤
        app_info_self: {
          id: '',
          category: '',
          product_name: '',
          platform: '',
          store_app_id: '',
          priority: 0,
          publish_time: '',
          logo: null,
          url: '',
        },
        config_firebase_info: {
          project_id: '',
          service_account: '',
        },
        config_parse_info: {
          app_id: '',
          server_url: 'https://push.thinkyeah.com/parse/push', // default
          master_key: 'testdev#1',
          restful_key: '',
        },
        config_server: {
          notification_config: 'firebase',
          message_config: 'firebase',
        },
        ref_list: [
          'appInfoForm',
          ['configFirebaseForm', 'configParseForm'],
          'serverInfoForm',
        ],
        form_count: 3,
      };
    },
    computed: {
      title() {
        return this.type === 'create' ? '创建APP' : '编辑APP';
      },
      formContainerTransform() {
        return {
          transform: `translateX(-${this.step * 33.3}%)`,
        };
      },
    },
    methods: {
      initAppInfo() {
        this.app_info_self = { ...this.$options.data().app_info_self };
        this.config_firebase_info = { ...this.$options.data().config_firebase_info };
      },
      previousStepHandler() {
        if (this.step > 0) {
          // 返回上一步
          this.step -= 1;
        }
      },
      nextStepHandler() {
        const callback = () => {
          this.step += 1;
        };
        this.validatePage(callback);
      },
      resetForm() {
        this.step = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.form_count; i++) {
          const ref = `form-${i}`;
          this.$refs[ref].resetForm();
        }
      },
      validateForm(formRef, step) { // 验证单个表单，返回Promise实例
        console.log(formRef, step);
        console.log(this.$refs[`form-${step}`]);
        return this.$refs[`form-${step}`].$refs[formRef].validate((valid) => valid);
      },
      validatePage(callback) { // 验证整个页面
        // 下一步，需要进行表单验证
        const currentFormRef = this.ref_list[this.step];
        if (Array.isArray(currentFormRef)) { // 当前页面有多个表单
          const promises = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < currentFormRef.length; i++) {
            const formRef = currentFormRef[i];
            const promise = this.validateForm(formRef, this.step);
            promises.push(promise);
          }
          Promise.all(promises).then((values) => {
            if (values.every((value) => value === true)) {
              callback();
            }
          });
        } else {
          const promise = this.validateForm(currentFormRef, this.step);
          promise.then((value) => {
            if (value === true) {
              callback();
            }
          });
        }
      },
      okHandler() {
        const callback = () => {
          const info = {
            app_info: this.app_info_self,
            config_firebase: this.config_firebase_info,
            config_parse: this.config_parse_info,
            config_server: this.config_server,
          };
          if (this.type === 'create') {
            this.$emit('create', info);
          } else if (!this.$tools.deepCompare(this.app_info, info)) { // true 表示有修改
            this.$emit('edit', info);
          } else {
            this.$Message.warning('未发生修改');
            return;
          }
          this.$emit('visible-change', false);
          // 在弹窗消失之后再清空
          setTimeout(() => {
            this.initAppInfo();
          }, 100);
        };
        this.validatePage(callback);
      },
      cancelHandler() {
        this.$emit('visible-change', false);
        this.$emit('cancel');
        setTimeout(() => {
          this.initAppInfo();
        }, 100);
      },
    },
    watch: {
      app_info(newVal) { // 编辑状态下，对表单赋值
        if (this.type === 'edit') {
          this.app_info_self = { ...newVal.app_info };
          this.config_firebase_info = { ...newVal.config_firebase };
          this.config_parse_info = { ...newVal.config_parse };
          this.config_server = { ...newVal.config_server };
        }
      },
      'app_info_self.id': {
        handler(value) {
          this.config_parse_info.app_id = value;
        },
      },
      modal_visible(newVal) {
        if (!newVal) {
          setTimeout(() => {
            this.resetForm();
          });
        }
      },
    },
  };
</script>

<style scoped lang="less">
  .create-app-modal {
    width: 520px;
    /deep/ .ivu-modal-body {
      overflow-x: hidden;
    }
    .step-box {
      width: 95%;
      margin: 0 auto 18px;
    }
    .form-container {
      display: flex;
      width: 300%;
      margin: 0 auto;
      overflow-x: hidden;
      transition: transform 0.4s ease-in-out;
      .form-sub-container {
        flex: 1 0 33.3%;
        padding: 0 16px;
      }
    }
    .footer {
      display: flex;
      flex-direction: row-reverse;
      .footer-btn {
        &:first-child {
          margin-left: 8px;
        }
      }
    }
  }
</style>
