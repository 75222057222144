import toolFunctions from '../share/toolFunctions';
import requestFactory from './requestFactory';
import urls from './urls';

const GET = 'get';
const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';
const getRequest = requestFactory(GET);
const postRequest = requestFactory(POST);
const putRequest = requestFactory(PUT);
const deleteRequest = requestFactory(DELETE);

const { requestURLParamParser } = toolFunctions;

export default {
  getTestMachineList: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.testMachineListURL, urlParams);
    return getRequest(newURL, params);
  },
  addTestMachine: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.addTestMachineURL, urlParams);
    return postRequest(newURL, params);
  },
  updateTestMachine: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.updateTestMachineURL, urlParams);
    return putRequest(newURL, params);
  },
  deleteTestMachine: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.deleteTestMachineURL, urlParams);
    return deleteRequest(newURL, params);
  },
  sendNotificationTest: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.notificationTestPushURL, urlParams);
    return postRequest(newURL, params);
  },
  sendMessageTest: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.messageTestPushURL, urlParams);
    return postRequest(newURL, params);
  },

};
