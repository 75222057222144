import toolFunctions from '../share/toolFunctions';
import chartColor from '../push-common/page_config/color.json';

const color = chartColor.color_list[0];
const colors = chartColor.color_list;
const titleColor = '#202444';
const getLineAndBarChartOption = (chartData, configuration) => {
  /**
   * line | multi_line
   * Example: {
                xAxis: x_axis,
                yAxis: y_axis,
                name: charts.map((chart) => chart.label),
                id: chartId,
                dimensions,
                type,
                unit_list,
              };
   */
  console.log('getLineChartOption', chartData, configuration);
  const wholeChartType = chartData.type || 'line';
  // Array
  const chartName = chartData.name;
  const chartTitle = chartData.title;
  const chartDimensions = chartData.dimensions;
  const { chartType, singleYAxis, isStackBar } = chartData;
  const series = [];
  const colorLength = configuration.color.length;
  const tooltipColorLength = configuration.tooltip_color.length;
  const colorMap = new Map();
  chartName.forEach((name, index) => {
    colorMap.set(name, {
      color: Array.isArray(configuration.color) ? configuration.color[index % colorLength] : configuration.color,
      tooltipColor: Array.isArray(configuration.tooltip_color) ? configuration.tooltip_color[index % tooltipColorLength] : configuration.tooltip_color,
    });
  });
  if (chartType.length === 1) {
    const dimension = chartDimensions[0];
    series.push({
      name: chartName[0],
      type: chartType[0],
      // point
      itemStyle: {
        color: colorMap.get(chartName[0]).color || color,
      },
      lineStyle: {
        width: 2,
        color: colorMap.get(chartName[0]).tooltipColor || color,
      },
      barMaxWidth: 24,
      emphasis: {
        focus: 'series',
      },
      blur: {
        itemStyle: {
          opacity: 0.6,
        },
        lineStyle: {
          opacity: 0.36,
        },
      },
      // areaStyle: {
      //   color: {
      //     type: 'linear',
      //     x: 0,
      //     y: 0,
      //     x2: 0,
      //     y2: 1,
      //     colorStops: [
      //       {
      //         offset: 0,
      //         color: chartData.area_color,
      //       },
      //       {
      //         offset: 1,
      //         color: 'rgba(255, 255, 255, 1)',
      //       },
      //     ],
      //     global: false,
      //   },
      // },
      symbol: 'emptyCircle',
      showSymbol: false,
      showAllSymbol: false,
      // markLine: {
      //   symbol: ['none', 'none'],
      //   lineStyle: {
      //     width: 1,
      //     // color: 'rgba(75, 92, 240, 1)',
      //   },
      //   label: {
      //     show: false,
      //   },
      //   emphasis: {
      //     label: {
      //       show: false,
      //     },
      //     lineStyle: {
      //       type: 'dotted',
      //       width: 1,
      //     },
      //   },
      //   data: markLines,
      // },
      data: chartData.yAxis[dimension],
    });
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < chartDimensions.length; i++) {
      const dimension = chartDimensions[i];
      const yAxisItem = chartData.yAxis[dimension];
      const name = chartName[i];
      const chartItemType = chartType[i];
      const temp = {
        name,
        type: chartItemType || 'line',
        itemStyle: {
          color: configuration.color[i % colorLength] || colors[i],
        },
        lineStyle: {
          width: configuration.series?.lineStyle?.width || 2,
          color: configuration.color[i % colorLength] || colors[i],
        },
        stack: isStackBar ? 'bar' : null,
        yAxisIndex: singleYAxis ? 0 : i,
        barMaxWidth: 24,
        symbol: 'emptyCircle',
        showSymbol: false,
        showAllSymbol: false,
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            opacity: 0.6,
          },
          lineStyle: {
            opacity: 0.36,
          },
        },
        data: yAxisItem,
      };
      series.push(temp);
    }
  }
  const yAxis = [];
  if (wholeChartType === 'line' || wholeChartType === 'bar' || singleYAxis) {
    const yAxisBasicItem = {
      show: !configuration.hideYAxis,
      name: chartName.length === 1 ? chartName[0] : null,
      axisLabel: {
        margin: configuration?.y?.margin
          ? configuration.y.margin
          : 16,
        fontSize: '12px',
        color: 'rgba(84, 88, 120, 1)',
        formatter: (value) => {
          const type = chartData.unit_list[0];
          if (type === 'rate') {
            return toolFunctions.percentageFormat(value);
          } if (type === 'normal' || type === 'dollar') {
            const numberVal = Number(value);
            let result = numberVal;
            const numberCount = toolFunctions.numberOfDigit(numberVal);
            if (!Number.isNaN(numberVal)) {
              if (numberCount > 6) {
                result = `${numberVal / 1000000}M`;
              } else if (numberCount > 3) {
                result = `${numberVal / 1000}K`;
              }
            }
            return result;
          }
          return value;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: configuration?.y?.splitLine
        ? {
          show: false,
        }
        : {
          lineStyle: {
            type: 'dashed',
            color: '#cdcedb',
          },
        },
    };
    yAxis.push(yAxisBasicItem);
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < chartDimensions.length; i++) {
      const yAxisBasicItem = {
        show: !configuration.hideYAxis,
        name: singleYAxis ? null : chartName[i],
        axisLabel: {
          margin: configuration?.y?.margin
            ? configuration.y.margin
            : 16,
          fontSize: '12px',
          color: 'rgba(84, 88, 120, 1)',
          formatter: (value) => {
            const type = chartData.unit_list[i];
            if (type === 'rate') {
              return toolFunctions.percentageFormat(value);
            } if (type === 'normal' || type === 'dollar') {
              const numberVal = Number(value);
              let result = numberVal;
              const numberCount = toolFunctions.numberOfDigit(numberVal);
              if (!Number.isNaN(numberVal)) {
                if (numberCount > 6) {
                  result = `${numberVal / 1000000}M`;
                } else if (numberCount > 3) {
                  result = `${numberVal / 1000}K`;
                }
              }
              return result;
            }
            return value;
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: i !== 0
          ? {
            show: false,
          }
          : {
            lineStyle: {
              type: 'dashed',
              color: '#cdcedb',
            },
          },
      };
      yAxis.push(yAxisBasicItem);
    }
  }
  const option = {
    title: {
      show: chartTitle,
      text: chartTitle,
      textStyle: {
        color: titleColor,
        fontWeight: 'bold',
        fontSize: 16,
      },
      top: '0.5%',
      left: '6%',
    },
    tooltip: {
      trigger: 'axis',
      // 将显示框固定在图表中，较为死板
      // confine: true,
      appendToBody: true,
      axisPointer: {
        type: 'none',
      },
      // alwaysShowContent: true,
      formatter(params) {
        const { name } = params[0];
        let str = `<div class="tooltip">
                    <h4 class="tooltip-title">${name}:</h4>`;
        let count = 0;
        if (configuration.tooltip_order === 'valueDesc') {
          params.sort((a, b) => b.value - a.value);
        } else if (configuration.tooltip_order === 'valueAsc') {
          params.sort((a, b) => a.value - b.value);
        }
        const flag = params.length > 16;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of params.slice(0, 16)) {
          const { seriesName, value } = item;
          // eslint-disable-next-line no-shadow
          let color;
          if (configuration.tooltip_order) {
            color = colorMap.get(seriesName).tooltipColor;
          } else {
            color = Array.isArray(configuration.tooltip_color)
              ? configuration.tooltip_color[count % tooltipColorLength]
              : configuration.tooltip_color;
          }
          if (value !== undefined) {
            const type = chartData.unit_list[0] || 'normal';
            let result = value;
            if (type === 'rate') {
              result = toolFunctions.percentageFormat(result);
            } else {
              result = toolFunctions.keepDecimals(result);
            }
            str += `<p class="tooltip-content">
                        <span class="tooltip-text" style="color: ${color}!important;">${seriesName}:</span>
                        <span class="tooltip-text" style="color: ${color}!important;">${result}</span>
                    </p>`;
            count += 1;
          }
        }
        if (flag) {
          str += `<p class="rest-text">以及其他${params.length - 16}项</p>`;
        }
        // The loop below need modification
        // const markList = chartData.mark_lines;
        // if (Array.isArray(markList)) {
        //   let flag = false;
        //   const color = chartData.mark_color || 'rgba(75, 92, 240, 1)';
        //   // eslint-disable-next-line no-restricted-syntax
        //   for (const item of markList) {
        //     const { xAxis, text } = item;
        //     if (xAxis === name || Number(xAxis) === Number(name)) {
        //       if (!flag) {
        //         str += '<h4 class="tooltip-mark-title">标注：</h4>';
        //         flag = true;
        //       }
        //       str += `<p class="tooltip-content">
        //                 <span class="tooltip-text" style="color: ${color}!important;"></span>
        //                 <span class="tooltip-text" style="color: ${color}!important;">${text}</span>
        //             </p>`;
        //     }
        //   }
        // }
        str += '</div>';
        // const { name, seriesName, value } = params[0];
        // const type = configuration.series.formatter;
        // let result = value;
        // if (type === 'rate') {
        //   result = toolFunctions.percentageFormat(result);
        // }
        // const str = `<div class="tooltip">
        //             <h4 class="tooltip-title">${seriesName}</h4>
        //             <p class="tooltip-content">
        //                 <span class="tooltip-text">${name}:</span>
        //                 <span class="tooltip-text">${result}</span>
        //             </p>
        //     </div>`;
        return str;
      },
      renderMode: 'html',
      backgroundColor: 'opacity',
      borderWidth: 0,
      extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0)',
    },
    legend: configuration.legend || {
      // show: wholeChartType.indexOf('multi') !== -1,
      show: false,
      top: '-2%',
      left: '20%',
      icon: 'path://M400,32L48,32C21.5,32,0,53.5,0,80l0,352c0,26.5,21.5,48,48,48l352,0c26.5,0,48,-21.5,48,-48L448,80c0,-26.5,-21.5,-48,-48,-48Z',
    },
    grid: configuration.grid
      ? {
        top: configuration.grid.top,
        bottom: configuration.grid.bottom,
        left: configuration.grid.left,
        right: configuration.grid.right,
      }
      : {
        top: '16%',
        bottom: '12%',
        left: '14%',
        right: '14%',
      },
    // : {
    //   top: '12%',
    //   bottom: '8%',
    //   left: '6%',
    //   right: '3%',
    // },
    xAxis: [
      {
        axisLabel: {
          margin: 8,
          color: 'rgba(135, 138, 162, 1)',
          showMaxLabel: !configuration?.x?.hideMaxLabel,
          formatter: typeof configuration?.x?.formatter === 'function'
            ? configuration.x.formatter
            : (value) => value,
          padding: configuration?.x?.padding
            ? configuration.x.padding
            : undefined,
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(233, 234, 247, 1)',
          },
        },
        axisPointer: {
          snap: true,
          type: 'line',
          lineStyle: {
            width: 2,
            color: '#E6E7F5',
          },
        },
        axisTick: {
          show: false,
        },
        boundaryGap: true,
        data: chartData.xAxis,
      },
    ],
    yAxis,
    series,
  };
  return option;
};
export default getLineAndBarChartOption;
