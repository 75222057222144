<template>
  <div class="template-detail-item">
    <div class="left-box">
      <div v-if="!template_info.url" class="empty-box">
        <img class="empty-icon" src="../../../../assets/image/pic_placeholder_s@2x.png" alt="placeholder">
      </div>
      <div v-if="template_info.url" class="template-img-box">
        <img :src="template_info.url" alt="template image" class="template-img">
      </div>
      <div class="content-box">
        <h4 class="template-title">{{ template_info.title }}</h4>
        <p class="template-content">{{ template_info.content }}</p>
      </div>
    </div>
    <div class="right-box">
      <img class="icon" @click="editHandler" src="../../../../assets/image/ic_edit@2x.png" alt="edit icon">
      <img class="icon" @click="deleteHandler" src="../../../../assets/image/ic_delete@2x.png" alt="delete icon">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateDetailItem',
  props: {
    template_info: Object,
  },
  methods: {
    editHandler() {
      this.$emit('edit', this.template_info);
    },
    deleteHandler() {
      this.$emit('delete', this.template_info);
    },
  },
};
</script>

<style scoped lang="less">
  .template-detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 98px;
    margin-bottom: 8px;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EAEBF7;
    transition: box-shadow 0.2s ease-in-out;
    .left-box {
      flex: 0 1 60%;
      display: flex;
      .template-img-box, .empty-box {
        width: 132px;
        height: 66px;
        margin-right: 16px;
        border-radius: 4px;
      }
      .template-img-box {
        line-height: 66px;
        text-align: center;
        .template-img {
          max-width: 100%;
          max-height: 100%;
          vertical-align: middle;
        }
      }
      .empty-box {
        line-height: 66px;
        text-align: center;
        background-color: #F5F7FA;
        .empty-icon {
          width: 32px;
          height: 32px;
          vertical-align: middle;
        }
      }
      .content-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: calc(~'100% - 82px');
        .template-title {
          margin-bottom: 6px;
          font-size: 14px;
          font-weight: 600;
          color: #202444;
          line-height: 20px;
        }
        .template-content {
          font-size: 14px;
          font-weight: 400;
          color: #545878;
          line-height: 20px;
        }
        .template-title, .template-content {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .right-box {
      line-height: 98px;
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 16px;
        padding: 8px;
        border: 1px solid #EAEBF7;
        border-radius: 50%;
        box-sizing: content-box;
        vertical-align: middle;
        transform: scale(0);
        transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out;
        cursor: pointer;
        &:last-child {
          margin-right: 8px;
        }
        &:hover {
          background-color: @basicOpacityColor;
          border-color: @basicOpacityColor;
        }
      }
    }
    &:hover {
      box-shadow: 0px 2px 8px 0px rgba(13, 17, 52, 0.08);
      .right-box {
        .icon {
          transform: scale(1);
        }
      }
    }
  }
</style>
