<template>
  <Modal
    :value="modal_visible"
    class="create-test-machine-modal"
    :closable="false"
    :mask-closable="false">
    <div class="header" slot="header">
      <h3 class="title">{{ title }}</h3>
    </div>
    <div class="body">
      <Form ref="createTestMachineForm" :model="test_machine" :rules="test_machine_rules" :label-width="120">
        <FormItem prop="fcm_token" label="Push设备令牌">
          <Input v-model="test_machine.fcm_token" placeholder="请输入"/>
        </FormItem>
        <FormItem prop="remark" label="名称">
          <Input v-model="test_machine.remark" placeholder="请输入"/>
        </FormItem>
        <FormItem prop="gaid" label="设备GAID">
          <Input v-model="test_machine.gaid" placeholder="请输入"/>
        </FormItem>
      </Form>
    </div>
    <div class="footer" slot="footer">
      <Button class="footer-btn" type="default" @click="cancelHandler">取消</Button>
      <Button class="footer-btn" type="primary" @click="okHandler">确认</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'CreateTestMachineModal',
  props: {
    modal_visible: Boolean,
  },
  model: {
    prop: 'modal_visible',
    event: 'visible-change',
  },
  data() {
    return {
      title: '新增测试机',
      test_machine: {
        fcm_token: '',
        remark: '',
        gaid: '',
      },
      test_machine_rules: {
        fcm_token: [
          { required: true, message: 'Push设备令牌不能为空', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    okHandler() {
      this.$refs.createTestMachineForm.validate((valid) => {
        if (valid) {
          this.$emit('visible-change', false);
          this.$emit('create', this.test_machine);
          setTimeout(() => {
            this.$refs.createTestMachineForm.resetFields();
          }, 200);
        }
      });
    },
    cancelHandler() {
      this.$emit('visible-change', false);
      this.$emit('cancel');
      setTimeout(() => {
        this.$refs.createTestMachineForm.resetFields();
      }, 200);
    },
  },
};
</script>

<style scoped lang="less">
  .create-test-machine-modal {
    /deep/ .ivu-modal-header {
      padding-bottom: 0;
      border-bottom: 0;
    }
    /deep/ .ivu-modal-body {
      .ivu-form-item:last-child {
        margin-bottom: 0;
      }
    }
    /deep/ .ivu-modal-footer {
      padding-top: 0;
      border-top: none;
    }
    /deep/ .ivu-form {
      padding: 0 24px;
    }
    .header {
      padding-bottom: 0;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
