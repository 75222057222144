import PushList from '../views/app_detail/PushList.vue';
import PushIcon from '../assets/image/ic_sidebar_push@2x.png';
import TemplateIcon from '../assets/image/ic_sidebar_material@2x.png';
import AnalysisIcon from '../assets/image/ic_sidebar_@2x.png';
import CreatePush from '../views/app_detail/push/CreatePush.vue';
import TemplateList from '../views/app_detail/TemplateList.vue';
import CreateTemplate from '../views/app_detail/template/CreateTemplate.vue';
import TempPush from '../views/app_detail/push/push_id/TempPushList.vue';
import CreateTempPush from '../views/app_detail/push/push_id/CreateTempPush.vue';
import DataAnalysis from '../views/app_detail/DataAnalysis.vue';
import DataAnalysisDetail from '../views/app_detail/analysis/DataAnalysisDetail.vue';

const APP_HOME_LAYOUT = 'AppHomeLayout';
export default [
  {
    path: '/app/:app_id/push',
    name: 'Push',
    component: PushList,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '推送列表',
      icon: PushIcon,
      level: 'appDetail',
    },
  },
  {
    path: '/app/:app_id/push/create',
    name: 'CreatePush',
    component: CreatePush,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '创建推送',
      icon: PushIcon,
      level: 'appDetail',
      hidden: true,
      rootName: 'Push', // 从PushList跳转而来，用于侧边栏菜单中，PushList项的高亮显示
      isKeepAlive: true,
    },
  },
  {
    path: '/app/:app_id/push/:task_id',
    name: 'PushDetail',
    component: CreatePush,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '推送详情',
      icon: PushIcon,
      level: 'appDetail',
      hidden: true,
      rootName: 'Push', // 从PushList跳转而来，用于侧边栏菜单中，PushList项的高亮显示
    },
  },
  {
    path: '/app/:app_id/push/:task_id/temp_push',
    name: 'TempPush',
    component: TempPush,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '临时推送列表',
      icon: PushIcon,
      level: 'appDetail',
      hidden: true,
      rootName: 'Push',
    },
  },
  {
    path: '/app/:app_id/push/:task_id/temp_rotation',
    name: 'CreateTempPush',
    component: CreateTempPush,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '创建临时推送',
      icon: PushIcon,
      level: 'appDetail',
      hidden: true,
      rootName: 'Push',
    },
  },
  {
    path: '/app/:app_id/template',
    name: 'Template',
    component: TemplateList,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '消息库',
      icon: TemplateIcon,
      level: 'appDetail',
    },
  },
  {
    path: '/app/:app_id/:group_id/template/create',
    name: 'CreateTemplate',
    component: CreateTemplate,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '批量新增',
      icon: TemplateIcon,
      level: 'appDetail',
      hidden: true,
      rootName: 'Template',
    },
  },
  {
    path: '/app/:app_id/data_analysis',
    name: 'DataAnalysis',
    component: DataAnalysis,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '数据分析',
      icon: AnalysisIcon,
      level: 'appDetail',
      hidden: false,
    },
  },
  {
    path: '/app/:app_id/data_analysis/:task_id',
    name: 'DataAnalysisDetail',
    component: DataAnalysisDetail,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '数据分析',
      icon: AnalysisIcon,
      level: 'appDetail',
      hidden: true,
      rootName: 'DataAnalysis',
    },
  },
];
