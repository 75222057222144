import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import { userStore } from 'dc-vue-sso';
import api from '../api/api';
import loaderFn from '../push-common/plugins/loaderFn';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app_list: [],
    current_app_detail: null,
    language_list: [],
    current_push_task_name: '',
    current_push_schedule_type: '',
    current_temp_push: null,
    push_form_status: 'create', // 用于判断当前Push的表单是创建还是编辑
    temp_push_form_status: 'create',
    recently_used_country_list: [
      {
        label: 'US',
        value: 'United States',
      },
      {
        label: 'TW',
        value: 'Taiwan',
      },
      {
        label: 'JP',
        value: 'Japan',
      },
      {
        label: 'HK',
        value: 'Hong Kong',
      },
      {
        label: 'BR',
        value: 'Brazil',
      },
      {
        label: 'DE',
        value: 'Germany',
      },
    ],
    sidebar_is_expanded: true,
    push_specific_status: null,
    push_schedule_type: null,
  },
  mutations: {
    setAppList(state, payload) { // 刚进入页面时也需要检查app list，如果为空，则需要重新请求
      state.app_list = payload.app_list;
    },
    setCurrentAppDetail(state, payload) {
      state.current_app_detail = payload.current_app_detail;
    },
    setLanguageList(state, payload) {
      state.language_list = payload.language_list;
    },
    setCurrentTempPush(state, payload) {
      state.current_temp_push = payload.current_temp_push;
    },
    setPushFormStatus(state, payload) {
      state.push_form_status = payload.push_form_status;
    },
    setTempPushFormStatus(state, payload) {
      state.temp_push_form_status = payload.temp_push_form_status;
    },
    updateRecentlyUsedCountryList(state, payload) {
      console.log(payload.country_list);
      state.recently_used_country_list = payload.country_list;
    },
    setSidebarIsExpanded(state, payload) {
      state.sidebar_is_expanded = payload.sidebar_is_expanded;
    },
    updatePushSpecificStatus(state, payload) {
      state.push_specific_status = payload.push_specific_status;
    },
    updatePushScheduleType(state, payload) {
      state.push_schedule_type = payload.push_schedule_type;
    },
    setCurrentPushTaskName(state, payload) {
      state.current_push_task_name = payload.task_name;
    },
  },
  actions: {
    fetchInitData() {
      const rootElement = document.querySelector('#app');
      const loader = loaderFn(rootElement).show();
      const promise = Promise.all([this.dispatch('fetchAppList', {})]);
      promise.catch((error) => {
        this.$Message.error(error.message);
      }).finally(() => {
        loader.hide();
      });
      // 注意返回的不是finally之后的promise
      return promise;
    },
    fetchAppList() {
      const promise = api.getAppList()
        .then((response) => {
          const { data } = response;
          const { list } = data;
          if (list) {
            this.commit('setAppList', {
              app_list: [...list],
            });
            return null;
          }
          return Promise.reject(new Error('应用列表获取失败'));
        });
      return promise;
    },

    async fetchAppDetail({ commit }, appId) {
      try {
      const { data } = await api.getAppDetail({ app_id: appId });
      commit('setCurrentAppDetail', { current_app_detail: data });
      } catch (e) {
        console.log(e);
      }
    },
  },
  modules: {
    userStore,
  },
});
