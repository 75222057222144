export default {
  data() {
    return {
      checkbox_visible: false,
    };
  },
  methods: {
    clickOutsideHandler(e) {
      if (this.checkbox_visible) {
        e.stopPropagation();
      }
      this.checkbox_visible = false;
    },
  },
};
