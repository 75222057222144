<template>
  <div class="loader-wrap">
    <div class="loader-inner-wrap" :style="{ 'max-height': `${maxHeight}px`, 'background-color': backgroundColor }">
      <div v-if="show" class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    maxHeight: Number,
    backgroundColor: String,
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    console.log(this.backgroundColor);
  },
};
</script>

<style scoped lang="less">
  @size: 36px;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 4px;
    z-index: 99;
  }
  .loader-inner-wrap {
    position: relative;
    height: 100%;
    border-radius: 4px;
  }
  .loader {
    position: absolute;
    top: calc(~'50% - @{size}/2');
    left: calc(~'50% - @{size}/2');
    transform: rotate(180deg);
    width: @size;
    height: @size;
    border: 3px solid rgba(19, 86, 237, 0.21);
    border-radius: 50%;
    animation: rotation 0.6s linear infinite;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(~'@{size} - 8px');
      height: calc(~'@{size} - 8px');
      border: 3px solid transparent;
      border-bottom-color: @basicColor;
      border-radius: 50%;
    }
  }
</style>
