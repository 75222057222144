<template>
  <div class="localized-translation-form" v-show="!form_data.group">
    <form-content-header title="本地化翻译">
      <div class="operation-box">
        <div class="add-language">
          <div @click="openLanguageSelect"><Icon type="md-add" size="16" color="#165DFF"/>添加语言</div>
          <add-language-select
            :language_list="language_list.filter(i => !i.selected)"
            :add-language-mode="addLanguageMode"
            @close="addLanguageMode = false"
            @confirm="addLanguageHandler"/>
        </div>
        <div class="switch-box">
          <span class="switch-text">自动翻译</span>
          <i-switch v-model="auto_translation"/>
        </div>
        <div class="switch-box">
          <span class="switch-text">继承主图</span>
          <i-switch v-model="extend_image"/>
        </div>
      </div>
    </form-content-header>
    <div class="container">
      <div class="translation-list">
        <basic-item-in-translation-and-template
          v-for="(item, index) in translation_info_list"
          :key="item.id"
          :language.sync="item.language"
          :title.sync="item.title"
          :content.sync="item.content || item.body"
          :status.sync="item.status"
          :image.sync="item.image"
          :url.sync="item.url"
          :language_list="language_list.filter(i => !i.selected || i.value === item.language)"
          @add="saveTranslationItemHandler(item, index)"
          @edit="editTranslationItemHandler(item)"
          @delete="deleteBasicItemHandler(index)"></basic-item-in-translation-and-template>
        <basic-item-in-translation-and-template
          v-show="create_translation_item"
          status="edit"
          :language_list="language_list.filter(item => !item.selected)"
          v-bind.sync="translation_info_item"
          :style="show_error_box? 'border-color: #ed4014;' : ''"
          @add="addTranslationItemHandler"
          @delete="deleteBasicItemHandler(-1)"></basic-item-in-translation-and-template>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */

  import FormContentHeader from '../../../common/form-content-header.vue';
import BasicItemInTranslationAndTemplate from '../../../common/BasicItemInTranslationAndTemplate.vue';
import addLanguageSelect from '../../../../components/common/addLanguageSelect'

export default {
  name: 'LocalizedTranslationForm',
  components: { BasicItemInTranslationAndTemplate, FormContentHeader, addLanguageSelect },
  props: {
    form_data: Object,
    current_push: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      auto_translation: false,
      extend_image: false,
      create_translation_item: false,
      show_error_box:false,
      translation_info_list: [
        // {
        //   title: '标题一',
        //   content: '测试内容一',
        //   language: 'chinese',
        // },
        // {
        //   title: '标题二',
        //   content: '测试内容二',
        //   language: 'english',
        // },
      ],
      translation_info_item: {
        title: '',
        content: '',
        language: '',
        image: null,
        url: '',
        status: 'edit',
      },
      translation_status: false,
      addLanguageMode: false,

    };
  },
  computed: {
    language_list() {
      const selectedLanguageList = this.translation_info_list.map(item => item.language);
      return this.$store.state.language_list.map(item => {
        return {
          ...item,
          selected: selectedLanguageList.includes(item.value),
        }
      });
    },
  },
  methods: {
    form() {
      const validate = (callback) => { // callback只是用户根据验证结果设置的自定义行为，callback的valid参数还需要系统设置
        const isValidate = !this.form_data.group ? true : this.translation_info_list.every(item => item.content !== '' && item.title !== '');
        // console.log('isValidate', validate);
        // if (this.create_translation_item) { // 还处于新增过程中
        //   const valid = false;
        //   this.show_error_box = true;
        //   return Promise.resolve(callback(valid));
        // }
        // const valid = true;
        return Promise.resolve(callback(isValidate));
      };
      return {
        validate,
      };
    },
    localizeTranslate() {  // 翻译localize列表中的title和body
      // 将form_data中的文本根据列表项的语言逐一翻译并填充
      const { title, body } = this.form_data;
      const titlePromiseList = [];
      const bodyPromiseList = [];
      const languageList = this.translation_info_list.map((item) => item.language);
      languageList.forEach((language, index) => {
        const translateInfoItem = this.translation_info_list[index];
        const titlePromise = this.localizeSingleTranslate(title, language, translateInfoItem, 'title');
        const bodyPromise = this.localizeSingleTranslate(body, language, translateInfoItem, 'content'); // 注意这里属性值是content
        titlePromiseList.push(titlePromise);
        bodyPromiseList.push(bodyPromise);
      });
      const itemLanguage = this.translation_info_item.language;
      let itemPromise = Promise.resolve();
      if (itemLanguage) {
        itemPromise = this.localizeTranslateInfoItem();
      }
      Promise.all([...titlePromiseList, ...bodyPromiseList, itemPromise])
        .catch((error) => {
          this.$Message.error(error.message);
        });
    },
    localizeTranslateInfoItem() { // 翻译新增选项中的title和body
      const { language } = this.translation_info_item;
      const { title, body } = this.form_data;
      if (!language) return Promise.resolve();
      const titlePromise = this.localizeSingleTranslate(title, language, this.translation_info_item, 'title');
      const bodyPromise = this.localizeSingleTranslate(body, language, this.translation_info_item, 'content');
      return Promise.all([titlePromise, bodyPromise]);
    },
    localizeSingleTranslate(text, language, translateInfoItem, param) {
      console.log(text);
      console.log(language);
      if (!text) {
        translateInfoItem[param] = '';
        return Promise.resolve();
      }
      const promise = this.$translate(text, '', language)
        .then((response) => {
          const { data } = response.data;
          if (data) {
            const resultText = data.translations[0].translatedText;
            translateInfoItem[param] = resultText;
            return null;
          }
          return Promise.reject(new Error('哎呀，自动翻译出问题了'));
        });
      return promise;
    },
    addTranslationItemTrigger() {
      // 开始新增过程
      if (this.create_translation_item === true || (this.translation_info_list.length > 0 && this.translation_info_list.map(item => item.status).includes('edit'))){
        this.$Message.warning('请先保存未保存的翻译')
      } else {
        this.show_error_box = false;
      }
      if (!(this.translation_info_list.length > 0 && this.translation_info_list.map(item => item.status).includes('edit'))) {
        this.create_translation_item = true;
      }
    },
    addTranslationItemHandler() {
      this.translation_info_item.status = 'read';
      this.translation_info_list.push({ ...this.translation_info_item });
      // 重置
      this.translation_info_item = this.$options.data().translation_info_item;
      // // 新增隐藏
      this.create_translation_item = false;
    },
    editTranslationItemHandler(val) {
      val.status = 'edit';
    },
    saveTranslationItemHandler(val, index) {
      val.status = 'read';
      this.$set(this.translation_info_list, index, val);
      this.translation_info_item = this.$options.data().translation_info_item;
    },
    deleteBasicItemHandler(index) {
      this.show_error_box = false;
      if (index === -1) { // 说明是最后新增的那一个
        this.create_translation_item = false;
        // 重置
        this.translation_info_item = { ...this.$options.data().translation_info_item };
      } else { // 不是最后新增的那一个
        // 直接从列表删除
        this.translation_info_list.splice(index, 1);
      }
    },
    languageChangeHandler() {},
    openLanguageSelect() {
      this.addLanguageMode = true;
    },
    generateRandomId() {
      const timestamp = Date.now().toString(36);
      const randomStr = Math.random().toString(36).substring(2, 8);
      const randomId = timestamp + randomStr;
      return randomId;
    },
    addLanguageHandler(val) {
      this.addLanguageMode = false;
      const addLanguageList = []
      val.forEach(item => {
        addLanguageList.push(this.language_list.find(i => i.label === item).value)
      })
      console.log(val);
      addLanguageList.forEach(item => {
        const obj = {
          content: '',
          image: null,
          language:item,
          status:'edit',
          title: '',
          url: '',
          id: this.generateRandomId(),
        };
        this.translation_info_list.push(obj);
      })

    }
  },
  watch: {
    current_push: {
      handler(newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          // notification 状态下才有localize
          if (newVal.push_content.push_type === 'notification') {
            const localizeList = newVal.push_content.notification.localize.map((item) => ({
              title: item.title,
              content: item.body,
              language: item.language,
              image: null,
              url: item.image,
            }));
            this.translation_info_list = [...localizeList];
          }
        }
      },
    },
    auto_translation(newVal) {
      if (newVal) { // 开启自动翻译
        this.localizeTranslate();
      } else {
        // 关闭自动翻译
      }
    },
    extend_image(newVal) {
      let url = '', image = null;
      if (newVal) { // 确认继承主图
        const hasImage = this.form_data.image || this.form_data.url; // 判断主图是否有image
        if (hasImage) {
          url = this.form_data.url;
          image = this.form_data.image;
        }
      }
      // 否则都为空
      // 将当前列表项的所有图片以及新增项的图片改成和主图一致
      this.translation_info_list.forEach((translationItem) => {
        if (translationItem.url === '') {
          translationItem.url = url;
          translationItem.image = image;
        }
      });
      this.translation_info_item.url = url;
      this.translation_info_item.image = image;
    },
    create_translation_item(newVal) {
      if (newVal) { // 如果处于新增状态，且确认继承主图，则对新增项赋主图
        if (this.extend_image && (this.form_data.image || this.form_data.url)) {
          this.translation_info_item.url = this.form_data.url;
          this.translation_info_item.image = this.form_data.image;
        } else {
          this.translation_info_item.url = '';
          this.translation_info_item.image = null;
        }
      }
    },
    'translation_info_item.language': function() {
      if (this.auto_translation) { // 开启自动翻译
        this.localizeTranslateInfoItem();
      }
    },
    'form_data.url': {
      handler(newVal) {
        if (this.extend_image && newVal) { // 确认继承主图且新的url不为空
          this.translation_info_list.forEach((translationItem) => {
            translationItem.url = newVal;
            translationItem.image = null;
          });
          this.translation_info_item.url = newVal;
          this.translation_info_item.image = null;
        }
      },
    },
    'form_data.image': {
      handler(newVal) {
        if (this.extend_image && newVal) {
          this.translation_info_list.forEach((translationItem) => {
            translationItem.url = '';
            translationItem.image = newVal;
          });
          this.translation_info_item.url = '';
          this.translation_info_item.image = newVal;
        }
      },
    },
    'form_data.title': function() {
      if (this.auto_translation) {
        this.localizeTranslate()
          .catch((error) => {
            this.$Message.error(error.message);
          });
      }
    },
    'form_data.body': function() {
      if (this.auto_translation) {
        this.localizeTranslate()
          .catch((error) => {
            this.$Message.error(error.message);
          });
      }
    },
    'form_data.group': function() {
      this.translation_info_list = []
    },
    'form_data.localize': {
      handler(val) {
        if (val && this.form_data.group === null) {
          this.translation_info_list = val.map(item => {
            const {body, ...restVal} = item;
            return {
              content: body,
              ...restVal
            }
          })
        } else {
          this.translation_info_list = []
        }

      },
      immediate: true,
    }
  },
};
</script>

<style scoped lang="less">
  .localized-translation-form {
    position: relative;
    padding: 0 24px 24px;
    background-color: #FFFFFF;
    border-radius: 4px;
    //overflow: hidden;
    .operation-box {
      display: flex;
      align-items: center;
      .add-language {
        font-size: 14px;
        color: #165DFF;
        margin-right: 32px;
        cursor: pointer;
        /deep/ .ivu-icon {
          margin-right: 4px;
        }

      }
      .switch-box {
        display: inline-block;
        margin-right: 32px;
        &:last-child {
          margin-right: 0;
        }
        .switch-text {
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }
    .container {
      max-height: 650px;
      overflow: auto;
      .translation-list {}
    }
  }
</style>
