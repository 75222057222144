export default {
  computed: {
    sortable_map() {
      const map = new Map();
      // 所有维度都可排序
      const dimensionList = this.dimension_list.map((dimension) => ({ value: dimension.value, sortable: true }));
      const list = [...dimensionList, ...this.index_list];
      list.forEach((item) => {
        map.set(item.value, item.sortable);
      });
      return map;
    },
  },
  methods: {
    sortChangeHandler(obj, tableData) {
      this.$emit('on-sort-change', obj);
      const tempTableData = tableData || this.table_data;
      const { key, order } = obj;
      if (order === 'asc') {
        tempTableData.sort((a, b) => {
          // console.log(a, b);
          if (a.date === -1) {
            // console.log('a.date', a.date);
            return -1;
          }
          if (b.date === -1) {
            // console.log('b.date', b.date);
            return 1;
          }
          if (a[key] > b[key]) {
            return 1;
          } if (a[key] < b[key]) {
            return -1;
          }
          return 0;
        });
      } else {
        tempTableData.sort((a, b) => {
          // console.log(a, b);
          if (a.date === -1) {
            // console.log('a.date', a.date);
            return -1;
          }
          if (b.date === -1) {
            // console.log('b.date', b.date);
            return 1;
          }
          if (a[key] > b[key]) {
            return -1;
          } if (a[key] < b[key]) {
            return 1;
          }
          return 0;
        });
      }
    },
  },
};
