/* eslint-disable no-param-reassign */
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

axios.defaults.timeout = 120000;
axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/v1/`;
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'https://push-test.thinkyeah.com/api/v1/';

axios.interceptors.request.use((config) => config, (error) => Promise.reject(error));

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  },
  (error) => {
    if (error instanceof axios.Cancel) {
      const cancelError = new Error('');
      cancelError.type = 'cancel';
      return Promise.reject(cancelError);
    }
    if (error.response) {
      const { status, data } = error.response;
      switch (status) {
        case 400:
          error.message = data.data ?? data.error;
          break;
        case 401:
          error.message = '身份过期，请重新登录';
          store.dispatch('userStore/sessionExpires');
          break;
        case 500:
          error.message = '服务器错误';
          break;
        default:
          error.message = '其他错误';
          break;
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
