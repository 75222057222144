<template>
  <div class="template-group-item"
       :class="{'selected-template-group-item': selected}">
    <div v-show="status === 'read'" class="read-box">
      <Tooltip :content="title" placement="top-start" :disabled="$tools.getStringPixel(title) < ellipsis_width" transfer>
        <span class="template-title">{{ title }}</span>
      </Tooltip>
      <div class="right-box">
        <span class="template-count">{{ count }}</span>
        <Dropdown style="margin-right: 10px" transfer>
          <Icon type="ios-more"></Icon>
          <DropdownMenu slot="list" style="min-width: 60px;">
            <DropdownItem @click.native.stop="editTrigger">编辑</DropdownItem>
            <DropdownItem @click.native.stop="deleteTrigger">删除</DropdownItem>
          </DropdownMenu>
        </Dropdown>
<!--        <div class="operation-box">-->
<!--          <Icon class="operation-icon" type="md-create" :size="18" @click.stop="editTrigger"/>-->
<!--          <Icon class="operation-icon" type="md-remove-circle" :size="18" @click.stop="deleteTrigger"/>-->
<!--        </div>-->
      </div>
    </div>
    <div v-show="status === 'edit'" class="edit-box" @click.stop="() => {}">
      <Input v-model="title_self" @on-enter="editGroupHandler" @on-blur="hideGroupInputItem" ref="groupName"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateGroupItem',
  props: {
    status: String, // read || edit
    id: Number,
    title: String,
    count: Number,
    selected: Boolean,
    ellipsis_width: {
      type: Number,
      default: 150,
    },
  },
  data() {
    return {
      title_self: '',
    };
  },
  methods: {
    editTrigger() {
      this.$emit('edit-trigger');
    },
    deleteTrigger() {
      this.$emit('delete', this.id);
    },
    editGroupHandler() {
      if (this.title === this.title_self) {
        this.$Message.warning('素材组名称未发生修改');
        return;
      }
      if (this.title_self === '') {
        this.$Message.warning('素材组名称不能为空');
        return;
      }
      this.$emit('edit', this.id, this.title_self);
    },
    hideGroupInputItem() {
      this.$emit('hide');
    },
  },
  watch: {
    title: {
      immediate: true,
      handler(newVal) {
        this.title_self = newVal;
      },
    },
    status() {
      if (this.status === 'edit') {
        this.$nextTick(() => {
          this.$refs.groupName.focus();
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
  .template-group-item {
    .read-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;
      margin-bottom: 4px;
      padding: 0 16px;
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;
      /deep/ .ivu-tooltip {
        width: calc(~'100% - 48px');
        .ivu-tooltip-rel {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: middle;
        }
      }
      .template-title {
        font-size: 14px;
        font-weight: 400;
        color: #202444;
        line-height: 22px;
      }
      .right-box {
        flex: 0 0 48px;
        height: 100%;
        text-align: right;
        .template-count {
          font-size: 12px;
          font-weight: 400;
          color: #878AA2;
          line-height: 40px;
          margin-right: 12px;
        }
      }
    }
    .edit-box {
      /deep/ .ivu-input {
        margin-bottom: 4px;
        padding: 4px 16px;
      }
    }
    &:hover {
      background-color: rgba(41, 120, 255, 0.04);
    }
  }
  .selected-template-group-item {
    background-color: #EBF2FF;
    &:hover {
      background-color: #EBF2FF;
    }
    .template-title {
      color: @basicColor!important;
    }
    .template-count {
      color: @basicColor!important;
    }
  }
</style>
