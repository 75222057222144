<template>
  <div class="empty-content">
    <div class="empty-info">
      <img class="empty-icon" src="../../assets/image/ic_empty_content@2x.png" alt="Empty Content">
      <p class="empty-text">{{ empty_text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyContent',
  props: {
    empty_text: {
      type: String,
      default: '请选择至少一个指标后，点击「开始分析」进行计算',
    },
  },
};
</script>

<style scoped lang="less">
.empty-content {
  position: relative;
  min-height: 200px;
  height: 100%;
  .empty-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .empty-icon {
      width: 64px;
      height: 64px;
    }
    .empty-text {
      font-size: 12px;
      font-weight: 400;
      color: #878AA2;
      line-height: 17px;
    }
  }
}
</style>
