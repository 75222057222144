<template>
  <div class="search-condition">
    <div class="condition">
      <DatePicker
        v-if="showDatePicker"
        class="time-option"
        :value="timeValue"
        :options="dateOption"
        @on-change="getTimeData"
        type="daterange"
        placement="bottom-start"
        placeholder="请选择">
      </DatePicker>
      <complex-multiple-select
        class="country-option"
        v-if="countryVisible"
        :value ="country"
        :checkbox_items="country_list"
        :recently_used_list="$store.state.recently_used_country_list"
        :recently_used_enabled="true"
        :style="{ marginLeft: !showDatePicker ? 0 : undefined }"
        @list-change="countryChangeHandler">
      </complex-multiple-select>
      <Select
        v-if="$route.name === 'DataAnalysis'"
        v-model="push_type"
        placeholder="请选择推送类型"
        style="width:200px; margin-left: 16px">
        <Option value='all'>全部</Option>
        <Option value="notification">通知</Option>
        <Option value="message">JSON</Option>
      </Select>
      <Select
        v-if="subVisible"
        v-model="label"
        :placeholder="subLabel"
        transfer
        style="width:200px; margin-left: 16px">
        <Option v-for="item in subList" :value="item.value" :key="item.value">{{item.label}}</Option>
      </Select>
    </div>
    <Button
      type="primary"
      :loading="loading"
      @click="search">
      <span v-if="!loading" >搜索</span>
      <span v-else>搜索</span>
    </Button>
  </div>
</template>

<script>
import TimeIcon from '../../../assets/image/ic_sidebar_ 2@2x.png';
import ComplexMultipleSelect from '../../../components/common/ComplexMultipleSelect.vue';

export default {
  name: 'SearchCondition',
  components: { ComplexMultipleSelect },
  props: {
    timeValue: Array,
    searchLoading: Boolean,
    country_value: {
      type: String,
      default() {
        return 'value';
      },
    },
    showDatePicker: {
      type: Boolean,
      default: true,
    },
    subList: Array,
    subLabel: String,
    subVisible: {
      type: Boolean,
      default: false,
    },
    countryVisible: Boolean,
  },
  data() {
    return {
      timeSearchIcon: TimeIcon,
      timeSlot: this.timeValue, // 时间段
      country: [], // 当前选中的国家列表
      country_list: [], // 所有国家列表
      dateOption: {
        disabledDate(date) {
          return date.valueOf() >= Date.now();
        },
      },
      loading: false,
      push_type: 'all',
      label: '',
    };
  },
  mounted() {
    if (this.countryVisible) {
      this.getCountryData();
    }
  },
  watch: {
    searchLoading() {
      if (this.searchLoading === true) {
        this.loading = false;
      }
    },
  },
  methods: {
    // updateRUCL() { // 更新最近使用国家列表项
    //   console.log(this.country, this.country_list);
    //   const filterCountryList = this.country.map((countryValue) => {
    //     const target = this.country_list.find((country) => country[this.country_value] === countryValue);
    //     return target;
    //   });
    //   if (filterCountryList.length === 0) return;
    //   let RUCL = [...this.$store.state.recently_used_country_list];
    //   if (filterCountryList.length > RUCL.length) { // 如果当前所选的国家列表超过常用列表项长度，则直接截取
    //     RUCL = filterCountryList.slice(0, RUCL.length);
    //   } else {
    //     filterCountryList.forEach((country) => {
    //       const index = RUCL.findIndex((item) => item.value === country.value);
    //       if (index !== -1) { // 如果该国家在之前的常用列表项中，则先从原来的位置移除
    //         RUCL.splice(index, 1);
    //       } else { // 如果该国家不在之前的常用列表项中，则将列表末尾项移除
    //         RUCL.pop();
    //       }
    //       // 插入列表首部
    //       RUCL.unshift(country);
    //     });
    //   }
    //   this.$store.commit('updateRecentlyUsedCountryList', {
    //     country_list: RUCL,
    //   });
    // },
    getCountryData() {
      const urlParams = {
        app_id: this.$route.params.app_id,
      };
      const promise = this.$api.getCountryData(urlParams);
      return promise.then((response) => {
        this.country_list = response.data.list;
      }).catch((error) => {
        this.$Message.error(error.message);
      });
    },
    getTimeData(data) {
      this.timeSlot = data;
    },
    search() {
      let params = {};
      if (this.$route.name === 'DataAnalysis') {
        if (this.push_type === 'all') {
          params = {
            begin_time: this.timeSlot[0],
            end_time: this.timeSlot[1],
            country: JSON.stringify(this.country),
            push_type: '',
          };
        } else {
          params = {
            begin_time: this.timeSlot[0],
            end_time: this.timeSlot[1],
            country: JSON.stringify(this.country),
            push_type: this.push_type,
          };
        }
      } else if (!this.subVisible && this.$route.name === 'DataAnalysisDetail') {
          params = {
            country: JSON.stringify(this.country),
            time_value: this.timeSlot,
          };
        } else if (this.subVisible && this.$route.name === 'DataAnalysisDetail') {
        if (this.label === '') {
          this.label = this.subList[0].value;
        }
          params = {
            country: JSON.stringify(this.country),
            value: this.label,
          };
        }
      // this.updateRUCL();
      this.loading = true;
      this.$emit('func', params);
    },
    countryChangeHandler(list) {
      this.country = list;
    },
  },
};
</script>

<style scoped lang="less">
.search-condition {
  height: 76px;
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 22px;
  .condition {
    height: 32px;
    width:100%;
    display: flex;
    flex: 1;
    .time-option {
      width:200px;
      height: 32px;
    }
    .country-option {
      margin-left: 16px;
    }
    button{
      margin-top: 2px;
    }
  }
}
</style>
