<template>
  <div class="empty-content" :style="{'min-height': `${min_height}px`}">
    <div class="empty-info">
      <Icon class="empty-icon" type="ios-filing-outline" :size="64" color="#ABAEC6"/>
      <span class="empty-text">当前数据为空~</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EmptyContent',
    props: {
      min_height: {
        type: Number,
        default: 200,
      },
    },
  };
</script>

<style scoped lang="less">
  .empty-content {
    position: relative;
    min-height: 200px;
    height: 100%;
    .empty-info {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .empty-icon {}
      .empty-text {
        font-size: 12px;
        font-weight: 400;
        color: #878AA2;
        line-height: 17px;
      }
    }
  }
</style>
