/* eslint-disable no-unused-vars */
import requestFactory from './requestFactory';
import urls from './urls';
import toolFunctions from '../share/toolFunctions';

const GET = 'get';
const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';
const getRequest = requestFactory(GET);
const postRequest = requestFactory(POST);
const putRequest = requestFactory(PUT);
const deleteRequest = requestFactory(DELETE);

const { requestURLParamParser } = toolFunctions;

/**
 * rotation 相关 API
 */
export default {
  getRotationInfo(urlParams) {
    const newURL = requestURLParamParser(urls.rotationInfoURL, urlParams);
    return getRequest(newURL);
  },
  addRotation(urlParams, params) {
    const newURL = requestURLParamParser(urls.addRotationURL, urlParams);
    return postRequest(newURL, params);
  },
  updateRotation(urlParams, params) {
    const newURL = requestURLParamParser(urls.updateRotationURL, urlParams);
    return putRequest(newURL, params);
  },
  deleteRotation(urlParams) {
    const newURL = requestURLParamParser(urls.deleteRotationURL, urlParams);
    return deleteRequest(newURL);
  },
};
