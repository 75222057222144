<template>
  <div class="time-config-form">
    <form-content-header title="时间设置"/>
    <div class="form-container">
      <Form ref="timeConfigForm" :model="time_config_form" :rules="timeConfigFormRules" :label-width="110">
        <FormItem label="时间标准" prop="time_type">
          <RadioGroup v-model="time_config_form.is_local">
            <!-- 发送如果选择 立即发送，则只能选择国际标准时间 -->
            <Radio :label="1" :disabled="time_config_form.schedule_type === 'immediate'">本地时间</Radio>
            <Radio :label="0">国际标准时间</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="发送时间" prop="schedule_type">
          <RadioGroup v-model="time_config_form.schedule_type" @on-change="handleScheduleTypeChange">
            <Radio label="periodic_time">重复</Radio>
            <Radio label="precise_time">定时</Radio>
            <Radio label="immediate">立即</Radio>
            <Radio label="interval">间隔</Radio>
          </RadioGroup>
          <div v-show="time_config_form.schedule_type === 'periodic_time'" class="periodic-time-box">
            <RadioGroup v-model="time_config_form.periodic_time.period">
              <Radio label="daily">每天</Radio>
              <Radio label="weekly">每周</Radio>
            </RadioGroup>
            <week-day-picker v-show="time_config_form.periodic_time.period === 'weekly'" v-model="time_config_form.periodic_time.period_day"/>
            <TimePicker type="time" v-model="time_config_form.periodic_time.time" transfer></TimePicker>
          </div>
          <div v-show="time_config_form.schedule_type === 'precise_time'" class="precise-time-box">
            <DatePicker type="datetime" v-model="time_config_form.precise_time.time" placeholder="请选择" transfer/>
          </div>
          <div v-show="time_config_form.schedule_type==='interval'" class="interval-time-box">
            <div class="interval-time-item">
              <label>时间选择</label>
              <Tooltip transfer  max-width="334" content="开始时间必填，结束时间可选填" transfer-class-name="toolTipTransfer">
                <Icon class="icon" type="ios-help-circle-outline"/>
              </Tooltip>
              <TimePicker type="time" v-model="time_config_form.periodic_time.execute_begin" placeholder="请选择" transfer style="width: 130px"></TimePicker>
              到
              <TimePicker type="time" v-model="time_config_form.periodic_time.execute_end" placeholder="请选择（可选）" transfer style="width: 130px"></TimePicker>
            </div>
            <div class="interval-time-item">
              <label>时间间隔</label>
              <Tooltip transfer  max-width="334" content="小时和分钟至少填一个" transfer-class-name="toolTipTransfer">
                <Icon class="icon" type="ios-help-circle-outline"/>
              </Tooltip>
              <InputNumber v-model="time_config_form.periodic_time.hours" :min="0" :max="23" :precision="0" style="width: 100px"></InputNumber>
              小时
              <InputNumber v-model="time_config_form.periodic_time.minutes" :min="0" :max="59" :precision="0" style="width: 100px"></InputNumber>
              分钟
            </div>
          </div>
        </FormItem>
        <FormItem v-show="time_config_form.schedule_type === 'periodic_time' || time_config_form.schedule_type === 'interval'" label="开始日期" prop="start_time">
          <DatePicker type="datetime" :time-picker-options="startTimeOptions" v-model="time_config_form.start_time" placeholder="请选择" transfer/>
        </FormItem>
        <FormItem v-show="time_config_form.schedule_type === 'periodic_time'|| time_config_form.schedule_type === 'interval'" label="结束日期" prop="end_time">
          <DatePicker type="datetime"
                      :time-picker-options="endTimeOptions"
                      v-model="time_config_form.end_time"
                      placeholder="请选择（可选）"
                      transfer/>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import FormContentHeader from '../../../common/form-content-header.vue';
import WeekDayPicker from './WeekDayPicker.vue';

export default {
  name: 'TimeConfigForm',
  components: { WeekDayPicker, FormContentHeader },
  props: {
    current_push: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      time_config_form: {
        is_local: 1,
        schedule_type: 'periodic_time',
        periodic_time: {
          period: 'daily',
          period_day: [], // weekly的情况下才必须
          time: '00:00:00',
          // 下面四个字段只在发送时间为间隔的时候才有
          execute_begin: '',
          execute_end: '',
          hours: null,
          minutes: null,
        },
        precise_time: {
          time: new Date(),
        },
        start_time: new Date(),
        end_time: null,
      },
      time_config_form_rules: {
        start_time: [
          {
            required: true,
            message: '开始时间不能为空',
            trigger: 'change',
            pattern: /.+/, // ???
          },
        ],
        // end_time: [
        //   {
        //     required: true,
        //     message: '结束时间不能为空',
        //     trigger: 'change',
        //     pattern: /.+/,
        //   },
        // ],
      },
    };
  },
  computed: {
    timeConfigFormRules() { // 判断规则根据表单输入的值动态修改
      if (this.time_config_form.schedule_type === 'immediate') {
        return {};
      }
      return this.time_config_form_rules;
    },
    // preciseTimeOptions() {
    //   return {
    //     disabledDate(date) {
    //       return date && date < Date.now();
    //     },
    //   };
    // },
    startTimeOptions() {
      const endTime = this.time_config_form.end_time;
      const startTime = this.time_config_form.start_time;
      const disabledHours = [];
      if (endTime && startTime && startTime.getDay() === endTime.getDay()) {
        const endHour = endTime.getHours();
        // eslint-disable-next-line no-plusplus
        for (let i = 23; i >= endHour; i--) {
          disabledHours.push(i);
        }
      }
      return {
        'disabled-hours': disabledHours,
      };
    },
    endTimeOptions() {
      const startTime = this.time_config_form.start_time;
      const endTime = this.time_config_form.end_time;
      const disabledHours = [];
      if (startTime && endTime && startTime.getDay() === endTime.getDay()) {
        const startHour = startTime.getHours();
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= startHour; i++) {
          disabledHours.push(i);
        }
      }
      return {
        'disabled-hours': disabledHours,
      };
    },
  },
  watch: {
    current_push: {
      handler(newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          console.log(newVal);
          this.time_config_form.is_local = newVal.push_schedule.is_local;
          this.time_config_form.schedule_type = newVal.push_schedule.schedule_type;
          if (this.time_config_form.schedule_type === 'precise_time') {
            this.time_config_form.precise_time.time = new Date(newVal.push_schedule.precise_time);
          } else if (this.time_config_form.schedule_type === 'periodic_time') { // 周期性任务才有开始和结束日期
            Object.assign(this.time_config_form.periodic_time, newVal.push_schedule.periodic_time);
            if (newVal.push_schedule.periodic_time?.period === 'interval') {
              this.time_config_form.schedule_type = 'interval';
            }
            this.time_config_form.start_time = newVal.push_schedule.begin_time
              ? new Date(newVal.push_schedule.begin_time)
              : null;
            this.time_config_form.end_time = newVal.push_schedule.end_time
              ? new Date(newVal.push_schedule.end_time)
              : null;
          }
        }
      },
      immediate: true,
    },
    'time_config_form.schedule_type': {
      handler(newVal) {
        if (newVal === 'immediate') {
          this.time_config_form.is_local = 0;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleScheduleTypeChange(val) {
      console.log(val);
      if (val === 'interval') {
        this.time_config_form.periodic_time.period = 'interval';
      } else {
        this.time_config_form.periodic_time.period = 'daily';
      }
    },
  },
};
</script>

<style scoped lang="less">
  .time-config-form {
    min-height: 369px;
    padding: 0 24px;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 8px;
    @import '../../../../assets/less/create_push_form/formContainer.less';
    .form-container {
      width: 90%;
      /deep/ .periodic-time-box, /deep/ .precise-time-box, /deep/ .interval-time-box {
        width: 100%;
        padding: 20px 20px 16px;
        background: #F5F7FA;
        border-radius: 4px;
        .interval-time-item{
          margin-bottom: 4px;
        }
        .ivu-tooltip {
          margin-right: 8px;
          cursor: pointer;
          .ivu-icon{
            color: #a5a7aa;
            margin-left: 4px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      /deep/ .periodic-time-box {
        display: flex;
        flex-direction: column;
        .ivu-date-picker {
          max-width: 200px;
        }
      }
    }
  }
</style>
