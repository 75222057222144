<template>
    <div class="app-list">
      <content-header title="项目概览">
        <Input
          class="header-search"
          clearable
          prefix="ios-search"
          v-model="search_content"
          placeholder="请输入项目名称"
          @on-change="searchEnter"
          @on-enter="searchEnter"
        />
        <Button type="primary" icon="md-add" @click="createAppTrigger">新建项目</Button>
      </content-header>
      <div class="list-container">
        <div v-show="item_list.length > 0" class="list">
          <div class="list-header">
            <div class="header-item"
                 v-for="item in title_list"
                 :key="item.value">
              <span class="item-text">{{ item.label }}</span>
            </div>
          </div>
          <div class="list-body">
            <div class="list-item"
                 v-for="(item, index) in item_list"
                 :key="`${item.app_name}-${index}`">
              <div class="app" @click="jumpToAppDetailHandler(item)">
                <img class="app-logo" :src="item.logo" alt="app logo"/>
                <span class="app-name">{{ item.app_name }}</span>
              </div>
              <div class="push">
                <span class="push-number">{{ item.task_total }}</span>
              </div>
              <div class="push">
                <span class="push-number">{{ item.notification_total }}</span>
              </div>
              <div class="push">
                <span class="push-number">{{ item.message_total }}</span>
              </div>
              <div class="operation">
                <Dropdown trigger="click">
                  <img class="operation-icon" src="../assets/image/ic_more@2x.png" alt="more">
                  <DropdownMenu slot="list">
                    <DropdownItem name="edit" @click.native="editAppTrigger(item)">
                      <span class="dropdown-item-text">编辑</span>
                    </DropdownItem>
                    <DropdownItem name="delete" @click.native="deleteAppTrigger(item)">
                      <span class="dropdown-item-text">删除</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <empty-content v-show="item_list.length === 0"/>
      </div>
      <create-app-modal
        v-model="create_app_modal_visible"
        :type="app_modal_status"
        :app_info="current_app_info"
        @create="createAppHandler"
        @edit="createAppHandler"/>
    </div>
</template>

<script>
import ContentHeader from '../components/page/content/ContentHeader.vue';
import CreateAppModal from '../components/common/CreateAppModal.vue';
import EmptyContent from '../components/common/EmptyContent.vue';

export default {
  name: 'AppList',
  components: { EmptyContent, CreateAppModal, ContentHeader },
  data() {
    return {
      title_list: [
        {
          label: 'APP',
          value: 'app',
        },
        {
          label: '执行中的任务',
          value: 'push_running',
        },
        {
          label: '通知 Push',
          value: 'notice_push',
        },
        {
          label: 'JSON Push',
          value: 'Json_push',
        },
        {
          label: '',
          value: 'operation',
        },
      ],
      item_list: [
        // {
        //   app_id: 'A30',
        //   app_name: 'Paintist2020',
        //   logo: 'https://d23ttoptv033g1.cloudfront.net/icon/mixcollage.png',
        //   task_total: 13,
        // },
      ],
      displayed_item_list: [],
      create_app_modal_visible: false,
      app_modal_status: 'create',
      // 应用编辑待定
      current_app_info: {},
      search_content: '',
    };
  },
  provide() {
    return {
      // 值为函数，实现inject值的实时更新 this问题？
      appIDListFn: () => this.item_list.map((item) => item.app_id),
    };
  },
  computed: {
    listContainerElement() {
      return document.querySelector('.list-container');
    },
  },
  methods: {
    resetList() {
      this.item_list = [];
    },
    fetchData() {
      // 添加加载动画
      const loader = this.$loader(this.listContainerElement).show();
      // 清空当前应用列表
      this.resetList();
      const promise = this.$api.getAppList();
      return promise.then((response) => {
        // 在这里应该不需要判断data不存在的情况
        const { data } = response;
        const { list } = data;
        if (list) {
          this.item_list = [...list];
          console.log(this.item_list);
          this.$store.commit('setAppList', {
            app_list: [...list],
          });
        }
      }).catch((error) => {
        this.$Message.error(error.message);
      }).finally(() => {
        loader.hide();
      });
    },
    fetchAppDetail(appId) {
      const promise = this.$api.getAppDetail({ app_id: appId })
        .then((response) => {
          const info = response.data;
          return info;
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    fetchImageFile(url) {
      const promise = this.$getRequest(url, {
        responseType: 'blob',
      }).then((response) => {
        console.log(response);
      });
      return promise;
    },
    jumpToAppDetailHandler(appInfoItem) {
      const appId = appInfoItem.app_id;
      this.$router.push({
        name: 'Push',
        params: {
          app_id: appId,
        },
      });
    },
    createAppTrigger() {
      this.app_modal_status = 'create';
      this.create_app_modal_visible = true;
    },
    uploadAppLogo(imageFile, pid) { // 可以抽成全局函数
      if (!imageFile) {
        return Promise.resolve('');
      }
      const promise = this.$api.fileUpload({ file: imageFile, pid })
        .then((response) => {
          const { msg, url } = response.data;
          if (msg === 'success') {
            // then中的返回的新Promise实例的值为url
            return url;
          }
          return Promise.reject(new Error('哎呀，图标上传出错啦'));
        });
      return promise;
    },
    createAppHandler(info) {
      const infoCopy = {
        app_info: { ...info.app_info },
        config_firebase: { ...info.config_firebase },
        config_parse: { ...info.config_parse },
        config_server: { ...info.config_server },
      };
      // eslint-disable-next-line no-param-reassign
      infoCopy.app_info.publish_time = this.$tools.dateFormatter(infoCopy.app_info.publish_time);
      // 获取图标文件，先将文件传至亚马逊服务器；编辑状态下，也有可能不需要upload
      const imageFile = info.app_info.logo;
      const promise = this.uploadAppLogo(imageFile, info.app_info.id)
        .then((url) => {
          if (url) {
            // 更新image url
            infoCopy.app_info.logo = url;
          } else {
            infoCopy.app_info.logo = infoCopy.app_info.url;
          }
          delete infoCopy.app_info.url;
          const api = this.app_modal_status === 'create' ? this.$api.addApp : this.$api.updateApp;
          return api({ ...infoCopy });
        })
        .then((response) => {
          const { result } = response.data;
          const successMsg = this.app_modal_status === 'create' ? '新建应用成功' : '更新应用成功';
          const errorMsg = this.app_modal_status === 'create' ? '新建应用失败' : '更新应用失败';
          if (result === 'success') {
            this.$Message.success(successMsg);
            // 刷新应用列表
            return this.fetchData();
          }
          return Promise.reject(new Error(errorMsg));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        })
        .finally(() => {});
      return promise;
    },
    editAppTrigger(item) {
      // 请求当前App的详细信息
      this.fetchAppDetail(item.app_id)
        .then((info) => {
          if (info) {
            // 创建一个副本
            this.current_app_info = { ...info };
            this.current_app_info.app_info.url = this.current_app_info.app_info.logo;
            this.current_app_info.app_info.logo = null;
            // 修改AppModal状态
            this.app_modal_status = 'edit';
            this.create_app_modal_visible = true;
          }
        });
    },
    deleteAppTrigger(item) {
      this.$Modal.confirm({
        title: '确定删除该应用吗？',
        content: null,
        onOk: () => {
          this.deleteAppHandler(item.app_id);
        },
        onCancel: () => {},
      });
    },
    deleteAppHandler(appId) {
      const promise = this.$api.deleteApp({
        id: appId,
      });
      promise.then((response) => {
        const { result } = response.data;
        if (result === 'success') {
          this.$Message.success('应用删除成功');
          // 删除列表中指定的应用
          const targetIndex = this.item_list.findIndex((item) => item.app_id === appId);
          if (targetIndex !== -1) {
            this.item_list.splice(targetIndex, 1);
          }
          return null;
        }
        return Promise.reject(new Error('应用删除失败'));
      }).catch((error) => {
        this.$Message.error(error.message);
      }).finally(() => {});
    },
    searchEnter() {
      const searchContent = this.search_content.trim().toLowerCase();
      if (searchContent === '') {
        this.item_list = [...this.$store.state.app_list];
      } else {
        this.item_list = [
          ...this.$store.state.app_list.filter((project) => project.app_name.toLowerCase().indexOf(searchContent) !== -1),
        ];
        // this.displayed_item_list.length === 0 ? (this.searchProject = false) : (this.searchProject = true);
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped lang="less">
  .app-list {
    .list-container {
      position: relative;
      min-height: 240px;
      padding: 24px;
      background: #FFFFFF;
      border-radius: 4px;
      .list {
        .list-header {
          display: flex;
          flex-direction: row;
          height: 48px;
          background: #F3F5FA;
          box-shadow: inset 0px -1px 0px 0px #EAEBF7;
          border-radius: 8px 8px 0px 0px;
          .header-item {
            flex: 1 1 17.5%;
            padding: 0 24px;
            text-align: right;
            line-height: 48px;
            &:first-child {
              flex-basis: 30%;
              text-align: left;
            }
            .item-text {
              font-size: 14px;
              font-weight: 600;
              color: #202444;
              line-height: 20px;
              vertical-align: middle;
            }
          }
        }
        .list-body {
          .list-item {
            display: flex;
            height: 72px;
            background: #FFFFFF;
            box-shadow: inset 0px -1px 0px 0px #EAEBF7;
            .app, .push, .operation {
              padding: 0 24px;
            }
            .app {
              flex: 1 1 30%;
              text-align: left;
              line-height: 72px;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              .app-logo {
                width: 48px;
                height: 48px;
                margin-right: 16px;
                line-height: 48px;
                border-radius: 6px;
                vertical-align: middle;
              }
              .app-name {
                font-size: 16px;
                font-weight: 600;
                color: #202444;
                line-height: 22px;
                vertical-align: middle;
              }
            }
            .push, .operation {
              flex: 1 1 17.5%;
              text-align: right;
              line-height: 72px;
            }
            .push {
              .push-number {
                font-size: 24px;
                font-weight: 500;
                color: #545878;
                line-height: 29px;
                vertical-align: middle;
              }
            }
            .operation {
              .operation-icon {
                width: 16px;
                height: 16px;
                vertical-align: middle;
                cursor: pointer;
              }
              /deep/ .ivu-dropdown-item {
                text-align: left;
              }
              /deep/ .ivu-dropdown {
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
</style>
