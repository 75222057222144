<template>
  <div
    v-bi-click-outside="clickOutsideHandler"
    class="complex-multiple-select">
    <div class="multiple-select-trigger" tabIndex="0" hidefocus="true" @click="selectClick($event)">
      <Tooltip :content="checkbox_text"
               :disabled="checked_group.length === 0"
               placement="top"
               :delay="800">
        <span
          class="checkbox-text"
          :class="{'checkbox-empty-text': checked_group.length === 0}">
          {{ checkbox_text }}
        </span>
      </Tooltip>
      <img class="arrow-img"
           :class="expanded_arrow_class"
           src="../../assets/image//ic_filter_arrow@2x.png"
           alt="Filter arrow">
      <Icon class="close-icon"
            :class="{
              'empty-close-icon': checked_group.length === 0,
              'active-close-icon': checkbox_visible}"
            type="ios-close-circle"
            size="16"
            @click.stop="clearCheckedGroup"/>
    </div>
    <transition
      name="select-transition">
      <div  v-show="checkbox_visible"
            class="modal-wrap">
        <div  class="complex-multiple-select-modal"
              @click.stop="handler">
          <div class="modal-main">
            <div class="modal-left">
              <div class="search-box">
                <Input v-model="search_content"
                       placeholder="搜索"
                       @on-enter="search"/>
              </div>
              <div class="select-options">
                <div>
                  <RadioGroup v-if="option_enabled" v-model="check_option">
                    <Radio :label="0">include</Radio>
                    <Radio :label="1">exclude</Radio>
                  </RadioGroup>
                </div>
                <span class="check-all" @click="checkAll">{{ check_all_text }}</span>
              </div>
              <div ref="checkbox_box" class="checkbox-box">
                <CheckboxGroup ref="checkbox_group" class="checkbox-group" v-model="checked_group">
                  <Checkbox class="checkbox"
                            v-for="(item, index) in displayed_items"
                            :key="`${item.value}-${index}`"
                            :label="value_param === 'value' ? item.value : item.label">
                    <span class="label-text">{{getItemLabel(item)}}</span>
                  </Checkbox>
                </CheckboxGroup>
              </div>
            </div>
            <div class="modal-right">
              <div class="checked-group-overview">
              <span class="text">
                已选择{{ checked_group.length }}项 (共{{ checkbox_items.length }}项)
              </span>
                <span class="clear-button" @click="clearCheckedGroup">清空</span>
              </div>
              <ul class="checked-group-list">
                <li v-for="item in checked_group"
                    :key="item"
                    class="checked-group-item">
                  <div class="name">{{ item }}</div>
                  <img class="delete-button"
                       src="../../assets/image/ic_close.png"
                       alt="Close"
                       @click="deleteCheckedItem(item)">
                </li>
              </ul>
            </div>
          </div>
<!--          <div class="modal-footer">-->
<!--            <div class="recently-used-list" v-if="recently_used_enabled">-->
<!--              <span class="recently-used-text">常用项：</span>-->
<!--              <div class="recently-used-item"-->
<!--                   :class="{ 'selected-recently-used-item': checked_group.indexOf(item[value_param]) !== -1 }"-->
<!--                   v-for="item in recently_used_list"-->
<!--                   :key="item.value"-->
<!--                   @click="selectRecentlyUsedItemHandler(item)">{{ getItemLabel(item) }}</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import clickOutsideMixin from '../../push-common/mixins/clickOutsideMixin';

export default {
  name: 'ComplexMultipleSelect',
  props: {
    data: Array,
    data_option: {
      type: Number,
      default: 0,
    },
    option_enabled: Boolean,
    checkbox_items: Array,
    button_loading: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '所有国家和地区',
    },
    value_param: {
      type: String,
      default: 'value',
    },
    label_param: {
      type: String,
      default: 'both',
    },
    recently_used_list: {
      type: Array,
    },
    recently_used_enabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [clickOutsideMixin],
  data() {
    return {
      search_content: '',
      search_items: [],
      displayed_items: [],
      checked_group: [],
      check_option: 0,
      check_all_text: '全选',
      checkbox_visible: false,
      limit_number: 200,
      selected_project_id: this.$store.selected_project_id,
    };
  },
  methods: {
    selectClick() {
      // evt.stopPropagation();
      this.checkbox_visible = !this.checkbox_visible;
    },
    documentClickCallback() {
      if (this.checkbox_visible) {
        this.checkbox_visible = false;
      }
    },
    search() {
      if (this.search_content === '') {
        this.search_items = [...this.checkbox_items];
      } else {
        // 先进行精确查询，再进行模糊查询
        const preciseSeachItems = this.checkbox_items.filter((val) => {
          const contentInLowerCase = this.search_content.toLowerCase();
          return val.value.toLowerCase() === contentInLowerCase
            || val.label.toLowerCase() === contentInLowerCase;
        });
        const fuzzySearchItems = this.checkbox_items.filter((val) => {
          const contentInLowerCase = this.search_content.toLowerCase();
          // label & value
          return val.value.toLowerCase().indexOf(contentInLowerCase) !== -1
            || val.label.toLowerCase().indexOf(contentInLowerCase) !== -1;
        });
        const filteredFuzzySearchItems = fuzzySearchItems.filter((val) => {
          if (preciseSeachItems.indexOf(val) === -1) return true;
          return false;
        });
        // 数组去重
        this.search_items = [...preciseSeachItems, ...filteredFuzzySearchItems];
      }
    },
    checkAll() {
      if (this.check_all_text === '全选') {
        // const arr = this.displayed_items.map((val) => (this.label_param === 'value'
        //   ? val.value : val.label));
        const arr = this.displayed_items.map((val) => val.value);
        this.checked_group = [...arr];
      } else {
        this.checked_group = [];
      }
    },
    clearCheckedGroup() {
      this.checked_group = [];
    },
    deleteCheckedItem(item) {
      const index = this.checked_group.findIndex((element) => element === item);
      this.checked_group.splice(index, 1);
    },
    getItemLabel(item) {
      if (this.label_param === 'both') {
        return `${item.label} (${item.value})`;
      } if (this.label_param === 'value') {
        return `${item.value}`;
      }
      return `${item.label}`;
    },
    handler() {},
    scrollHandler(evt) {
      const { target } = evt;
      const checkboxGroupElm = this.checkbox_group_element;
      const totalHeight = checkboxGroupElm.scrollHeight;
      const { scrollTop } = target;
      if (totalHeight - scrollTop < 1000 && this.displayed_items.length < this.search_items.length) {
        const arr = this.search_items.length - this.displayed_items.length > this.limit_number
          ? this.search_items.slice(this.displayed_items.length, this.displayed_items.length + 200)
          : this.search_items.slice(this.displayed_items.length);
        this.displayed_items.push(...arr);
      }
    },
    selectRecentlyUsedItemHandler(item) {
      const value = item[this.value_param];
      const targetIndex = this.checked_group.indexOf(value);
      if (targetIndex !== -1) {
        this.checked_group.splice(targetIndex, 1);
      } else {
        this.checked_group.push(value);
      }
    },
  },
  computed: {
    checkbox_text() {
      if (this.checked_group.length === 0) {
        return this.placeholder;
      }
      if (this.checked_group.length === 1) {
        return this.checked_group[0];
      }
      return `已选中${this.checked_group.length}个选项`;
    },
    expanded_arrow_class() {
      return this.checkbox_visible
        ? { 'expanded-arrow-img': true }
        : { 'expanded-arrow-img': false };
    },
    checkbox_element() {
      const checkboxElm = this.$refs.checkbox_box;
      return checkboxElm;
    },
    checkbox_group_element() {
      const checkboxGroup = this.$refs.checkbox_group;
      const checkboxGroupElm = checkboxGroup.$el;
      return checkboxGroupElm;
    },
  },
  watch: {
    checkbox_visible(newVal) {
      if (!newVal) {
        if (this.option_enabled) {
          this.$emit('option-change', this.check_option);
        }
        this.$emit('hide', this.checked_group);
        this.$emit('list-change', this.checked_group);
        setTimeout(() => {
          this.search_content = '';
          this.search_items = [...this.checkbox_items];
        }, 200);
      }
    },
    data(newVal) {
      this.checked_group = [...newVal];
    },
    data_option(newVal) {
      this.check_option = newVal;
    },
    checkbox_items: {
      immediate: true,
      handler(val) {
        this.search_items = [...val];
      },
    },
    'checked_group.length': function (val) {
      const total = this.displayed_items.length;
      this.$emit('list-change', this.checked_group);
      if (val === total) {
        this.check_all_text = '全不选';
      } else {
        this.check_all_text = '全选';
      }
    },
    search_items: {
      immediate: true,
      handler(val) {
        const totalLength = val.length;
        this.displayed_items = totalLength > this.limit_number ? val.slice(0, this.limit_number) : val;
      },
    },
    '$store.state.selected_project_id': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.clearCheckedGroup();
        }
      },
    },
  },
  mounted() {
    this.checkbox_element.addEventListener('scroll', this.scrollHandler);
    console.log(this.$store.state.recently_used_country_list);
  },
  beforeDestroy() {
    this.checkbox_element.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>
<style lang="less">
  .complex-multiple-select {
    .ivu-checkbox-wrapper {
      margin-right: 0!important;
    }
    .ivu-radio-wrapper {
      margin-right: 0;
      border: none!important;
    }
    .ivu-radio-wrapper-checked {
      color: #2F54EB!important;
      background-color: #ffffff!important;
    }
    .select-options {
      .ivu-radio-wrapper {
        margin-right: 8px;
      }
    }
  }
</style>
<style scoped lang="less">
  @marginSide: 10px;
  @paddingSide: 10px;
  @itemPaddingSide: 8px;
  @inputBgColor: #FFFFFF;
  @inputColor: #545878;
  @inputPlaceholderColor: #C5C5C5;
  @inputActiveColor: #2978FF;
  @borderRadius: 4px;
  @modalWrapWidth: 600px;
  .select-transition-enter, .select-transition-leave-to {
    opacity: 0;
    transform: scaleY(0.8);
  }
  .select-transition-enter-active, .select-transition-leave-active {
    transform-origin: top;
    transition: transform 0.24s ease-in-out, opacity 0.24s ease-in-out;
  }
  .select-transition-leave, .select-transition-enter-to {
    opacity: 1;
    transform: scaleY(1);
  }
  .complex-multiple-select {
    position: relative;
    .multiple-select-trigger {
      position: relative;
      width: 200px;
      height: 32px;
      line-height: 26px;
      padding: 2px 8px;
      background-color: @inputBgColor;
      border: 1px solid #dcdee2;
      border-radius: @borderRadius;
      color: #c1c1c1;
      cursor: pointer;
      transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      &:hover {
        border-color: @inputActiveColor;
        .close-icon {
          opacity: 1;
        }
      }
      &:focus {
        border-color: @inputActiveColor;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(41, 120, 255, 0.2);
      }
      .checkbox-text {
        display: inline-block;
        width: 160px;
        color: @inputColor;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .checkbox-empty-text {
        color: @inputPlaceholderColor;
      }
      .arrow-img {
        position: absolute;
        top: 50%;
        right: 12px;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        transition: transform 0.2s ease-in-out;
      }
      .close-icon {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        z-index: 1;
        color: #6a6d95;
        opacity: 0;
        transition: opacity 0.1s ease-in-out, transform 0.2s ease-in-out;
        background-color: #FFFFFF;
      }
      .empty-close-icon {
        visibility: hidden;
      }
      .active-close-icon {
        transform: translateY(-50%) rotate(180deg);
      }
      .expanded-arrow-img {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .modal-wrap {
      position: absolute;
      top: 40px;
      left: 0;
      width: @modalWrapWidth;
      z-index: 99;
      .complex-multiple-select-modal {
        width: 100%;
        background-color: #ffffff;
        overflow: hidden;
        border-radius: @borderRadius;
        border: 1px solid #e8eaec;
        box-shadow: 0 0.6px 6px rgba(0, 0, 0, 0.18);
        .modal-main {
          display: flex;
          border-bottom: 1px solid #dcdee2;
        }
        .modal-footer {
          min-height: 36px;
          padding: 8px;
          .recently-used-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .recently-used-text {
              font-size: 14px;
              font-weight: 400;
              color: #5D7092;
            }
            .recently-used-item {
              height: 30px;
              margin: 0 8px 4px 0;
              padding: 0 16px;
              line-height: 30px;
              border: 1px solid #eeeeee;
              border-radius: 24px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: #202444;
              background-color: #FFFFFF;
              transition: all ease-in-out 0.2s;
              cursor: pointer;
              &:hover {
                color: #FFFFFF;
                background-color: #2D8cF0;
                opacity: 0.6;
              }
            }
            .selected-recently-used-item {
              background-color: #2D8cF0;
              color: #FFFFFF;
              &:hover {
                background-color: #2D8cF0;
                color: #FFFFFF;
                opacity: 1;
              }
            }
          }
        }

        .modal-left {
          width: calc(~'@{modalWrapWidth} / 2');
          height: 248px;
          border-right: 1px solid #dcdee2;
          .search-box {
            margin: @marginSide;
          }

          .select-options {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 0 @marginSide @marginSide;

            .check-all {
              color: #2F54EB;
              font-size: 12px;
              font-weight: 400;
              cursor: pointer;
            }
          }

          .checkbox-box {
            height: calc(~'100% - 87px');
            padding: 4px 0;
            border-top: 1px solid #d1d1d1;
            overflow-y: auto;

            .checkbox {
              display: block;
              padding: @itemPaddingSide;
              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
              .label-text {
                word-break: break-all;
              }
            }
          }
        }

        .modal-right {
          width: calc(~'@{modalWrapWidth} / 2');
          height: 248px;

          .checked-group-overview {
            display: flex;
            justify-content: space-between;
            height: 48px;
            line-height: 48px;
            padding: 0 @paddingSide;

            .text, .clear-button {
              color: #999999;
              font-size: 12px;
              font-weight: 400;
            }

            .clear-button {
              color: #2F54EB;
              cursor: pointer;
              img {
                width: 12px;
              }
            }
          }

          .checked-group-list {
            width: 100%;
            height: calc(~'100% - 48px');
            padding: 4px 0;
            border-top: 1px solid #d1d1d1;
            overflow-y: auto;

            .checked-group-item {
              /*width: 100%;*/
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: @itemPaddingSide;
              cursor: pointer;
              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
              .name {
                flex: 0 0 90%;
                word-break: break-all;
              }
              .delete-button {
                flex: 0 0 10px;
                height: 10px;
                transform-origin: center;
                transition: transform 0.2s ease-in-out;
                &:hover {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }
</style>
