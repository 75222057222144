import AppList from '../views/AppList.vue';
import AppOverviewIcon from '../assets/image/ic_sidebar_app_overview@2x.png';

const APP_HOME_LAYOUT = 'AppHomeLayout';
export default [
  {
    path: '/app',
    name: 'AppList',
    component: AppList,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '项目概览',
      icon: AppOverviewIcon,
      level: 'appOverview',
    },
  },
  {
    path: '/app_config',
    name: 'AppConfig',
    component: AppList,
    meta: {
      layout: APP_HOME_LAYOUT,
      title: '项目配置',
      icon: AppOverviewIcon,
      level: 'appOverview',
      hidden: true, // 项目配置暂时隐藏
    },
  },
];
