<template>
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>选项显示切换</title>
    <g id="2.16修改" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="修改-图表" transform="translate(-507.000000, -895.000000)" stroke="currentColor" stroke-width="1.2">
        <g id="编组-9" transform="translate(248.000000, 546.000000)">
          <g id="编组-14备份" transform="translate(24.000000, 349.000000)">
            <g id="ic_sidebar_备份-3" transform="translate(235.000000, 0.000000)">
              <circle id="椭圆形" cx="8" cy="8" r="4.4"></circle>
              <line x1="8" y1="2" x2="8" y2="5" id="路径-115" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="8" y1="11" x2="8" y2="14" id="路径-115备份" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="2" y1="8" x2="5" y2="8" id="路径-116" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="11" y1="8" x2="14" y2="8" id="路径-116备份" stroke-linecap="round" stroke-linejoin="round"></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LegendItemSVG',
};
</script>

<style scoped>

</style>
