<template>
  <div class="extra-config-form">
    <form-content-header title="额外设置"/>
    <div class="extra-form-container">
      <Form ref="extraConfigForm" :model="extraConfigForm" :label-width="110">
        <FormItem label="优先级">
          <RadioGroup v-model="extraConfigForm.priority">
            <Radio :label="0">普通优先级</Radio>
            <Radio :label="1">高优先级</Radio>
          </RadioGroup>
        </FormItem>
        <template v-if="dataType==='notification'">
          <FormItem label="提示音">
            <i-switch v-model="extraConfigForm.sound" />
          </FormItem>
          <FormItem label="Channel">
            <Input v-model="extraConfigForm.channel_id" placeholder="请输入Channel" clearable />
          </FormItem>
          <FormItem label="参数设置">
            <div class="params-box">
              <div class="params-value-box" v-for="(item, index) in extraConfigForm.data" :key="index">
                <Input v-model="item.key" placeholder="请输入参数" clearable/>
                <Input v-model="item.value" placeholder="请输入值" clearable  class="params-value"/>
                <Icon type="md-close" size="16" class="params-close-icon" @click="deleteParamsList(item)"/>
              </div>
              <div :class="addIconClassName">
                <Icon type="md-add" size="16" class="params-add-icon" @click="addParamsList"/>
                <span @click="addParamsList">添加参数</span>
              </div>
            </div>
          </FormItem>
        </template>
      </Form>
    </div>
  </div>
</template>

<script>
import FormContentHeader from '../../../common/form-content-header.vue';

export default {
  name: 'ExtraConfigForm',
  components: { FormContentHeader },
  props: {
    dataType: {
      type: String,
    },
    current_push: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      extraConfigForm: {
        priority: 0,
        data: [],
        sound: false,
        channel_id: '',
      },
      addIconClassName: 'params-add',
    };
  },
  methods: {
    addParamsList() {
      if (this.extraConfigForm.data.every((item) => item.key !== '' && item.value !== '')) {
        this.extraConfigForm.data.push({ key: '', value: '' });
      }
    },
    deleteParamsList(val) {
      this.extraConfigForm.data = this.extraConfigForm.data.filter((item) => item.key !== val.key && item.value !== val.value);
    },
  },
  watch: {
    current_push: {
      async handler(newVal) {
        await this.$store.dispatch('fetchAppDetail', this.$route.params.app_id);
        if (newVal && JSON.stringify(newVal) !== '{}') {
          if (newVal.push_content.push_data) {
            this.extraConfigForm.data = !newVal.push_content.push_data.data
              ? []
              : Object.entries(newVal.push_content.push_data.data).map(([key, value]) => ({ key, value }));
            this.extraConfigForm.sound = newVal.push_content.push_data.sound === 1;
            this.extraConfigForm.channel_id = newVal.push_content.push_data.channel_id;
            this.extraConfigForm.priority = newVal.push_content.push_data.priority;
          } else {
            this.extraConfigForm.data = [];
            this.extraConfigForm.sound = false;
            this.extraConfigForm.channel_id = '';
            this.extraConfigForm.priority = this.$store.state.current_app_detail?.app_info?.priority ?? 0;
          }
        } else {
          this.extraConfigForm.priority = this.$store.state.current_app_detail?.app_info?.priority ?? 0;
        }
      },
      immediate: true,
    },
    'extraConfigForm.data': {
      handler(val) {
        if (Array.isArray(val)) {
          if (val.every((item) => item.key !== '' && item.value !== '')) {
            this.addIconClassName = 'params-add';
          } else {
            this.addIconClassName = 'prohibit-params-add';
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.extra-config-form {
  padding: 0 24px;
  background-color: #FFFFFF;
  border-radius: 4px;
  overflow: hidden;
  .extra-form-container {
    width: 83%;
    margin-bottom: 16px;
    .params-box {
      flex: 1;
      .params-value-box {
        display: flex;
        align-items: center;
        &:nth-child(n+2) {
          margin-top: 4px;
        }
      }
      .params-close-icon, .params-value {
        margin-left: 4px;
      }
      .params-close-icon, .params-add {
        cursor: pointer;
      }
      .prohibit-params-add {
        cursor: not-allowed;
        font-size: 14px;
        color: #2978FF;
      }
      .params-add {
        font-size: 14px;
        color: #2978FF;
      }
      .params-add-icon {
        margin-right: 4px;
      }
    }
  }

}
</style>
