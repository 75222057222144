<template>
  <div class="content-form">
    <form-content-header title="内容">
      <slot name="tempPushButtonGroup"></slot>
    </form-content-header>
    <!--  临时任务显示框  -->
    <slot></slot>
    <div class="form-container">
      <Form ref="contentForm" :model="content_form" :rules="contentFormRules" :disabled="auto" :label-width="110">
        <FormItem label="类型" prop="type">
          <RadioGroup v-model="content_form.type">
            <Radio label="notification" :disabled="pushFormStatus === 'edit'">通知</Radio>
            <Radio label="message" :disabled="pushFormStatus === 'edit'">JSON</Radio>
          </RadioGroup>
        </FormItem>
        <div v-show="content_form.type === 'notification'" class="notification-form">
          <FormItem label="消息组" prop="notification.group">
            <Select v-model="content_form.notification.group" placeholder="请选择（可选）" clearable filterable @on-change="groupChangeHandler">
              <Option v-for="item in group_list" :key="item.id" :value="item.id">{{ item.title }}</Option>
            </Select>
          </FormItem>
          <FormItem label="下次推送消息" prop="notification.template">
            <Select v-model="content_form.notification.template" placeholder="请选择（可选）" clearable filterable @on-change="templateChangeHandler">
              <Option v-for="item in template_list" :key="item.id" :value="item.id">{{ item.title }}</Option>
            </Select>
          </FormItem>
          <FormItem label="标题" prop="notification.title">
            <Input v-model="content_form.notification.title" placeholder="请输入标题" clearable :disabled="editDisable"/>
          </FormItem>
          <FormItem label="内容" prop="notification.body">
            <Input type="textarea" :rows="3" v-model="content_form.notification.body" placeholder="请输入内容" clearable :disabled="editDisable"/>
          </FormItem>
          <FormItem label="图片" prop="notification.image">
            <basic-image-upload
              :image.sync="content_form.notification.image"
              :editDisable="editDisable"
              :url.sync="content_form.notification.url"/>
          </FormItem>
        </div>
        <div v-show="content_form.type === 'message'" class="message-form">
          <FormItem label="内容（JSON）" prop="message.body">
            <Input type="textarea" :rows="4" v-model="content_form.message.body" placeholder="请输入内容" clearable/>
          </FormItem>
        </div>
      </Form>

    </div>
  </div>
</template>

<script>
import FormContentHeader from '../../../common/form-content-header.vue';
import BasicImageUpload from '../../../common/BasicImageUpload.vue';

export default {
  name: 'ContentForm',
  components: { BasicImageUpload, FormContentHeader },
  props: {
    auto: Boolean,
    currentPush: {
      type: Object,
      default: () => {},
    },
    pushFormStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    const validateMessage = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入JSON格式内容'));
      } else if (!this.$tools.checkJSON(value)) {
        callback(new Error('内容不是JSON格式'));
      } else {
        callback();
      }
    };
    return {
      group_list: [],
      template_list: [],
      content_form: {
        type: 'notification',
        // 需要根据type的值分层处理
        notification: {
          group: '', // 绑定的是id
          template: '', // 绑定的是id
          title: '',
          body: '',
          image: null,
          url: '',
        },
        message: {
          body: '',
        },
      },
      notification_form_rules: {
        'notification.title': [
          { required: true, message: '请输入标题', trigger: 'change' },
        ],
        'notification.body': [
          { required: true, message: '请输入内容', trigger: 'change' },
        ],
      },
      message_form_rules: {
        'message.body': [
          { validator: validateMessage, trigger: 'blur' },
        ],
      },
      editDisable: false,
      addIconClassName: 'params-add',
    };
  },
  computed: {
    contentFormRules() {
      if (this.auto) { // auto模式下，内容不需要输入
        return {};
      }
      return this.content_form.type === 'notification'
        ? this.notification_form_rules
        : this.message_form_rules;
    },
  },
  methods: {
    fetchGroupList() {
      this.group_list = [];
      this.content_form.notification.group = '';
      const promise = this.$api.getGroupList({
        app_id: this.$route.params.app_id,
      }).then((response) => {
        console.log('123123', response);
        const groupInfo = response.data.group_info;
        this.group_list = groupInfo.map((item) => (
          {
            id: item.id,
            title: item.group_name,
            count: item.count || 0,
            template_list: null,
          }
        ));
      }).catch((error) => {
        this.$Message.error(error.message);
      }).finally(() => {});
      return promise;
    },
    fetchTemplateList(groupId, templateId) {
      this.template_list = [];
      // this.content_form.notification.template = '';
      const urlParams = {
        app_id: this.$route.params.app_id,
        group_id: groupId,
      };
      const promise = this.$api.getTemplateList(urlParams)
        .then((response) => {
          // eslint-disable-next-line camelcase
          const { template_info } = response.data;
          const targetGroupItem = this.group_list.find((val) => val.id === groupId);
          targetGroupItem.template_list = template_info.map((item) => ({
            id: item.nid,
            title: item.title,
            body: item.body,
            url: item.image_url,
            localize_list: item.localize_list,
            group_id: item.group.id,
          }));
          this.template_list = targetGroupItem.template_list;
          this.content_form.notification.template = templateId
            ? this.content_form.notification.template
            : this.template_list[0].id;
          const targetTemplate = this.template_list.find((template) => template.id === this.content_form.notification.template);
          this.content_form.notification.title = targetTemplate.title;
          this.content_form.notification.body = targetTemplate.body;
          this.content_form.notification.url = targetTemplate.url;
          this.content_form.notification.image = null;
          this.content_form.notification.localize = targetTemplate.localize_list;
          // this.content_form.notification.template = this.template_list.length > 0
          //   ? this.template_list[0].id
          //   : '';
        }).catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    createTempPushTrigger() {
      this.$emit('create-temp-push');
    },
    showTempPushListTrigger() {
      this.$emit('show-temp-push-list');
    },
    groupChangeHandler(val) {
        if (val) {
          this.editDisable = true;
          // 当所选得素材组发生更新，则需要更新素材列表，以及当前所选的素材
          const targetGroup = this.group_list.find((group) => group.id === val);
          if (targetGroup && targetGroup.template_list) { // 如果之前已经请求过，可以直接使用
            this.template_list = targetGroup.template_list;
            this.content_form.notification.template = this.template_list.length > 0
              ? this.template_list[0].id
              : null;
          } else { // 否则请求template_list
            this.content_form.notification.template = '';
            this.fetchTemplateList(val, '');
          }
        } else { // group 为空，则对应的 template 也应该为空
          this.editDisable = false;
          this.content_form.notification.template = null;
          this.content_form.notification.localize = [];
        }
    },
    templateChangeHandler(val) {
        if (val) {
          const targetTemplate = this.template_list.find((template) => template.id === val);
          this.content_form.notification.title = targetTemplate.title;
          this.content_form.notification.body = targetTemplate.body;
          this.content_form.notification.url = targetTemplate.url;
          this.content_form.notification.image = null;
          this.content_form.notification.localize = targetTemplate.localize_list;
        }
    },
  },
  watch: {
    // 获取内容部分数据
    currentPush: {
      async handler(newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          this.content_form.type = newVal.push_content.push_type;
          if (this.content_form.type === 'notification') {
            await this.fetchGroupList();
            if (newVal.push_content.notification.group === null) {
              Object.assign(this.content_form.notification, newVal.push_content.notification);
              // 因为detail接口返回的数据中，图片属性名为image
              this.content_form.notification.url = this.content_form.notification.image;
              this.content_form.notification.image = null;
              this.content_form.message.body = '';
            } else {
              this.content_form.notification.group = newVal.push_content.notification.group;
              this.content_form.notification.template = newVal.push_content.notification.template;
              this.editDisable = true;
              this.fetchTemplateList(this.content_form.notification.group, this.content_form.notification.template);
            }
          } else if (this.content_form.type === 'message') {
            this.content_form.notification = this.$options.data().content_form.notification;
            this.content_form.message.body = this.$tools.formatJSON(newVal.push_content.message); // 对 JSON 字符串格式化处理
          }
        } else {
          await this.fetchGroupList();
        }
      },
      immediate: true,
      deep: true,
    },
    'content_form.notification.image': async function (val) {
      if (val) {
        console.log(val);
        this.content_form.notification.url = await this.$uploadImage(val, this.$route.params.app_id);
      }
    },
    'content_form.type': (val) => {
      console.log(val);
    },
  },
};
</script>

<style scoped lang="less">
  .content-form {
    margin-bottom: 8px;
    padding: 0 24px;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    @import '../../../../assets/less/create_push_form/formContainer.less';
    /deep/ .ivu-btn-group {
      .create-temp-push-btn {
        padding: 0 8px;
        &:hover {
          color: @basicColor;
        }
      }
      .create-temp-push-btn[disabled] {
        &:hover {
          color: #c5c8ce;
        }
      }
      .show-temp-push-list-btn {
        padding: 0 4px;
        &:hover {
          color: @basicColor;
        }
      }
      .show-temp-push-list-btn[disabled] {
        &:hover {
          color: #c5c8ce;
        }
      }
    }
    .form-container {
      /deep/ .image-url-input-box {
        .input-text {
          margin-right: 4px;
        }
        .url-input {
          display: inline-block;
          width: calc(~'100% - 64px');
        }
      }
    }
    .params-box {
      flex: 1;
      .params-value-box {
        display: flex;
        align-items: center;
        &:nth-child(n+2) {
          margin-top: 4px;
        }
      }
      .params-close-icon, .params-value {
        margin-left: 4px;
      }
      .params-close-icon, .params-add {
        cursor: pointer;
      }
      .prohibit-params-add {
        cursor: not-allowed;
        font-size: 14px;
        color: #2978FF;
      }
      .params-add {
        font-size: 14px;
        color: #2978FF;
      }
      .params-add-icon {
        margin-right: 4px;
      }
    }

  }
</style>
