/* eslint-disable no-unused-vars */
import requestFactory from './requestFactory';
import urls from './urls';
import toolFunctions from '../share/toolFunctions';
import groupApi from './groupApi';
import testMachineApi from './testMachineApi';
import rotationApi from './rotationApi';
import dataAnalysis from './dataAnanlysis';

const GET = 'get';
const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';
const getRequest = requestFactory(GET);
const postRequest = requestFactory(POST);
const putRequest = requestFactory(PUT);
const deleteRequest = requestFactory(DELETE);

const { requestURLParamParser } = toolFunctions;

export default {
  getAppList: () => getRequest(urls.appListURL),
  addApp: (params) => postRequest(urls.addAppURL, params),
  updateApp: (params) => postRequest(urls.updateAppURL, params),
  deleteApp: (params) => deleteRequest(urls.deleteAppURL, params),
  getAppDetail: (urlParams) => {
    const newURL = requestURLParamParser(urls.appDetailURL, urlParams);
    return getRequest(newURL);
  },
  getAppInfo: (urlParams) => {
    const newURL = requestURLParamParser(urls.appInfoURL, urlParams);
    return getRequest(newURL);
  },
  getAppUserGroups: (urlParams) => {
    const newURL = requestURLParamParser(urls.getAppUserGroupsURL, urlParams);
    return getRequest(newURL);
  },

  getTaskList: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.taskListURL, urlParams);
    return getRequest(newURL, params);
  },
  getTaskDetail: (urlParams) => {
    const newURL = requestURLParamParser(urls.taskDetailURL, urlParams);
    return getRequest(newURL);
  },
  addTask: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.addTaskURL, urlParams);
    return postRequest(newURL, params);
  },
  updateTask: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.updateTaskURL, urlParams);
    return putRequest(newURL, params);
  },
  pauseTask: (urlParams) => {
    const newURL = requestURLParamParser(urls.pauseTaskURL, urlParams);
    return postRequest(newURL);
  },
  deleteTask: (urlParams) => {
    const newURL = requestURLParamParser(urls.deleteTaskURL, urlParams);
    return postRequest(newURL);
  },
  recoverTask: (urlParams) => {
    const newURL = requestURLParamParser(urls.recoverTaskURL, urlParams);
    return postRequest(newURL);
  },
  updateTaskStatus: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.updateTaskStatusURL, urlParams);
    return putRequest(newURL, params);
  },
  getTempTaskList: (urlParams) => {
    const newURL = requestURLParamParser(urls.tempTaskListURL, urlParams);
    return getRequest(newURL);
  },
  getTempTaskDetail: (urlParams) => {
    const newURL = requestURLParamParser(urls.tempTaskDetailURL, urlParams);
    return getRequest(newURL);
  },
  addTempTask: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.addTempTaskURL, urlParams);
    return postRequest(newURL, params);
  },
  updateTempTask: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.updateTempTaskURL, urlParams);
    return postRequest(newURL, params);
  },
  pauseTempTask: (urlParams) => {
    const newURL = requestURLParamParser(urls.pauseTempTaskURL, urlParams);
    return postRequest(newURL);
  },
  addMessageTempTask: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.addMessageTempTaskURL, urlParams);
    return postRequest(newURL, params);
  },
  pauseMessageTempTask: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.pauseMessageTempTaskURL, urlParams);
    return putRequest(newURL, params);
  },
  getBiApps: () => {
    const newURL = requestURLParamParser(urls.biApps);
    return getRequest(newURL);
  },
  fileUpload: (params) => postRequest(urls.fileUploadURL, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  getEstimationsData: (urlParams, params) => {
    const newURL = requestURLParamParser(urls.getEstimations, urlParams);
    return postRequest(newURL, params);
  },

  translate: (url, params) => postRequest(url, params, { withCredentials: false }),
  ...groupApi,
  ...rotationApi,
  ...testMachineApi,
  ...dataAnalysis,
};
