<template>
  <div class="create-temp-push">
    <div class="content-header">
      <Breadcrumb>
        <BreadcrumbItem :to="{ name: 'Push' }">Push 列表</BreadcrumbItem>
        <BreadcrumbItem :to="{ name: 'PushDetail', params: { app_id: $route.params.app_id,task_id: $route.params.task_id }}">{{ task_name }}</BreadcrumbItem>
        <BreadcrumbItem :to="{ name: 'TempPush' }" v-if="tempPushFormStatus === 'edit'">临时推送列表</BreadcrumbItem>
        <BreadcrumbItem>{{ tempPushFormTitle }}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="push-form">
      <div class="main-form">
        <div class="left-form form">
          <temp-name-form ref="tempNameForm" class="temp-name-form"></temp-name-form>
          <temp-content-form ref="tempContentForm" :push_schedule="push_schedule"></temp-content-form>
        </div>
        <div class="right-form form">
          <div class="wrap">
            <test-machine-form
              v-if="false"
              push_type="notification"
              :notification_data="content_form_data"
              @send-trigger="sendTestHandler"
              ></test-machine-form>
            <rendering-box
              push_type="notification"
              :notification_data="content_form_data"
              :height="460"></rendering-box>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-box">
      <Button type="primary" class="save-btn submit-btn" @click="saveHandler">
        <Icon type="md-open" class="save-icon"/>
        <span class="save-text">保存</span>
      </Button>
      <Button class="submit-btn" @click="cancelHandler">取消</Button>
    </div>
  </div>
</template>

<script>
  /* eslint-disable camelcase */

  import TempNameForm from '../../../../components/page/content/create_temp_push_form/TempNameForm.vue';
import TestMachineForm from '../../../../components/page/content/create_push_form/TestMachineForm.vue';
import RenderingBox from '../../../../components/page/content/create_push_form/RenderingBox.vue';
import TempContentForm
  from '../../../../components/page/content/create_temp_push_form/TempContentForm.vue';

export default {
  name: 'CreateTempPush',
  components: {
    TempContentForm,
    RenderingBox,
    TempNameForm,
    TestMachineForm,
  },
  data() {
    return {
      content_form_data: {},
      name_form_data: {},
      task_name: '',
      push_schedule: {},
    };
  },
  computed: {
    nameForm() {
      return this.$refs.tempNameForm.$refs.tempNameForm;
    },
    contentForm() {
      return this.$refs.tempContentForm.$refs.tempContentForm;
    },
    formList() {
      return [
        this.nameForm,
        this.contentForm,
      ];
    },
    tempPushFormStatus() {
      return this.$store.state.temp_push_form_status;
    },
    tempPushFormTitle() {
      return this.tempPushFormStatus === 'create'
        ? '创建临时推送'
        : '编辑临时推送';
    },
  },
  methods: {
    validateForm(form) { // 验证单个表单，返回Promise实例
      return form.validate((valid) => valid);
    },
    validatePage(callback) {
      const promiseArr = [];
      this.formList.forEach((form) => {
        const promise = this.validateForm(form);
        promiseArr.push(promise);
      });
      Promise.all(promiseArr).then((values) => {
        console.log(values);
        if (values.every((value) => value)) {
          // 如果所有表单都验证通过，则执行回调函数
          callback.call(this);
        } else {
          this.$Message.warning('请将表单填写完整');
        }
      });
    },
    packUpParams() {
      const {
        group: group_id,
        template: next_display,
        start_time: begin_time,
        end_time,
      } = this.content_form_data;
      const {
        name,
      } = this.name_form_data;
      const pushInfo = {
        name,
        task_id: this.$route.params.task_id,
        group_id,
        next_display,
        begin_time: this.$tools.dateTimeFormatter(begin_time),
        end_time: this.$tools.dateTimeFormatter(end_time),
      };
      return pushInfo;
    },
    saveTempPushInfo(tempPushInfo) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
        temp_task_id: this.tempPushFormStatus === 'create' ? undefined : this.$store.state.current_temp_push.id,
      };
      if (this.$tools.deepCompare(tempPushInfo, this.$store.state.current_temp_push)) { // 没有更改
        this.$Message.warning('未发生修改');
        return null;
      }
      const params = {
        ...tempPushInfo,
      };
      const api = this.tempPushFormStatus === 'create' ? this.$api.addTempTask : this.$api.updateTempTask;
      const successMsg = this.tempPushFormStatus === 'create'
        ? '新建临时推送成功'
        : '更新临时推送成功';
      const errorMsg = this.tempPushFormStatus === 'create'
        ? '新建临时推送失败'
        : '更新临时推送失败';
      this.$Message.success('正在保存...');
      const promise = api(urlParams, params)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success' || result === 'modify success') {
            this.$Message.success(successMsg);
            this.$router.push({
              name: 'TempPush',
            });
            return null;
          }
          return Promise.reject(new Error(errorMsg));
        }).catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    save() {
      const tempPushInfo = this.packUpParams();
      const promise = this.saveTempPushInfo(tempPushInfo);
      return promise;
    },
    saveHandler() {
      this.validatePage(this.save);
    },
    cancelHandler() {
      // 跳转到临时推送列表
      this.$router.push({
        name: 'TempPush',
      });
    },
    fetchTaskDetail() {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
      };
      const promise = this.$api.getTaskDetail(urlParams);
      promise.then((response) => {
        if (response.data.task) {
          this.task_name = response.data.task.task_name;
          this.push_schedule = { ...response.data.task.push_schedule };
        }
      });
    },
    // sendTestHandler(deviceToken, pushType) { // 发送测试
    //   const urlParams = {
    //     app_id: this.$route.params.app_id,
    //   };
    //   let pushContent = {};
    //   let api;
    //   if (pushType === 'notification') {
    //     pushContent = {
    //       title: this.content_form_data.title,
    //       body: this.content_form_data.body,
    //       image: this.content_form_data.url
    //         || (this.content_form_data.image && this.$tools.imageFileToURL(this.content_form_data.image))
    //         || undefined,
    //     };
    //     api = this.$api.sendNotificationTest;
    //   }
    //   if (pushType === 'message') {
    //     pushContent = {
    //       ...this.content_form_message_data,
    //     };
    //     api = this.$api.sendMessageTest;
    //   }
    //   const params = {
    //     push_content: pushContent,
    //     device_token: deviceToken,
    //   };
    //   const promise = api(urlParams, params)
    //     .then((response) => {
    //       const { result } = response.data;
    //       if (result === 'success') {
    //         this.$Message.success('测试发送成功');
    //         return null;
    //       }
    //       return Promise.reject(new Error('测试发送失败'));
    //     })
    //     .catch((error) => {
    //       this.$Message.error(error.message);
    //     });
    //   return promise;
    // },
  },
  mounted() {
    this.fetchTaskDetail();
    this.$watch(
      '$refs.tempNameForm.temp_name_form',
      (val) => {
        this.name_form_data = val;
      },
      {
        immediate: true,
        deep: true,
      },
    );
    this.$watch(
      '$refs.tempContentForm.temp_content_form',
      (val) => {
        this.content_form_data = val;
      },
      {
        immediate: true,
        deep: true,
      },
    );
  },
};
</script>

<style scoped lang="less">
  .create-temp-push {
    padding-bottom: 36px;
    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      background: transparent;
      /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-link, /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-separator {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .push-form {
      background-color: @contentBackgroundColor;
      .form {
        background: #FFFFFF;
        border-radius: 4px;
      }
      .main-form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .left-form {
          flex: 0 1 calc(~'50% - 4px');
          .temp-name-form {
            margin-bottom: -24px;
          }
        }
        .right-form {
          flex: 0 1 calc(~'50% - 4px');
          display: flex;
          justify-content: center;
          padding: 82px 0 36px;
          /*布局需要改进*/
          .wrap {
            transform: translateX(-55px);
          }
        }
      }
    }
    .submit-box {
      position: fixed;
      left: @sidebarWidth;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
      .save-btn {
        .save-icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          vertical-align: middle;
        }
        .save-text {
          line-height: 16px;
          vertical-align: middle;
        }
      }
      .submit-btn {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
