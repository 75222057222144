<template>
  <div class="translation-and-template-button" @click="trigger">
    <Icon class="add-icon" type="md-add" :size="16"/>
    <span class="add-text"><slot/></span>
  </div>
</template>

<script>
export default {
  name: 'TranslationAndTemplateButton',
  methods: {
    trigger() {
      this.$emit('trigger');
    },
  },
};
</script>

<style scoped lang="less">
  .translation-and-template-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    border: 1px dashed #D5DBE6;
    border-radius: 4px;
    transition: border 0.2s ease-in-out;
    cursor: pointer;
    .add-icon {
      margin-right: 8px;
      color: #D5DBE6;
    }
    .add-text {
      font-size: 14px;
      font-weight: 400;
      color: #D5DBE6;
      line-height: 22px;
    }
    .add-icon, .add-text {
      transition: color 0.2s ease-in-out;
    }
    &:hover {
      border: 1px dashed @basicColor;
      .add-icon, .add-text {
        color: @basicColor;
      }
    }
  }
</style>
