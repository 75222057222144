export default {
  data() {
    return {
      isFullscreen: false,
    };
  },
  created() {
    document.addEventListener('keyup', this.keyupHandler);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyupHandler);
  },
  methods: {
    handleFullscreenChange() {
      this.isFullscreen = !this.isFullscreen;
    },
    keyupHandler(event) {
      if (event.code === 'Escape') {
        this.isFullscreen = false;
      }
    },
  },
};
