<template>
  <div class="name-form">
    <form-content-header title="名称"/>
    <div class="form-container">
      <Form ref="nameForm" :model="name_form" :rules="name_form_rules" :label-width="110">
        <FormItem label="名称" prop="name">
          <Input v-model="name_form.name" placeholder="请输入名称" clearable/>
        </FormItem>
<!--        <FormItem label="自动" prop="auto">-->
<!--          <i-switch v-model="name_form.auto"></i-switch>-->
<!--        </FormItem>-->
      </Form>
    </div>
  </div>
</template>

<script>
import FormContentHeader from '../../../common/form-content-header.vue';

export default {
  name: 'NameForm',
  components: { FormContentHeader },
  props: {
    current_push: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name_form: {
        name: '',
        auto: false, // v2.1 暂时隐藏
      },
      name_form_rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    current_push: {
      handler(newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          this.name_form.name = newVal.task_name;
          this.name_form.auto = !!newVal.push_content.auto;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
  .name-form {
    margin-bottom: 8px;
    padding: 0 24px;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    @import '../../../../assets/less/create_push_form/formContainer.less';
  }
</style>
