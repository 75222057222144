<template>
  <div class="temp-push">
    <div class="content-header">
      <Breadcrumb>
        <BreadcrumbItem :to="{ name: 'Push' }">Push 列表</BreadcrumbItem>
        <BreadcrumbItem :to="{ name: 'PushDetail', params: { app_id: $route.params.app_id,task_id: $route.params.task_id } }">{{ task_name }}</BreadcrumbItem>
        <BreadcrumbItem>临时推送列表</BreadcrumbItem>
      </Breadcrumb>
      <div class="right-box">
        <Button type="primary" :disabled="!(tempPushButtonGroupVisible && !temp_task)" icon="md-add" @click="createPushTrigger">创建临时推送</Button>
      </div>
    </div>
    <div class="table-container">
      <div class="table-wrap" ref="tempPushListWrap">
        <Table v-show="table_data.length > 0"
               :columns="columns"
               :data="table_data"
               ref="tempPushList">
          <template slot-scope="{ row }" slot="name">
            <span class="group-name" style="font-weight: 600;">{{ row.name }}</span>
          </template>
          <template slot-scope="{ row }" slot="status">
            <span class="status-content">
              <span class="dot" :style="{ 'background-color': statusMap[row.status].color }"></span>
              <span class="status-text" :style="{ 'color': statusMap[row.status].color }">{{ statusMap[row.status].label }}</span>
            </span>
          </template>
          <template slot-scope="{ row }" slot="operation">
            <div :style="{ 'text-align': 'right' }">
              <span class="operation-btn" @click="editItemHandler(row)">编辑</span>
              <span v-if="row.status !== 3" class="operation-text">|</span>
              <span v-if="row.status !== 3" class="operation-btn" @click="pauseItemHandler(row)">停用</span>
<!--              <span v-else class="operation-btn" @click="deleteItemHandler(row)">删除</span>-->
            </div>
          </template>
        </Table>
        <div v-show="table_data.length > 0" class="page-box">
          <Page :total="table_data.length" :current.sync="page_num" :page-size="page_size" show-sizer/>
        </div>
        <empty-content v-show="table_data.length === 0"/>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import EmptyContent from '../../../../components/common/EmptyContent.vue';
import TempPushListConfig from '../../../../push-common/page_config/TempPushListConfig.json';

export default {
  name: 'TempPushList',
  components: { EmptyContent },
  data() {
    return {
      table_data: [],
      columns: [],
      page_num: 1,
      page_size: 20,
      column_config: TempPushListConfig.column_config,
      statusMap: {
        1: {
          label: '待开始',
          color: '#2978FF',
        },
        2: {
          label: '运行中',
          color: '#00C371',
        },
        3: {
          label: '已结束',
          color: '#BBBCC9',
        },
      },
      current_temp_push: null,
      task_name: '',
      task_status: '',
      schedule_type: '',
      temp_task: [],
    };
  },
  computed: {
    // currentPushName() { // 当前所在push的名称
    //   return this.$store.state.current_push_task_name;
    // },
    tempPushButtonGroupVisible() {
      return this.task_status === 'edit' && this.schedule_type === 'periodic_time';
    },
  },
  methods: {
    fetchTaskDetail() {
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
      };
      const promise = this.$api.getTaskDetail(urlParams);
      promise.then((response) => {
        if (response.data.task) {
          this.task_name = response.data.task.task_name;
          this.task_status = response.data.task.task_status === 4 ? 'edit' : 'create';
          this.schedule_type = response.data.task.push_schedule.schedule_type;
        }
      });
    },
    fetchTempPushList() {
      const loader = this.$loader(this.$refs.tempPushListWrap).show();
      // this.resetTableData();
      const urlParams = {
        app_id: this.$route.params.app_id,
        task_id: this.$route.params.task_id,
      };
      const promise = this.$api.getTempTaskList(urlParams);
      promise.then((response) => {
        const { header, list } = response.data;
        if (header && list) {
          this.configTable(header, list);
          this.temp_task = list.find((item) => item.status === 2);
          return null;
        }
        return Promise.reject(new Error('临时推送数据为空'));
      }).catch((error) => {
        console.log(error);
        this.$Message.error(error.message);
      }).finally(() => {
        loader.hide();
      });
    },
    configTable(header, records) {
      this.columns = this.configColumns(header);
      this.table_data = this.configTableData(records);
    },
    configColumns(header) {
      const columns = [];
      const newHeader = [...header, 'operation']; // 在末尾增加操作字段
      newHeader.forEach((key) => {
        const keyConfig = this.column_config[key];
        console.log(key, keyConfig);
        const column = {
          key,
          title: keyConfig.label || key,
          fixed: keyConfig.fixed || null,
          minWidth: keyConfig.minWidth || this.column_config.minWidth,
          maxWidth: keyConfig.maxWidth || this.column_config.maxWidth,
          align: keyConfig.align || this.column_config.align,
          slot: keyConfig.slot || null,
        };
        columns.push(column);
      });
      return columns;
    },
    configTableData(records) {
      return records || [];
    },
    resetTableData() { // 清空table数据
      this.table_data = [];
    },
    fetchTempPushDetail(tempTaskId, callback) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        temp_task_id: tempTaskId,
      };
      const promise = this.$api.getTempTaskDetail(urlParams)
        .then((response) => {
          const { result, data } = response.data;
          if (result === 'success') {
            this.current_temp_push = { ...data };
            callback.call(this);
            return null;
          }
          return Promise.reject(new Error('临时推送详情获取失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    createPushTrigger() {
      this.$store.commit('setCurrentTempPush', {
        current_temp_push: null,
      });
      this.$store.commit('setTempPushFormStatus', {
        temp_push_form_status: 'create',
      });
      this.$router.push({
        // 沿用当前的params
        name: 'CreateTempPush',
      });
    },
    editItemHandler(row) {
      const callback = function () {
        // 保存当前选中的临时Push详细信息
        this.$store.commit('setCurrentTempPush', {
          current_temp_push: this.current_temp_push,
        });
        // 设置TempPush表单为编辑模式
        this.$store.commit('setTempPushFormStatus', {
          temp_push_form_status: 'edit',
        });
        this.$router.push({
          name: 'CreateTempPush',
        });
      };
      this.fetchTempPushDetail(row.rid, callback);
    },
    pauseItemHandler(tempPushItem, index) {
      this.$Modal.confirm({
        title: '确定停用该临时推送吗？',
        content: null,
        onOk: () => {
          this.pauseItem(tempPushItem, index);
        },
        onCancel: () => {},
      });
    },
    pauseItem(tempPushItem, index) {
      const urlParams = {
        app_id: this.$route.params.app_id,
        temp_task_id: tempPushItem.rid,
      };
      const promise = this.$api.pauseTempTask(urlParams)
        .then((response) => {
          const { result } = response.data;
          if (result === 'success') {
            this.$Message.success('临时推送停用成功');
            // 重新获取临时推送列表
            return this.fetchTempPushList();
          }
          return Promise.reject(new Error('临时应用停用失败'));
        })
        .catch((error) => {
          this.$Message.error(error.message);
        });
      return promise;
    },
    deleteItemHandler() {},
  },
  async mounted() {
    await this.fetchTaskDetail();
    this.fetchTempPushList();
  },
};
</script>

<style scoped lang="less">
  .temp-push {
    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0;
      background: transparent;
      /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-link, /deep/ .ivu-breadcrumb .ivu-breadcrumb-item-separator {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .table-container {
      padding: 24px;
      background: #FFFFFF;
      border-radius: 4px;
      .table-wrap {
        position: relative;
        min-height: 180px;
      }
      /deep/ .ivu-table {
        .status-content {
          .dot, .status-text {
            vertical-align: middle;
          }
          .dot {
            display: inline-block;
            width: 6px;
            height: 6px;
            line-height: 6px;
            margin-right: 6px;
            border-radius: 50%;
          }
          .status-text {
            font-size: 14px;
            font-weight: 400;
            color: #00C371;
            line-height: 20px;
          }
        }
        .operation-btn, .operation-text {
          vertical-align: middle;
        }
        .operation-btn {
          margin: 0 6px;
          font-size: 14px;
          font-weight: 400;
          color: #2978FF;
          line-height: 20px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
        .operation-text {
          width: 1px;
          height: 12px;
          line-height: 12px;
          color: #EAEBF7;
        }
      }
      .page-box {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
      }
    }
  }
</style>
