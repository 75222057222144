import axios from './index';

const GET = 'get';
const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';

const getRequest = (url, params = {}) => axios.get(url, { params });
const postRequest = (url, params = {}, config = {}) => axios.post(url, params, config);
const putRequest = (url, params = {}) => axios.put(url, params);
const deleteRequest = (url, params = {}) => axios.delete(url, { data: params });

export default function (method) {
  if (method === GET) return getRequest;
  if (method === POST) return postRequest;
  if (method === PUT) return putRequest;
  if (method === DELETE) return deleteRequest;
  throw new Error('不存在该请求方法函数');
}
