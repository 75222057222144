<template>
  <div  v-show="addLanguageMode"
        v-bi-click-outside.stop="cancelHandler"
        class="add-language-select">
    <div  class="complex-multiple-select-modal"
          @click.stop="handler">
      <div class="modal-main">
        <div class="modal-left">
          <div class="search-box">
            <Input v-model="search_content"
                   placeholder="搜索"
                    @on-change="search"/>
          </div>
          <div class="select-options">
            <div>
              <RadioGroup  v-model="check_option">
                <Radio :label="0">include</Radio>
                <Radio :label="1">exclude</Radio>
              </RadioGroup>
            </div>
            <span class="check-all" @click="checkAll">{{ check_all_text }}</span>
          </div>
          <div ref="checkbox_box" class="checkbox-box">
            <CheckboxGroup ref="checkbox_group" class="checkbox-group" v-model="checked_group">
              <Checkbox class="checkbox"
                        v-for="(item, index) in search_items"
                        :key="`${item.value}-${index}`"
                        :label="item.label">
                <span class="label-text">{{item.label}}</span>
              </Checkbox>
            </CheckboxGroup>
          </div>
        </div>
        <div class="modal-right">
          <div class="checked-group-overview">
              <span class="text">
                已选择{{ checked_group.length }}项 (共{{ language_list.length }}项)
              </span>
            <span class="clear-button" @click="clearCheckedGroup">清空</span>
          </div>
          <ul class="checked-group-list">
            <li v-for="item in checked_group"
                :key="item"
                class="checked-group-item">
              <div class="name">{{ item }}</div>
              <img class="delete-button"
                   src="../../assets/image/ic_close.png"
                   alt="Close"
                   @click="deleteCheckedItem(item)">
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <Button class="cancel" @click="cancelHandler">取消</Button>
        <Button type="primary" @click="confirmHander">确认</Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'addLanguageSelect',
  props: {
    language_list: Array,
    addLanguageMode: Boolean,
  },
  data() {
    return {
      search_content: '',
      check_option: 0,
      check_all_text: '全选',
      checked_group: [],
      search_items: [],
    };
  },
  methods: {
    checkAll() {
      if (this.check_all_text === '全选') {
        const arr = this.search_items.map((val) => val.value);
        this.checked_group = [...arr];
      } else {
        this.checked_group = [];
      }
    },
    clearCheckedGroup() {
      this.checked_group = [];
    },
    deleteCheckedItem(item) {
      const index = this.checked_group.findIndex((element) => element === item);
      this.checked_group.splice(index, 1);
    },
    handler() {},
    cancelHandler() {
      this.$emit('close');
    },
    confirmHander() {
      const val = this.check_option ? this.language_list.filter((item) => !this.checked_group.includes(item.value)).map((item) => item.label) : [...this.checked_group];
      this.$emit('confirm', val);
      this.checked_group = [];
    },
    search() {
      this.noContextText = '暂无数据';
      this.$refs.checkbox_box.scrollTop = 0;
      if (this.search_content === '') {
        this.search_items = [...this.language_list];
      } else {
        // 先进行精确查询，再进行模糊查询
        const preciseSeachItems = this.language_list.filter((val) => {
          const contentInLowerCase = this.search_content.toLowerCase();
          return val.value.toLowerCase() === contentInLowerCase || val.label.toLowerCase() === contentInLowerCase;
        });
        const fuzzySearchItems = this.language_list.filter((val) => {
          const contentInLowerCase = this.search_content.toLowerCase();
          // label & value
          return (
            val.value.toLowerCase().indexOf(contentInLowerCase) !== -1
            || val.label.toLowerCase().indexOf(contentInLowerCase) !== -1
          );
        });
        const filteredFuzzySearchItems = fuzzySearchItems.filter((val) => {
          if (preciseSeachItems.indexOf(val) === -1) return true;
          return false;
        });
        // 数组去重
        this.search_items = [...preciseSeachItems, ...filteredFuzzySearchItems];
      }
    },
  },
  watch: {
    language_list: {
      handler(val) {
        this.search_items = [...val];
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
.add-language-select {
  color: #333;
  position: absolute;
  top:-240px;
  right: 0;
  z-index: 99;
  .complex-multiple-select-modal {
    width: 100%;
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #e8eaec;
    box-shadow: 0 0.6px 6px rgba(0, 0, 0, 0.18);
    .modal-main {
      display: flex;
      border-bottom: 1px solid #dcdee2;
    }

    .modal-left {
      width: 300px;
      height: 248px;
      border-right: 1px solid #dcdee2;
      .search-box {
        margin: 10px;
      }

      .select-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px 10px;
        height: 22px;
        .check-all {
          color: #2F54EB;
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
        }
      }

      .checkbox-box {
        height: calc(~'100% - 87px');
        padding: 4px 0;
        border-top: 1px solid #d1d1d1;
        overflow-y: auto;

        .checkbox {
          display: block;
          padding: 8px;
          line-height: 100%;
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
          .label-text {
            word-break: break-all;
          }
        }
      }
    }

    .modal-right {
      width: 300px;
      height: 248px;

      .checked-group-overview {
        display: flex;
        justify-content: space-between;
        height: 48px;
        line-height: 48px;
        padding: 0 10px;

        .text, .clear-button {
          color: #999999;
          font-size: 12px;
          font-weight: 400;
        }

        .clear-button {
          color: #2F54EB;
          cursor: pointer;
          img {
            width: 12px;
          }
        }
      }

      .checked-group-list {
        width: 100%;
        height: calc(~'100% - 48px');
        padding: 4px 0;
        border-top: 1px solid #d1d1d1;
        overflow-y: auto;

        .checked-group-item {
          /*width: 100%;*/
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px;
          cursor: pointer;
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
          .name {
            flex: 0 0 90%;
            word-break: break-all;
          }
          .delete-button {
            flex: 0 0 10px;
            height: 10px;
            transform-origin: center;
            transition: transform 0.2s ease-in-out;
            &:hover {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .modal-footer {
      min-height: 36px;
      padding: 8px;
      display: flex;
      justify-content: flex-end;
      .cancel {
        margin-right: 8px;
      }
    }
  }
}
</style>
